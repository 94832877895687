@font-face {
    font-family: 'infinitye';
    src:  url('/styelSheets/sass/fonts/infinitye/infinitye.eot?17jtqf');
    src:  url('styelSheets/sass/fonts/infinitye/infinitye.eot?17jtqf#iefix') format('embedded-opentype'),
      url('styelSheets/sass/fonts/infinitye/infinitye.ttf?17jtqf') format('truetype'),
      url('styelSheets/sass/fonts/infinitye/infinitye.woff?17jtqf') format('woff'),
      url('styelSheets/sass/fonts/infinitye/infinitye.svg?17jtqf#infinitye') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'infinitye' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-sessions-history:before {
    content: "\e90f";
    color: #474747;
  }
  .icon-sessions:before {
    content: "\e900";
    color: #474747;
  }
  .icon-dashboard:before {
    content: "\e901";
  }
  .icon-contact:before {
    content: "\e902";
  }
  .icon-stations:before {
    content: "\e903";
  }
  .icon-cards:before {
    content: "\e904";
  }
  .icon-user-profile:before {
    content: "\e905";
  }
  .icon-requests:before {
    content: "\e906";
  }
  .icon-coin:before {
    content: "\e907";
  }
  .icon-gov:before {
    content: "\e908";
  }
  .icon-car-brands:before {
    content: "\e909";
  }
  .icon-station:before {
    content: "\e90a";
  }
  .icon-battery:before {
    content: "\e90b";
  }
  .icon-masterdata:before {
    content: "\e90c";
  }
  .icon-roles:before {
    content: "\e90d";
  }
  .icon-car-types:before {
    content: "\e90e";
  }

  /** Hide pip button in firefox*/

  .controlsOverlay {
    display: none !important;
  }