@font-face {
    font-family: 'infinitye';
    src:  url('/styelSheets/sass/fonts/infinitye/infinitye.eot?17jtqf');
    src:  url(/static/media/infinitye.d4f871ee.eot#iefix) format('embedded-opentype'),
      url(/static/media/infinitye.b74597a7.ttf) format('truetype'),
      url(/static/media/infinitye.ec68fb6f.woff) format('woff'),
      url(/static/media/infinitye.bc90b282.svg#infinitye) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'infinitye' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-sessions-history:before {
    content: "\e90f";
    color: #474747;
  }
  .icon-sessions:before {
    content: "\e900";
    color: #474747;
  }
  .icon-dashboard:before {
    content: "\e901";
  }
  .icon-contact:before {
    content: "\e902";
  }
  .icon-stations:before {
    content: "\e903";
  }
  .icon-cards:before {
    content: "\e904";
  }
  .icon-user-profile:before {
    content: "\e905";
  }
  .icon-requests:before {
    content: "\e906";
  }
  .icon-coin:before {
    content: "\e907";
  }
  .icon-gov:before {
    content: "\e908";
  }
  .icon-car-brands:before {
    content: "\e909";
  }
  .icon-station:before {
    content: "\e90a";
  }
  .icon-battery:before {
    content: "\e90b";
  }
  .icon-masterdata:before {
    content: "\e90c";
  }
  .icon-roles:before {
    content: "\e90d";
  }
  .icon-car-types:before {
    content: "\e90e";
  }

  /** Hide pip button in firefox*/

  .controlsOverlay {
    display: none !important;
  }
/*!
 * 
 * antd v3.15.2
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-no-unknown */
:root {
  --primary: #16adb8; 
}
html,
body {
  width: 100%;
  max-height: 100%;
} 
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color:  #16adb8;
  color:  var(--primary);
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects; 
}
a:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
::selection {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  content: '';
  display: table;
}
.clearfix::after {
  clear: both;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  display: block;
  border: 0 solid  #16adb8;
  border: 0 solid  var(--primary);
  border-radius: inherit;
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  pointer-events: none;
  content: '';
}
@-webkit-keyframes waveEffect {
  100% {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px;
  }
}
@keyframes waveEffect {
  100% {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px;
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
.swing-enter,
.swing-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.swing-enter.swing-enter-active,
.swing-appear.swing-appear-active {
  -webkit-animation-name: antSwingIn;
          animation-name: antSwingIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
@keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse {
  overflow: hidden;
}
.ant-motion-collapse-active {
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
  position: fixed;
  z-index: 10;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 8px 15px 8px 37px;
  border-radius: 4px;
}
.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert.ant-alert-closable {
  padding-right: 30px;
}
.ant-alert-icon {
  position: absolute;
  top: 11.5px;
  left: 16px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
}
.ant-alert-error .ant-alert-icon {
  color: #f5222d;
}
.ant-alert-close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  position: absolute;
  right: 16px;
}
.ant-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 64px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  border-radius: 4px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
.ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-close {
  height: 0 !important;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert-slide-up-leave {
  -webkit-animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
@-webkit-keyframes antAlertSlideUpIn {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antAlertSlideUpIn {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antAlertSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antAlertSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #e8e8e8;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid  #16adb8;
  border: 2px solid  var(--primary);
  border-radius: 8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: top 0.3s ease-in-out;
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-select-auto-complete.ant-select .ant-select-selection {
  border: 0;
  box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  line-height: 32px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-right: 12px;
  margin-left: 12px;
}
.ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}
.ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: left;
}
.ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  height: 32px;
  line-height: 1.5;
  background: transparent;
  border-width: 1px;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  background-color: transparent;
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 40px;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
  height: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: 24px;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
  height: 24px;
  padding-top: 1px;
  padding-bottom: 1px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-selection:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-selection__clear::before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
}
.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 30px;
}
.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  pointer-events: none;
  content: '.';
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
  height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin: 0 7px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -7px; 
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap; 
  text-align: left;
  text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 4px;
  outline: 0;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  content: '';
  display: table;
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}
.ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
  padding-right: 16px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-select-dropdown-menu-item:last-child {
  border-radius: 0 0 4px 4px;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-select-dropdown-menu-item-active {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color:  #16adb8;
  color:  var(--primary);
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-description {
  margin: 0;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-small {
  margin: 8px 0;
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-normal {
  margin: 32px 0;
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  margin: -1px;
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  float: left;
  width: 100%;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  content: '';
  display: table;
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-affix-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.5;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input-password-icon {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: #333;
}
.ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.ant-input-search-enter-button input {
  border-right: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-btn > .anticon {
  /* line-height: 1; */
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color:  #16adb8;
  color:  var(--primary);
  background-color: #fff;
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active,
.ant-btn.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
.ant-btn:active > a:only-child,
.ant-btn.active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after,
.ant-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-disabled > a:only-child,
.ant-btn.disabled > a:only-child,
.ant-btn[disabled] > a:only-child,
.ant-btn-disabled:hover > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn-disabled:focus > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn-disabled:active > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn-disabled.active > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-disabled > a:only-child::after,
.ant-btn.disabled > a:only-child::after,
.ant-btn[disabled] > a:only-child::after,
.ant-btn-disabled:hover > a:only-child::after,
.ant-btn.disabled:hover > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn-disabled:focus > a:only-child::after,
.ant-btn.disabled:focus > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn-disabled:active > a:only-child::after,
.ant-btn.disabled:active > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after,
.ant-btn-disabled.active > a:only-child::after,
.ant-btn.disabled.active > a:only-child::after,
.ant-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > i,
.ant-btn > span {
  display: inline-block;
  pointer-events: none;
}
.ant-btn-primary {
  color: #fff;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary:active > a:only-child,
.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after,
.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary-disabled > a:only-child,
.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color:  #16adb8;
  border-right-color:  var(--primary);
  border-left-color:  #16adb8;
  border-left-color:  var(--primary);
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color:  #16adb8;
  border-right-color:  var(--primary);
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color:  #16adb8;
  border-left-color:  var(--primary);
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color:  #16adb8;
  color:  var(--primary);
  background-color: transparent;
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9;
}
.ant-btn-ghost:active > a:only-child,
.ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after,
.ant-btn-ghost.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost-disabled,
.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost-disabled > a:only-child,
.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost-disabled:hover > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost-disabled:focus > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost-disabled:active > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost-disabled.active > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost-disabled > a:only-child::after,
.ant-btn-ghost.disabled > a:only-child::after,
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost-disabled:hover > a:only-child::after,
.ant-btn-ghost.disabled:hover > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost-disabled:focus > a:only-child::after,
.ant-btn-ghost.disabled:focus > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost-disabled:active > a:only-child::after,
.ant-btn-ghost.disabled:active > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after,
.ant-btn-ghost-disabled.active > a:only-child::after,
.ant-btn-ghost.disabled.active > a:only-child::after,
.ant-btn-ghost[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color:  #16adb8;
  color:  var(--primary);
  background-color: #fff;
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
.ant-btn-dashed:active > a:only-child,
.ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after,
.ant-btn-dashed.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed-disabled,
.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed-disabled > a:only-child,
.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed-disabled:hover > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed-disabled:focus > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed-disabled:active > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed-disabled.active > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed-disabled > a:only-child::after,
.ant-btn-dashed.disabled > a:only-child::after,
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed-disabled:hover > a:only-child::after,
.ant-btn-dashed.disabled:hover > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed-disabled:focus > a:only-child::after,
.ant-btn-dashed.disabled:focus > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed-disabled:active > a:only-child::after,
.ant-btn-dashed.disabled:active > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after,
.ant-btn-dashed-disabled.active > a:only-child::after,
.ant-btn-dashed.disabled.active > a:only-child::after,
.ant-btn-dashed[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #f5222d;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-btn-danger:hover > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:focus {
  color: #ff4d4f;
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: #fff;
  background-color: #cf1322;
  border-color: #cf1322;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after,
.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger-disabled > a:only-child,
.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-round {
  height: 32px;
  padding: 0 16px;
  font-size: 16px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 0 20px;
  font-size: 18px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 16px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  pointer-events: none;
  content: '';
}
.ant-btn .anticon {
  -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  position: relative;
  padding-left: 29px;
  pointer-events: none;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
  margin-left: -14px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
.ant-btn-group {
  position: relative;
  display: inline-block;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > .ant-btn.active,
.ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
  line-height: 38px;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 22px;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color:  #16adb8;
  color:  var(--primary);
  background-color: transparent;
  border-color:  #16adb8;
  border-color:  var(--primary);
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color:  #16adb8;
  color:  var(--primary);
  background-color: transparent;
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #f5222d;
  background-color: transparent;
  border-color: #f5222d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff4d4f;
  background-color: transparent;
  border-color: #ff4d4f;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: #cf1322;
  background-color: transparent;
  border-color: #cf1322;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  vertical-align: top;
}
a.ant-btn {
  line-height: 30px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-square {
  border-radius: 4px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}
.ant-badge-count {
  z-index: 10;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #f5222d;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: 10;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color:  #16adb8;
  background-color:  var(--primary);
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid  #16adb8;
  border: 1px solid  var(--primary);
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
          animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #f5222d;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
          transform: none;
}
@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
            transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
            transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-scroll-number-only > p {
  height: 20px;
  margin: 0;
}
@-webkit-keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-fullcalendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid #d9d9d9;
  outline: none;
}
.ant-select.ant-fullcalendar-year-select {
  min-width: 90px;
}
.ant-select.ant-fullcalendar-year-select.ant-select-sm {
  min-width: 70px;
}
.ant-select.ant-fullcalendar-month-select {
  min-width: 80px;
  margin-left: 8px;
}
.ant-select.ant-fullcalendar-month-select.ant-select-sm {
  min-width: 60px;
}
.ant-fullcalendar-header {
  padding: 11px 16px 11px 0;
  text-align: right;
}
.ant-fullcalendar-header .ant-select-dropdown {
  text-align: left;
}
.ant-fullcalendar-header .ant-radio-group {
  margin-left: 8px;
  text-align: left;
}
.ant-fullcalendar-header label.ant-radio-button {
  height: 22px;
  padding: 0 10px;
  line-height: 20px;
}
.ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}
.ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}
.ant-fullcalendar table {
  width: 100%;
  max-width: 100%;
  height: 256px;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-fullcalendar table,
.ant-fullcalendar th,
.ant-fullcalendar td {
  border: 0;
}
.ant-fullcalendar td {
  position: relative;
}
.ant-fullcalendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-fullcalendar-column-header {
  width: 33px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}
.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}
.ant-fullcalendar-month,
.ant-fullcalendar-date {
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-fullcalendar-value {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-fullcalendar-value:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-fullcalendar-value:active {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}
.ant-fullcalendar-today .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px  #16adb8 inset;
          box-shadow: 0 0 0 1px  var(--primary) inset;
}
.ant-fullcalendar-selected-day .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-month-panel-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-fullcalendar-content {
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
}
.ant-fullcalendar-fullscreen {
  border-top: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-table {
  table-layout: fixed;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: 32px;
  line-height: 30px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 116px;
  margin: 0 4px;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  border-top: 2px solid #e8e8e8;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #bae7ff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  padding-right: 12px;
  padding-bottom: 5px;
  text-align: right;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  width: auto;
  text-align: right;
  background: transparent;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.65);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  background: transparent;
  border-top-color:  #16adb8;
  border-top-color:  var(--primary);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #e6f7ff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 88px;
  overflow-y: auto;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
  cursor: not-allowed;
}
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date:hover {
  background: transparent;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid  #16adb8;
  border: 1px solid  var(--primary);
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(0.875);
          transform: scale(0.875);
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 4px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color:  #16adb8;
  color:  var(--primary);
}
.ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked {
  z-index: 1;
  color:  #16adb8;
  color:  var(--primary);
  background: #fff;
  border-color:  #16adb8;
  border-color:  var(--primary);
  box-shadow: -1px 0 0 0  #16adb8;
          box-shadow: -1px 0 0 0  var(--primary);
}
.ant-radio-button-wrapper-checked::before {
  background-color:  #16adb8 !important;
  background-color:  var(--primary) !important;
  opacity: 0.1;
}
.ant-radio-button-wrapper-checked:first-child {
  border-color:  #16adb8;
  border-color:  var(--primary);
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:hover {
  color:  #16adb8;
  color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
  box-shadow: -1px 0 0 0  #16adb8;
          box-shadow: -1px 0 0 0  var(--primary);
}
.ant-radio-button-wrapper-checked:active {
  color: #096dd9;
  border-color: #096dd9;
  box-shadow: -1px 0 0 0 #096dd9;
}
.ant-radio-button-wrapper-checked:focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-card-hoverable {
  cursor: pointer;
}
.ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #e8e8e8;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
.ant-card-head::before,
.ant-card-head::after {
  content: '';
  display: table;
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-body {
  padding: 24px;
  zoom: 1;
}
.ant-card-body::before,
.ant-card-body::after {
  content: '';
  display: table;
}
.ant-card-body::after {
  clear: both;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-card-grid:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom: 1;
}
.ant-card-actions::before,
.ant-card-actions::after {
  content: '';
  display: table;
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: inline-block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color:  #16adb8;
  color:  var(--primary);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li > span a {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
.ant-card-actions > li > span a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.ant-card-wider-padding .ant-card-head {
  padding: 0 32px;
}
.ant-card-wider-padding .ant-card-body {
  padding: 24px 32px;
}
.ant-card-padding-transition .ant-card-head,
.ant-card-padding-transition .ant-card-body {
  -webkit-transition: padding 0.3s;
  transition: padding 0.3s;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
.ant-card-meta::before,
.ant-card-meta::after {
  content: '';
  display: table;
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
  background: -webkit-linear-gradient(left, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
}
@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 40px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 38px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color:  #16adb8;
  color:  var(--primary);
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
  padding: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  width: 16px;
  height: 16px;
  height: 14px;
  margin-right: -5px;
  margin-left: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
  -webkit-transition: none !important;
  transition: none !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
.ant-tabs-extra-content {
  line-height: 40px;
}
.ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color:  #16adb8;
  color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
  height: auto;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
  width: 90%;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-right: 1px;
  border-right: 0;
  border-radius: 4px 0 0 4px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-left: 1px;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  border-top: 0;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  padding-top: 1px;
  padding-bottom: 0;
  color:  #16adb8;
  color:  var(--primary);
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  overflow: hidden;
  zoom: 1;
}
.ant-tabs::before,
.ant-tabs::after {
  content: '';
  display: table;
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 2px;
  background-color:  #16adb8;
  background-color:  var(--primary);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav-container {
  position: relative;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  content: '';
  display: table;
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  margin-bottom: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  top: 1px;
  bottom: auto;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
  margin-top: -1px;
  margin-bottom: 0;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  font-size: 12px;
}
.ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab-next {
  right: 2px;
}
.ant-tabs-tab-prev {
  left: 0;
}
:root .ant-tabs-tab-prev {
  -webkit-filter: none;
          filter: none;
}
.ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}
.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
.ant-tabs-nav {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav::before,
.ant-tabs-nav::after {
  display: table;
  content: ' ';
}
.ant-tabs-nav::after {
  clear: both;
}
.ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  margin: 0 32px 0 0;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: #096dd9;
}
.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
}
.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color:  #16adb8;
  color:  var(--primary);
  font-weight: 500;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  font-size: 16px;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
  font-size: 14px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 8px 16px;
}
.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  width: 100%;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  flex-shrink: 0;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.45s;
  transition: opacity 0.45s;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
}
.ant-tabs .ant-tabs-left-bar,
.ant-tabs .ant-tabs-right-bar {
  height: 100%;
  border-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar-tab-prev,
.ant-tabs .ant-tabs-right-bar-tab-prev,
.ant-tabs .ant-tabs-left-bar-tab-next,
.ant-tabs .ant-tabs-right-bar-tab-next {
  width: 32px;
  height: 0;
  -webkit-transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs .ant-tabs-left-bar-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-right-bar-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-left-bar-tab-next.ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-right-bar-tab-next.ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
.ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
  text-align: center;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
  width: auto;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  height: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
  width: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 2px;
  height: auto;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
  bottom: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-content,
.ant-tabs .ant-tabs-right-content {
  width: auto;
  margin-top: 0 !important;
  overflow: hidden;
}
.ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: 1px;
}
.ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar {
  float: right;
  margin-bottom: 0;
  margin-left: -1px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  left: 1px;
}
.ant-tabs .ant-tabs-right-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  -webkit-transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
  -webkit-transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.no-flex > .ant-tabs-content > .ant-tabs-content-animated,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  display: none;
}
.ant-tabs-left-content > .ant-tabs-content-animated,
.ant-tabs-right-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive {
  display: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
.ant-row::before,
.ant-row::after {
  content: '';
  display: table;
}
.ant-row::after {
  clear: both;
}
.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
.ant-row-flex::before,
.ant-row-flex::after {
  display: flex;
}
.ant-row-flex-start {
  justify-content: flex-start;
}
.ant-row-flex-center {
  justify-content: center;
}
.ant-row-flex-end {
  justify-content: flex-end;
}
.ant-row-flex-space-between {
  justify-content: space-between;
}
.ant-row-flex-space-around {
  justify-content: space-around;
}
.ant-row-flex-top {
  align-items: flex-start;
}
.ant-row-flex-middle {
  align-items: center;
}
.ant-row-flex-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  display: block;
}
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}
.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-xs-1,
.ant-col-xs-2,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-xs-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-1,
  .ant-col-sm-2,
  .ant-col-sm-3,
  .ant-col-sm-4,
  .ant-col-sm-5,
  .ant-col-sm-6,
  .ant-col-sm-7,
  .ant-col-sm-8,
  .ant-col-sm-9,
  .ant-col-sm-10,
  .ant-col-sm-11,
  .ant-col-sm-12,
  .ant-col-sm-13,
  .ant-col-sm-14,
  .ant-col-sm-15,
  .ant-col-sm-16,
  .ant-col-sm-17,
  .ant-col-sm-18,
  .ant-col-sm-19,
  .ant-col-sm-20,
  .ant-col-sm-21,
  .ant-col-sm-22,
  .ant-col-sm-23,
  .ant-col-sm-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-sm-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-1,
  .ant-col-md-2,
  .ant-col-md-3,
  .ant-col-md-4,
  .ant-col-md-5,
  .ant-col-md-6,
  .ant-col-md-7,
  .ant-col-md-8,
  .ant-col-md-9,
  .ant-col-md-10,
  .ant-col-md-11,
  .ant-col-md-12,
  .ant-col-md-13,
  .ant-col-md-14,
  .ant-col-md-15,
  .ant-col-md-16,
  .ant-col-md-17,
  .ant-col-md-18,
  .ant-col-md-19,
  .ant-col-md-20,
  .ant-col-md-21,
  .ant-col-md-22,
  .ant-col-md-23,
  .ant-col-md-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-md-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-1,
  .ant-col-lg-2,
  .ant-col-lg-3,
  .ant-col-lg-4,
  .ant-col-lg-5,
  .ant-col-lg-6,
  .ant-col-lg-7,
  .ant-col-lg-8,
  .ant-col-lg-9,
  .ant-col-lg-10,
  .ant-col-lg-11,
  .ant-col-lg-12,
  .ant-col-lg-13,
  .ant-col-lg-14,
  .ant-col-lg-15,
  .ant-col-lg-16,
  .ant-col-lg-17,
  .ant-col-lg-18,
  .ant-col-lg-19,
  .ant-col-lg-20,
  .ant-col-lg-21,
  .ant-col-lg-22,
  .ant-col-lg-23,
  .ant-col-lg-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-lg-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-1,
  .ant-col-xl-2,
  .ant-col-xl-3,
  .ant-col-xl-4,
  .ant-col-xl-5,
  .ant-col-xl-6,
  .ant-col-xl-7,
  .ant-col-xl-8,
  .ant-col-xl-9,
  .ant-col-xl-10,
  .ant-col-xl-11,
  .ant-col-xl-12,
  .ant-col-xl-13,
  .ant-col-xl-14,
  .ant-col-xl-15,
  .ant-col-xl-16,
  .ant-col-xl-17,
  .ant-col-xl-18,
  .ant-col-xl-19,
  .ant-col-xl-20,
  .ant-col-xl-21,
  .ant-col-xl-22,
  .ant-col-xl-23,
  .ant-col-xl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-1,
  .ant-col-xxl-2,
  .ant-col-xxl-3,
  .ant-col-xxl-4,
  .ant-col-xxl-5,
  .ant-col-xxl-6,
  .ant-col-xxl-7,
  .ant-col-xxl-8,
  .ant-col-xxl-9,
  .ant-col-xxl-10,
  .ant-col-xxl-11,
  .ant-col-xxl-12,
  .ant-col-xxl-13,
  .ant-col-xxl-14,
  .ant-col-xxl-15,
  .ant-col-xxl-16,
  .ant-col-xxl-17,
  .ant-col-xxl-18,
  .ant-col-xxl-19,
  .ant-col-xxl-20,
  .ant-col-xxl-21,
  .ant-col-xxl-22,
  .ant-col-xxl-23,
  .ant-col-xxl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xxl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  bottom: 12px;
  display: block;
  width: 100%;
  height: 3px;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  right: 12px;
  bottom: auto;
  width: 3px;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-cascader-input.ant-input {
  position: relative;
  width: 100%;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 4px 4px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 4px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #e6f7ff;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background: #f5f5f5;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
}
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #f5222d;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid  #16adb8;
  border: 1px solid  var(--primary);
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox-wrapper + span,
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px 12px 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  margin-top: 2px;
  font-size: 12px;
  line-height: 46px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-anim-active {
  -webkit-transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-collapse-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-inactive {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}
.ant-collapse-borderless {
  background-color: #fff;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-comment {
  position: relative;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  height: 18px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-calendar-picker-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-calendar-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-calendar-picker-input {
  outline: none;
}
.ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-picker-clear {
  z-index: 2;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.ant-calendar-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
.ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-input {
  width: 100%;
  height: 22px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-calendar-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-week-number {
  width: 286px;
}
.ant-calendar-week-number-cell {
  text-align: center;
}
.ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-header a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  content: '«';
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  content: '»';
}
.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
}
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  content: '‹';
}
.ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
}
.ant-calendar-header .ant-calendar-next-month-btn::after {
  content: '›';
}
.ant-calendar-body {
  padding: 8px 12px;
}
.ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
  text-align: center;
  border: 0;
}
.ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
.ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
.ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-date-panel {
  position: relative;
  outline: none;
}
.ant-calendar-date:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-date:active {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-calendar-today .ant-calendar-date {
  color:  #16adb8;
  color:  var(--primary);
  font-weight: bold;
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-selected-day .ant-calendar-date {
  background: #d1e9ff;
}
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
  border: 1px solid transparent;
}
.ant-calendar-selected-date .ant-calendar-date:hover,
.ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-selected-end-date .ant-calendar-date:hover {
  background:  #16adb8;
  background:  var(--primary);
}
.ant-calendar-disabled-cell .ant-calendar-date {
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  content: ' ';
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-footer:empty {
  border-top: 0;
}
.ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
.ant-calendar-footer-extra {
  text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
.ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar .ant-calendar-ok-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  color: #fff;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 22px;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #fff;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
}
.ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-calendar-range-part {
  position: relative;
  width: 50%;
}
.ant-calendar-range-left {
  float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-right {
  float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
.ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: rgba(0, 0, 0, 0.45);
  line-height: 34px;
  text-align: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
.ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #e6f7ff;
  border: 0;
  border-radius: 0;
  content: '';
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
div.ant-calendar-range-quick-selector {
  text-align: left;
}
div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header {
  border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
.ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
}
.ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 1050;
  width: 100%;
}
.ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
}
.ant-calendar-time-picker-combobox {
  width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
.ant-calendar-time-picker-input-wrap {
  display: none;
}
.ant-calendar-time-picker-select {
  position: relative;
  float: left;
  box-sizing: border-box;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
.ant-calendar-time-picker-select ul {
  box-sizing: border-box;
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-calendar-time-picker-select li {
  box-sizing: content-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding-left: 32px;
  line-height: 24px;
  list-style: none;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: '';
}
.ant-calendar-time-picker-select li:hover {
  background: #e6f7ff;
}
li.ant-calendar-time-picker-select-option-selected {
  font-weight: bold;
  background: #f5f5f5;
}
li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-month-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-month-panel-hidden {
  display: none;
}
.ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-month-panel-header a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  content: '«';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  content: '»';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  content: '‹';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  content: '›';
}
.ant-calendar-month-panel-body {
  flex: 1 1;
}
.ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-calendar-month-panel-cell {
  text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-year-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-year-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-year-panel-hidden {
  display: none;
}
.ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-year-panel-header a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  content: '«';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  content: '»';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  content: '‹';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  content: '›';
}
.ant-calendar-year-panel-body {
  flex: 1 1;
}
.ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-year-panel-cell {
  text-align: center;
}
.ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-decade-panel-header a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  content: '«';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  content: '»';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  content: '‹';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  content: '›';
}
.ant-calendar-decade-panel-body {
  flex: 1 1;
}
.ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
.ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
.ant-calendar-week-number-cell {
  opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #e6f7ff;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #bae7ff;
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: rgba(0, 0, 0, 0.65);
  background: transparent;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-time-picker-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-time-picker-panel-inner {
  position: relative;
  left: -2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-time-picker-panel-input {
  width: 100%;
  max-width: 154px;
  margin: 0;
  padding: 0;
  line-height: normal;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input-wrap {
  position: relative;
  box-sizing: border-box;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-time-picker-panel-input-invalid {
  border-color: #f5222d;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
.ant-time-picker-panel-select {
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 56px;
  max-height: 192px;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
}
.ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-time-picker-panel-select:last-child {
  border-right: 0;
}
.ant-time-picker-panel-select:only-child {
  width: 100%;
}
.ant-time-picker-panel-select ul {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}
.ant-time-picker-panel-select li {
  box-sizing: content-box;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 32px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-time-picker-panel-select li:hover {
  background: #e6f7ff;
}
li.ant-time-picker-panel-select-option-selected {
  font-weight: bold;
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-time-picker-panel-combobox {
  zoom: 1;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  content: '';
  display: table;
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-time-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 128px;
  outline: none;
  cursor: text;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-time-picker-input:focus {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-time-picker-open {
  opacity: 0;
}
.ant-time-picker-icon,
.ant-time-picker-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 14px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-time-picker-clear .ant-time-picker-clock-icon {
  display: block;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
}
.ant-time-picker-clear {
  z-index: 2;
  background: #fff;
  opacity: 0;
  pointer-events: none;
}
.ant-time-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-time-picker:hover .ant-time-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-time-picker-large .ant-time-picker-input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-small .ant-time-picker-input {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-small .ant-time-picker-icon,
.ant-time-picker-small .ant-time-picker-clear {
  right: 7px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: normal;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
:root .ant-tag .anticon-close {
  font-size: 12px;
}
.ant-tag .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color:  #16adb8;
  background-color:  var(--primary);
}
.ant-tag-checkable:active {
  background-color: #096dd9;
}
.ant-tag-close {
  width: 0 !important;
  margin: 0;
  padding: 0;
}
.ant-tag-zoom-enter,
.ant-tag-zoom-appear {
  -webkit-animation: antFadeIn 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation: antFadeIn 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-tag-zoom-leave {
  -webkit-animation: antZoomOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation: antZoomOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color:  #16adb8;
  color:  var(--primary);
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  background: #e8e8e8;
}
.ant-divider,
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text::after,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::after {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid #e8e8e8;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 10px;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}
.ant-divider-dashed {
  background: none;
  border-top: 1px dashed #e8e8e8;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::after {
  border-style: dashed none none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-drawer {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 0%;
  height: 100%;
}
.ant-drawer > * {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), -webkit-transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
  transition: box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), -webkit-transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
  transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
  transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), -webkit-transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
}
.ant-drawer-content-wrapper {
  position: fixed;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
}
.ant-drawer-left.ant-drawer-open.no-mask,
.ant-drawer-right.ant-drawer-open.no-mask {
  width: 0%;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-top,
.ant-drawer-bottom {
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
}
.ant-drawer-top.ant-drawer-open.no-mask,
.ant-drawer-bottom.ant-drawer-open.no-mask {
  height: 0%;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 0.3;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
          animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-drawer-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-drawer-mask {
  position: fixed;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  filter: alpha(opacity=50);
  -webkit-transition: opacity 0.3s linear, height 0s ease 0.3s;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.ant-drawer-open {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-open-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
.ant-dropdown-wrap .anticon-down::before {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > a:focus,
.ant-dropdown-menu-submenu-title > a:focus {
  text-decoration: none;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color:  #16adb8;
  color:  var(--primary);
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-trigger > .anticon.anticon-down,
:root .ant-dropdown-link > .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background:  #16adb8;
  background:  var(--primary);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-required::before {
  display: none;
}
input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled {
  cursor: not-allowed;
}
.ant-radio-inline.disabled,
.ant-radio-vertical.disabled,
.ant-checkbox-inline.disabled,
.ant-checkbox-vertical.disabled {
  cursor: not-allowed;
}
.ant-radio.disabled label,
.ant-checkbox.disabled label {
  cursor: not-allowed;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item label {
  position: relative;
}
.ant-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-control {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  content: '';
  display: table;
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-children {
  position: relative;
}
.ant-form-item-with-help {
  margin-bottom: 5px;
}
.ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.85);
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-no-colon .ant-form-item-label label::after {
  content: ' ';
}
.ant-form-explain,
.ant-form-extra {
  clear: both;
  min-height: 22px;
  margin-top: -2px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-explain {
  margin-bottom: -1px;
}
.ant-form-extra {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form .has-feedback .ant-input {
  padding-right: 24px;
}
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}
form .ant-upload {
  background: transparent;
}
form input[type='radio'],
form input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}
form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
form .ant-input-number-handler-wrap {
  z-index: 2;
}
form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}
form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}
form :not(.ant-input-group-wrapper) > .ant-input-group,
form .ant-input-group-wrapper {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
}
.ant-input-group-wrap .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-wrap .ant-select-selection:hover {
  border-color: #d9d9d9;
}
.ant-input-group-wrap .ant-select-selection--single {
  height: 40px;
  margin-left: -1px;
  background-color: rgba(0, 0, 0, 0.07);
}
.ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-right: 25px;
  padding-left: 8px;
  line-height: 30px;
}
.ant-input-group-wrap .ant-select-open .ant-select-selection {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label label::after,
.ant-col-24.ant-form-item-label label::after,
.ant-col-xl-24.ant-form-item-label label::after {
  display: none;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
.ant-form-vertical .ant-form-item-control {
  line-height: 1.5;
}
.ant-form-vertical .ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}
.ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  .ant-form-item-label,
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item-label label::after {
    display: none;
  }
  .ant-col-xs-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label label::after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .has-feedback {
  display: inline-block;
}
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.has-error.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: #faad14;
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  border-color: #faad14;
}
.has-warning .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  border-color: #faad14;
}
.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #faad14;
}
.has-warning .ant-input-prefix {
  color: #faad14;
}
.has-warning .ant-input-group-addon {
  color: #faad14;
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .has-feedback {
  color: #faad14;
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: #faad14;
  -webkit-animation-name: diffZoomIn3 !important;
          animation-name: diffZoomIn3 !important;
}
.has-warning .ant-select-selection {
  border-color: #faad14;
}
.has-warning .ant-select-selection:hover {
  border-color: #faad14;
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-calendar-picker-icon::after,
.has-warning .ant-time-picker-icon::after,
.has-warning .ant-picker-icon::after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: #faad14;
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: #faad14;
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #f5222d;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  border-color: #f5222d;
}
.has-error .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  border-color: #f5222d;
}
.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #f5222d;
}
.has-error .ant-input-prefix {
  color: #f5222d;
}
.has-error .ant-input-group-addon {
  color: #f5222d;
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .has-feedback {
  color: #f5222d;
}
.has-error.has-feedback .ant-form-item-children-icon {
  color: #f5222d;
  -webkit-animation-name: diffZoomIn2 !important;
          animation-name: diffZoomIn2 !important;
}
.has-error .ant-select-selection {
  border-color: #f5222d;
}
.has-error .ant-select-selection:hover {
  border-color: #f5222d;
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #f5222d;
}
.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
.has-error .ant-calendar-picker-icon::after,
.has-error .ant-time-picker-icon::after,
.has-error .ant-picker-icon::after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: #f5222d;
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #f5222d;
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-transfer-list {
  border-color: #f5222d;
}
.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color:  #16adb8;
  color:  var(--primary);
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 24px;
}
.ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: 5px;
}
.show-help-enter,
.show-help-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
          animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
          animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-input-number:focus {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -moz-appearance: textfield;
}
.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  -webkit-transition: opacity 0.24s linear 0.1s;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  -webkit-transform: scale(0.58333333) rotate(0deg);
          transform: scale(0.58333333) rotate(0deg);
  min-width: auto;
  margin-right: 0;
}
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 12px;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e;
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  text-align: center;
}
.ant-list-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list-item-content {
  color: rgba(0, 0, 0, 0.65);
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  font-size: 0;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color:  #16adb8;
  color:  var(--primary);
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
  content: '';
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.ant-spin-dot i {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-radius: 100%;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot i:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot i:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot i:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
  width: 14px;
  height: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
  width: 32px;
  height: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  margin: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  -webkit-transition: none;
  transition: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-pagination-item-active {
  font-weight: 500;
  background: transparent;
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-pagination-item-active a {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  color:  #16adb8;
  color:  var(--primary);
  letter-spacing: -1px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgba(0, 0, 0, 0.65);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color:  #16adb8;
  color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: 24px;
  padding: 1px 7px;
  width: 44px;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-mention-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.ant-mention-wrapper .ant-mention-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 32px;
  padding: 0;
  line-height: 1.5;
}
.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mention-wrapper .ant-mention-editor::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mention-wrapper .ant-mention-editor:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor:focus {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mention-wrapper .ant-mention-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-mention-wrapper .ant-mention-editor {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-mention-wrapper .ant-mention-editor-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mention-wrapper .ant-mention-editor-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mention-wrapper .ant-mention-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color:  #16adb8;
  border-color:  var(--primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mention-wrapper.disabled .ant-mention-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper.disabled .ant-mention-editor:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
.ant-mention-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mention-dropdown-placement-top {
  margin-top: -0.1em;
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  display: block;
  color:  #16adb8;
  color:  var(--primary);
  text-align: center;
}
.ant-mention-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.ant-mention-dropdown-item:hover {
  background-color: #e6f7ff;
}
.ant-mention-dropdown-item.focus,
.ant-mention-dropdown-item-active {
  background-color: #e6f7ff;
}
.ant-mention-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mention-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mention-dropdown-item-selected,
.ant-mention-dropdown-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  background-color: #f5f5f5;
}
.ant-mention-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transition: background 0.3s, width 0.2s;
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
.ant-menu::before,
.ant-menu::after {
  content: '';
  display: table;
}
.ant-menu::after {
  clear: both;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  -webkit-transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #80d6981c;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item > a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-menu-item > a:focus {
  text-decoration: none;
}
.ant-menu-item > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #16adb8;
  color: var(--primary);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #80d6981c;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  padding: 0;
  border-right: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: #fff;
  border-radius: 4px;
}
.ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 4px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  border-radius: 2px;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateY(-2px);
          transform: rotate(45deg) translateY(-2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateY(2px);
          transform: rotate(-45deg) translateY(2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from( #16adb8), to( #16adb8));
  background: -webkit-gradient(linear, left top, right top, from( var(--primary)), to( var(--primary)));
  background: -webkit-linear-gradient(left,  #16adb8,  #16adb8);
  background: -webkit-linear-gradient(left,  var(--primary),  var(--primary));
  background: -webkit-gradient(linear,  left top, right top,  from(#16adb8),  to(#16adb8));
  background: linear-gradient(to right,  #16adb8,  #16adb8);
  background: -webkit-gradient(linear,  left top, right top,  from(var(--primary)),  to(var(--primary)));
  background: linear-gradient(to right,  var(--primary),  var(--primary));
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateX(2px);
          transform: rotate(-45deg) translateX(2px);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateX(-2px);
          transform: rotate(45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateX(-2px);
          transform: rotate(-45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateX(2px);
          transform: rotate(45deg) translateX(2px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: none;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color:  #16adb8;
  color:  var(--primary);
  border-bottom: 2px solid  #16adb8;
  border-bottom: 2px solid  var(--primary);
}
.ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid  #16adb8;
  border-right: 3px solid  var(--primary);
  -webkit-transform: scaleY(0.0001);
          transform: scaleY(0.0001);
  opacity: 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.01px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  opacity: 1;
  -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color:  #16adb8;
  background-color:  var(--primary);
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 3px;
  border-width: 5px 5px 0;
  border-top-color: rgba(0, 0, 0, 0.75);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 16px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 16px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 3px;
  border-width: 5px 5px 5px 0;
  border-right-color: rgba(0, 0, 0, 0.75);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  margin-top: -5px;
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 8px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 8px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 3px;
  border-width: 5px 0 5px 5px;
  border-left-color: rgba(0, 0, 0, 0.75);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  margin-top: -5px;
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 8px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 8px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 3px;
  border-width: 0 5px 5px;
  border-bottom-color: rgba(0, 0, 0, 0.75);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 16px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 16px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice:first-child {
  margin-top: -8px;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #f5222d;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  -webkit-animation-name: MessageMoveOut;
          animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
          transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-close {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper {
  zoom: 1;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  content: '';
  display: table;
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  margin-left: 38px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
  margin-right: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
          animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.anticon.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color:  #16adb8;
  color:  var(--primary);
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #f5222d;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
a.ant-notification-notice-close:focus {
  text-decoration: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
          animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
          animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 32px;
  background: #fff;
}
.ant-page-header.ant-page-header-has-footer {
  padding: 20px 32px;
  padding-bottom: 0;
}
.ant-page-header-back-icon {
  display: inline-block;
  padding: 4px 0;
  font-size: 16px;
  line-height: 100%;
  cursor: pointer;
}
.ant-page-header-back-icon i:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-page-header .ant-divider {
  height: 14px;
  margin: 0 12px;
}
.ant-page-header .ant-breadcrumb {
  margin-bottom: 12px;
}
.ant-page-header-title-view {
  display: inline-block;
}
.ant-page-header-title-view-title {
  display: inline-block;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
}
.ant-page-header-title-view-sub-title {
  display: inline-block;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.8;
}
.ant-page-header-title-view-tags {
  display: inline-block;
  vertical-align: top;
}
.ant-page-header-title-view-extra {
  position: absolute;
  top: 16px;
  right: 32px;
}
.ant-page-header-title-view-extra > * {
  margin-right: 8px;
}
.ant-page-header-title-view-extra > *:last-child {
  margin-right: 0;
}
.ant-page-header-content-view {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin: 0 -8px;
  padding-top: 24px;
}
.ant-page-header-footer .ant-tabs-bar {
  margin-bottom: 1px;
  border-bottom: 0;
}
.ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 8px;
  padding-top: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-width: 4.24264069px;
  border-style: solid;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
          animation: ant-progress-appear 0.3s;
  stroke:  #16adb8;
  stroke:  var(--primary);
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color:  #16adb8;
  background-color:  var(--primary);
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #f5222d;
}
.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-status-exception .ant-progress-circle-path {
  stroke: #f5222d;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1;
  white-space: normal;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  margin-right: 8px;
  padding: 0;
  color: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #e8e8e8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: -webkit-linear-gradient(left, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: -webkit-linear-gradient(left, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 14px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-bottom: -7px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #91d5ff;
  border-radius: 4px;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  margin-left: -7px;
  background-color: #fff;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-transition: border-color 0.3s, box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
}
.ant-slider-handle.ant-tooltip-open {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  cursor: pointer;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #8cc8ff;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-statistic-content {
  font-size: 24px;
  font-family: Tahoma, 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-statistic-content-value-decimal {
  font-size: 16px;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child .ant-steps-item-tail,
.ant-steps-item:last-child .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -1px;
  color:  #16adb8;
  color:  var(--primary);
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  border-radius: 1px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
  content: '';
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background:  #16adb8;
  background:  var(--primary);
}
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-process > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background:  #16adb8;
  background:  var(--primary);
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background:  #16adb8;
  background:  var(--primary);
}
.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color:  #16adb8;
  background-color:  var(--primary);
}
.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-tail::after {
  background-color:  #16adb8;
  background-color:  var(--primary);
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-description {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #f5222d;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
  padding: 0 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
          transform: none;
}
.ant-steps-vertical {
  display: block;
}
.ant-steps-vertical .ant-steps-item {
  display: block;
  overflow: visible;
}
.ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-title {
  line-height: 24px;
}
@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-title {
    line-height: 24px;
  }
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 51px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 104px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical.ant-steps-small .ant-steps-item-icon {
  margin-left: 40px;
}
.ant-steps-dot .ant-steps-item-title {
  line-height: 1.5;
}
.ant-steps-dot .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  margin-left: -16px;
  padding-left: 16px;
  background: #fff;
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item {
  margin-left: -12px;
  padding-left: 12px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
  overflow: hidden;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child .ant-steps-icon-dot::after {
  right: -200px;
  width: 200px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::before,
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 8px;
  background: #fff;
  content: '';
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  right: -10px;
  left: auto;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ccc;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.36s;
  transition: all 0.36s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 24px;
  color: #fff;
  font-size: 12px;
}
.ant-switch-loading-icon,
.ant-switch::after {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-switch::after {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
.ant-switch:active::before,
.ant-switch:active::after {
  width: 24px;
}
.ant-switch-loading-icon {
  z-index: 1;
  display: none;
  font-size: 12px;
  background: transparent;
}
.ant-switch-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 14px;
}
.ant-switch-small .ant-switch-inner {
  margin-right: 3px;
  margin-left: 18px;
  font-size: 12px;
}
.ant-switch-small::after {
  width: 12px;
  height: 12px;
}
.ant-switch-small:active::before,
.ant-switch-small:active::after {
  width: 16px;
}
.ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-right: 18px;
  margin-left: 3px;
}
.ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
.ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  font-weight: bold;
  -webkit-transform: scale(0.66667);
          transform: scale(0.66667);
}
.ant-switch-checked {
  background-color:  #16adb8;
  background-color:  var(--primary);
}
.ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: 6px;
}
.ant-switch-checked::after {
  left: 100%;
  margin-left: -1px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  cursor: not-allowed;
}
.ant-switch-loading::before,
.ant-switch-disabled::before,
.ant-switch-loading::after,
.ant-switch-disabled::after {
  cursor: not-allowed;
}
@-webkit-keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform: rotate(0deg) scale(0.66667);
            transform: rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.66667);
            transform: rotate(360deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform: rotate(0deg) scale(0.66667);
            transform: rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.66667);
            transform: rotate(360deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  content: '';
  display: table;
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  clear: both;
}
.ant-table-body {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow: auto !important;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: collapse;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan] {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  position: absolute;
  top: 50%;
  right: 6px;
  width: 14px;
  height: 17px;
  margin-top: -8.5px;
  color: #bfbfbf;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  -webkit-transform: scale(0.91666667) rotate(0deg);
          transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 4px;
  line-height: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter-down.on {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-table-thead > tr > th .ant-table-column-sorter-down {
  margin-top: 4px;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
  /* stylelint-disable-next-line */
  -webkit-background-clip: border-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-has-filters {
  /* padding-right: 54px !important; */  
}
.ant-table-thead > tr > th .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-column-sorters::before {
  position: absolute;
  top: 0; 
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-table-thead > tr > th .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-filters .ant-table-column-sorter {
  right: 34px;
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: all 0.3s, border 0s;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #e6f7ff;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  position: relative;
  padding: 16px 16px;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #e8e8e8;
}
.ant-table-title + .ant-table-content {
  position: relative;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
.ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
.ant-table-header table {
  border-radius: 4px 4px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  z-index: 1;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-table-placeholder .anticon {
  margin-right: 4px;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
.ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  border: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 400px;
  overflow-x: hidden;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color:  #16adb8;
  color:  var(--primary);
  font-weight: bold;
  text-shadow: 0 0 2px #bae7ff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-table-filter-dropdown-link:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-table-filter-dropdown-link:active {
  color: #096dd9;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  line-height: 14px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-row-expanded::after {
  content: '-';
}
.ant-table-row-collapsed::after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced::after {
  content: '.';
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  width: auto;
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: 20;
  overflow: hidden;
  border-radius: 0;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 4px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 4px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -16px -13px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: #fff;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -16px -9px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 0.75em;
  left: 4px;
  height: 100%;
  border-left: 2px solid #e8e8e8;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color:  #16adb8;
  color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-timeline-item-head-red {
  color: #f5222d;
  border-color: #f5222d;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -6px;
  margin: 0 0 0 18px;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  left: 50%;
  width: 50%;
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  right: 50%;
  left: -30px;
  width: 50%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: 100%;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  right: 0;
  left: -30px;
  width: 100%;
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  display: block;
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 200px;
  padding-top: 34px;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
.ant-transfer-list-search {
  padding: 0 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 6px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
.ant-transfer-list-body {
  position: relative;
  height: 100%;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 4px;
}
.ant-transfer-list-body-with-search {
  padding-top: 40px;
}
.ant-transfer-list-content {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content > .LazyLoad {
  -webkit-animation: transferHighlightIn 1s;
          animation: transferHighlightIn 1s;
}
.ant-transfer-list-content-item {
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > span {
  padding-right: 0;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #e6f7ff;
  cursor: pointer;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-body-not-found {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 16px;
}
.ant-transfer-list-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-transfer-operation {
  display: inline-block;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@-webkit-keyframes transferHighlightIn {
  0% {
    background: #bae7ff;
  }
  100% {
    background: transparent;
  }
}
@keyframes transferHighlightIn {
  0% {
    background: #bae7ff;
  }
  100% {
    background: transparent;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid  #16adb8;
  border: 1px solid  var(--primary);
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox-wrapper + span,
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
}
.ant-select-tree li {
  margin: 8px 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-select-tree li.filter-node > span {
  font-weight: 500;
}
.ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 0;
  padding: 3px 5px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #e6f7ff;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #bae7ff;
}
.ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
.ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree li span.ant-select-tree-switcher,
.ant-select-tree li span.ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  color:  #16adb8;
  color:  var(--primary);
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color:  #16adb8;
  color:  var(--primary);
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
.ant-select-tree-child-tree {
  display: none;
}
.ant-select-tree-child-tree-open {
  display: block;
}
li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-select-tree-dropdown .ant-select-dropdown-search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  padding: 4px;
  background: #fff;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
}
.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
.ant-select-tree-dropdown .ant-select-not-found {
  display: block;
  padding: 7px 16px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-tree.ant-tree-directory {
  position: relative;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #e6f7ff;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background:  #16adb8;
  background:  var(--primary);
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid  #16adb8;
  border: 1px solid  var(--primary);
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color:  #16adb8;
  background-color:  var(--primary);
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox-wrapper + span,
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color:  #16adb8;
  background-color:  var(--primary);
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
}
.ant-tree ol,
.ant-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-tree li span[draggable],
.ant-tree li span[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.ant-tree li.drag-over > span[draggable] {
  color: white;
  background-color:  #16adb8;
  background-color:  var(--primary);
  opacity: 0.8;
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color:  #16adb8;
  border-top-color:  var(--primary);
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color:  #16adb8;
  border-bottom-color:  var(--primary);
}
.ant-tree li.filter-node > span {
  color: #f5222d !important;
  font-weight: 500 !important;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color:  #16adb8;
  color:  var(--primary);
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
  opacity: 0;
}
.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #e6f7ff;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff;
}
.ant-tree li span.ant-tree-checkbox {
  margin: 4px 4px 0 2px;
}
.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-tree li span.ant-tree-switcher {
  position: relative;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-tree li:last-child > span.ant-tree-switcher::before,
.ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
.ant-tree > li:first-child {
  padding-top: 7px;
}
.ant-tree > li:last-child {
  padding-bottom: 7px;
}
.ant-tree-child-tree {
  display: none;
}
.ant-tree-child-tree-open {
  display: block;
}
.ant-tree-child-tree > li:first-child {
  padding-top: 8px;
}
.ant-tree-child-tree > li:last-child {
  padding-bottom: 0;
}
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree.ant-tree-show-line li {
  position: relative;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: rgba(0, 0, 0, 0.45);
  background: #fff;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  margin: 22px 0;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
.ant-tree.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
  width: calc(100% - 46px);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-typography {
  color: rgba(0, 0, 0, 0.65);
}
.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography-warning {
  color: #d48806;
}
.ant-typography-danger {
  color: #cf1322;
}
.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4 {
  margin-top: 1.2em;
}
span.ant-typography-ellipsis {
  display: inline-block;
}
.ant-typography a {
  color:  #16adb8;
  color:  var(--primary);
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-typography a:focus,
.ant-typography a:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-typography a:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography a:active {
  color: #096dd9;
}
.ant-typography a:active,
.ant-typography a:hover {
  text-decoration: none;
}
.ant-typography a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color:  #16adb8;
  color:  var(--primary);
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  margin-left: 8px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color:  #16adb8;
  color:  var(--primary);
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #096dd9;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 2px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul li {
  list-style-type: circle;
}
.ant-typography ul li li {
  list-style-type: disc;
}
.ant-typography ol li {
  list-style-type: decimal;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-select-picture-card {
  display: table;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border: 2px dashed  #16adb8;
  border: 2px dashed  var(--primary);
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color:  #16adb8;
  border-color:  var(--primary);
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color:  #16adb8;
  color:  var(--primary);
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  zoom: 1;
}
.ant-upload-list::before,
.ant-upload-list::after {
  content: '';
  display: table;
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
:root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #e6f7ff;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #f5222d;
}
.ant-upload-list-item-error .anticon-close {
  color: #f5222d !important;
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card {
  float: left;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
}
.ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  padding-left: 0;
}
.ant-upload-list .ant-upload-success-icon {
  color: #52c41a;
  font-weight: bold;
}
.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-enter {
  -webkit-animation-name: uploadAnimateIn;
          animation-name: uploadAnimateIn;
}
.ant-upload-list .ant-upload-animate-leave {
  -webkit-animation-name: uploadAnimateOut;
          animation-name: uploadAnimateOut;
}
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
          animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
          animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}


/*# sourceMappingURL=antd.css.map*/
@charset "UTF-8";
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("DroidKufi-Regular"), local("DroidKufi-Regular"), url(/static/media/DroidKufi-Regular.a9b030e2.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("DroidKufi-Bold"), local("DroidKufi-Bold"), url(/static/media/DroidKufi-Bold.1b89eb34.ttf);
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: Pridi;
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(/static/media/Montserrat-Regular.ee653992.ttf);
  font-display: swap;
}

@font-face {
  font-family: Pridi;
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url(/static/media/Montserrat-Light.409c7f79.ttf);
  font-display: swap;
}

@font-face {
  font-family: Pridi;
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(/static/media/Montserrat-Medium.c8b6e083.ttf);
  font-display: swap;
}

@font-face {
  font-family: Pridi;
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(/static/media/Montserrat-SemiBold.c641dbee.ttf);
  font-display: swap;
}

@font-face {
  font-family: Pridi;
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url(/static/media/Montserrat-Bold.ade91f47.ttf);
  font-display: swap;
}

.ant-upload-picture-card-wrapper, .ant-upload.ant-upload-select-picture-card > .ant-upload, .ant-tooltip .ant-tooltip-inner, header.user-header .notify-holder .notify-count, header.user-header .profile-holder, header.user-header .profile-holder::after, header.main-header .profile-holder, header.main-header .profile-holder::after, .notify-menu-wrapper > li:last-of-type, .admin-modal-wrapper .ant-modal-content .ant-modal-footer, .dashboard-wrapper .ant-table-wrapper .ant-pagination, .home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 span, .contact-section-wrapper .contact-holder .infinty-info-holder > div, .contact-section-wrapper .contact-holder .infinty-info-holder > div .icon-holder, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 span {
  display: flex;
  align-items: center;
  justify-content: center;
}

header.user-header, header.grid-action-header, header.main-header, header.main-header .links-wrapper, header.main-header ul, header.main-header.res-header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/************LINKS ********************/
/************ triangle ********************/
/************MEDIA QUERY ********************/
/*****************prfix*************/
/*****************input placeholder*************/
/***************** slide imgs *************/
/************ spacing ********************/
.mr-0 {
  margin-right: 0px;
}

body.rtl .mr-0 {
  margin-left: 0px;
  margin-right: 0;
}

.ml-0 {
  margin-left: 0px;
}

body.rtl .ml-0 {
  margin-right: 0px;
  margin-left: 0;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.pr-0 {
  padding-right: 0px;
}

body.rtl .pr-0 {
  padding-left: 0px;
  padding-right: 0;
}

.pl-0 {
  padding-left: 0px;
}

body.rtl .pl-0 {
  padding-right: 0px;
  padding-left: 0;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.m-0 {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.p-0 {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.mr-5 {
  margin-right: 5px;
}

body.rtl .mr-5 {
  margin-left: 5px;
  margin-right: 0;
}

.ml-5 {
  margin-left: 5px;
}

body.rtl .ml-5 {
  margin-right: 5px;
  margin-left: 0;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.pr-5 {
  padding-right: 5px;
}

body.rtl .pr-5 {
  padding-left: 5px;
  padding-right: 0;
}

.pl-5 {
  padding-left: 5px;
}

body.rtl .pl-5 {
  padding-right: 5px;
  padding-left: 0;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.m-5 {
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.p-5 {
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.mr-10 {
  margin-right: 10px;
}

body.rtl .mr-10 {
  margin-left: 10px;
  margin-right: 0;
}

.ml-10 {
  margin-left: 10px;
}

body.rtl .ml-10 {
  margin-right: 10px;
  margin-left: 0;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pr-10 {
  padding-right: 10px;
}

body.rtl .pr-10 {
  padding-left: 10px;
  padding-right: 0;
}

.pl-10 {
  padding-left: 10px;
}

body.rtl .pl-10 {
  padding-right: 10px;
  padding-left: 0;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.m-10 {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.p-10 {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.mr-15 {
  margin-right: 15px;
}

body.rtl .mr-15 {
  margin-left: 15px;
  margin-right: 0;
}

.ml-15 {
  margin-left: 15px;
}

body.rtl .ml-15 {
  margin-right: 15px;
  margin-left: 0;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pr-15 {
  padding-right: 15px;
}

body.rtl .pr-15 {
  padding-left: 15px;
  padding-right: 0;
}

.pl-15 {
  padding-left: 15px;
}

body.rtl .pl-15 {
  padding-right: 15px;
  padding-left: 0;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.m-15 {
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.p-15 {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.mr-20 {
  margin-right: 20px;
}

body.rtl .mr-20 {
  margin-left: 20px;
  margin-right: 0;
}

.ml-20 {
  margin-left: 20px;
}

body.rtl .ml-20 {
  margin-right: 20px;
  margin-left: 0;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pr-20 {
  padding-right: 20px;
}

body.rtl .pr-20 {
  padding-left: 20px;
  padding-right: 0;
}

.pl-20 {
  padding-left: 20px;
}

body.rtl .pl-20 {
  padding-right: 20px;
  padding-left: 0;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.m-20 {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.p-20 {
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.mr-25 {
  margin-right: 25px;
}

body.rtl .mr-25 {
  margin-left: 25px;
  margin-right: 0;
}

.ml-25 {
  margin-left: 25px;
}

body.rtl .ml-25 {
  margin-right: 25px;
  margin-left: 0;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.pr-25 {
  padding-right: 25px;
}

body.rtl .pr-25 {
  padding-left: 25px;
  padding-right: 0;
}

.pl-25 {
  padding-left: 25px;
}

body.rtl .pl-25 {
  padding-right: 25px;
  padding-left: 0;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.m-25 {
  margin-top: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.p-25 {
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
}

.mr-30 {
  margin-right: 30px;
}

body.rtl .mr-30 {
  margin-left: 30px;
  margin-right: 0;
}

.ml-30 {
  margin-left: 30px;
}

body.rtl .ml-30 {
  margin-right: 30px;
  margin-left: 0;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pr-30 {
  padding-right: 30px;
}

body.rtl .pr-30 {
  padding-left: 30px;
  padding-right: 0;
}

.pl-30 {
  padding-left: 30px;
}

body.rtl .pl-30 {
  padding-right: 30px;
  padding-left: 0;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.m-30 {
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.p-30 {
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
}

.mr-35 {
  margin-right: 35px;
}

body.rtl .mr-35 {
  margin-left: 35px;
  margin-right: 0;
}

.ml-35 {
  margin-left: 35px;
}

body.rtl .ml-35 {
  margin-right: 35px;
  margin-left: 0;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.pr-35 {
  padding-right: 35px;
}

body.rtl .pr-35 {
  padding-left: 35px;
  padding-right: 0;
}

.pl-35 {
  padding-left: 35px;
}

body.rtl .pl-35 {
  padding-right: 35px;
  padding-left: 0;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.m-35 {
  margin-top: 35px;
  margin-right: 35px;
  margin-bottom: 35px;
  margin-left: 35px;
}

.p-35 {
  padding-top: 35px;
  padding-right: 35px;
  padding-bottom: 35px;
  padding-left: 35px;
}

.mr-40 {
  margin-right: 40px;
}

body.rtl .mr-40 {
  margin-left: 40px;
  margin-right: 0;
}

.ml-40 {
  margin-left: 40px;
}

body.rtl .ml-40 {
  margin-right: 40px;
  margin-left: 0;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pr-40 {
  padding-right: 40px;
}

body.rtl .pr-40 {
  padding-left: 40px;
  padding-right: 0;
}

.pl-40 {
  padding-left: 40px;
}

body.rtl .pl-40 {
  padding-right: 40px;
  padding-left: 0;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.m-40 {
  margin-top: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-left: 40px;
}

.p-40 {
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
}

.mr-45 {
  margin-right: 45px;
}

body.rtl .mr-45 {
  margin-left: 45px;
  margin-right: 0;
}

.ml-45 {
  margin-left: 45px;
}

body.rtl .ml-45 {
  margin-right: 45px;
  margin-left: 0;
}

.mx-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.pr-45 {
  padding-right: 45px;
}

body.rtl .pr-45 {
  padding-left: 45px;
  padding-right: 0;
}

.pl-45 {
  padding-left: 45px;
}

body.rtl .pl-45 {
  padding-right: 45px;
  padding-left: 0;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.m-45 {
  margin-top: 45px;
  margin-right: 45px;
  margin-bottom: 45px;
  margin-left: 45px;
}

.p-45 {
  padding-top: 45px;
  padding-right: 45px;
  padding-bottom: 45px;
  padding-left: 45px;
}

.mr-50 {
  margin-right: 50px;
}

body.rtl .mr-50 {
  margin-left: 50px;
  margin-right: 0;
}

.ml-50 {
  margin-left: 50px;
}

body.rtl .ml-50 {
  margin-right: 50px;
  margin-left: 0;
}

.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.pr-50 {
  padding-right: 50px;
}

body.rtl .pr-50 {
  padding-left: 50px;
  padding-right: 0;
}

.pl-50 {
  padding-left: 50px;
}

body.rtl .pl-50 {
  padding-right: 50px;
  padding-left: 0;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.m-50 {
  margin-top: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  margin-left: 50px;
}

.p-50 {
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}

.delay-0-half {
  -webkit-transition-delay: 0.5s !important;
          transition-delay: 0.5s !important;
}

.delay-1 {
  -webkit-transition-delay: 1s !important;
          transition-delay: 1s !important;
}

.delay-1-half {
  -webkit-transition-delay: 1.5s !important;
          transition-delay: 1.5s !important;
}

.delay-2 {
  -webkit-transition-delay: 2s !important;
          transition-delay: 2s !important;
}

.delay-2-half {
  -webkit-transition-delay: 2.5s !important;
          transition-delay: 2.5s !important;
}

.delay-3 {
  -webkit-transition-delay: 3s !important;
          transition-delay: 3s !important;
}

.delay-3-half {
  -webkit-transition-delay: 3.5s !important;
          transition-delay: 3.5s !important;
}

.delay-4 {
  -webkit-transition-delay: 4s !important;
          transition-delay: 4s !important;
}

.delay-4-half {
  -webkit-transition-delay: 4.5s !important;
          transition-delay: 4.5s !important;
}

.delay-5 {
  -webkit-transition-delay: 5s !important;
          transition-delay: 5s !important;
}

.delay-5-half {
  -webkit-transition-delay: 5.5s !important;
          transition-delay: 5.5s !important;
}

.delay-6 {
  -webkit-transition-delay: 6s !important;
          transition-delay: 6s !important;
}

.delay-6-half {
  -webkit-transition-delay: 6.5s !important;
          transition-delay: 6.5s !important;
}

.delay-7 {
  -webkit-transition-delay: 7s !important;
          transition-delay: 7s !important;
}

.delay-7-half {
  -webkit-transition-delay: 7.5s !important;
          transition-delay: 7.5s !important;
}

.delay-8 {
  -webkit-transition-delay: 8s !important;
          transition-delay: 8s !important;
}

.delay-8-half {
  -webkit-transition-delay: 8.5s !important;
          transition-delay: 8.5s !important;
}

.delay-9 {
  -webkit-transition-delay: 9s !important;
          transition-delay: 9s !important;
}

.delay-9-half {
  -webkit-transition-delay: 9.5s !important;
          transition-delay: 9.5s !important;
}

.delay-10 {
  -webkit-transition-delay: 10s !important;
          transition-delay: 10s !important;
}

.delay-10-half {
  -webkit-transition-delay: 10.5s !important;
          transition-delay: 10.5s !important;
}

.delay-11 {
  -webkit-transition-delay: 11s !important;
          transition-delay: 11s !important;
}

.pb-24 {
  padding-bottom: 1.5rem !important;
}

.pt-24 {
  padding-top: 1.5rem !important;
}

.py-24 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.d-none {
  display: none;
}

.h-90 {
  height: 90%;
}

.float-unset {
  float: unset !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-underline-none {
  text-decoration: none !important;
}

.p-relative {
  position: relative !important;
}

.cursor-p {
  cursor: pointer;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.h-auto {
  height: auto !important;
}

.text-nowrap {
  white-space: nowrap;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

.w-100 {
  width: 100% !important;
}

.w_90 {
  width: 90%;
}

.w_47 {
  width: 47%;
}

.w-80 {
  width: 80%;
}

.text_center {
  text-align: center;
}

.text-right, .home-content .work-wrapper .content .steps-wrapper .step-holder, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder {
  text-align: right !important;
}

body.rtl .text-right, body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder, .home-content .work-wrapper .content .steps-wrapper body.rtl .step-holder, body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps body.rtl .step-holder {
  text-align: left !important;
}

.text-left, .home-content .hero-wrapper > div:first-of-type .content, .home-content .statistics-wrapper .content > div, .home-content .work-wrapper .content .steps-wrapper .step-holder .content, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content {
  text-align: left !important;
}

body.rtl .text-left, body.rtl .home-content .hero-wrapper > div:first-of-type .content, .home-content .hero-wrapper > div:first-of-type body.rtl .content, body.rtl .home-content .statistics-wrapper .content > div, .home-content .statistics-wrapper body.rtl .content > div, body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .content, .home-content .work-wrapper .content .steps-wrapper .step-holder body.rtl .content, body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content, .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder body.rtl .content {
  text-align: right !important;
}

.border_none {
  border: none !important;
}

.fit-cover {
  object-fit: cover;
}

.visibility-hidden {
  visibility: hidden !important;
}

.ant-pagination-options-quick-jumper input {
  display: none;
}

.mt-70 {
  margin-top: 70px !important;
}

.col-direction {
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.align-baseLine {
  align-items: baseline;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.font-18 {
  font-size: 18px;
}

.font-28 {
  font-size: 28px;
}

.bold {
  font-weight: bold;
}

.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.gray-33 {
  color: #333333;
}

.w-50-100 {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .w-50-100 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 379px) {
  .w-50-100.margin-mobile {
    margin: 0px 10px;
  }
}

.mx-7 {
  margin: 0 7px;
}

.mx-20 {
  margin: 0 23px;
}

.m-auto {
  margin: auto;
}

.py-20 {
  padding: 20px 0;
}

.p-25 {
  padding: 25px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pr {
  padding-right: 10px !important;
}

body.rtl .pr {
  padding-right: 0 !important;
  padding-left: 10px !important;
}

.my-40 {
  margin: 40px 0;
}

.text-center {
  text-align: center;
}

/*
  Conditionally apply styles based upon child count
*/
.child-equal-wrapper {
  display: flex;
}

.child-equal-wrapper > div {
  flex: 1 1;
}

.child-equal-wrapper div:first-child:nth-last-child(1),
.child-equal-wrapper div:first-child:nth-last-child(1) ~ div {
  width: 100%;
}

.child-equal-wrapper div:first-child:nth-last-child(2),
.child-equal-wrapper div:first-child:nth-last-child(2) ~ div {
  width: 50%;
}

.child-equal-wrapper div:first-child:nth-last-child(3),
.child-equal-wrapper div:first-child:nth-last-child(3) ~ div {
  width: 33.33333%;
}

.child-equal-wrapper div:first-child:nth-last-child(4),
.child-equal-wrapper div:first-child:nth-last-child(4) ~ div {
  width: 25%;
}

.child-equal-wrapper div:first-child:nth-last-child(5),
.child-equal-wrapper div:first-child:nth-last-child(5) ~ div {
  width: 20%;
}

.child-equal-wrapper div:first-child:nth-last-child(6),
.child-equal-wrapper div:first-child:nth-last-child(6) ~ div {
  width: 16.66667%;
}

.child-equal-wrapper div:first-child:nth-last-child(7),
.child-equal-wrapper div:first-child:nth-last-child(7) ~ div {
  width: 14.28571%;
}

.child-equal-wrapper div:first-child:nth-last-child(8),
.child-equal-wrapper div:first-child:nth-last-child(8) ~ div {
  width: 12.5%;
}

.child-equal-wrapper > div {
  height: 50px;
}

.justify-space-between {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
/*******  created 26/3/2019 ************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
  font-family: lato, Cairo, sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  font-weight: normal;
}

body,
html {
  font-size: 16px;
  overflow-x: hidden;
  width: 100%;
  max-height: 100%;
  position: relative;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus {
  outline: none;
}

input:focus {
  outline: none !important;
}

a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
  outline: none !important;
}

button,
select {
  text-transform: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
div::-webkit-scrollbar,
section::-webkit-scrollbarb,
main::-webkit-scrollbar,
ul::-webkit-scrollbar,
.ant-layout::-webkit-scrollbar {
  background-color: #f4f4f4 !important;
  width: 7px !important;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb,
main::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
.ant-layout::-webkit-scrollbar-thumb {
  background-color: #62c2c9 !important;
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:default,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.slide-right {
  opacity: 0;
  -webkit-transform: translateX(-50px);
          transform: translateX(-50px);
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-left {
  opacity: 0;
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-top {
  opacity: 0;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-bottom {
  opacity: 0;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.rotate-y {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateY(90deg);
          transform: rotateY(90deg);
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.animation-active .slide-right {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  visibility: visible;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-left {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  visibility: visible;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-top {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-bottom {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.animation-active .rotate-y {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
}

.animation-active .charger-img-holder > div {
  top: 0 !important;
}

.animation-active .rotate-moving {
  -webkit-animation-name: chargingMove;
          animation-name: chargingMove;
  -webkit-animation-duration: .7s;
          animation-duration: .7s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

body.rtl .animation-active .rotate-moving {
  -webkit-animation-name: chargingMoveRtl;
          animation-name: chargingMoveRtl;
}

/***********************headeings*********************/
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  color: #4b4b4b;
  line-height: normal;
}

h1 {
  font-size: 2.1875rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5625rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 0.875rem;
  font-weight: normal;
}

/*************TEXT******************/
.black-text {
  color: #000;
}

.white-text {
  color: #FFF !important;
}

.dark-blue-text {
  color: #46c3cc;
}

.primary {
  color: #62c2c9;
}

.primary-2 {
  color: #46c3cc;
}

.primary-3 {
  color: #79dbe2;
}

.primary-4 {
  color: #50cdd6;
}

.secondary {
  color: #2db2bb;
}

.secondary-2 {
  color: #c30c00;
}

.secondary-3 {
  color: #df5950;
}

.gray {
  color: #DADCE0;
}

.gray-2 {
  color: #CBCBCB;
}

.gray-3 {
  color: #f4f4f4;
}

.gray-4 {
  color: #7e7e7e;
}

.gray-5 {
  color: #656565;
}

.gray-6 {
  color: #4b4b4b;
}

.gray-7 {
  color: #242424;
}

/*disabled link*/
.isdisabled, .primary_link:disabled, .secondary_link:disabled, .Grayscale_link:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

/*backgrounds*/
.bg-white {
  background-color: #FFF;
}

.bg-primary {
  background-color: #62c2c9;
}

.bg-primary-2 {
  background-color: #46c3cc;
}

.bg-primary-3 {
  background-color: #79dbe2;
}

.bg-primary-4 {
  background-color: #50cdd6;
}

.bg-secondary {
  background-color: #2db2bb;
}

.bg-secondary-2 {
  background-color: #c30c00;
}

.bg-secondary-3 {
  background-color: #df5950;
}

.bg-gray {
  background-color: #DADCE0;
}

.bg-gray-2 {
  background-color: #CBCBCB;
}

.bg-gray-3 {
  background-color: #f4f4f4;
}

.bg-gray-4 {
  background-color: #7e7e7e;
}

.bg-gray-5 {
  background-color: #656565;
}

.bg-gray-6 {
  background-color: #4b4b4b;
}

.bg-gray-7 {
  background-color: #242424;
}

.primary-fill, .primary-outline, .secondary-fill, .secondary-outline, .grayscale-fill, .grayscale-outline, .grayscale-light-fill, .grayscale-light-outline, .warning-fill, .warning-outline, .info-fill, .info-outline, .gradient-fill, .gradient-light-fill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 33px !important;
  font-size: 13px;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: none;
  border-radius: 17px;
  white-space: nowrap;
  text-decoration: none !important;
}

.sm-btn.primary-fill, .sm-btn.primary-outline, .sm-btn.secondary-fill, .sm-btn.secondary-outline, .sm-btn.grayscale-fill, .sm-btn.grayscale-outline, .sm-btn.grayscale-light-fill, .sm-btn.grayscale-light-outline, .sm-btn.warning-fill, .sm-btn.warning-outline, .sm-btn.info-fill, .sm-btn.info-outline, .sm-btn.gradient-fill, .sm-btn.gradient-light-fill {
  min-height: 24px !important;
  padding: 0 18px;
  font-size: 14px;
  font-weight: normal;
}

.rounded.primary-fill, .rounded.primary-outline, .rounded.secondary-fill, .rounded.secondary-outline, .rounded.grayscale-fill, .rounded.grayscale-outline, .rounded.grayscale-light-fill, .rounded.grayscale-light-outline, .rounded.warning-fill, .rounded.warning-outline, .rounded.info-fill, .rounded.info-outline, .rounded.gradient-fill, .rounded.gradient-light-fill {
  border-radius: 20px;
}

.light-green.primary-fill, .light-green.primary-outline, .light-green.secondary-fill, .light-green.secondary-outline, .light-green.grayscale-fill, .light-green.grayscale-outline, .light-green.grayscale-light-fill, .light-green.grayscale-light-outline, .light-green.warning-fill, .light-green.warning-outline, .light-green.info-fill, .light-green.info-outline, .light-green.gradient-fill, .light-green.gradient-light-fill {
  color: #16adb8 !important;
  border: 1px solid #16adb8 !important;
}

.light-green.primary-fill:hover, .light-green.primary-outline:hover, .light-green.secondary-fill:hover, .light-green.secondary-outline:hover, .light-green.grayscale-fill:hover, .light-green.grayscale-outline:hover, .light-green.grayscale-light-fill:hover, .light-green.grayscale-light-outline:hover, .light-green.warning-fill:hover, .light-green.warning-outline:hover, .light-green.info-fill:hover, .light-green.info-outline:hover, .light-green.gradient-fill:hover, .light-green.gradient-light-fill:hover {
  background-color: #16adb8 !important;
  color: white !important;
}

.light-green-bg.primary-fill, .light-green-bg.primary-outline, .light-green-bg.secondary-fill, .light-green-bg.secondary-outline, .light-green-bg.grayscale-fill, .light-green-bg.grayscale-outline, .light-green-bg.grayscale-light-fill, .light-green-bg.grayscale-light-outline, .light-green-bg.warning-fill, .light-green-bg.warning-outline, .light-green-bg.info-fill, .light-green-bg.info-outline, .light-green-bg.gradient-fill, .light-green-bg.gradient-light-fill {
  background-color: #16adb8 !important;
}

.light-green-bg.primary-fill:focus, .light-green-bg.primary-outline:focus, .light-green-bg.secondary-fill:focus, .light-green-bg.secondary-outline:focus, .light-green-bg.grayscale-fill:focus, .light-green-bg.grayscale-outline:focus, .light-green-bg.grayscale-light-fill:focus, .light-green-bg.grayscale-light-outline:focus, .light-green-bg.warning-fill:focus, .light-green-bg.warning-outline:focus, .light-green-bg.info-fill:focus, .light-green-bg.info-outline:focus, .light-green-bg.gradient-fill:focus, .light-green-bg.gradient-light-fill:focus {
  color: #fff;
}

.lg-btn.primary-fill, .lg-btn.primary-outline, .lg-btn.secondary-fill, .lg-btn.secondary-outline, .lg-btn.grayscale-fill, .lg-btn.grayscale-outline, .lg-btn.grayscale-light-fill, .lg-btn.grayscale-light-outline, .lg-btn.warning-fill, .lg-btn.warning-outline, .lg-btn.info-fill, .lg-btn.info-outline, .lg-btn.gradient-fill, .lg-btn.gradient-light-fill {
  min-height: 38px !important;
  padding: 0 3.125rem;
  font-size: 16px;
  font-weight: normal;
}

@media only screen and (max-width: 575px) {
  .lg-btn.primary-fill, .lg-btn.primary-outline, .lg-btn.secondary-fill, .lg-btn.secondary-outline, .lg-btn.grayscale-fill, .lg-btn.grayscale-outline, .lg-btn.grayscale-light-fill, .lg-btn.grayscale-light-outline, .lg-btn.warning-fill, .lg-btn.warning-outline, .lg-btn.info-fill, .lg-btn.info-outline, .lg-btn.gradient-fill, .lg-btn.gradient-light-fill {
    padding: 0 10px;
  }
}

.xlg-btn.primary-fill, .xlg-btn.primary-outline, .xlg-btn.secondary-fill, .xlg-btn.secondary-outline, .xlg-btn.grayscale-fill, .xlg-btn.grayscale-outline, .xlg-btn.grayscale-light-fill, .xlg-btn.grayscale-light-outline, .xlg-btn.warning-fill, .xlg-btn.warning-outline, .xlg-btn.info-fill, .xlg-btn.info-outline, .xlg-btn.gradient-fill, .xlg-btn.gradient-light-fill {
  min-height: 50px !important;
  padding: 0.75rem 3.25rem;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: 1.06;
  border-radius: 29px;
}

@media only screen and (max-width: 575px) {
  .xlg-btn.primary-fill, .xlg-btn.primary-outline, .xlg-btn.secondary-fill, .xlg-btn.secondary-outline, .xlg-btn.grayscale-fill, .xlg-btn.grayscale-outline, .xlg-btn.grayscale-light-fill, .xlg-btn.grayscale-light-outline, .xlg-btn.warning-fill, .xlg-btn.warning-outline, .xlg-btn.info-fill, .xlg-btn.info-outline, .xlg-btn.gradient-fill, .xlg-btn.gradient-light-fill {
    min-height: 40px !important;
    padding: 0.75rem 1.25rem;
    font-size: 15px;
    border-radius: 20px;
  }
}

.primary-fill:focus, .primary-outline:focus, .secondary-fill:focus, .secondary-outline:focus, .grayscale-fill:focus, .grayscale-outline:focus, .grayscale-light-fill:focus, .grayscale-light-outline:focus, .warning-fill:focus, .warning-outline:focus, .info-fill:focus, .info-outline:focus, .gradient-fill:focus, .gradient-light-fill:focus {
  outline: none !important;
}

.sharpe.primary-fill, .sharpe.primary-outline, .sharpe.secondary-fill, .sharpe.secondary-outline, .sharpe.grayscale-fill, .sharpe.grayscale-outline, .sharpe.grayscale-light-fill, .sharpe.grayscale-light-outline, .sharpe.warning-fill, .sharpe.warning-outline, .sharpe.info-fill, .sharpe.info-outline, .sharpe.gradient-fill, .sharpe.gradient-light-fill {
  border-radius: 4px;
}

.primary-fill {
  background: #62c2c9;
  color: #FFF;
}

.primary-fill:hover {
  background: #62c2c9;
  color: #FFF;
  border-color: #62c2c9;
}

.primary-fill:focus {
  background: #62c2c9;
  color: #FFF;
  border-color: #62c2c9;
}

.primary-outline {
  background-color: transparent;
  color: #62c2c9;
  border: 1px solid #62c2c9;
  border-radius: 4px;
}

.primary-outline:hover {
  color: #fff;
  background-color: #62c2c9;
  border-color: #62c2c9;
}

.secondary-fill {
  background: #2db2bb;
  color: #FFF;
}

.secondary-fill:hover {
  background: #2db2bb;
  color: #FFF;
  border-color: #2db2bb;
}

.secondary-fill:focus {
  background: #2db2bb;
  color: #FFF;
  border-color: #2db2bb;
}

.secondary-outline {
  background-color: transparent;
  color: #2db2bb;
  border: 1px solid #2db2bb;
  border-radius: 4px;
}

.secondary-outline:hover {
  color: #fff;
  background-color: #2db2bb;
  border-color: #2db2bb;
}

.grayscale-fill {
  background: #4b4b4b;
  color: #FFF;
}

.grayscale-fill:hover {
  background: #FFF;
  color: #4b4b4b;
  border: 1px solid #4b4b4b;
}

.grayscale-outline {
  background-color: transparent;
  color: #4b4b4b;
  border: 1px solid #4b4b4b;
  border-radius: 4px;
}

.grayscale-outline:hover {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}

.grayscale-light-fill {
  background: #cccccc;
  color: #FFF;
}

.grayscale-light-fill:hover {
  background: #cccccc;
  color: #FFF;
  border-color: #cccccc;
}

.grayscale-light-fill:focus {
  background: #cccccc;
  color: #FFF;
  border-color: #cccccc;
}

.grayscale-light-outline {
  background-color: transparent;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.grayscale-light-outline:hover {
  color: #fff;
  background-color: #cccccc;
  border-color: #cccccc;
}

.warning-fill {
  background: #d83025;
  color: #FFF;
}

.warning-fill:hover {
  background: #d83025;
  color: #FFF;
  border-color: #d83025;
}

.warning-fill:focus {
  background: #d83025;
  color: #FFF;
  border-color: #d83025;
}

.warning-outline {
  background-color: transparent;
  color: #d83025;
  border: 1px solid #d83025;
  border-radius: 4px;
}

.warning-outline:hover {
  color: #fff;
  background-color: #d83025;
  border-color: #d83025;
}

.info-fill {
  background: #d4b425;
  color: #FFF;
}

.info-fill:hover {
  background: #d4b425;
  color: #FFF;
  border-color: #d4b425;
}

.info-fill:focus {
  background: #d4b425;
  color: #FFF;
  border-color: #d4b425;
}

.info-outline {
  background-color: transparent;
  color: #d4b425;
  border: 1px solid #d4b425;
  border-radius: 4px;
}

.info-outline:hover {
  color: #fff;
  background-color: #d4b425;
  border-color: #d4b425;
}

.gradient-fill {
  background: -webkit-gradient(linear, right top, left top, from(#27b0b9), color-stop(3%, #62c2c9));
  background: -webkit-linear-gradient(right, #27b0b9, #62c2c9 3%);
  background: linear-gradient(to left, #27b0b9, #62c2c9 3%);
  color: #FFF;
}

.gradient-fill:hover {
  background: -webkit-gradient(linear, right top, left top, from(#27b0b9), color-stop(3%, #62c2c9));
  background: -webkit-linear-gradient(right, #27b0b9, #62c2c9 3%);
  background: linear-gradient(to left, #27b0b9, #62c2c9 3%);
  color: #FFF;
  border-color: linear-gradient(to left, #27b0b9, #62c2c9 3%);
}

.gradient-fill:focus {
  background: -webkit-gradient(linear, right top, left top, from(#27b0b9), color-stop(3%, #62c2c9));
  background: -webkit-linear-gradient(right, #27b0b9, #62c2c9 3%);
  background: linear-gradient(to left, #27b0b9, #62c2c9 3%);
  color: #FFF;
  border-color: linear-gradient(to left, #27b0b9, #62c2c9 3%);
}

.gradient-light-fill {
  background: -webkit-linear-gradient(1deg, #27b0b9 1%, #62c2c9 100%);
  background: linear-gradient(89deg, #27b0b9 1%, #62c2c9 100%);
  color: #FFF;
}

.gradient-light-fill:hover {
  background: -webkit-linear-gradient(1deg, #27b0b9 1%, #62c2c9 100%);
  background: linear-gradient(89deg, #27b0b9 1%, #62c2c9 100%);
  color: #FFF;
  border-color: linear-gradient(89deg, #27b0b9 1%, #62c2c9 100%);
}

.gradient-light-fill:focus {
  background: -webkit-linear-gradient(1deg, #27b0b9 1%, #62c2c9 100%);
  background: linear-gradient(89deg, #27b0b9 1%, #62c2c9 100%);
  color: #FFF;
  border-color: linear-gradient(89deg, #27b0b9 1%, #62c2c9 100%);
}

.remove-icon-btn {
  display: flex;
  align-items: center;
  color: #c30c00 !important;
  background: transparent !important;
  border: 0px;
  font-size: 15px;
  font-weight: 700;
  padding: 0;
}

.ant-dropdown.has-close-icon .ant-dropdown-menu-item.warning {
  color: #d83025;
}

.ant-dropdown.has-close-icon .anticon-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -10px;
  right: -13px;
  width: 28px;
  height: 28px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #2db2bb;
  color: #FFF;
}

.ant-dropdown.has-close-icon .anticon-close svg {
  width: 14px;
  height: 14px;
}

.ant-dropdown.has-close-icon .ant-dropdown-menu {
  top: -20px;
  right: 10px;
  padding: 0;
}

.ant-dropdown.has-close-icon .ant-dropdown-menu li {
  color: #383838;
  font-size: 14px;
  line-height: 1.36;
  background-color: #fff !important;
  padding: 9px 33px 9px 15px;
}

body.rtl .ant-dropdown.has-close-icon .anticon-close {
  right: auto;
  left: -13px;
}

body.rtl .ant-dropdown.has-close-icon .ant-dropdown-menu {
  right: auto;
  left: 10px;
}

body.rtl .ant-dropdown.has-close-icon li {
  padding: 9px 15px 9px 33px;
}

.search-dropdown-holder ul > li {
  height: 45px;
  color: #414141;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 14px;
}

/******************links***************/
/*primary_link*/
.primary_link {
  color: #62c2c9;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.primary_link:hover {
  text-decoration: underline;
}

.primary_link:active, .primary_link:focus {
  text-decoration: none;
}

.primary_link:disabled {
  color: red !important;
}

.primary_link:hover {
  color: #46c3cc;
}

/*secondary_link*/
.secondary_link {
  color: #2db2bb;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.secondary_link:hover {
  text-decoration: underline;
}

.secondary_link:active, .secondary_link:focus {
  text-decoration: none;
}

.secondary_link:disabled {
  color: red !important;
}

.secondary_link:hover {
  color: #c30c00;
}

/*Grayscale_link*/
.Grayscale_link {
  color: #4b4b4b;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.Grayscale_link:hover {
  text-decoration: underline;
}

.Grayscale_link:active, .Grayscale_link:focus {
  text-decoration: none;
}

.Grayscale_link:disabled {
  color: red !important;
}

.Grayscale_link:hover {
  color: #000;
}

.ant-radio-group-outline .ant-radio-wrapper {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: bold;
}

.ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-color: #242424;
  color: #242424;
  box-shadow: none !important;
}

.ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner:after {
  background-color: #62c2c9;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  border-color: #62c2c9;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked + span {
  color: #62c2c9;
}

body.rtl .ant-radio-group-outline .ant-radio-wrapper {
  direction: rtl;
}

body.rtl .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.ant-radio-group-outline .ant-radio-wrapper:hover {
  color: #62c2c9;
}

.ant-radio-group-outline .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #62c2c9;
}

.ant-radio-group-outline .ant-radio-wrapper:hover .ant-radio-inner:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio-inner {
  background-color: #FFF;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-disabled:hover .ant-radio-inner:after {
  opacity: 0;
}

.ant-checkbox-wrapper {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: bold;
}

.ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-color: #242424;
  box-shadow: none !important;
}

.ant-checkbox-wrapper .ant-checkbox-inner.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  border-color: #62c2c9;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #62c2c9;
  border-color: #62c2c9;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #62c2c9;
}

.ant-checkbox-wrapper:hover .ant-checkbox + span {
  color: #62c2c9;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
  background-color: #FFF;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover .ant-checkbox + span {
  color: rgba(0, 0, 0, 0.25);
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover .ant-radio-inner:after {
  opacity: 0;
}

.ant-checkbox-wrapper.remove-checkbox .ant-checkbox-inner {
  border-radius: 3px;
  border: solid 2px #e2e2e2;
}

.ant-checkbox-wrapper.remove-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #c30c00;
  border-color: #c30c00;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #62c2c9;
}

.ant-switch {
  min-width: 50px !important;
  height: 16px !important;
  margin-left: 5px !important;
  background-color: #e5e5e5;
}

.ant-switch::after {
  width: 25px;
  height: 25px;
  top: -6px;
  margin-left: -5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #FAFAFA;
}

.ant-switch.ant-switch-checked {
  background-color: #50cdd6;
}

.ant-switch.ant-switch-checked::after {
  margin-left: 5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #62c2c9;
}

.switch-input-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.switch-input-holder span {
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
}

.switch-input-holder .ant-form-item {
  display: flex;
  align-items: center;
}

.switch-input-holder .ant-form-item .ant-form-item-label {
  position: static;
  flex-shrink: 0;
}

.switch-input-holder .ant-form-item .ant-form-item-label label {
  margin-right: 15px;
  font-size: 14px;
  min-width: 50px;
  text-align: left;
}

body.rtl .switch-input-holder .ant-form-item .ant-form-item-label label {
  margin-left: 15px;
  margin-right: 0;
  text-align: right;
}

.upload-input-wrapper .ant-form-item-label {
  top: 19px !important;
  left: 30px !important;
  pointer-events: none;
}

body.rtl .upload-input-wrapper .ant-form-item-label {
  right: 30px !important;
  left: auto !important;
}

.upload-input-wrapper .ant-form-item-label label {
  font-size: 17px !important;
  color: #989898 !important;
  pointer-events: none;
}

.upload-input-wrapper .ant-form-item-children > span {
  display: flex;
  flex-direction: column-reverse;
}

body.rtl .upload-input-wrapper .ant-upload-list-item-actions {
  right: 0;
  left: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.upload-input-wrapper .ant-upload {
  width: 100% !important;
  border: none !important;
  background: none !important;
  height: auto !important;
}

.upload-input-wrapper .ant-upload button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 55px;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #dadce0 !important;
  padding-right: 0 !important;
}

body.rtl .upload-input-wrapper .ant-upload button {
  padding-right: 10px !important;
}

.upload-input-wrapper .ant-upload button i {
  font-size: 22px;
  padding: 6px;
  background: #e0e0e0;
  border-radius: 50%;
  color: #000000;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item {
  padding: 0;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item-info {
  display: flex;
  align-items: center;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item-info:after {
  top: 8px;
  right: 8px;
}

.upload-input-wrapper .ant-upload-list-item-uploading-text {
  display: none;
}

.upload-input-wrapper .ant-upload-list-item-name {
  font-size: 0;
}

.ant-input-number {
  border: none;
  border-bottom: 1px solid #dadce0;
  border-radius: 0;
  width: 100%;
  margin-top: 20px;
}

.ant-input-number.ant-input-number-focused {
  border-bottom: 1px solid #62c2c9;
  outline: none !important;
  box-shadow: none !important;
}

.ant-input-number:hover {
  border-bottom: 1px solid #dadce0;
}

.ant-input-number ::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 16px;
}

.ant-input-number .ant-input-number-input {
  padding: 0 !important;
}

body.rtl .ant-input-number .ant-input-number-input {
  text-align: right !important;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1 !important;
  border: none !important;
}

body.rtl .ant-input-number .ant-input-number-handler-wrap {
  right: unset !important;
  left: 0;
}

.ant-input-number .ant-input-number-handler-down {
  border: none !important;
}

.counter-input-holder {
  display: flex;
  flex-direction: column;
}

.counter-input-holder .counter-header {
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
  margin-bottom: 5px;
}

.counter-input-holder .ant-form-item {
  display: flex !important;
}

.counter-input-holder .ant-form-item .ant-input-number {
  min-height: 55px;
  min-width: 100px;
}

.counter-input-holder .ant-input-number-input {
  height: 100%;
  padding-top: 18px;
  text-align: left;
}

body.rtl .counter-input-holder .ant-input-number-input {
  text-align: right;
}

.counter-input-holder .ant-input-number-input-wrap {
  height: 100%;
  font-size: 16px;
  color: #242424;
}

.counter-input-holder .ant-input-number-handler-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 30px;
  padding-bottom: 8px;
  top: auto;
  bottom: 0;
  right: 0;
  border: 0;
  opacity: 1 !important;
}

body.rtl .counter-input-holder .ant-input-number-handler-wrap {
  left: 0;
  right: auto;
}

.counter-input-holder .ant-input-number-handler-wrap span {
  position: relative;
  height: 16px !important;
  width: 16px;
  font-size: 16px;
  border: 0;
  margin-right: 8px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #dadce0;
  border-radius: 2px;
}

.counter-input-holder .ant-input-number-handler-wrap span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.counter-input-holder .ant-input-number-handler-wrap span.ant-input-number-handler-up::after {
  content: "\002B";
}

.counter-input-holder .ant-input-number-handler-wrap span.ant-input-number-handler-down::after {
  content: "\2212";
}

.counter-input-holder .ant-input-number-handler-wrap span:last-of-type {
  margin-right: 0;
}

body.rtl .counter-input-holder .ant-input-number-handler-wrap span:last-of-type {
  margin-right: 20px;
  margin-left: 0;
}

.counter-input-holder .ant-input-number-handler-wrap span i {
  display: none !important;
}

.ant-input {
  height: 55px;
  border: none;
  border-bottom: 1px solid #dadce0 !important;
  border-radius: 0;
  background-color: transparent !important;
  padding: 0px !important;
  padding-top: 15px !important;
  font-size: 16px;
  font-weight: 500;
  color: #262626;
}

.ant-input ::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 16px;
}

.ant-input:-moz-placeholder {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: 500;
}

.ant-input::-moz-placeholder {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: 500;
}

.ant-input:-ms-input-placeholder {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: 500;
}

.ant-input::-webkit-input-placeholder {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: 500;
}

.ant-input:hover {
  border: none;
  outline: none;
}

.ant-input:focus {
  border: none;
  border-bottom: 1px solid #62c2c9 !important;
  box-shadow: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.ant-input-prefix {
  display: none;
}

.ant-form-item {
  position: relative;
  outline: none;
  /*  &:focus-within{
         .ant-form-item-label{
             top: -6px;
         }
    } */
}

.ant-form-item:focus-within label {
  color: #62c2c9 !important;
}

.ant-form-item .ant-form-item-label {
  position: absolute;
  line-height: unset;
  top: -6px;
  left: 0px;
  z-index: 1;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.ant-form-item .ant-form-item-label label {
  font-size: 16px;
  color: #7e7e7e;
  font-weight: normal;
}

.ant-form-item .ant-form-item-label label::before, .ant-form-item .ant-form-item-label label::after {
  display: none;
}

body.rtl .ant-form-item .ant-form-item-label {
  left: auto;
  right: 0px;
}

.ant-form-item .ant-form-item-control {
  outline: none;
}

.ant-form-item.ant-form-item-with-help {
  margin-bottom: 15px;
  color: #123456;
}

.ant-form-item.ant-form-item-with-help .ant-form-item-label label {
  color: #d83025 !important;
}

.ant-input-affix-wrapper,
.ant-form-item-control-wrapper {
  outline: none;
}

.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover,
.has-error .ant-input,
.has-error .ant-input-number,
.has-error .ant-time-picker-input,
.has-error .ant-input:hover {
  border-bottom: solid 2px #d83025 !important;
  box-shadow: none !important;
}

.ant-form-item-control.has-error .ant-form-explain {
  font-size: 12px;
  font-weight: bold;
  color: #d83025;
  padding: 2px 0px 0 0px;
  text-align: left;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-right-width: 1px !important;
}

.ant-input[disabled] {
  background-color: #f2f2f2;
}

.ant-select .ant-select-selection {
  height: 55px;
  border: none;
  border-bottom: solid 1px #dadce0;
  border-radius: 0;
  background-color: transparent;
  padding: 0 !important;
  padding-top: 15px !important;
  font-size: 16px;
  font-weight: 500;
  color: #242424;
  box-shadow: none;
  margin: 0;
  /* &.ant-select-open {
            .ant-select-selection {}
        } */
}

.ant-select .ant-select-selection .ant-select-selection__rendered {
  margin: 0;
}

.ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: 500;
}

.ant-select.ant-select-focused .ant-select-selection {
  border-bottom: solid 1px #62c2c9 !important;
  box-shadow: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.ant-select.ant-select-auto-complete .ant-select-selection {
  border-bottom: solid 1px #dadce0;
}

.ant-select.ant-select-auto-complete .ant-input {
  border: 0 !important;
  padding: 0 !important;
}

.ant-select.ant-select-auto-complete .ant-select-selection__placeholder {
  line-height: 10px;
  margin: 0;
}

.ant-input-search {
  height: 35px;
}

.ant-input-search .ant-input {
  height: 35px;
  padding-top: 10px !important;
  background-color: rgba(255, 255, 255, 0.47);
}

.ant-input-search .ant-input:-moz-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input::-moz-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input:-ms-input-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input::-webkit-input-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input-suffix i {
  font-size: 18px;
  color: #62c2c9;
}

.ant-upload.ant-upload-select-picture-card {
  display: flex;
  border-radius: 50% !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}

.ant-upload.ant-upload-select-picture-card img {
  height: 100%;
  border-radius: 50%;
}

.ant-upload.ant-upload-select-picture-card.ant-upload-disabled {
  opacity: 0.3;
}

body.rtl .ant-upload.ant-upload-select-picture-card {
  display: flex;
}

.ant-select-dropdown .ant-select-dropdown-menu {
  /* Track */
  /* Handle */
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar {
  width: 8px;
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  background: #747474;
  border-radius: 5px;
}

.ant-tooltip .ant-tooltip-inner {
  min-height: 23px;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  background-color: #000000d9;
}

.ant-pagination {
  /* .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
       display: none !important;
    } */
}

.ant-pagination li:hover {
  border-color: #62c2c9;
}

.ant-pagination li:hover a {
  color: #62c2c9 !important;
  border-color: #62c2c9 !important;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #62c2c9 !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #62c2c9;
}

.ant-pagination .ant-pagination-jump-prev {
  order: -1;
  color: #000000;
}

.ant-pagination .ant-pagination-jump-next {
  order: 999;
  color: #000000;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  display: none;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link-icon,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link-icon {
  opacity: 1;
}

.ant-pagination .ant-pagination-jump-prev .anticon svg,
.ant-pagination .ant-pagination-jump-next .anticon svg {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  font-weight: bold;
}

.ant-pagination .ant-pagination-jump-prev:hover .anticon svg,
.ant-pagination .ant-pagination-jump-next:hover .anticon svg {
  color: #62c2c9 !important;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-bottom: 1px solid #62c2c9 !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #62c2c9;
  border-bottom: 1px solid #62c2c9 !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #d83025;
  border-bottom: 1px solid #d83025 !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.has-error .ant-select-selection {
  border-color: #d83025 !important;
  border-width: 2px !important;
}

.phone-input-holder .ant-form-item-label {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  left: 60px;
}

.phone-input-holder .dlt-phone {
  display: none;
}

.phone-input-holder.has-delete .ant-form-item-children {
  display: flex;
  align-items: center;
}

.phone-input-holder.has-delete .dlt-phone {
  display: flex;
  cursor: pointer;
  margin-right: -20px;
}

.search-wrapper .ant-input-search .ant-input {
  min-width: 250px;
  padding-top: 0 !important;
  border-radius: 0;
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #b6b6b6;
  padding: 0 4px !important;
}

@media only screen and (max-width: 767px) {
  .search-wrapper .ant-input-search .ant-input {
    min-width: 120px;
  }
}

.search-wrapper .ant-input-search .ant-input:focus, .search-wrapper .ant-input-search .ant-input:hover {
  border: 0 !important;
  border-bottom: 1px solid #62c2c9 !important;
}

.search-wrapper .ant-input-search .ant-input:focus + .ant-input-suffix i, .search-wrapper .ant-input-search .ant-input:hover + .ant-input-suffix i {
  color: #62c2c9;
  -webkit-transform: scale(1.1) rotate(2deg);
          transform: scale(1.1) rotate(2deg);
}

.search-wrapper .ant-input-search .ant-input-suffix {
  right: 0;
}

.search-wrapper .ant-input-search .ant-input-suffix i {
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  color: #b6b6b6;
}

.comment-textArea {
  border: solid 0.5px #cacaca !important;
  padding: 4px 11px !important;
}

body.rtl .comment-textArea {
  text-align: right;
}

.comment-textArea.ant-input:focus {
  border: solid 0.5px #cacaca !important;
}

.tel-wrapper .react-tel-input {
  display: flex;
  align-items: stretch;
  flex-direction: row-reverse;
  height: 45px;
  outline: 0;
}

.tel-wrapper .react-tel-input .selected-flag {
  margin: 3px 5px;
  /* body.rtl &{
              margin: unset;
          } */
  width: 100%;
}

.tel-wrapper .react-tel-input .form-control {
  box-shadow: none;
}

.tel-wrapper .react-tel-input .flag-dropdown {
  position: static;
  width: 50px;
  height: 30px;
  margin-right: -50px;
  margin-top: 15px;
  z-index: 4;
  display: flex;
  border: none;
  background: transparent;
}

body.rtl .tel-wrapper .react-tel-input .flag-dropdown {
  margin-right: 0 !important;
  margin-left: -40px !important;
}

.tel-wrapper .react-tel-input .flag-dropdown:hover {
  background: transparent !important;
}

.tel-wrapper .react-tel-input .flag-dropdown.open {
  background: transparent !important;
}

.tel-wrapper .react-tel-input .form-control {
  border: none !important;
  border-bottom: 1px solid #dadce0 !important;
  padding-top: 15px;
  border-radius: 0;
}

.tel-wrapper .react-tel-input .form-control.open {
  z-index: unset !important;
}

.tel-wrapper .country-list {
  width: 100% !important;
  top: 40px;
}

.tel-wrapper .country-list .search {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 5px !important;
  padding-bottom: 0;
}

.tel-wrapper .country-list .search .search-emoji {
  display: none;
}

.tel-wrapper .country-list .country {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px !important;
  height: 35px;
}

.tel-wrapper .country-list .country .flag {
  margin-left: 10px;
}

body.rtl .tel-wrapper .country-list .country .flag {
  margin-left: 0;
  margin-right: 10px;
}

.react-tel-input input[type=text],
.react-tel-input input[type=tel] {
  width: 100% !important;
  min-height: 55px;
  padding-left: 45px !important;
}

.react-tel-input {
  min-height: 55px;
}

.react-tel-input .flag-dropdown {
  min-width: 50px;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 11px;
}

.react-tel-input .flag-dropdown:hover .selected-flag {
  background-color: #eaeaea !important;
}

.react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  background: #eaeaea !important;
  border-radius: 3px 0 0 0;
}

body.rtl .react-tel-input input[type=text],
body.rtl .react-tel-input input[type=tel] {
  padding-right: 45px !important;
  direction: ltr !important;
  text-align: end !important;
}

.react-tel-input .flag-dropdown.open-dropdown {
  background: #eaeaea !important;
  border-radius: 3px 0 0 0;
}

.ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.lottie-spinner {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}

.croping-modal .ant-modal-close .ant-modal-close-x {
  width: 28px;
  height: 56px;
  font-size: 16px;
  line-height: 34px;
}

.croping-modal.rounded .cropper-view-box,
.croping-modal.rounded .cropper-move {
  border-radius: 50%;
}

.croping-modal.rounded .ant-modal-close {
  top: 0px !important;
}

.croping-modal.rounded .ant-modal-close .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.pac-item {
  text-align: left !important;
}

body.rtl .pac-item {
  text-align: right !important;
}

.gm-bundled-control-on-bottom {
  top: 0px !important;
  bottom: unset !important;
}

header.user-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 55;
  border-bottom: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  min-height: 60px;
  padding-left: 55px;
  padding-right: calc( 14px + 20px);
}

body.rtl header.user-header {
  padding-left: calc(14px + 20px);
  padding-right: 55px;
}

header.user-header p {
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1.3px;
  color: #62c2c9;
}

body.rtl header.user-header p {
  letter-spacing: normal;
}

header.user-header .ant-input-search {
  display: none;
  width: 275px;
}

header.user-header .ant-input-search .ant-input {
  padding: 0 15px !important;
}

header.user-header .ant-input-search .ant-input-suffix {
  right: 15px;
}

body.rtl header.user-header .ant-input-search .ant-input-suffix {
  right: auto;
  left: 15px;
}

@media only screen and (max-width: 767px) {
  header.user-header .ant-input-search {
    width: 150px;
  }
}

header.user-header .notify-holder {
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}

header.user-header .notify-holder .notify-count {
  box-sizing: content-box;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 17px;
  height: 17px;
  border-radius: 12px;
  border: solid 1.5px #f5f5f5;
  background-color: #62c2c9;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
}

body.rtl header.user-header .notify-holder .notify-count {
  left: -6px;
  right: auto;
}

header.user-header .profile-holder {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #e5e5e5;
  box-sizing: content-box;
  cursor: pointer;
}

header.user-header .profile-holder::after {
  content: "\2193";
  font-size: 10px;
  font-weight: 400;
  width: 20px;
  height: 17px;
  border-radius: 0 50% 50% 0;
  background-color: #e5e5e5;
  position: absolute;
  top: 50%;
  right: -20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

body.rtl header.user-header .profile-holder::after {
  right: auto;
  left: -20px;
  border-radius: 50% 0 0 50%;
}

header.user-header .profile-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header.user-header .lang-btn {
  font-size: 16px;
  color: #46c3cc;
  margin: 0 15px;
  cursor: pointer;
}

header.user-header .ant-divider {
  height: 35px;
  margin: 0;
}

header.grid-action-header {
  height: 66px;
  background-color: #f5f5f5;
  padding-left: 26px;
  padding-right: 14px;
}

body.rtl header.grid-action-header {
  padding-right: 26px;
  padding-left: 14px;
}

header.grid-action-header .action-btns {
  margin-left: 20px;
}

header.grid-action-header .action-btns > span {
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

header.grid-action-header .action-btns > span:last-of-type {
  margin-right: 0;
}

body.rtl header.grid-action-header .action-btns > span:last-of-type {
  margin-left: 0;
}

header.grid-action-header .action-btns > span[disabled] {
  pointer-events: none;
}

header.grid-action-header .action-btns > span.duplicate-btn {
  background-image: url(/static/media/duplicate-icon-active.e7366fb1.svg);
}

header.grid-action-header .action-btns > span.duplicate-btn[disabled] {
  background-image: url(/static/media/duplicate-icon.550db4ae.svg);
}

header.grid-action-header .action-btns > span.delete-btn {
  background-image: url(/static/media/delete-icon-active.934a99b4.svg);
}

header.grid-action-header .action-btns > span.delete-btn[disabled] {
  background-image: url(/static/media/delete-icon.b8fa947f.svg);
}

header.grid-action-header .action-btns > span.archive-btn {
  background-image: url(/static/media/archive-icon-active.e54096d7.svg);
}

header.grid-action-header .action-btns > span.archive-btn[disabled] {
  background-image: url(/static/media/archive-icon.042b8a1b.svg);
}

header.grid-action-header .action-btns > span.edit-btn {
  background-image: url(/static/media/edit-icon-active.05126f7d.svg);
}

header.grid-action-header .action-btns > span.edit-btn[disabled] {
  background-image: url(/static/media/edit-icon.2ec2b9f8.svg);
}

body.rtl header.grid-action-header .action-btns > span {
  margin-left: 0px;
  margin-right: 20px;
}

header.grid-action-header .cascade-select {
  margin-left: 20px;
}

header.grid-action-header .cascade-select .ant-select-selection {
  height: 30px;
  width: 200px;
  padding: 5px !important;
  background: transparent;
}

@media only screen and (max-width: 767px) {
  header.grid-action-header .cascade-select .ant-select-selection {
    width: 120px;
  }
}

header.grid-action-header .cascade-select .ant-select-selection__rendered {
  height: 100%;
  display: flex;
  align-items: center;
}

header.main-header {
  height: 70px;
  min-height: 70px;
  background-color: #ffffff;
  padding: 15px 35px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 60;
  -webkit-transition: background-color ease-in-out 0.4s;
  transition: background-color ease-in-out 0.4s;
}

header.main-header .logo {
  cursor: pointer;
}

header.main-header .dark-logo {
  display: block;
  width: 150px;
}

@media only screen and (max-width: 1199px) {
  header.main-header .dark-logo {
    width: 120px !important;
  }
}

header.main-header .white-global {
  display: none;
}

header.main-header.active {
  -webkit-transition: background-color ease-in-out 0.4s;
  transition: background-color ease-in-out 0.4s;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(255, 255, 255, 0.84);
}

header.main-header.active .lang-btn {
  color: #000000;
}

header.main-header.active .white-global {
  display: inline-block;
}

header.main-header.active .white-global + img {
  display: none;
}

header.main-header.active ul > li a {
  color: #000000;
}

header.main-header .lang-btn {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.07;
  letter-spacing: 0.38px;
  color: #16adb8;
  outline: none !important;
  font-family: Cairo;
}

body.rtl header.main-header .lang-btn {
  font-family: Pridi;
  margin-left: auto;
}

@media only screen and (max-width: 1199px) {
  header.main-header .lang-btn {
    margin-top: -40px;
    padding: 0;
    display: flex;
    align-items: center;
  }
  header.main-header .lang-btn img {
    margin: 0 !important;
    margin-right: 6px !important;
    padding: 0 1px;
  }
  body.rtl header.main-header .lang-btn img {
    margin-left: 6px !important;
    margin-right: 0 !important;
  }
}

header.main-header .links-wrapper button {
  font-size: 18px;
  font-weight: normal;
}

header.main-header ul > li {
  margin-right: 35px;
}

body.rtl header.main-header ul > li {
  margin-right: 0;
  margin-left: 35px;
}

header.main-header ul > li a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.22;
  color: #242430;
}

header.main-header ul > li a.active {
  font-weight: bold !important;
}

header.main-header.transparent-header {
  position: fixed;
  right: 0;
  left: 0;
  background-color: transparent;
}

header.main-header.transparent-header .lang-btn {
  color: #ffffff;
}

header.main-header.transparent-header .white-logo {
  display: block;
}

header.main-header.transparent-header .white-global {
  display: inline-block;
}

header.main-header.transparent-header .white-global + img {
  display: none;
}

header.main-header.transparent-header ul > li a {
  color: #ffffff;
}

header.main-header .profile-holder {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
  margin-right: 20px;
}

body.rtl header.main-header .profile-holder {
  margin-left: 20px;
  margin-right: 0;
}

header.main-header .profile-holder::after {
  content: url(/static/media/play-button.89fcb1e4.svg);
  font-size: 10px;
  font-weight: 400;
  width: 15.1px;
  height: 8.8px;
  position: absolute;
  top: 50%;
  right: -20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

body.rtl header.main-header .profile-holder::after {
  right: auto;
  left: -20px;
  border-radius: 50% 0 0 50%;
}

header.main-header .profile-holder.has-notifications::before {
  content: "";
  font-size: 10px;
  font-weight: 400;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #db4a41;
  position: absolute;
  top: 5px;
  right: -5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

body.rtl header.main-header .profile-holder.has-notifications::before {
  right: auto;
  left: -5px;
  border-radius: 50%;
}

header.main-header .profile-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header.main-header.res-header {
  padding: 10px 20px;
}

header.main-header.res-header .lang-btn {
  color: #16adb8 !important;
}

header.main-header.res-header .white-global {
  display: none;
}

header.main-header.res-header .white-global + img {
  display: inline-block;
}

header.main-header.res-header.active .btn2 .icon, header.main-header.res-header.transparent-header .btn2 .icon {
  background-color: #FFF;
}

header.main-header.res-header.active .btn2 .icon::after, header.main-header.res-header.active .btn2 .icon::before, header.main-header.res-header.transparent-header .btn2 .icon::after, header.main-header.res-header.transparent-header .btn2 .icon::before {
  background-color: #FFF;
}

header.main-header.res-header.active .btn2 .user-img-holder + .menu-icon-holder .icon, header.main-header.res-header.transparent-header .btn2 .user-img-holder + .menu-icon-holder .icon {
  background-color: #16adb8;
}

header.main-header.res-header.active .btn2 .user-img-holder + .menu-icon-holder .icon::after, header.main-header.res-header.active .btn2 .user-img-holder + .menu-icon-holder .icon::before, header.main-header.res-header.transparent-header .btn2 .user-img-holder + .menu-icon-holder .icon::after, header.main-header.res-header.transparent-header .btn2 .user-img-holder + .menu-icon-holder .icon::before {
  background-color: #16adb8;
}

header.main-header.res-header .logo img {
  width: 80%;
}

header.main-header.res-header ul > li {
  margin-right: 35px;
}

body.rtl header.main-header.res-header ul > li {
  margin-right: 0;
  margin-left: 35px;
}

header.main-header.res-header ul > li a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.22;
  color: #242430;
}

header.main-header.res-header .profile-info-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
}

header.main-header.res-header .profile-info-holder .img-holder {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

header.main-header.res-header .profile-info-holder .img-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header.main-header.res-header .profile-info-holder .details-holder {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body.rtl header.main-header.res-header .profile-info-holder .details-holder {
  margin-right: 12px;
  margin-left: 0;
}

header.main-header.res-header .profile-info-holder .details-holder .name {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

header.main-header.res-header .profile-info-holder .details-holder a {
  font-size: 14px;
  font-weight: normal;
  line-height: 2.43;
  color: #16adb8;
  margin-right: 1.25rem;
}

header.main-header.res-header .profile-info-holder .details-holder a:last-child {
  margin-right: 0;
}

body.rtl header.main-header.res-header .profile-info-holder .details-holder a {
  margin-left: 1.25rem;
  margin-right: 0;
}

body.rtl header.main-header.res-header .profile-info-holder .details-holder a:last-child {
  margin-left: 0;
}

@media only screen and (max-width: 575px) {
  header.main-header.res-header .profile-info-holder .details-holder a {
    font-size: 12px;
    white-space: nowrap;
  }
}

header.main-header.res-header .profile-info-holder .notify-holder {
  position: relative;
  margin-right: 10px;
  align-self: start;
}

body.rtl header.main-header.res-header .profile-info-holder .notify-holder {
  margin-left: 10px;
  margin-right: 0;
}

body.rtl header.main-header.res-header .profile-info-holder .notify-holder:after {
  right: auto;
  left: 2px;
}

header.main-header.res-header .profile-info-holder .notify-holder:after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #db4a41;
  position: absolute;
  top: 4px;
  right: 2px;
  border-radius: 50%;
}

header.main-header.res-header .links-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  align-items: flex-start;
  justify-content: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 80%;
  margin-left: auto;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.6);
  background-color: #f6f8f9;
  padding-bottom: 130px;
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: 555;
}

body.rtl header.main-header.res-header .links-wrapper {
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
  margin-right: auto;
  margin-left: unset;
  align-items: center;
}

body.rtl header.main-header.res-header .links-wrapper + .overlay {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

header.main-header.res-header .links-wrapper + .overlay {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(92, 92, 92, 0.7);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

header.main-header.res-header .links-wrapper ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

header.main-header.res-header .links-wrapper ul > li {
  min-height: 50px;
}

header.main-header.res-header .links-wrapper ul > li a {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

header.main-header.res-header .links-wrapper ul > li a.active {
  font-weight: bold !important;
}

header.main-header.res-header .links-wrapper ul + button,
header.main-header.res-header .links-wrapper ul + a {
  width: 130px;
  min-height: 40px !important;
  border-radius: 29px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
  bottom: -60px;
}

header.main-header.res-header .btn2 {
  width: 25px;
  height: 30px;
  top: 100px;
  left: 120px;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  z-index: 556;
  cursor: pointer;
}

header.main-header.res-header .btn2 .icon {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  height: 3px;
  width: 24px;
  top: 30px;
  background-color: #62c2c9;
}

header.main-header.res-header .btn2 .icon:before {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  width: 24px;
  height: 3px;
  background-color: #62c2c9;
  content: "";
  top: -8px;
}

header.main-header.res-header .btn2 .icon:after {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  position: absolute;
  width: 24px;
  height: 3px;
  background-color: #62c2c9;
  content: "";
  top: 8px;
}

header.main-header.res-header .btn2 .user-img-holder {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  top: -5px;
  left: -30px;
  -webkit-transition: opacity ease-in-out 0.4s;
  transition: opacity ease-in-out 0.4s;
}

body.rtl header.main-header.res-header .btn2 .user-img-holder {
  right: -30px;
  left: auto;
}

header.main-header.res-header .btn2 .user-img-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header.main-header.res-header .btn2 .user-img-holder + .menu-icon-holder {
  position: relative;
  height: 26px;
  width: 26px;
  background: #FFF;
  top: -28px;
  border-radius: 50%;
  left: -5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body.rtl header.main-header.res-header .btn2 .user-img-holder + .menu-icon-holder {
  right: -5px;
  left: auto;
}

body.rtl header.main-header.res-header .btn2 .user-img-holder + .menu-icon-holder .icon {
  right: 1px;
  left: auto;
}

header.main-header.res-header .btn2 .user-img-holder + .menu-icon-holder .icon {
  top: 11px;
  left: 1px;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

header.main-header.res-header.open .btn2 .icon {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  background: transparent;
}

header.main-header.res-header.open .btn2 .icon:before {
  -webkit-transform: rotateZ(45deg) scaleX(1.25) translate(6px, 4px);
          transform: rotateZ(45deg) scaleX(1.25) translate(6px, 4px);
  background-color: #555555;
}

header.main-header.res-header.open .btn2 .icon:after {
  -webkit-transform: rotateZ(-45deg) scaleX(1.25) translate(5px, -4px);
          transform: rotateZ(-45deg) scaleX(1.25) translate(5px, -4px);
  background-color: #555555;
}

header.main-header.res-header.open .user-img-holder {
  opacity: 0;
}

header.main-header.res-header.open .user-img-holder + .menu-icon-holder {
  box-shadow: none;
  background: transparent;
}

header.main-header.res-header.open .links-wrapper {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

header.main-header.res-header.open .links-wrapper + .overlay {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

body.rtl header.main-header.res-header.open .links-wrapper {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

body.rtl header.main-header.res-header.open .links-wrapper + .overlay {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.user-menu-wrapper {
  padding: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-width: 170px;
  position: relative;
}

.user-menu-wrapper.web-dropdown::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border: 1em solid black;
  border-color: transparent transparent white white;
  right: 44px;
  top: -12px;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  box-shadow: -2px 3px 3px -2px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

body.rtl .user-menu-wrapper.web-dropdown::before {
  left: 22px;
  right: unset !important;
}

.user-menu-wrapper > li {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 12px 32px 12px 23px;
  z-index: 2;
  position: relative;
}

.user-menu-wrapper > li a {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  color: #565656;
}

.user-menu-wrapper > li:first-of-type {
  border: 1px solid #f5f5f5;
}

.user-menu-wrapper > li:first-of-type span {
  font-size: 9px;
  font-weight: bold;
  color: #565656;
  letter-spacing: 0.18px;
}

.user-menu-wrapper > li .link-d {
  font-size: 18px;
  line-height: 1.89;
  color: #5f5f5f;
  -webkit-padding-start: 13.5px;
          padding-inline-start: 13.5px;
}

.user-menu-wrapper > li .logout {
  color: #db4a41;
  line-height: 1.89;
  font-size: 18px;
  -webkit-padding-start: 13px;
          padding-inline-start: 13px;
}

.user-menu-wrapper > li:hover {
  background-color: #f0f3f8;
}

.user-menu-wrapper .profile-img {
  width: 54px;
  height: 54px;
  min-width: 54px;
  max-width: 54px;
  min-height: 54px;
  max-height: 54px;
  border-radius: 50%;
}

.user-menu-wrapper .profile-link-holder {
  -webkit-padding-start: 23px;
          padding-inline-start: 23px;
}

.user-menu-wrapper .profile-button {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(16%, #5CAE30), color-stop(115%, #86D958));
  background-image: -webkit-linear-gradient(left, #5CAE30 16%, #86D958 115%);
  background-image: linear-gradient(to right, #5CAE30 16%, #86D958 115%);
  width: 120px;
  height: 25px;
  border-radius: 13px;
  color: white;
  font-size: 12px !important;
}

.user-menu-wrapper .user-name {
  font-size: 18px;
  line-height: 1.89;
  color: #000000;
}

.user-menu-wrapper .notification-count {
  width: 21px !important;
  height: 21px !important;
}

.notify-menu-wrapper {
  padding: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d6d6d6;
}

.notify-menu-wrapper header {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 18px;
  color: #ffffff;
  background-color: #62c2c9;
}

.notify-menu-wrapper > li:not(:last-of-type) {
  min-height: 85px;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 30px;
  background-color: rgba(57, 103, 169, 0.09) !important;
  border-bottom: 1px solid #d6d6d6;
}

.notify-menu-wrapper > li:not(:last-of-type) a {
  padding: 0;
  margin: 0;
  position: relative;
}

.notify-menu-wrapper > li:not(:last-of-type) a h6 {
  font-size: 14px;
  font-weight: bold;
  color: #242424;
}

.notify-menu-wrapper > li:not(:last-of-type) a span {
  font-size: 10px;
  font-weight: bold;
  color: #565656;
}

.notify-menu-wrapper > li:not(:last-of-type) a::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #62c2c9;
  border-radius: 50%;
  position: absolute;
  left: -20px;
}

body.rtl .notify-menu-wrapper > li:not(:last-of-type) a::before {
  left: auto;
  right: -20px;
}

.notify-menu-wrapper > li:not(:last-of-type).seen {
  background-color: #ffffff !important;
}

.notify-menu-wrapper > li:not(:last-of-type).seen a::before {
  display: none;
}

body.rtl .notify-menu-wrapper > li:not(:last-of-type) {
  padding: 12px 30px 12px 12px;
}

.notify-menu-wrapper > li:last-of-type {
  min-height: 40px;
}

.notify-menu-wrapper > li:last-of-type a {
  padding: 0;
  margin: 0;
}

.notify-menu-wrapper > li:last-of-type a span {
  font-size: 12px;
  font-weight: bold;
  color: #565656;
}

.notify-menu-wrapper > li:last-of-type:hover {
  background-color: transparent;
}

.main-menu-root {
  /* left: 1023px !important;
  top: 73px !important;
  body.rtl &{
    right: 1023px !important;
    top: 73px !important;
    left: unset !important;
  } */
}

.main-menu-root .ant-dropdown-menu {
  display: flex !important;
  flex-direction: column !important;
}

.main-menu-root .ant-dropdown-menu-item {
  margin-right: unset;
  width: 100%;
}

body.rtl .main-menu-root .ant-dropdown-menu-item {
  margin-left: unset;
}

aside.dashboard-menu, .side_menu_wrapper.dashboard-menu {
  background-color: #62c2c9;
  overflow: visible !important;
}

aside.dashboard-menu .anticon.trigger, .side_menu_wrapper.dashboard-menu .anticon.trigger {
  position: absolute;
  z-index: 555;
  right: -28px;
  top: 21px;
}

@media only screen and (max-width: 767px) {
  aside.dashboard-menu .anticon.trigger, .side_menu_wrapper.dashboard-menu .anticon.trigger {
    display: none;
  }
}

body.rtl aside.dashboard-menu .anticon.trigger, body.rtl .side_menu_wrapper.dashboard-menu .anticon.trigger {
  left: -28px;
  right: auto;
}

aside.dashboard-menu .ant-menu, .side_menu_wrapper.dashboard-menu .ant-menu {
  background: transparent !important;
  box-shadow: none !important;
}

aside.dashboard-menu .ant-menu > li, .side_menu_wrapper.dashboard-menu .ant-menu > li {
  border-bottom: 1px solid #FFF;
  background: transparent;
  color: #ffffff;
  min-height: 77px;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

aside.dashboard-menu .ant-menu > li a, .side_menu_wrapper.dashboard-menu .ant-menu > li a {
  color: #ffffff;
}

aside.dashboard-menu .ant-menu > li .ant-menu-submenu-title, .side_menu_wrapper.dashboard-menu .ant-menu > li .ant-menu-submenu-title {
  min-height: 77px;
  line-height: 77px;
  margin: 0;
}

body.rtl aside.dashboard-menu .ant-menu > li .ant-menu-submenu-title, body.rtl .side_menu_wrapper.dashboard-menu .ant-menu > li .ant-menu-submenu-title {
  padding-right: 16px;
}

aside.dashboard-menu .ant-menu > li:last-of-type, .side_menu_wrapper.dashboard-menu .ant-menu > li:last-of-type {
  border-bottom: none;
}

aside.dashboard-menu .ant-menu > li.ant-menu-submenu-open, .side_menu_wrapper.dashboard-menu .ant-menu > li.ant-menu-submenu-open {
  background-color: #80d6981c !important;
}

aside.dashboard-menu .ant-menu > li.ant-menu-submenu-open, .side_menu_wrapper.dashboard-menu .ant-menu > li.ant-menu-submenu-open {
  padding-bottom: 30px;
}

aside.dashboard-menu .ant-menu > li.ant-menu-submenu-open .ant-menu-submenu-title, .side_menu_wrapper.dashboard-menu .ant-menu > li.ant-menu-submenu-open .ant-menu-submenu-title {
  min-height: 50px;
  line-height: 50px;
}

aside.dashboard-menu .ant-menu > li.ant-menu-submenu-open.ant-menu-submenu-vertical, .side_menu_wrapper.dashboard-menu .ant-menu > li.ant-menu-submenu-open.ant-menu-submenu-vertical {
  padding-bottom: 0;
}

aside.dashboard-menu .ant-menu .ant-menu-item, .side_menu_wrapper.dashboard-menu .ant-menu .ant-menu-item {
  margin-top: 0;
}

aside.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected, .side_menu_wrapper.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected {
  background-color: #80d6981c;
  position: relative;
}

aside.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected::after, .side_menu_wrapper.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected::after {
  contain: "";
  position: absolute;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #FFF;
}

body.rtl aside.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected::after, body.rtl .side_menu_wrapper.dashboard-menu .ant-menu .ant-menu-item.ant-menu-item-selected::after {
  right: 0;
  left: auto;
}

aside.dashboard-menu .ant-menu.ant-menu-vertical > li, .side_menu_wrapper.dashboard-menu .ant-menu.ant-menu-vertical > li {
  color: #FFF;
}

aside.dashboard-menu .ant-menu.ant-menu-sub > li, .side_menu_wrapper.dashboard-menu .ant-menu.ant-menu-sub > li {
  height: 35px !important;
  min-height: 35px;
  line-height: 35px !important;
  border-bottom: none;
}

aside.dashboard-menu-light, .side_menu_wrapper.dashboard-menu-light {
  overflow: visible !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 551;
}

aside.dashboard-menu-light .infinitye-icons, .side_menu_wrapper.dashboard-menu-light .infinitye-icons {
  margin-right: 8px;
}

body.rtl aside.dashboard-menu-light .infinitye-icons, body.rtl .side_menu_wrapper.dashboard-menu-light .infinitye-icons {
  margin-right: 0;
  margin-left: 8px;
}

aside.dashboard-menu-light .infinitye-icons::before, .side_menu_wrapper.dashboard-menu-light .infinitye-icons::before {
  color: #474747;
}

aside.dashboard-menu-light .infinitye-icons.active::before, .side_menu_wrapper.dashboard-menu-light .infinitye-icons.active::before {
  color: #79dbe2;
}

aside.dashboard-menu-light .anticon.trigger, .side_menu_wrapper.dashboard-menu-light .anticon.trigger {
  position: absolute;
  z-index: 555;
  right: -40px;
  top: 21px;
  font-size: 20px;
  color: #62c2c9;
}

@media only screen and (max-width: 767px) {
  aside.dashboard-menu-light .anticon.trigger, .side_menu_wrapper.dashboard-menu-light .anticon.trigger {
    display: none;
  }
}

body.rtl aside.dashboard-menu-light .anticon.trigger, body.rtl .side_menu_wrapper.dashboard-menu-light .anticon.trigger {
  left: -28px;
  right: auto;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

aside.dashboard-menu-light .ant-layout-sider-children > ul, .side_menu_wrapper.dashboard-menu-light .ant-layout-sider-children > ul {
  border: 0;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul li a, .side_menu_wrapper.dashboard-menu-light .ant-layout-sider-children > ul li a {
  display: flex;
  align-items: center;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul li .anticon, .side_menu_wrapper.dashboard-menu-light .ant-layout-sider-children > ul li .anticon {
  font-size: 20px;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul li span, .side_menu_wrapper.dashboard-menu-light .ant-layout-sider-children > ul li span {
  font-size: 16px;
  font-weight: 500;
  color: #474747;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul.ant-menu-inline-collapsed li.ant-menu-item span, .side_menu_wrapper.dashboard-menu-light .ant-layout-sider-children > ul.ant-menu-inline-collapsed li.ant-menu-item span {
  display: none;
}

aside.dashboard-menu-light .ant-layout-sider-children > ul.ant-menu-inline-collapsed .ant-menu-submenu-title span > span, .side_menu_wrapper.dashboard-menu-light .ant-layout-sider-children > ul.ant-menu-inline-collapsed .ant-menu-submenu-title span > span {
  display: none;
}

aside.dashboard-menu-light + .ant-layout > header, .side_menu_wrapper.dashboard-menu-light + .ant-layout > header {
  background-color: #FFF;
}

aside .logo, .side_menu_wrapper .logo {
  text-align: center;
  height: 60px;
  padding-top: 8px;
  border-bottom: 1px solid #fff;
}

.ant-menu-submenu-title:hover span i {
  color: #2DBB54 !important;
}

.ant-menu-submenu-popup .ant-menu-sub .ant-menu-item i {
  -webkit-padding-end: 5px;
          padding-inline-end: 5px;
}

.admin_main_drawer .ant-drawer-body {
  padding: 24px 0px;
}

.ant-table-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.mid-grid .ant-table-body {
  height: 400px;
  overflow: auto;
}

.dashboard-wrapper .more-icon-holder i {
  font-size: 28px;
  cursor: pointer;
  color: #818385;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 25%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div {
  justify-content: flex-end !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(/static/media/fuel.6e896f20.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(/static/media/car.df6767a9.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(/static/media/car-out.8602912f.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  content: url(/static/media/flag.e7c35c92.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td .no-charger-out {
  color: #474747;
  font-size: 16px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  color: #000000;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2) {
  text-align: right !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  color: #16adb8;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(4) {
  color: #c30707;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 25%;
  text-align: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
  display: none;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  padding: 0 45px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 24%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div, .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div {
  justify-content: flex-end;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(/static/media/user.ee4d8493.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(/static/media/email.b26422be.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(/static/media/phone.628e6962.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2), .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  color: #000000;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2), body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  text-align: right !important;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 24%;
  text-align: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder {
  display: flex;
  align-items: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder .img-holder {
  width: 35px;
  height: 35px;
  max-width: 35px;
  max-height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder .img-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder {
  color: #16adb8;
  text-transform: capitalize;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inactive {
  color: #c30707;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.delivered {
  color: #2d6fbb;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inprogress {
  color: #bbbb2d;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.requested {
  color: #2db2bb;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  width: 5%;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type div::after {
  display: none;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(/static/media/user.ee4d8493.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(/static/media/email.b26422be.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(/static/media/phone.628e6962.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  content: url(/static/media/credit-card.de8caf39.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-body tr > td:last-of-type {
  width: 5%;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
  display: none;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  padding: 0 45px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 19%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(/static/media/email.b26422be.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(/static/media/phone.628e6962.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 19%;
  text-align: center;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder {
  color: #16adb8;
  text-transform: capitalize;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inactive {
  color: #c30707;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.delivered {
  color: #2d6fbb;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inprogress {
  color: #bbbb2d;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.requested {
  color: #2db2bb;
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
  display: none;
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  padding: 0 45px;
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 18%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-thead tr > th div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.dashboard-wrapper .booked-sessions-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 18%;
  text-align: left;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(1) {
  width: 2%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(2), .masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(3) {
  display: inline-block;
  width: 49%;
  max-width: 49%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(1) {
  width: 2%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(2), .masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(3) {
  display: inline-block;
  width: 49%;
  max-width: 49%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.masterdata-wrapper .search-wrapper .ant-input-search {
  height: 30px;
}

.masterdata-wrapper .search-wrapper .ant-input-search .ant-input {
  height: 30px;
}

body.rtl .masterdata-wrapper .grid-action-header .cascade-select {
  margin-right: 20px;
  margin-left: 0;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  display: none;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) {
  padding: 12px 30px !important;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll tr > td:nth-child(2) {
  padding: 12px 30px !important;
}

.more-icon-holder-gray .ant-dropdown.has-close-icon .anticon-close {
  width: 20px !important;
  height: 20px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #9d9d9d !important;
}

.more-icon-holder-gray .delete-warning {
  color: #d83025 !important;
}

.grid_responsive_card.ant-card-bordered {
  width: 95%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin: 10px auto;
}

.grid_responsive_card.ant-card-bordered .card_body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.grid_responsive_card.ant-card-bordered .card_body .card_label {
  color: #62c2c9;
  font-weight: bold;
  margin-right: 10px;
}

body.rtl .grid_responsive_card.ant-card-bordered .card_body .card_label {
  margin-left: 10px;
  margin-right: 0;
}

.grid_responsive_card.ant-card-bordered .card_body > div {
  display: flex;
  align-items: center;
}

.grid_responsive_card.ant-card-bordered .card_body > div img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
}

body.rtl .grid_responsive_card.ant-card-bordered .card_body > div img {
  margin-left: 5px;
  margin-right: 0;
}

.grid_responsive_card.ant-card-bordered .card_body--actions {
  display: flex;
}

.grid_responsive_card.ant-card-bordered .card_body--actions .card__actions {
  flex-shrink: 0;
  width: 10%;
  position: relative;
}

.grid_responsive_card.ant-card-bordered .card_body--actions .card__actions .card__actions--icon {
  position: absolute;
  left: unset;
  top: 0;
  right: 0;
}

body.rtl .grid_responsive_card.ant-card-bordered .card_body--actions .card__actions .card__actions--icon {
  right: unset;
  left: 0;
}

.grid_responsive_cards_wrapper {
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.grid_responsive_cards_pagination_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75px;
  position: -webkit-sticky;
  position: sticky;
}

.ant-table-filter-dropdown .ant-btn {
  line-height: 0;
}

.ant-table-filter-dropdown .ant-btn > span {
  line-height: 17px;
}

.admin-modal-wrapper .ant-modal {
  width: 90% !important;
  max-width: 90%;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 639px) {
  .admin-modal-wrapper .ant-modal {
    width: 100% !important;
    max-width: 100%;
  }
}

.admin-modal-wrapper .ant-modal-content {
  height: 92%;
  width: 90%;
  margin: auto;
  top: 4%;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-close-x {
  color: #d83025;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #46c3cc;
}

body.rtl .admin-modal-wrapper .ant-modal-content .ant-modal-header {
  text-align: right !important;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-body {
  border-radius: 0;
  height: calc(100% - 50px - 90px);
  overflow: auto;
  padding: 0;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-footer {
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .admin-modal-wrapper .ant-modal-content .ant-modal-footer {
    justify-content: center;
  }
}

.admin-modal-wrapper .ant-form {
  height: 100%;
}

.admin-modal-wrapper .Personal-info-wrapper {
  height: auto;
  overflow: auto;
  padding-top: 0 !important;
}

body.rtl .admin-modal-wrapper .Personal-info-wrapper {
  padding: 0px 50px;
}

.admin-modal-wrapper .Personal-info-wrapper h2 {
  font-weight: 400;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse {
  background-color: transparent;
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header {
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: 11px;
  font-weight: bold;
  color: #46c3cc;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header .ant-collapse-item {
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px;
  font-weight: bold;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header .ant-collapse-arrow {
  left: auto;
  right: 0;
  font-size: 9px;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-item {
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-content {
  border: 0px;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-content-box {
  padding: 0;
}

body.rtl .admin-modal-wrapper .Personal-info-wrapper .ant-switch {
  margin-right: 15px;
}

.admin-modal-wrapper .rules-wrapper {
  background-color: #f8f8f8;
  height: 100% !important;
  min-height: 450px;
}

body.rtl .admin-modal-wrapper .rules-wrapper {
  padding: 2%;
}

.admin-modal-wrapper .rules-wrapper h6 {
  color: #16adb8;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
}

.admin-modal-wrapper .rules-wrapper .remove-icon-btn {
  font-size: 11px;
  font-weight: normal;
  color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper {
  height: 300px;
  border: solid 0.8px #cacaca;
  background-color: #ffffff;
  padding: 10px 14px;
  overflow: auto;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content > .ant-checkbox-wrapper {
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content .ant-checkbox-group > .ant-checkbox-wrapper {
  margin-bottom: 5px;
  color: #404040;
  font-size: 15px;
  font-weight: normal;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper {
  height: 220px;
  border: solid 0.8px #cacaca;
  background-color: #ffffff;
  padding: 10px 14px;
  overflow: auto;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-indeterminate .ant-checkbox-inner::after,
.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #d83025;
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper:hover .ant-checkbox + span {
  color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content > .ant-checkbox-wrapper {
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group > .ant-checkbox-wrapper {
  margin-bottom: 5px;
  color: #404040;
  font-size: 15px;
  font-weight: normal;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d83025;
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .admins-preview-placeholder {
  text-align: center;
  font-size: 12px;
  color: #a4a4a4;
}

.admin-modal-wrapper .rules-wrapper .ant-form-item .ant-form-item-label {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
}

.admin-modal-wrapper .rules-wrapper .ant-form-item .ant-form-item-label label {
  pointer-events: none;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 5px 30px;
  cursor: pointer;
  border-top: 1px solid #cacaca;
}

body.rtl .admin-modal-wrapper .rules-wrapper .action-btn-holder {
  flex-direction: row-reverse;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #474747;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder .add-btn {
  color: #16adb8;
}

.admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li:not(:last-of-type) {
  height: 0;
  margin-top: 0;
  line-height: 0;
  width: 0;
  opacity: 0;
  display: none;
}

.admin-modal-wrapper .rules-wrapper .error_holder {
  position: relative;
}

.admin-modal-wrapper .rules-wrapper .error_holder .ant-form-explain {
  position: absolute;
  top: -15px;
}

.admin-modal-wrapper .remove_holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-modal-wrapper .remove_holder h6 {
  padding-bottom: 0;
}

.admin-modal-wrapper .popup-medium {
  height: 70%;
  width: 50% !important;
}

@media only screen and (max-width: 767px) {
  .admin-modal-wrapper .popup-medium {
    width: 95% !important;
  }
}

.admin-modal-wrapper .select-admin-dropdown .ant-form-item-label label {
  font-size: 15px;
  font-weight: 400;
  color: #c4c4c4;
}

.modal-global-style .ant-modal-header {
  padding: 26px 55px;
}

.modal-global-style .ant-modal-title {
  font-size: 1.5625rem;
  font-weight: bold;
  color: #16adb8;
  font-weight: 600;
}

body.rtl .modal-global-style .ant-modal-close {
  right: unset !important;
}

.modal-global-style .ant-modal-close .ant-modal-close-x {
  color: #d83025;
}

.modal-global-style .ant-modal-footer {
  border: none !important;
}

.modal-global-style .ant-modal-footer button + button {
  margin-left: 12px;
}

body.rtl .modal-global-style .ant-modal-footer button + button {
  margin-left: 0 !important;
  margin-right: 12px;
}

.modal-global-style .ant-modal-header {
  border: none !important;
}

.modal-global-style .ant-modal-header .anticon {
  color: #c30707;
}

body.rtl .modal-global-style .ant-modal-header {
  text-align: right !important;
}

.role-holder .ant-modal-body h2 {
  font-weight: normal;
}

.ant-transfer .ant-transfer-list-search {
  padding-top: 0 !important;
  height: 32px;
}

.preview-licenses-modal {
  display: flex;
  align-items: center;
}

.preview-licenses-modal .ant-modal {
  top: 0;
}

.preview-licenses-modal .ant-modal-body {
  padding: 30px;
}

.preview-licenses-modal .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #d83025;
  font-size: 18px;
}

.add-admin-holder .ant-modal-header {
  padding-bottom: 10px;
}

.demo-wrapper {
  width: 85%;
  margin: auto;
}

.demo-wrapper > section {
  margin-top: 25px;
}

.demo-wrapper > section > h3 {
  margin-bottom: 20px;
}

.demo-wrapper .color-palette-wrapper .content {
  display: flex;
}

.demo-wrapper .color-palette-wrapper .content .panel {
  display: flex;
  width: 100%;
  border: 0;
  box-shadow: none;
}

.demo-wrapper .color-palette-wrapper .content .panel .sample {
  width: 190px;
  margin-left: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.demo-wrapper .color-palette-wrapper .content .panel .sample:first-child {
  margin-left: 0px;
}

.demo-wrapper .color-palette-wrapper .color-text {
  min-height: 90px;
  background: #52565e;
  color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 5px;
}

.demo-wrapper .color-palette-wrapper .color-text h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.demo-wrapper .color-palette-wrapper .color-text p {
  display: flex;
  flex-wrap: wrap;
}

.demo-wrapper .color-palette-wrapper .color-text p span {
  cursor: pointer;
  font-size: 12px;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split {
  display: flex;
  height: 150px;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split > div:first-of-type {
  border-radius: 4px 0 0 0;
  width: 100%;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split > div:last-of-type {
  border-radius: 0 4px 0 0;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split-2 {
  width: 45px;
}

.rtl .ant-pagination.mini .ant-pagination-prev,
.rtl .ant-pagination.mini .ant-pagination-next {
  -webkit-transform: rotate(0) !important;
          transform: rotate(0) !important;
}

footer.web-footer * {
  font-family: Pridi;
}

footer.web-footer .footer-top-wrapper {
  padding: 11% 13% 5% 13%;
  background: url(/static/media/footer-bg.c1989223.png) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

footer.web-footer .footer-top-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
}

footer.web-footer .footer-top-wrapper .logo-holder {
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-top-wrapper .logo-holder {
    justify-content: center;
  }
}

footer.web-footer .footer-top-wrapper .content {
  position: relative;
  z-index: 1;
  display: flex;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-top-wrapper .content {
    flex-direction: column;
  }
  footer.web-footer .footer-top-wrapper .content i {
    display: block !important;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}

footer.web-footer .footer-top-wrapper .content > div {
  flex: 1 1;
}

footer.web-footer .footer-top-wrapper .content > div h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  white-space: nowrap;
}

footer.web-footer .footer-top-wrapper .content > div h4 i {
  display: none;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

footer.web-footer .footer-top-wrapper .content > div .links-holder {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  -webkit-transition: max-height ease 0.4s;
  transition: max-height ease 0.4s;
}

footer.web-footer .footer-top-wrapper .content > div .links-holder * {
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-top-wrapper .content > div .links-holder {
    overflow: hidden;
    align-items: center;
    -webkit-transition: max-height ease 0.4s;
    transition: max-height ease 0.4s;
    margin-top: 14px;
    margin-bottom: 12px;
  }
}

footer.web-footer .footer-top-wrapper .content > div a, footer.web-footer .footer-top-wrapper .content > div address {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.71;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-top-wrapper .content > div a, footer.web-footer .footer-top-wrapper .content > div address {
    font-weight: normal;
  }
}

footer.web-footer .footer-top-wrapper .content > div.active i {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

footer.web-footer .footer-top-wrapper .content > div.active .links-holder {
  -webkit-transition: max-height ease 0.4s;
  transition: max-height ease 0.4s;
  max-height: 180px;
  height: auto;
}

footer.web-footer .footer-bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.17;
  letter-spacing: 0.35px;
  color: #ffffff;
  height: 50px;
  background-color: #242430;
  padding: 10px;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-bottom-wrapper {
    font-size: 13px;
  }
}

#root {
  height: 100%;
}

.auth_bg {
  height: 100vh;
  background: url(/static/media/background.189c3fba.png);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.85;
  background-position: right;
}

.auth_bg .overlay {
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.13);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: #000000;
}

.auth_bg.website-auth-wrapper {
  background-image: url(/static/media/bannerweb.060e6d76.png);
}

.auth_bg.website-auth-wrapper .overlay {
  opacity: 0.48;
}

.auth_bg.website-auth-wrapper .center_div .logo-img {
  display: none;
}

.auth_bg.website-auth-wrapper .center_div .title {
  font-size: 23px;
}

.auth_bg.website-auth-wrapper .center_div .sammary {
  padding-top: 22px;
}

.auth_bg.website-auth-wrapper .center_div .login-form-forgot {
  font-size: 13px;
  text-decoration: none;
  font-weight: normal;
}

.auth_bg.website-auth-wrapper .center_div .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
}

.auth_bg.website-auth-wrapper .center_div .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 40px !important;
}

body.rtl .auth_bg.website-auth-wrapper .center_div .ant-form-item input {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-label label {
  display: none;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control {
  position: relative;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control::before {
  content: url(/static/media/password.a3e7763a.svg);
  position: absolute;
  top: 6px;
  left: 10px;
}

body.rtl .auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control::before {
  right: 10px;
  left: auto;
}

@media only screen and (max-width: 479px) {
  .auth_bg.website-auth-wrapper.rest-password-wrapper .center_div .title {
    font-size: 20px !important;
  }
}

.center_div {
  position: relative;
  background: #fff;
  width: 55%;
  margin: auto;
  padding: 50px 100px;
  border-radius: 4px;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.13);
}

.center_div .title {
  font-size: 29px;
  font-weight: bold;
  color: #000;
  padding-top: 14px;
}

.center_div .logo-img {
  width: 322.1px;
  height: 46.9px;
}

.center_div .login-form-forgot {
  text-decoration: underline;
}

.pt-2 {
  padding-top: 20px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-4 {
  padding-bottom: 40px;
}

.mr-2 {
  margin-right: 20px;
}

body.rtl .mr-2 {
  margin-left: 20px;
}

.title {
  font-size: 25px;
  font-weight: bold;
  color: #4b4b4b;
  padding-top: 14px;
}

.lang_style {
  font-size: 16px;
  color: #62c2c9;
  text-transform: none;
}

.rem_color {
  color: #62c2c9 !important;
}

.sammary {
  font-size: 14px;
  color: #565656;
  line-height: 1.21;
  padding-top: 10px;
}

.size17 {
  font-size: 17px;
  color: #565656;
  line-height: 1.24;
}

.black {
  color: #000;
}

.graytitle {
  color: #a3a3a3;
  font-size: 45px;
}

.ligt_black {
  color: #242424;
}

.weight-600 {
  font-weight: 600;
}

.font-19 {
  font-size: 19px;
}

.ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 15px;
}

.rtl .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 15px !important;
  right: auto !important;
}

.withOut_icons .ant-form-item-children-icon {
  display: none;
}

.reset_holder .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 30px;
}

body.rtl .reset_holder .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: auto !important;
  left: 30px !important;
}

.ant-form-item-control.has-error .ant-form-explain {
  padding-left: 0px;
}

body.rtl .ant-form-item-control.has-error .ant-form-explain {
  padding-left: 0 !important;
  padding-right: 0px !important;
}

/* @media screen and (max-width:950px) {
    .center_div {
      
    }
}
 */
@media screen and (max-width: 660px) {
  .center_div {
    width: 95% !important;
  }
}

@media screen and (max-width: 1200px) {
  .center_div {
    padding: 50px 30px !important;
    width: 80% !important;
  }
}

/* @media screen and (max-width:1200px) {
    .center_div {
        width: 50%;
    }
} */
.dashboard-wrapper {
  overflow: hidden;
  height: 100vh;
}

.dashboard-wrapper .ant-layout {
  height: 100%;
}

.dashboard-wrapper .ant-table-wrapper {
  overflow: auto;
}

.dashboard-wrapper .ant-table-wrapper .ant-pagination {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ededed;
  margin: 0;
}

.dashboard-wrapper .ant-table-wrapper .ant-spin-nested-loading,
.dashboard-wrapper .ant-table-wrapper .ant-spin-container {
  height: 100%;
}

.dashboard-wrapper .ant-table-wrapper .ant-spin-nested-loading .ant-spin,
.dashboard-wrapper .ant-table-wrapper .ant-spin-container .ant-spin {
  top: 50%;
  left: 50%;
}

.dashboard-wrapper .ant-table-wrapper .ant-spin-nested-loading .lottie-spinner,
.dashboard-wrapper .ant-table-wrapper .ant-spin-container .lottie-spinner {
  position: relative;
  top: 0;
  left: 0;
}

.dashboard-wrapper .ant-table-wrapper .ant-table-filter-dropdown .ant-input {
  height: 30px;
  padding: 5px !important;
}

.dashboard-wrapper .ant-table-scroll .ant-table-header {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 0 !important;
  overflow: auto;
  max-height: 44px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.dashboard-wrapper .ant-table-scroll .ant-table-thead th:not(.ant-table-selection-column) {
  font-size: 16px;
  font-weight: 600;
  color: #474747;
  text-transform: capitalize;
}

.dashboard-wrapper .ant-table-scroll .ant-table-thead th:not(.ant-table-selection-column):last-of-type {
  border: none;
}

.dashboard-wrapper .ant-table-scroll .ant-table-body {
  overflow: auto !important;
  max-height: calc( 100vh - 230px) !important;
  min-height: calc(100vh - 230px) !important;
  /* Track */
  /* Handle */
}

.dashboard-wrapper .ant-table-scroll .ant-table-body::-webkit-scrollbar {
  width: 8px;
}

.dashboard-wrapper .ant-table-scroll .ant-table-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.dashboard-wrapper .ant-table-scroll .ant-table-body::-webkit-scrollbar-thumb {
  background: #62c2c9;
  border-radius: 5px;
}

.dashboard-wrapper .ant-table-scroll .ant-table-tbody > tr:nth-child(even) {
  background-color: #f4f4f4;
}

.dashboard-wrapper .ant-table-scroll .ant-table-tbody > tr:hover > td {
  background: transparent;
}

.dashboard-wrapper .ant-table-scroll .ant-table-tbody > tr > td {
  border: 0;
  padding: 9px 16px;
  font-size: 16px;
  font-weight: 600;
  color: #474747;
}

.dashboard-wrapper .setting-wrapper {
  padding: 3.125rem 4.375rem;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  text-align: center;
}

.dashboard-wrapper .setting-wrapper h3 {
  text-align: left;
  color: #46c3cc;
  padding-bottom: 4.375rem;
}

body.rtl .dashboard-wrapper .setting-wrapper h3 {
  text-align: right;
}

.dashboard-wrapper .setting-wrapper .last-header h3 {
  padding-top: 4.375rem;
  padding-bottom: 3.75rem;
}

@media only screen and (max-width: 767px) {
  .dashboard-wrapper .setting-wrapper .counter-input-holder {
    width: 100%;
    align-items: left;
  }
  body.rtl .dashboard-wrapper .setting-wrapper .counter-input-holder {
    align-items: right;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-wrapper .setting-wrapper .ant-row-flex-space-around {
    justify-content: flex-start;
  }
}

.custom_pagination_holder {
  position: absolute;
  top: 6px;
  right: 5px;
}

.custom_pagination_holder .ant-pagination-options {
  margin: 0;
}

.custom_pagination_holder .ant-pagination-options-quick-jumper {
  display: flex;
  font-size: 0;
}

.custom_pagination_holder .ant-pagination-item {
  display: none;
}

.custom_pagination_holder .ant-select-selection-selected-value {
  margin-top: -4px;
}

body.rtl .custom_pagination_holder {
  right: auto;
  left: 5px;
}

body.rtl .custom_pagination_holder .anticon-right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

body.rtl .custom_pagination_holder .anticon-left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.custom_pagination_holder .ant-select .ant-select-selection {
  background-color: #ffffff;
  padding: 4px 3px !important;
  margin: 0 0 0 20px;
  width: 70px;
  height: 33px;
}

body.rtl .custom_pagination_holder .ant-select .ant-select-selection {
  margin: 0 0px 0 0px;
}

.user_modal-holder .Personal-info-wrapper {
  height: 100%;
}

.admin-grid .ant-table-thead tr:first-of-type th:last-of-type .ant-table-column-sorter {
  right: 50% !important;
}

body.rtl .admin-grid .ant-table-thead tr:first-of-type th:last-of-type .ant-table-column-sorter {
  right: 41% !important;
}

.admin-grid .ant-table-thead tr:first-of-type th:last-of-type .ant-table-filter-icon {
  right: 45% !important;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.bg_color {
  background: #F5F5F5;
  height: 100%;
}

.p-2 {
  padding: 20px;
}

.Error_holder {
  width: 80%;
  margin: auto;
}

.error_num {
  color: #a3a3a3;
  font-size: 11.625rem;
  font-weight: bold;
}

.not_found {
  color: #a3a3a3;
  font-size: 3.9375rem;
  font-weight: bold;
}

.error_text {
  color: #a3a3a3;
  font-size: 1.125rem;
}

.pb-3 {
  padding-bottom: 30px;
}

@media screen and (max-width: 545px) {
  .d-none {
    display: none;
  }
  .custom_width {
    width: 90% !important;
  }
}

.add_School .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #62c2c9;
  padding-top: 28px;
  padding-left: 16px;
}

.add_School .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #62c2c9;
  border-radius: 0;
}

.add_School .ant-collapse > .ant-collapse-item:last-of-type {
  margin-bottom: 60px;
}

.add_School .ant-collapse-content {
  border-top: 1px solid #62c2c9;
}

.add_School .ant-collapse-content.ant-collapse-content-active {
  overflow: visible;
}

.add_School .ant-collapse-content .ant-collapse-content-box {
  padding: 16px 0;
}

.add_School .ant-collapse-item.ant-collapse-item-active {
  border-bottom: none !important;
}

.add_School .ant-collapse {
  border: none;
  background-color: transparent;
  width: 80%;
  margin: auto;
}

.add_School .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 60%;
  right: 16px;
  left: unset !important;
}

.add_School .small {
  font-weight: 500;
  color: #989797;
  line-height: 2.18;
}

.add_School .handel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
}

.add_School .image_map {
  width: 100%;
  -webkit-backdrop-filter: blur(29.1px);
          backdrop-filter: blur(29.1px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  height: 227px;
}

.add_School .mt-1 {
  margin-top: 10px;
}

.add_School .counter-input-holder .ant-form-item .ant-input-number {
  min-width: 160px !important;
}

.payment_modal .radio_holders .ant-radio-group {
  width: 100%;
}

.payment_modal .radio_holders .ant-radio-group > div {
  display: flex;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .payment_modal .radio_holders .ant-radio-group > div {
    flex-direction: column;
  }
}

.payment_modal .radio_holders .Dayes_holder {
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
}

.payment_modal .radio_holders .Dayes_holder input {
  color: #62c2c9;
}

.payment_modal .radio_holders .Dayes_holder .payment_span {
  left: 63px;
  top: 9px;
  position: absolute;
}

.payment_modal .radio_holders .Dayes_holder .ant-form-item {
  margin-bottom: 0;
}

.payment_modal .day_input {
  max-height: 30px;
  max-width: 100px;
  padding: 5px !important;
}

.payment_modal .ant-tabs-nav-container {
  overflow: visible;
}

.payment_modal .ant-tabs-nav {
  width: 100%;
}

.payment_modal .ant-tabs-nav .ant-tabs-tab {
  width: 50%;
  margin: 0 !important;
  text-align: center;
  color: #62c2c9;
  font-weight: bold;
  font-size: 1.375rem;
  background-color: #ededed;
  padding: 19px 16px;
}

.payment_modal .ant-tabs-nav .ant-tabs-ink-bar {
  display: none !important;
}

.payment_modal .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #FFF;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.payment_modal .two_inputs_holder {
  border: solid 1px #dadce0 !important;
  border-radius: 4px;
  width: 130px;
}

.payment_modal .two_inputs_holder input {
  border: none;
  width: 40px;
}

.payment_modal .two_inputs_holder input:hover {
  outline: none !important;
  border: none !important;
}

.payment_modal .payment_span2 {
  left: 90px !important;
  top: 6px !important;
}

.fixed_width {
  width: 150px;
}

@media only screen and (max-width: 767px) {
  .fixed_width {
    width: 120px;
  }
}

.change_input {
  height: 30px;
  width: 50px;
  padding: 4px !important;
}

.payment-holder {
  width: 85%;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .payment-holder {
    width: 95%;
  }
}

.payment-holder h4 {
  color: #242424;
  padding: 0 0 38px 0;
}

body.rtl .payment-holder h4 {
  text-align: right;
}

.payment-holder h6 {
  color: #242424;
  padding: 0 0 38px 0;
  font-size: 0.875rem;
}

body.rtl .payment-holder h6 {
  text-align: right;
}

.payment_table .ant-table-body {
  margin: 0 !important;
}

.payment_table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.payment_table .ant-table-thead {
  background-color: #dadce0;
}

.manage_student_modal .ant-modal-header {
  border: none;
}

.manage_student_modal .ant-modal-title {
  color: #656565;
  font-size: 1.5rem;
  font-weight: bold;
}

.manage_student_modal .ant-row > div {
  background: transparent;
  border: 0;
}

.manage_student_modal .gutter-box {
  padding: 5px 0;
}

.manage_student_modal .ant-col-10 {
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 10px;
  line-height: 1.8;
  height: 80px;
}

.manage_student_modal .ant-col-14 .gutter-box {
  display: flex;
  align-items: center;
  border: solid 1px #dadce0;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 !important;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div {
  padding: 10px;
  width: 50%;
  min-height: 80px;
}

.manage_student_modal .ant-col-14 .gutter-box .border_right {
  border-right: 1px solid #dadce0;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div {
  height: auto;
  padding: 6px 10px !important;
  font-size: 12px;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div p {
  margin-bottom: 10px;
}

.manage_student_modal .ant-col-14 .gutter-box .ant-input {
  height: auto;
  padding: 6px 10px !important;
  font-size: 12px;
  color: #62c2c9;
  border: none;
}

.manage_student_modal .ant-col-14 .plus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding: 0;
  border: none;
}

.manage_student_modal .line_span {
  display: block;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 20px 0;
}

.manage_student_modal .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}

.manage_student_modal tr:nth-child(even) {
  background-color: #f8f8f8;
}

.manage_student_modal .ant-table-thead {
  background-color: #dadce0;
}

.manage_student_modal td, .manage_student_modal th {
  padding: 3px 8px !important;
  text-align: center !important;
}

.manage_student_modal .btn-60 {
  margin-right: 10px;
}

body.rtl .manage_student_modal .btn-60 {
  margin-left: 10px;
  margin-right: 0px;
}

.up {
  color: #62c2c9;
  position: relative;
}

.up::before {
  content: "+";
  position: absolute;
  left: -10px;
  color: #62c2c9;
}

.down {
  color: #2db2bb;
  position: relative;
}

.down::before {
  content: "-";
  position: absolute;
  left: -10px;
  color: #2db2bb;
}

.evictive_date {
  border: solid 1px #dadce0;
  border-radius: 4px;
}

.green_label {
  color: #62c2c9;
}

@media only screen and (max-width: 991px) {
  body,
  html {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  body,
  html {
    font-size: 14px !important;
  }
}

.show-in-ipad {
  display: none !important;
}

@media only screen and (max-width: 991px) {
  .show-in-ipad {
    display: flex !important;
  }
}

.hide-in-ipad {
  display: flex !important;
}

@media only screen and (max-width: 991px) {
  .hide-in-ipad {
    display: none !important;
  }
}

.montserrat-font * {
  font-family: Pridi, Cairo, sans-serif;
}

body.rtl .montserrat-font * {
  font-family: Cairo, Pridi, sans-serif;
}

.montserrat-font .ant-form-explain {
  font-size: 10px;
}

.montserrat-font .ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-select .ant-select-selection .ant-select-selection__placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input:-moz-placeholder, .montserrat-font .ant-time-picker-input:-moz-placeholder {
  font-family: Pridi;
  font-size: 15px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-input:-moz-placeholder, .montserrat-font .ant-time-picker-input:-moz-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input::-moz-placeholder, .montserrat-font .ant-time-picker-input::-moz-placeholder {
  font-family: Pridi;
  font-size: 15px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-input::-moz-placeholder, .montserrat-font .ant-time-picker-input::-moz-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input:-ms-input-placeholder, .montserrat-font .ant-time-picker-input:-ms-input-placeholder {
  font-family: Pridi;
  font-size: 15px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-input:-ms-input-placeholder, .montserrat-font .ant-time-picker-input:-ms-input-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input::-webkit-input-placeholder, .montserrat-font .ant-time-picker-input::-webkit-input-placeholder {
  font-family: Pridi;
  font-size: 15px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-input::-webkit-input-placeholder, .montserrat-font .ant-time-picker-input::-webkit-input-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-upload-list-picture-card .ant-upload-list-item-error {
  border: none;
}

html[lang="ar"] {
  font-size: 14px !important;
}

.rtl {
  direction: rtl;
  /* .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
     margin-right: 12px;
     margin-left: 12px;
  } */
  /*  .ant-tabs-tab-next {
    // left: 2px;
    // left: auto;
    // right: 2px;
    // transform: rotate(180deg);
  } */
  /* .ant-tabs-tab-prev {
    // left: 3px;
    // right : auto; 
    // transform: rotate(180deg); 
  } */
  /* .ant-tabs-nav .ant-tabs-tab{
   // transform: scaleX(-1);
  } */
  /* .ant-carousel .slick-slide {
    // float: right;
    // height: 100%;
    // min-height: 1px;
    // display: none;
  } */
}

.rtl caption {
  text-align: right;
}

.rtl .react-tel-input .selected-flag .arrow {
  left: auto;
  right: 20px;
}

.rtl .react-tel-input .selected-flag {
  margin-right: 12px;
}

.rtl .move-left-enter,
.rtl .move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .move-left-enter.move-left-enter-active,
.rtl .move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveleftIn;
          animation-name: antMoveleftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rtl .move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveleftOut;
          animation-name: antMoveleftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}

.rtl .move-left-enter,
.rtl .move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.rtl .move-right-enter,
.rtl .move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .move-right-enter.move-right-enter-active,
.rtl .move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoverightIn;
          animation-name: antMoverightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rtl .move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoverightOut;
          animation-name: antMoverightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}

.rtl .move-right-enter,
.rtl .move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@-webkit-keyframes antMoveleftIn {
  0% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes antMoveleftIn {
  0% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
}

@-webkit-keyframes antMoveleftOut {
  0% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes antMoveleftOut {
  0% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
}

@-webkit-keyframes antMoverightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes antMoverightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes antMoverightOut {
  0% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes antMoverightOut {
  0% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
  }
}

.rtl .slide-left-enter,
.rtl .slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .slide-left-enter.slide-left-enter-active,
.rtl .slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideleftIn;
          animation-name: antSlideleftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rtl .slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideleftOut;
          animation-name: antSlideleftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}

.rtl .slide-left-enter,
.rtl .slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rtl .slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.rtl .slide-right-enter,
.rtl .slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .slide-right-enter.slide-right-enter-active,
.rtl .slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSliderightIn;
          animation-name: antSliderightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rtl .slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSliderightOut;
          animation-name: antSliderightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}

.rtl .slide-right-enter,
.rtl .slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rtl .slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideleftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes antSlideleftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@-webkit-keyframes antSlideleftOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
}

@keyframes antSlideleftOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
}

@-webkit-keyframes antSliderightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes antSliderightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@-webkit-keyframes antSliderightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
}

@keyframes antSliderightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
}

.rtl .zoom-left-enter,
.rtl .zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .zoom-left-enter.zoom-left-enter-active,
.rtl .zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomleftIn;
          animation-name: antZoomleftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rtl .zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomleftOut;
          animation-name: antZoomleftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}

.rtl .zoom-left-enter,
.rtl .zoom-left-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rtl .zoom-right-enter,
.rtl .zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rtl .zoom-right-enter.zoom-right-enter-active,
.rtl .zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomrightIn;
          animation-name: antZoomrightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rtl .zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomrightOut;
          animation-name: antZoomrightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}

.rtl .zoom-right-enter,
.rtl .zoom-right-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomleftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes antZoomleftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes antZoomleftOut {
  0% {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@keyframes antZoomleftOut {
  0% {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@-webkit-keyframes antZoomrightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes antZoomrightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes antZoomrightOut {
  0% {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@keyframes antZoomrightOut {
  0% {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.rtl .ant-alert-icon {
  top: 12.5px;
  right: 16px;
  position: absolute;
}

.rtl .ant-alert-close-icon {
  font-size: 12px;
  position: absolute;
  left: 16px;
  top: 8px;
  line-height: 22px;
  overflow: hidden;
  cursor: pointer;
}

.rtl .ant-alert-close-text {
  position: absolute;
  left: 16px;
}

.rtl .ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  right: 24px;
  font-size: 24px;
}

.rtl .ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
  font-size: 14px;
}

.rtl .ant-anchor {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  padding-right: 2px;
}

.rtl .ant-anchor-wrapper {
  background-color: #fff;
  overflow: auto;
  padding-right: 4px;
  margin-right: -4px;
}

.rtl .ant-anchor-ink {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}

.rtl .ant-anchor-ink-ball {
  right: 50%;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  margin-right: 0;
  margin-left: 0;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: right;
}

.rtl .ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-left: 0 !important;
}

.rtl .ant-select-arrow {
  left: 11px;
  right: auto;
}

.rtl .ant-select-selection__clear {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  opacity: 0;
  position: absolute;
  left: 11px;
  z-index: 1;
  background: #fff;
  top: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.rtl .ant-select-selection-selected-value {
  float: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 0;
}

.rtl .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  background: #f5f5f5;
  color: #aaa;
  padding-left: 10px;
}

.rtl .ant-select-selection__rendered {
  margin-right: 11px;
  margin-left: 11px;
}

.rtl .ant-select-sm .ant-select-selection__clear,
.rtl .ant-select-sm .ant-select-arrow {
  left: 8px;
}

.rtl .ant-select-selection__placeholder,
.rtl .ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 0;
  left: 9px;
  color: #bfbfbf;
  line-height: 20px;
  height: 20px;
  max-width: 100%;
  margin-top: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

.rtl .ant-select-search__field__placeholder {
  right: 12px;
}

.rtl .ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  right: -9999px;
  white-space: pre;
  pointer-events: none;
}

.rtl .ant-select-search--inline > i {
  float: left;
}

.rtl .ant-select-selection--multiple .ant-select-search--inline {
  float: right;
  position: static;
  width: auto;
  padding: 0;
  max-width: 100%;
}

.rtl .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 5px;
}

.rtl .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-right: 6px;
}

.rtl .ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  float: right;
  margin-left: 4px;
  max-width: 99%;
  position: relative;
  overflow: hidden;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 10px 0 20px;
}

.rtl .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 20px;
}

.rtl .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-left: 20px;
}

.rtl .ant-table-thead > tr > th .ant-table-column-sorter {
  right: auto;
  left: 35px;
}

.rtl .ant-select-dropdown {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  margin: 0;
  padding: 0;
  list-style: none;
  direction: rtl;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 1050;
  top: -9999px;
  position: absolute;
  outline: none;
  font-size: 14px;
}

.rtl .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomleft,
.rtl .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomleft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}

.rtl .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topleft,
.rtl .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topleft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}

.rtl .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomleft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}

.rtl .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topleft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}

.rtl .ant-select-dropdown-menu {
  outline: none;
  margin-bottom: 0;
  padding-right: 0;
  list-style: none;
  max-height: 250px;
  overflow: auto;
}

.rtl .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-right: 20px;
}

.rtl .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\E632";
  color: transparent;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333) rotate(0deg);
          transform: scale(0.83333) rotate(0deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 12px;
  font-weight: bold;
  text-shadow: 0 0.1px 0, -0.1px 0 0, 0 -0.1px 0, 0.1px 0;
}

.rtl .ant-input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-input-group > [class*="col-"] {
  padding-left: 8px;
}

.rtl .ant-input-group > [class*="col-"]:last-child {
  padding-left: 0;
}

.rtl .ant-input-group .ant-input {
  float: right;
  width: 100%;
  margin-bottom: 0;
}

.rtl .ant-input-group-addon > i:only-child:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.rtl .ant-input-group > .ant-input:first-child,
.rtl .ant-input-group-addon:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.rtl .ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group-addon:first-child {
  border-left: 0;
}

.rtl .ant-input-group-addon:last-child {
  border-right: 0;
}

.rtl .ant-input-group > .ant-input:last-child,
.rtl .ant-input-group-addon:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.rtl .ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  width: 100%;
  float: right;
}

.rtl .ant-input-group.ant-input-group-compact > * {
  border-radius: 0;
  border-left-width: 0;
  vertical-align: top;
  float: none;
  display: inline-block;
}

.rtl .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-radius: 0;
  border-left-width: 0;
}

.rtl .ant-input-group.ant-input-group-compact > *:first-child,
.rtl .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .has-success.has-feedback .ant-form-item-children-icon,
.rtl .has-warning.has-feedback .ant-form-item-children-icon,
.rtl .has-error.has-feedback .ant-form-item-children-icon,
.rtl .is-validating.has-feedback .ant-form-item-children-icon {
  right: auto;
  left: 0;
}

.rtl .ant-input-group.ant-input-group-compact > *:last-child,
.rtl .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}

.rtl .ant-form-item-control.has-error .ant-form-explain {
  text-align: right;
}

.rtl .ant-input-affix-wrapper .ant-input-prefix {
  left: auto;
  right: 12px;
}

.rtl .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  right: auto;
}

.rtl .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-right: 30px;
}

.rtl .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-left: 30px;
}

.rtl .ant-input-search:not(.ant-input-search-small) > .ant-input-suffix {
  left: 12px;
}

.rtl .ant-input-search > .ant-input-suffix > .ant-input-search-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .ant-input-search.ant-input-search-enter-button > .ant-input {
  padding-left: 46px;
}

.rtl .ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
  left: 0;
}

.rtl .ant-btn > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:hover > a:only-child:after,
.rtl .ant-btn:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:active > a:only-child:after,
.rtl .ant-btn.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn.disabled > a:only-child:after,
.rtl .ant-btn[disabled] > a:only-child:after,
.rtl .ant-btn.disabled:hover > a:only-child:after,
.rtl .ant-btn[disabled]:hover > a:only-child:after,
.rtl .ant-btn.disabled:focus > a:only-child:after,
.rtl .ant-btn[disabled]:focus > a:only-child:after,
.rtl .ant-btn.disabled:active > a:only-child:after,
.rtl .ant-btn[disabled]:active > a:only-child:after,
.rtl .ant-btn.disabled.active > a:only-child:after,
.rtl .ant-btn[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary:hover > a:only-child:after,
.rtl .ant-btn-primary:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary:active > a:only-child:after,
.rtl .ant-btn-primary.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary.disabled > a:only-child:after,
.rtl .ant-btn-primary[disabled] > a:only-child:after,
.rtl .ant-btn-primary.disabled:hover > a:only-child:after,
.rtl .ant-btn-primary[disabled]:hover > a:only-child:after,
.rtl .ant-btn-primary.disabled:focus > a:only-child:after,
.rtl .ant-btn-primary[disabled]:focus > a:only-child:after,
.rtl .ant-btn-primary.disabled:active > a:only-child:after,
.rtl .ant-btn-primary[disabled]:active > a:only-child:after,
.rtl .ant-btn-primary.disabled.active > a:only-child:after,
.rtl .ant-btn-primary[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-left-color: #40a9ff;
  border-right-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.rtl .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-left-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-left-color: #d9d9d9;
}

.rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
}

.rtl .ant-btn-ghost > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost:hover > a:only-child:after,
.rtl .ant-btn-ghost:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost:active > a:only-child:after,
.rtl .ant-btn-ghost.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost.disabled > a:only-child:after,
.rtl .ant-btn-ghost[disabled] > a:only-child:after,
.rtl .ant-btn-ghost.disabled:hover > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:hover > a:only-child:after,
.rtl .ant-btn-ghost.disabled:focus > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:focus > a:only-child:after,
.rtl .ant-btn-ghost.disabled:active > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:active > a:only-child:after,
.rtl .ant-btn-ghost.disabled.active > a:only-child:after,
.rtl .ant-btn-ghost[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed:hover > a:only-child:after,
.rtl .ant-btn-dashed:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed:active > a:only-child:after,
.rtl .ant-btn-dashed.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed.disabled > a:only-child:after,
.rtl .ant-btn-dashed[disabled] > a:only-child:after,
.rtl .ant-btn-dashed.disabled:hover > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:hover > a:only-child:after,
.rtl .ant-btn-dashed.disabled:focus > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:focus > a:only-child:after,
.rtl .ant-btn-dashed.disabled:active > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:active > a:only-child:after,
.rtl .ant-btn-dashed.disabled.active > a:only-child:after,
.rtl .ant-btn-dashed[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:hover > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:active > a:only-child:after,
.rtl .ant-btn-danger.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger.disabled > a:only-child:after,
.rtl .ant-btn-danger[disabled] > a:only-child:after,
.rtl .ant-btn-danger.disabled:hover > a:only-child:after,
.rtl .ant-btn-danger[disabled]:hover > a:only-child:after,
.rtl .ant-btn-danger.disabled:focus > a:only-child:after,
.rtl .ant-btn-danger[disabled]:focus > a:only-child:after,
.rtl .ant-btn-danger.disabled:active > a:only-child:after,
.rtl .ant-btn-danger[disabled]:active > a:only-child:after,
.rtl .ant-btn-danger.disabled.active > a:only-child:after,
.rtl .ant-btn-danger[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: #fff;
  opacity: 0.35;
  content: "";
  border-radius: inherit;
  z-index: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  pointer-events: none;
  display: none;
}

.rtl .ant-btn .anticon {
  -webkit-transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .ant-btn.ant-btn-loading:before {
  display: block;
}

.rtl .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-right: 29px;
  pointer-events: none;
  position: relative;
}

.rtl .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-right: -14px;
  margin-left: 0;
}

.rtl .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-right: 24px;
}

.rtl .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-right: -17px;
}

.rtl .ant-btn-group .ant-btn + .ant-btn,
.rtl .ant-btn + .ant-btn-group,
.rtl .ant-btn-group span + .ant-btn,
.rtl .ant-btn-group .ant-btn + span,
.rtl .ant-btn-group + .ant-btn,
.rtl .ant-btn-group + .ant-btn-group {
  margin-right: -1px;
}

.rtl .ant-btn-group > .ant-btn:first-child,
.rtl .ant-btn-group > span:first-child > .ant-btn {
  margin-right: 0;
}

.rtl .ant-btn-group > .ant-btn:first-child:not(:last-child),
.rtl .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-btn-group > .ant-btn:last-child:not(:first-child),
.rtl .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-btn-group > .ant-btn-group {
  float: right;
}

.rtl .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 8px;
}

.rtl .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 8px;
}

.rtl .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-btn:focus > span,
.rtl .ant-btn:active > span {
  position: relative;
}

.rtl .ant-btn > .anticon + span,
.rtl .ant-btn > span + .anticon {
  margin-right: 8px;
}

.rtl .ant-btn-clicked:after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: inherit;
  border: 0 solid #62c2c9;
  opacity: 0.4;
  -webkit-animation: buttonEffect 0.4s;
          animation: buttonEffect 0.4s;
  display: block;
}

.rtl .ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-two-chinese-chars > * {
  letter-spacing: 0.34em;
  margin-left: -0.34em;
}

@-webkit-keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px;
  }
}

@keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px;
  }
}

.rtl .ant-back-top {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
  position: fixed;
  left: 100px;
  bottom: 50px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .rtl .ant-back-top {
    left: 60px;
  }
}

@media screen and (max-width: 480px) {
  .rtl .ant-back-top {
    left: 20px;
  }
}

.rtl .ant-badge-status-processing:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #62c2c9;
  content: "";
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
          animation: antStatusProcessing 1.2s infinite ease-in-out;
}

.rtl .ant-badge-status-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-right: 8px;
}

.rtl .ant-breadcrumb-link > .anticon + span {
  margin-right: 4px;
}

.rtl .ant-fullcalendar-month-select {
  margin-right: 5px;
}

.rtl .ant-fullcalendar-header {
  padding: 11px 0 11px 16px;
  text-align: left;
}

.rtl .ant-fullcalendar-header .ant-select-dropdown {
  text-align: right;
}

.rtl .ant-fullcalendar-header .ant-radio-group {
  margin-right: 8px;
  text-align: right;
}

.rtl .ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rtl .ant-fullcalendar-content {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -9px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-right: 16px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  text-align: right;
  margin: 0 4px;
  display: block;
  color: rgba(0, 0, 0, 0.65);
  height: 116px;
  padding: 4px 8px;
  border-top: 2px solid #e8e8e8;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  text-align: left;
  padding-left: 12px;
  padding-bottom: 5px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  text-align: left;
  background: transparent;
  width: auto;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  height: 90px;
  overflow-y: auto;
  position: static;
  width: auto;
  right: auto;
  bottom: auto;
}

.rtl .ant-radio-wrapper {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin-left: 8px;
  cursor: pointer;
}

.rtl .ant-radio-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #62c2c9;
  content: "";
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-radio-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  border-color: #d9d9d9;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rtl .ant-radio-inner:after {
  position: absolute;
  width: 8px;
  height: 8px;
  right: 3px;
  top: 3px;
  border-radius: 4px;
  display: table;
  border-top: 0;
  border-right: 0;
  content: " ";
  background-color: #62c2c9;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rtl .ant-radio-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
}

.rtl span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-radio-button-wrapper {
  margin: 0;
  height: 32px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-top-width: 1.02px;
  background: #fff;
  padding: 0 15px;
  position: relative;
}

.rtl .ant-radio-button-wrapper > .ant-radio-button {
  margin-right: 0;
  display: block;
  width: 0;
  height: 0;
}

.rtl .ant-radio-button-wrapper:not(:first-child)::before {
  content: "";
  display: block;
  top: 0;
  right: -1px;
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: #d9d9d9;
}

.rtl .ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

.rtl .ant-card-extra {
  float: left;
  padding: 17.5px 0;
  text-align: left;
  margin-right: auto;
}

.rtl .ant-card-grid {
  border-radius: 0;
  border: 0;
  box-shadow: -1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, -1px 1px 0 0 #e8e8e8, -1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  width: 33.33%;
  float: right;
  padding: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rtl .ant-card-actions > li {
  float: right;
  text-align: center;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-card-actions > li:not(:last-child) {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-card-padding-transition .ant-card-extra {
  -webkit-transition: left 0.3s;
  transition: left 0.3s;
}

.rtl .ant-card-meta-avatar {
  padding-left: 16px;
  float: right;
}

.rtl .ant-card-loading-block {
  display: inline-block;
  margin: 5px 0 0 2%;
  height: 14px;
  border-radius: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
  background: -webkit-linear-gradient(right, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background: linear-gradient(-90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
  background-size: 600% 600%;
}

.rtl .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  margin: 0;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  background: #fafafa;
  margin-left: 2px;
  padding: 0 16px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  line-height: 38px;
}

.rtl .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 12px;
  margin-right: 3px;
  margin-left: -5px;
  overflow: hidden;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  height: 14px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  border-left: 0;
  border-radius: 0 4px 4px 0;
  margin-left: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab {
  border-right: 0;
  border-radius: 4px 0 0 4px;
  margin-right: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}

.rtl .ant-tabs-ink-bar {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 1px;
  box-sizing: border-box;
  height: 2px;
  background-color: #62c2c9;
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}

.rtl .ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}

.rtl .ant-tabs-tab-prev-icon,
.rtl .ant-tabs-tab-next-icon {
  font-style: normal;
  font-weight: bold;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  line-height: inherit;
  vertical-align: baseline;
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  text-align: center;
  text-transform: none;
}

.rtl .ant-tabs-nav-scroll {
  direction: ltr;
}

.rtl .ant-tabs-nav {
  box-sizing: border-box;
  padding-right: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.rtl .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-left: 0;
}

.rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-left: 8px;
}

.rtl .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  will-change: margin-left;
  -webkit-transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (max-width: 575px) {
  .rtl .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content-animated {
    display: unset;
    flex-direction: unset !important;
  }
}

.rtl .ant-tabs-vertical > .ant-tabs-bar .ant-tabs-ink-bar {
  width: 2px;
  right: auto;
  height: auto;
  top: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar {
  float: right;
  border-left: 1px solid #e8e8e8;
  margin-left: -1px;
  margin-bottom: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  text-align: left;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-container {
  margin-left: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-ink-bar {
  left: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar {
  float: left;
  border-right: 1px solid #e8e8e8;
  margin-right: -1px;
  margin-bottom: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-container {
  margin-right: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-ink-bar {
  right: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-tabs-left .ant-tabs-ink-bar-animated,
.rtl .ant-tabs-right .ant-tabs-ink-bar-animated {
  -webkit-transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .no-flex > .ant-tabs-content-animated,
.rtl .ant-tabs-no-animation > .ant-tabs-content-animated,
.rtl .ant-tabs-vertical > .ant-tabs-content-animated {
  -webkit-transform: none !important;
          transform: none !important;
  margin-right: 0 !important;
}

.rtl .ant-carousel .slick-track {
  position: relative;
  right: 0;
  top: 0;
  display: block;
}

.rtl [dir="rtl"] .ant-carousel .slick-slide {
  float: left;
}

.rtl .ant-carousel .slick-prev {
  right: -25px;
}

.rtl .ant-carousel .slick-prev:before {
  content: "\2190";
}

.rtl .ant-carousel .slick-next {
  left: -25px;
}

.rtl .ant-carousel-vertical .slick-dots {
  width: 3px;
  bottom: auto;
  left: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: auto;
}

.rtl .ant-cascader-picker-label {
  position: absolute;
  right: 0;
  height: 20px;
  line-height: 20px;
  top: 50%;
  margin-top: -10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: 0 12px;
}

.rtl .ant-cascader-picker-clear {
  opacity: 0;
  position: absolute;
  left: 12px;
  z-index: 2;
  background: #fff;
  top: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.rtl .ant-cascader-picker-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 12px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  margin-top: -6px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.25);
}

.rtl .ant-cascader-picker-small .ant-cascader-picker-clear,
.rtl .ant-cascader-picker-small .ant-cascader-picker-arrow {
  left: 8px;
}

.rtl .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomleft,
.rtl .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomleft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}

.rtl .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topleft,
.rtl .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topleft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}

.rtl .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomleft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}

.rtl .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topleft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}

.rtl .ant-cascader-menu {
  display: inline-block;
  vertical-align: top;
  min-width: 111px;
  height: 180px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 1px solid #e8e8e8;
  overflow: auto;
}

.rtl .ant-cascader-menu:last-child {
  border-left-color: transparent;
  margin-left: -1px;
  border-radius: 4px 0 0 4px;
}

.rtl .ant-cascader-menu-item-expand {
  position: relative;
  padding-left: 24px;
}

.rtl .ant-cascader-menu-item-expand:after {
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\E61F";
  display: inline-block;
  font-size: 12px;
  font-size: 8px \9;
  -webkit-transform: scale(0.66667) rotate(0deg);
          transform: scale(0.66667) rotate(0deg);
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  left: 12px;
}

.rtl .ant-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #62c2c9;
  content: "";
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-checkbox-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rtl .ant-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-checkbox-wrapper + span,
.rtl .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
  margin-right: 0px;
}

.rtl .ant-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-row {
  position: relative;
  margin-right: 0;
  margin-left: 0;
  height: auto;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}

.rtl .ant-col-1,
.rtl .ant-col-xs-1,
.rtl .ant-col-sm-1,
.rtl .ant-col-md-1,
.rtl .ant-col-lg-1,
.rtl .ant-col-2,
.rtl .ant-col-xs-2,
.rtl .ant-col-sm-2,
.rtl .ant-col-md-2,
.rtl .ant-col-lg-2,
.rtl .ant-col-3,
.rtl .ant-col-xs-3,
.rtl .ant-col-sm-3,
.rtl .ant-col-md-3,
.rtl .ant-col-lg-3,
.rtl .ant-col-4,
.rtl .ant-col-xs-4,
.rtl .ant-col-sm-4,
.rtl .ant-col-md-4,
.rtl .ant-col-lg-4,
.rtl .ant-col-5,
.rtl .ant-col-xs-5,
.rtl .ant-col-sm-5,
.rtl .ant-col-md-5,
.rtl .ant-col-lg-5,
.rtl .ant-col-6,
.rtl .ant-col-xs-6,
.rtl .ant-col-sm-6,
.rtl .ant-col-md-6,
.rtl .ant-col-lg-6,
.rtl .ant-col-7,
.rtl .ant-col-xs-7,
.rtl .ant-col-sm-7,
.rtl .ant-col-md-7,
.rtl .ant-col-lg-7,
.rtl .ant-col-8,
.rtl .ant-col-xs-8,
.rtl .ant-col-sm-8,
.rtl .ant-col-md-8,
.rtl .ant-col-lg-8,
.rtl .ant-col-9,
.rtl .ant-col-xs-9,
.rtl .ant-col-sm-9,
.rtl .ant-col-md-9,
.rtl .ant-col-lg-9,
.rtl .ant-col-10,
.rtl .ant-col-xs-10,
.rtl .ant-col-sm-10,
.rtl .ant-col-md-10,
.rtl .ant-col-lg-10,
.rtl .ant-col-11,
.rtl .ant-col-xs-11,
.rtl .ant-col-sm-11,
.rtl .ant-col-md-11,
.rtl .ant-col-lg-11,
.rtl .ant-col-12,
.rtl .ant-col-xs-12,
.rtl .ant-col-sm-12,
.rtl .ant-col-md-12,
.rtl .ant-col-lg-12,
.rtl .ant-col-13,
.rtl .ant-col-xs-13,
.rtl .ant-col-sm-13,
.rtl .ant-col-md-13,
.rtl .ant-col-lg-13,
.rtl .ant-col-14,
.rtl .ant-col-xs-14,
.rtl .ant-col-sm-14,
.rtl .ant-col-md-14,
.rtl .ant-col-lg-14,
.rtl .ant-col-15,
.rtl .ant-col-xs-15,
.rtl .ant-col-sm-15,
.rtl .ant-col-md-15,
.rtl .ant-col-lg-15,
.rtl .ant-col-16,
.rtl .ant-col-xs-16,
.rtl .ant-col-sm-16,
.rtl .ant-col-md-16,
.rtl .ant-col-lg-16,
.rtl .ant-col-17,
.rtl .ant-col-xs-17,
.rtl .ant-col-sm-17,
.rtl .ant-col-md-17,
.rtl .ant-col-lg-17,
.rtl .ant-col-18,
.rtl .ant-col-xs-18,
.rtl .ant-col-sm-18,
.rtl .ant-col-md-18,
.rtl .ant-col-lg-18,
.rtl .ant-col-19,
.rtl .ant-col-xs-19,
.rtl .ant-col-sm-19,
.rtl .ant-col-md-19,
.rtl .ant-col-lg-19,
.rtl .ant-col-20,
.rtl .ant-col-xs-20,
.rtl .ant-col-sm-20,
.rtl .ant-col-md-20,
.rtl .ant-col-lg-20,
.rtl .ant-col-21,
.rtl .ant-col-xs-21,
.rtl .ant-col-sm-21,
.rtl .ant-col-md-21,
.rtl .ant-col-lg-21,
.rtl .ant-col-22,
.rtl .ant-col-xs-22,
.rtl .ant-col-sm-22,
.rtl .ant-col-md-22,
.rtl .ant-col-lg-22,
.rtl .ant-col-23,
.rtl .ant-col-xs-23,
.rtl .ant-col-sm-23,
.rtl .ant-col-md-23,
.rtl .ant-col-lg-23,
.rtl .ant-col-24,
.rtl .ant-col-xs-24,
.rtl .ant-col-sm-24,
.rtl .ant-col-md-24,
.rtl .ant-col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-col-1,
.rtl .ant-col-2,
.rtl .ant-col-3,
.rtl .ant-col-4,
.rtl .ant-col-5,
.rtl .ant-col-6,
.rtl .ant-col-7,
.rtl .ant-col-8,
.rtl .ant-col-9,
.rtl .ant-col-10,
.rtl .ant-col-11,
.rtl .ant-col-12,
.rtl .ant-col-13,
.rtl .ant-col-14,
.rtl .ant-col-15,
.rtl .ant-col-16,
.rtl .ant-col-17,
.rtl .ant-col-18,
.rtl .ant-col-19,
.rtl .ant-col-20,
.rtl .ant-col-21,
.rtl .ant-col-22,
.rtl .ant-col-23,
.rtl .ant-col-24 {
  float: right;
  flex: 0 0 auto;
}

.rtl .ant-col-push-24 {
  right: 100%;
}

.rtl .ant-col-pull-24 {
  left: 100%;
}

.rtl .ant-col-offset-24 {
  margin-right: 100%;
}

.rtl .ant-col-push-23 {
  right: 95.83333333%;
}

.rtl .ant-col-pull-23 {
  left: 95.83333333%;
}

.rtl .ant-col-offset-23 {
  margin-right: 95.83333333%;
}

.rtl .ant-col-push-22 {
  right: 91.66666667%;
}

.rtl .ant-col-pull-22 {
  left: 91.66666667%;
}

.rtl .ant-col-offset-22 {
  margin-right: 91.66666667%;
}

.rtl .ant-col-push-21 {
  right: 87.5%;
}

.rtl .ant-col-pull-21 {
  left: 87.5%;
}

.rtl .ant-col-offset-21 {
  margin-right: 87.5%;
}

.rtl .ant-col-push-20 {
  right: 83.33333333%;
}

.rtl .ant-col-pull-20 {
  left: 83.33333333%;
}

.rtl .ant-col-offset-20 {
  margin-right: 83.33333333%;
}

.rtl .ant-col-push-19 {
  right: 79.16666667%;
}

.rtl .ant-col-pull-19 {
  left: 79.16666667%;
}

.rtl .ant-col-offset-19 {
  margin-right: 79.16666667%;
}

.rtl .ant-col-push-18 {
  right: 75%;
}

.rtl .ant-col-pull-18 {
  left: 75%;
}

.rtl .ant-col-offset-18 {
  margin-right: 75%;
}

.rtl .ant-col-push-17 {
  right: 70.83333333%;
}

.rtl .ant-col-pull-17 {
  left: 70.83333333%;
}

.rtl .ant-col-offset-17 {
  margin-right: 70.83333333%;
}

.rtl .ant-col-push-16 {
  right: 66.66666667%;
}

.rtl .ant-col-pull-16 {
  left: 66.66666667%;
}

.rtl .ant-col-offset-16 {
  margin-right: 66.66666667%;
}

.rtl .ant-col-push-15 {
  right: 62.5%;
}

.rtl .ant-col-pull-15 {
  left: 62.5%;
}

.rtl .ant-col-offset-15 {
  margin-right: 62.5%;
}

.rtl .ant-col-push-14 {
  right: 58.33333333%;
}

.rtl .ant-col-pull-14 {
  left: 58.33333333%;
}

.rtl .ant-col-offset-14 {
  margin-right: 58.33333333%;
}

.rtl .ant-col-push-13 {
  right: 54.16666667%;
}

.rtl .ant-col-pull-13 {
  left: 54.16666667%;
}

.rtl .ant-col-offset-13 {
  margin-right: 54.16666667%;
}

.rtl .ant-col-push-12 {
  right: 50%;
}

.rtl .ant-col-pull-12 {
  left: 50%;
}

.rtl .ant-col-offset-12 {
  margin-right: 50%;
}

.rtl .ant-col-push-11 {
  right: 45.83333333%;
}

.rtl .ant-col-pull-11 {
  left: 45.83333333%;
}

.rtl .ant-col-offset-11 {
  margin-right: 45.83333333%;
}

.rtl .ant-col-push-10 {
  right: 41.66666667%;
}

.rtl .ant-col-pull-10 {
  left: 41.66666667%;
}

.rtl .ant-col-offset-10 {
  margin-right: 41.66666667%;
}

.rtl .ant-col-push-9 {
  right: 37.5%;
}

.rtl .ant-col-pull-9 {
  left: 37.5%;
}

.rtl .ant-col-offset-9 {
  margin-right: 37.5%;
}

.rtl .ant-col-push-8 {
  right: 33.33333333%;
}

.rtl .ant-col-pull-8 {
  left: 33.33333333%;
}

.rtl .ant-col-offset-8 {
  margin-right: 33.33333333%;
}

.rtl .ant-col-push-7 {
  right: 29.16666667%;
}

.rtl .ant-col-pull-7 {
  left: 29.16666667%;
}

.rtl .ant-col-offset-7 {
  margin-right: 29.16666667%;
}

.rtl .ant-col-push-6 {
  right: 25%;
}

.rtl .ant-col-pull-6 {
  left: 25%;
}

.rtl .ant-col-offset-6 {
  margin-right: 25%;
}

.rtl .ant-col-push-5 {
  right: 20.83333333%;
}

.rtl .ant-col-pull-5 {
  left: 20.83333333%;
}

.rtl .ant-col-offset-5 {
  margin-right: 20.83333333%;
}

.rtl .ant-col-push-4 {
  right: 16.66666667%;
}

.rtl .ant-col-pull-4 {
  left: 16.66666667%;
}

.rtl .ant-col-offset-4 {
  margin-right: 16.66666667%;
}

.rtl .ant-col-push-3 {
  right: 12.5%;
}

.rtl .ant-col-pull-3 {
  left: 12.5%;
}

.rtl .ant-col-offset-3 {
  margin-right: 12.5%;
}

.rtl .ant-col-push-2 {
  right: 8.33333333%;
}

.rtl .ant-col-pull-2 {
  left: 8.33333333%;
}

.rtl .ant-col-offset-2 {
  margin-right: 8.33333333%;
}

.rtl .ant-col-push-1 {
  right: 4.16666667%;
}

.rtl .ant-col-pull-1 {
  left: 4.16666667%;
}

.rtl .ant-col-offset-1 {
  margin-right: 4.16666667%;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-offset-0 {
  margin-right: 0;
}

.rtl .ant-col-xs-1,
.rtl .ant-col-xs-2,
.rtl .ant-col-xs-3,
.rtl .ant-col-xs-4,
.rtl .ant-col-xs-5,
.rtl .ant-col-xs-6,
.rtl .ant-col-xs-7,
.rtl .ant-col-xs-8,
.rtl .ant-col-xs-9,
.rtl .ant-col-xs-10,
.rtl .ant-col-xs-11,
.rtl .ant-col-xs-12,
.rtl .ant-col-xs-13,
.rtl .ant-col-xs-14,
.rtl .ant-col-xs-15,
.rtl .ant-col-xs-16,
.rtl .ant-col-xs-17,
.rtl .ant-col-xs-18,
.rtl .ant-col-xs-19,
.rtl .ant-col-xs-20,
.rtl .ant-col-xs-21,
.rtl .ant-col-xs-22,
.rtl .ant-col-xs-23,
.rtl .ant-col-xs-24 {
  float: right;
  flex: 0 0 auto;
}

.rtl .ant-col-xs-push-24 {
  right: 100%;
}

.rtl .ant-col-xs-pull-24 {
  left: 100%;
}

.rtl .ant-col-xs-offset-24 {
  margin-right: 100%;
}

.rtl .ant-col-xs-push-23 {
  right: 95.83333333%;
}

.rtl .ant-col-xs-pull-23 {
  left: 95.83333333%;
}

.rtl .ant-col-xs-offset-23 {
  margin-right: 95.83333333%;
}

.rtl .ant-col-xs-push-22 {
  right: 91.66666667%;
}

.rtl .ant-col-xs-pull-22 {
  left: 91.66666667%;
}

.rtl .ant-col-xs-offset-22 {
  margin-right: 91.66666667%;
}

.rtl .ant-col-xs-push-21 {
  right: 87.5%;
}

.rtl .ant-col-xs-pull-21 {
  left: 87.5%;
}

.rtl .ant-col-xs-offset-21 {
  margin-right: 87.5%;
}

.rtl .ant-col-xs-push-20 {
  right: 83.33333333%;
}

.rtl .ant-col-xs-pull-20 {
  left: 83.33333333%;
}

.rtl .ant-col-xs-offset-20 {
  margin-right: 83.33333333%;
}

.rtl .ant-col-xs-push-19 {
  right: 79.16666667%;
}

.rtl .ant-col-xs-pull-19 {
  left: 79.16666667%;
}

.rtl .ant-col-xs-offset-19 {
  margin-right: 79.16666667%;
}

.rtl .ant-col-xs-push-18 {
  right: 75%;
}

.rtl .ant-col-xs-pull-18 {
  left: 75%;
}

.rtl .ant-col-xs-offset-18 {
  margin-right: 75%;
}

.rtl .ant-col-xs-push-17 {
  right: 70.83333333%;
}

.rtl .ant-col-xs-pull-17 {
  left: 70.83333333%;
}

.rtl .ant-col-xs-offset-17 {
  margin-right: 70.83333333%;
}

.rtl .ant-col-xs-push-16 {
  right: 66.66666667%;
}

.rtl .ant-col-xs-pull-16 {
  left: 66.66666667%;
}

.rtl .ant-col-xs-offset-16 {
  margin-right: 66.66666667%;
}

.rtl .ant-col-xs-push-15 {
  right: 62.5%;
}

.rtl .ant-col-xs-pull-15 {
  left: 62.5%;
}

.rtl .ant-col-xs-offset-15 {
  margin-right: 62.5%;
}

.rtl .ant-col-xs-push-14 {
  right: 58.33333333%;
}

.rtl .ant-col-xs-pull-14 {
  left: 58.33333333%;
}

.rtl .ant-col-xs-offset-14 {
  margin-right: 58.33333333%;
}

.rtl .ant-col-xs-push-13 {
  right: 54.16666667%;
}

.rtl .ant-col-xs-pull-13 {
  left: 54.16666667%;
}

.rtl .ant-col-xs-offset-13 {
  margin-right: 54.16666667%;
}

.rtl .ant-col-xs-push-12 {
  right: 50%;
}

.rtl .ant-col-xs-pull-12 {
  left: 50%;
}

.rtl .ant-col-xs-offset-12 {
  margin-right: 50%;
}

.rtl .ant-col-xs-push-11 {
  right: 45.83333333%;
}

.rtl .ant-col-xs-pull-11 {
  left: 45.83333333%;
}

.rtl .ant-col-xs-offset-11 {
  margin-right: 45.83333333%;
}

.rtl .ant-col-xs-push-10 {
  right: 41.66666667%;
}

.rtl .ant-col-xs-pull-10 {
  left: 41.66666667%;
}

.rtl .ant-col-xs-offset-10 {
  margin-right: 41.66666667%;
}

.rtl .ant-col-xs-push-9 {
  right: 37.5%;
}

.rtl .ant-col-xs-pull-9 {
  left: 37.5%;
}

.rtl .ant-col-xs-offset-9 {
  margin-right: 37.5%;
}

.rtl .ant-col-xs-push-8 {
  right: 33.33333333%;
}

.rtl .ant-col-xs-pull-8 {
  left: 33.33333333%;
}

.rtl .ant-col-xs-offset-8 {
  margin-right: 33.33333333%;
}

.rtl .ant-col-xs-push-7 {
  right: 29.16666667%;
}

.rtl .ant-col-xs-pull-7 {
  left: 29.16666667%;
}

.rtl .ant-col-xs-offset-7 {
  margin-right: 29.16666667%;
}

.rtl .ant-col-xs-push-6 {
  right: 25%;
}

.rtl .ant-col-xs-pull-6 {
  left: 25%;
}

.rtl .ant-col-xs-offset-6 {
  margin-right: 25%;
}

.rtl .ant-col-xs-push-5 {
  right: 20.83333333%;
}

.rtl .ant-col-xs-pull-5 {
  left: 20.83333333%;
}

.rtl .ant-col-xs-offset-5 {
  margin-right: 20.83333333%;
}

.rtl .ant-col-xs-push-4 {
  right: 16.66666667%;
}

.rtl .ant-col-xs-pull-4 {
  left: 16.66666667%;
}

.rtl .ant-col-xs-offset-4 {
  margin-right: 16.66666667%;
}

.rtl .ant-col-xs-push-3 {
  right: 12.5%;
}

.rtl .ant-col-xs-pull-3 {
  left: 12.5%;
}

.rtl .ant-col-xs-offset-3 {
  margin-right: 12.5%;
}

.rtl .ant-col-xs-push-2 {
  right: 8.33333333%;
}

.rtl .ant-col-xs-pull-2 {
  left: 8.33333333%;
}

.rtl .ant-col-xs-offset-2 {
  margin-right: 8.33333333%;
}

.rtl .ant-col-xs-push-1 {
  right: 4.16666667%;
}

.rtl .ant-col-xs-pull-1 {
  left: 4.16666667%;
}

.rtl .ant-col-xs-offset-1 {
  margin-right: 4.16666667%;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-xs-push-0 {
  right: auto;
}

.rtl .ant-col-xs-pull-0 {
  left: auto;
}

.rtl .ant-col-xs-offset-0 {
  margin-right: 0;
}

@media (min-width: 576px) {
  .rtl .ant-col-sm-1,
  .rtl .ant-col-sm-2,
  .rtl .ant-col-sm-3,
  .rtl .ant-col-sm-4,
  .rtl .ant-col-sm-5,
  .rtl .ant-col-sm-6,
  .rtl .ant-col-sm-7,
  .rtl .ant-col-sm-8,
  .rtl .ant-col-sm-9,
  .rtl .ant-col-sm-10,
  .rtl .ant-col-sm-11,
  .rtl .ant-col-sm-12,
  .rtl .ant-col-sm-13,
  .rtl .ant-col-sm-14,
  .rtl .ant-col-sm-15,
  .rtl .ant-col-sm-16,
  .rtl .ant-col-sm-17,
  .rtl .ant-col-sm-18,
  .rtl .ant-col-sm-19,
  .rtl .ant-col-sm-20,
  .rtl .ant-col-sm-21,
  .rtl .ant-col-sm-22,
  .rtl .ant-col-sm-23,
  .rtl .ant-col-sm-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-sm-push-24 {
    right: 100%;
  }
  .rtl .ant-col-sm-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-sm-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-sm-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-sm-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-sm-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-sm-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-sm-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-sm-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-sm-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-sm-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-sm-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-sm-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-sm-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-sm-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-sm-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-sm-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-sm-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-sm-push-18 {
    right: 75%;
  }
  .rtl .ant-col-sm-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-sm-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-sm-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-sm-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-sm-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-sm-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-sm-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-sm-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-sm-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-sm-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-sm-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-sm-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-sm-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-sm-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-sm-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-sm-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-sm-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-sm-push-12 {
    right: 50%;
  }
  .rtl .ant-col-sm-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-sm-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-sm-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-sm-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-sm-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-sm-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-sm-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-sm-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-sm-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-sm-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-sm-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-sm-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-sm-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-sm-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-sm-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-sm-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-sm-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-sm-push-6 {
    right: 25%;
  }
  .rtl .ant-col-sm-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-sm-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-sm-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-sm-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-sm-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-sm-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-sm-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-sm-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-sm-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-sm-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-sm-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-sm-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-sm-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-sm-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-sm-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-sm-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-sm-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-sm-push-0 {
    right: auto;
  }
  .rtl .ant-col-sm-pull-0 {
    left: auto;
  }
  .rtl .ant-col-sm-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .rtl .ant-col-md-1,
  .rtl .ant-col-md-2,
  .rtl .ant-col-md-3,
  .rtl .ant-col-md-4,
  .rtl .ant-col-md-5,
  .rtl .ant-col-md-6,
  .rtl .ant-col-md-7,
  .rtl .ant-col-md-8,
  .rtl .ant-col-md-9,
  .rtl .ant-col-md-10,
  .rtl .ant-col-md-11,
  .rtl .ant-col-md-12,
  .rtl .ant-col-md-13,
  .rtl .ant-col-md-14,
  .rtl .ant-col-md-15,
  .rtl .ant-col-md-16,
  .rtl .ant-col-md-17,
  .rtl .ant-col-md-18,
  .rtl .ant-col-md-19,
  .rtl .ant-col-md-20,
  .rtl .ant-col-md-21,
  .rtl .ant-col-md-22,
  .rtl .ant-col-md-23,
  .rtl .ant-col-md-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-md-push-24 {
    right: 100%;
  }
  .rtl .ant-col-md-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-md-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-md-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-md-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-md-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-md-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-md-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-md-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-md-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-md-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-md-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-md-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-md-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-md-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-md-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-md-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-md-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-md-push-18 {
    right: 75%;
  }
  .rtl .ant-col-md-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-md-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-md-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-md-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-md-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-md-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-md-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-md-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-md-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-md-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-md-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-md-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-md-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-md-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-md-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-md-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-md-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-md-push-12 {
    right: 50%;
  }
  .rtl .ant-col-md-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-md-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-md-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-md-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-md-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-md-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-md-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-md-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-md-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-md-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-md-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-md-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-md-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-md-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-md-order-8 {
    order: 8;
  }
  .rtl .ant-col-md-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-md-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-md-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-md-push-6 {
    right: 25%;
  }
  .rtl .ant-col-md-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-md-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-md-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-md-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-md-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-md-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-md-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-md-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-md-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-md-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-md-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-md-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-md-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-md-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-md-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-md-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-md-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-md-push-0 {
    right: auto;
  }
  .rtl .ant-col-md-pull-0 {
    left: auto;
  }
  .rtl .ant-col-md-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .rtl .ant-col-lg-1,
  .rtl .ant-col-lg-2,
  .rtl .ant-col-lg-3,
  .rtl .ant-col-lg-4,
  .rtl .ant-col-lg-5,
  .rtl .ant-col-lg-6,
  .rtl .ant-col-lg-7,
  .rtl .ant-col-lg-8,
  .rtl .ant-col-lg-9,
  .rtl .ant-col-lg-10,
  .rtl .ant-col-lg-11,
  .rtl .ant-col-lg-12,
  .rtl .ant-col-lg-13,
  .rtl .ant-col-lg-14,
  .rtl .ant-col-lg-15,
  .rtl .ant-col-lg-16,
  .rtl .ant-col-lg-17,
  .rtl .ant-col-lg-18,
  .rtl .ant-col-lg-19,
  .rtl .ant-col-lg-20,
  .rtl .ant-col-lg-21,
  .rtl .ant-col-lg-22,
  .rtl .ant-col-lg-23,
  .rtl .ant-col-lg-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-lg-push-24 {
    right: 100%;
  }
  .rtl .ant-col-lg-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-lg-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-lg-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-lg-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-lg-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-lg-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-lg-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-lg-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-lg-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-lg-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-lg-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-lg-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-lg-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-lg-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-lg-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-lg-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-lg-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-lg-push-18 {
    right: 75%;
  }
  .rtl .ant-col-lg-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-lg-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-lg-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-lg-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-lg-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-lg-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-lg-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-lg-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-lg-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-lg-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-lg-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-lg-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-lg-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-lg-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-lg-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-lg-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-lg-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-lg-push-12 {
    right: 50%;
  }
  .rtl .ant-col-lg-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-lg-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-lg-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-lg-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-lg-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-lg-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-lg-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-lg-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-lg-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-lg-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-lg-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-lg-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-lg-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-lg-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-lg-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-lg-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-lg-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-lg-push-6 {
    right: 25%;
  }
  .rtl .ant-col-lg-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-lg-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-lg-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-lg-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-lg-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-lg-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-lg-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-lg-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-lg-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-lg-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-lg-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-lg-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-lg-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-lg-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-lg-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-lg-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-lg-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-lg-push-0 {
    right: auto;
  }
  .rtl .ant-col-lg-pull-0 {
    left: auto;
  }
  .rtl .ant-col-lg-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .ant-col-xl-1,
  .rtl .ant-col-xl-2,
  .rtl .ant-col-xl-3,
  .rtl .ant-col-xl-4,
  .rtl .ant-col-xl-5,
  .rtl .ant-col-xl-6,
  .rtl .ant-col-xl-7,
  .rtl .ant-col-xl-8,
  .rtl .ant-col-xl-9,
  .rtl .ant-col-xl-10,
  .rtl .ant-col-xl-11,
  .rtl .ant-col-xl-12,
  .rtl .ant-col-xl-13,
  .rtl .ant-col-xl-14,
  .rtl .ant-col-xl-15,
  .rtl .ant-col-xl-16,
  .rtl .ant-col-xl-17,
  .rtl .ant-col-xl-18,
  .rtl .ant-col-xl-19,
  .rtl .ant-col-xl-20,
  .rtl .ant-col-xl-21,
  .rtl .ant-col-xl-22,
  .rtl .ant-col-xl-23,
  .rtl .ant-col-xl-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-xl-push-24 {
    right: 100%;
  }
  .rtl .ant-col-xl-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-xl-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-xl-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-xl-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-xl-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-xl-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-xl-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-xl-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-xl-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-xl-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-xl-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-xl-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-xl-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-xl-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-xl-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-xl-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-xl-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-xl-push-18 {
    right: 75%;
  }
  .rtl .ant-col-xl-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-xl-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-xl-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-xl-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-xl-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-xl-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-xl-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-xl-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-xl-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-xl-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-xl-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-xl-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-xl-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-xl-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-xl-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-xl-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-xl-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-xl-push-12 {
    right: 50%;
  }
  .rtl .ant-col-xl-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-xl-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-xl-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-xl-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-xl-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-xl-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-xl-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-xl-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-xl-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-xl-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-xl-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-xl-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-xl-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-xl-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-xl-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-xl-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-xl-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-xl-push-6 {
    right: 25%;
  }
  .rtl .ant-col-xl-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-xl-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-xl-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-xl-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-xl-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-xl-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-xl-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-xl-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-xl-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-xl-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-xl-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-xl-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-xl-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-xl-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-xl-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-xl-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-xl-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xl-push-0 {
    right: auto;
  }
  .rtl .ant-col-xl-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xl-offset-0 {
    margin-right: 0;
  }
  .rtl .ant-col-xl-order-0 {
    order: 0;
  }
}

@media (min-width: 1600px) {
  .rtl .ant-col-xxl-1,
  .rtl .ant-col-xxl-2,
  .rtl .ant-col-xxl-3,
  .rtl .ant-col-xxl-4,
  .rtl .ant-col-xxl-5,
  .rtl .ant-col-xxl-6,
  .rtl .ant-col-xxl-7,
  .rtl .ant-col-xxl-8,
  .rtl .ant-col-xxl-9,
  .rtl .ant-col-xxl-10,
  .rtl .ant-col-xxl-11,
  .rtl .ant-col-xxl-12,
  .rtl .ant-col-xxl-13,
  .rtl .ant-col-xxl-14,
  .rtl .ant-col-xxl-15,
  .rtl .ant-col-xxl-16,
  .rtl .ant-col-xxl-17,
  .rtl .ant-col-xxl-18,
  .rtl .ant-col-xxl-19,
  .rtl .ant-col-xxl-20,
  .rtl .ant-col-xxl-21,
  .rtl .ant-col-xxl-22,
  .rtl .ant-col-xxl-23,
  .rtl .ant-col-xxl-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-xxl-push-24 {
    right: 100%;
  }
  .rtl .ant-col-xxl-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-xxl-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-xxl-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-xxl-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-xxl-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-xxl-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-xxl-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-xxl-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-xxl-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-xxl-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-xxl-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-xxl-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-xxl-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-xxl-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-xxl-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-xxl-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-xxl-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-xxl-push-18 {
    right: 75%;
  }
  .rtl .ant-col-xxl-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-xxl-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-xxl-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-xxl-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-xxl-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-xxl-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-xxl-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-xxl-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-xxl-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-xxl-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-xxl-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-xxl-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-xxl-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-xxl-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-xxl-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-xxl-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-xxl-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-xxl-push-12 {
    right: 50%;
  }
  .rtl .ant-col-xxl-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-xxl-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-xxl-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-xxl-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-xxl-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-xxl-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-xxl-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-xxl-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-xxl-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-xxl-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-xxl-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-xxl-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-xxl-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-xxl-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-xxl-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-xxl-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-xxl-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-xxl-push-6 {
    right: 25%;
  }
  .rtl .ant-col-xxl-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-xxl-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-xxl-order-6 {
    order: 6;
  }
  .rtl .ant-col-xxl-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-xxl-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-xxl-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .rtl .ant-col-xxl-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-xxl-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-xxl-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-xxl-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-xxl-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-xxl-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-xxl-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-xxl-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-xxl-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-xxl-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-xxl-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-xxl-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xxl-push-0 {
    right: auto;
  }
  .rtl .ant-col-xxl-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xxl-offset-0 {
    margin-right: 0;
  }
}

.rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  right: 16px;
}

.rtl .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
}

.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topright,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topright {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}

.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomright,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomright {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}

.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topright {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}

.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomright {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}

.rtl .ant-calendar-picker-clear,
.rtl .ant-calendar-picker-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 12px;
  right: unset;
  top: 50%;
  margin-top: -7px;
  line-height: 14px;
  font-size: 12px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.rtl .ant-calendar-picker-small .ant-calendar-picker-clear,
.rtl .ant-calendar-picker-small .ant-calendar-picker-icon {
  left: 8px;
}

.rtl .ant-calendar {
  position: relative;
  outline: none;
  width: 280px;
  border: 1px solid #fff;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  line-height: 1.5;
}

.rtl .ant-calendar-header .ant-calendar-prev-century-btn,
.rtl .ant-calendar-header .ant-calendar-prev-decade-btn,
.rtl .ant-calendar-header .ant-calendar-prev-year-btn {
  right: 7px;
  left: unset;
}

.rtl .ant-calendar-header .ant-calendar-next-century-btn,
.rtl .ant-calendar-header .ant-calendar-next-decade-btn,
.rtl .ant-calendar-header .ant-calendar-next-year-btn {
  left: 7px;
  right: unset;
}

.rtl .ant-calendar-header .ant-calendar-prev-month-btn {
  right: 29px;
  left: unset;
}

.rtl .ant-calendar-header .ant-calendar-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-header .ant-calendar-next-month-btn {
  left: 29px;
  right: unset;
}

.rtl .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  margin-left: 5px;
  padding-right: 5px;
}

.rtl .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
  content: " ";
  position: absolute;
  top: -1px;
  right: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid #bcbcbc;
  border-radius: 2px;
}

.rtl .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rtl .ant-calendar .ant-calendar-clear-btn {
  display: none;
  position: absolute;
  left: 5px;
  text-indent: -76px;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}

.rtl .ant-calendar .ant-calendar-ok-btn > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar-range-left {
  float: right;
}

.rtl .ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-left: 1.5px solid #e8e8e8;
}

.rtl .ant-calendar-range-right {
  float: left;
}

.rtl .ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-right: 1.5px solid #e8e8e8;
}

.rtl .ant-calendar-range-middle {
  position: absolute;
  right: 50%;
  width: 20px;
  margin-right: -132px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-right: -118px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  margin-right: -12px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-right: 0;
}

.rtl .ant-calendar-range .ant-calendar-input,
.rtl .ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 24px;
  border: 0;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-calendar-range .ant-calendar-in-range-cell:before {
  content: "";
  display: block;
  background: #e6f7ff;
  border-radius: 0;
  border: 0;
  position: absolute;
  top: 4px;
  bottom: 4px;
  right: 0;
  left: 0;
}

.rtl div.ant-calendar-range-quick-selector {
  text-align: right;
}

.rtl div.ant-calendar-range-quick-selector > a {
  margin-left: 8px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-left: 8px;
}

.rtl .ant-calendar-time-picker-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  background-clip: padding-box;
  line-height: 1.5;
  overflow: hidden;
  width: 100%;
}

.rtl .ant-calendar-time-picker-select {
  float: right;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  height: 226px;
}

.rtl .ant-calendar-time-picker-select:first-child {
  border-right: 0;
  margin-right: 0;
}

.rtl .ant-calendar-time-picker-select:last-child {
  border-left: 0;
}

.rtl .ant-calendar-time .ant-calendar-footer-btn {
  text-align: left;
}

.rtl .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: right;
  margin: 0;
}

.rtl .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-calendar-month-panel {
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  outline: none;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
  content: "\AB";
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-calendar-year-panel {
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  outline: none;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after {
  content: "\203A";
}

.rtl .ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
  content: "\AB";
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-time-picker-panel-inner {
  position: relative;
  outline: none;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  overflow: hidden;
  right: -2px;
}

.rtl .ant-time-picker-panel-clear-btn {
  position: absolute;
  left: 8px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}

.rtl .ant-time-picker-panel-select {
  float: right;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative;
  max-height: 192px;
}

.rtl .ant-time-picker-panel-select:first-child {
  border-right: 0;
  margin-right: 0;
}

.rtl .ant-time-picker-panel-select:last-child {
  border-left: 0;
}

.rtl .ant-time-picker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 12px 0 0;
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: right;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topright,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topright {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}

.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomright,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomright {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}

.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topright {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}

.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomright {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}

.rtl .ant-time-picker-icon {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 14px;
  height: 14px;
  line-height: 14px;
  left: 11px;
  color: rgba(0, 0, 0, 0.25);
  top: 50%;
  margin-top: -7px;
}

.rtl .ant-time-picker-small .ant-time-picker-icon {
  left: 7px;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left {
  display: table;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin: 16px 0;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left:before {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 5%;
  border-top: 1px solid #e8e8e8;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left:after {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 95%;
  border-top: 1px solid #e8e8e8;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left-inner-text {
  display: inline-block;
  padding: 0 10px;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin: 16px 0;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right:before {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 95%;
  border-top: 1px solid #e8e8e8;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right:after {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 5%;
  border-top: 1px solid #e8e8e8;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right-inner-text {
  display: inline-block;
  padding: 0 10px;
}

.rtl .ant-dropdown-menu {
  text-align: right;
}

.rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  left: 8px;
}

.rtl .ant-dropdown-menu-submenu-title {
  padding-left: 26px;
}

.rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  top: 0;
  right: 100%;
  position: absolute;
  min-width: 100%;
  margin-right: 4px;
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}

.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomright,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomright {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}

.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topright,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topright {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}

.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomright {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}

.rtl .ant-pagination.mini .ant-pagination-prev,
.rtl .ant-pagination.mini .ant-pagination-next {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topright {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}

.rtl .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-form-item-required:before {
  display: inline-block;
  margin-left: 4px;
  content: "*";
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
}

.rtl .ant-form-item-label {
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.rtl .ant-form-text {
  display: inline-block;
  padding-left: 8px;
}

.rtl form .has-feedback .ant-input {
  padding-left: 24px;
}

.rtl form .has-feedback > .ant-select .ant-select-arrow,
.rtl form .has-feedback > .ant-select .ant-select-selection__clear,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  left: 28px;
}

.rtl form .has-feedback > .ant-select .ant-select-selection-selected-value,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-left: 42px;
}

.rtl form .has-feedback .ant-cascader-picker-arrow {
  margin-left: 17px;
}

.rtl form .has-feedback .ant-cascader-picker-clear {
  left: 28px;
}

.rtl form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  left: 28px;
}

.rtl form .has-feedback .ant-calendar-picker-icon,
.rtl form .has-feedback .ant-time-picker-icon,
.rtl form .has-feedback .ant-calendar-picker-clear,
.rtl form .has-feedback .ant-time-picker-clear {
  left: 28px;
}

.rtl form .ant-radio-inline,
.rtl form .ant-checkbox-inline {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
  margin-right: 8px;
}

.rtl form .ant-radio-inline:first-child,
.rtl form .ant-checkbox-inline:first-child {
  margin-right: 0;
}

.rtl form .ant-checkbox-vertical,
.rtl form .ant-radio-vertical {
  display: block;
}

.rtl form .ant-checkbox-vertical + .ant-checkbox-vertical,
.rtl form .ant-radio-vertical + .ant-radio-vertical {
  margin-right: 0;
}

.rtl form .ant-input-number + .ant-form-text {
  margin-right: 8px;
}

.rtl .ant-input-group-wrap .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group-wrap .ant-select-selection:hover {
  border-color: #d9d9d9;
}

.rtl .ant-input-group-wrap .ant-select-selection--single {
  margin-right: -1px;
  height: 40px;
  background-color: #eee;
}

.rtl .ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-right: 8px;
  padding-left: 25px;
}

.rtl .ant-form-vertical .ant-form-item-label,
.rtl .ant-col-24.ant-form-item-label,
.rtl .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  margin: 0;
  display: block;
  text-align: right;
  line-height: 1.5;
}

@media (max-width: 575px) {
  .rtl .ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
  .rtl .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 767px) {
  .rtl .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 991px) {
  .rtl .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 1199px) {
  .rtl .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 1599px) {
  .rtl .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

.rtl .ant-form-inline .ant-form-item {
  display: inline-block;
  margin-left: 16px;
  margin-bottom: 0;
}

.rtl .has-success.has-feedback .ant-form-item-children:after,
.rtl .has-warning.has-feedback .ant-form-item-children:after,
.rtl .has-error.has-feedback .ant-form-item-children:after,
.rtl .is-validating.has-feedback .ant-form-item-children:after {
  position: absolute;
  top: 50%;
  left: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  text-align: center;
  font-size: 14px;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  z-index: 1;
}

.rtl .ant-input-number-handler-up-inner,
.rtl .ant-input-number-handler-down-inner {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  width: 12px;
  height: 12px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  -webkit-transform: scale(0.58333) rotate(0deg);
          transform: scale(0.58333) rotate(0deg);
  left: 4px;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-layout-sider-right {
  order: 1;
}

.rtl .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  left: -36px;
  text-align: center;
  width: 36px;
  height: 42px;
  line-height: 42px;
  background: #001529;
  color: #fff;
  font-size: 18px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.rtl .ant-list-pagination {
  margin-top: 24px;
  text-align: left;
}

.rtl .ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}

.rtl .ant-list-item-meta-avatar {
  margin-left: 16px;
}

.rtl .ant-list-item-action {
  font-size: 0;
  flex: 0 0 auto;
  margin-right: 48px;
  padding: 0;
  list-style: none;
}

.rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
}

.rtl .ant-list-item-action-split {
  background-color: #e8e8e8;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 14px;
}

.rtl .ant-list-vertical .ant-list-item-extra {
  margin-right: 58px;
}

.rtl .ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}

.rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
}

.rtl .ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}

.rtl .ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}

@media screen and (max-width: 768px) {
  .rtl .ant-list-item-action {
    margin-right: 24px;
  }
  .rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
  }
}

@media screen and (max-width: 480px) {
  .rtl .ant-list-item-action {
    margin-right: 12px;
  }
  .rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 0;
  }
}

.rtl .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  right: 50%;
  margin: -10px;
}

.rtl .ant-spin-blur:after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.3;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 10;
}

.rtl .ant-spin-dot i:nth-child(1) {
  right: 0;
  top: 0;
}

.rtl .ant-spin-dot i:nth-child(2) {
  left: 0;
  top: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.rtl .ant-spin-dot i:nth-child(3) {
  left: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.rtl .ant-spin-dot i:nth-child(4) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}

.rtl .ant-pagination-item {
  cursor: pointer;
  border-radius: 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-left: 8px;
  margin-right: 0;
  font-family: Arial;
  outline: 0;
}

.rtl .ant-pagination-prev,
.rtl .ant-pagination-next,
.rtl .ant-pagination-jump-prev,
.rtl .ant-pagination-jump-next {
  margin-left: 8px;
  margin-right: 0;
  -webkit-transform: scale(-1);
          transform: scale(-1);
}

.rtl .ant-pagination-options {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}

.rtl .ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  margin-left: 8px;
  height: 24px;
}

.rtl .ant-pagination-simple .ant-pagination-simple-pager input {
  margin-left: 8px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 0 6px;
  height: 100%;
  text-align: center;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.rtl .ant-pagination.mini .ant-pagination-jump-prev,
.rtl .ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  line-height: 24px;
  margin-left: 0;
}

.rtl .ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
}

.rtl .ant-mention-dropdown {
  right: -9999px;
}

.rtl .ant-menu-item .anticon,
.rtl .ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-left: 10px;
  -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 50%;
  left: 16px;
  right: auto;
  width: 10px;
}

.rtl .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 34px;
  padding-right: 16px;
}

.rtl .ant-tooltip-placement-right,
.rtl .ant-tooltip-placement-rightTop,
.rtl .ant-tooltip-placement-rightBottom {
  padding-right: 8px;
}

.rtl .ant-tooltip-placement-left,
.rtl .ant-tooltip-placement-leftTop,
.rtl .ant-tooltip-placement-leftBottom {
  padding-left: 8px;
}

.rtl .ant-tooltip-inner {
  text-align: right;
}

.rtl .ant-tooltip-placement-top .ant-tooltip-arrow {
  right: 50%;
  margin-right: -5px;
}

.rtl .ant-tooltip-placement-topleft .ant-tooltip-arrow {
  right: 16px;
}

.rtl .ant-tooltip-placement-topright .ant-tooltip-arrow {
  left: 16px;
}

.rtl .ant-tooltip-placement-right .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  right: 3px;
  border-width: 5px 0 5px 5px;
  border-left-color: rgba(0, 0, 0, 0.75);
}

.rtl .ant-tooltip-placement-left .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  left: 3px;
  border-width: 5px 5px 5px 0;
  border-right-color: rgba(0, 0, 0, 0.75);
}

.rtl .ant-tooltip-placement-bottom .ant-tooltip-arrow {
  right: 50%;
  margin-right: -5px;
}

.rtl .ant-tooltip-placement-bottomleft .ant-tooltip-arrow {
  right: 16px;
}

.rtl .ant-tooltip-placement-bottomright .ant-tooltip-arrow {
  left: 16px;
}

.rtl .ant-message {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  right: 0;
  pointer-events: none;
}

.rtl .ant-message .anticon {
  margin-left: 8px;
  font-size: 16px;
  top: 1px;
  position: relative;
}

.rtl .ant-modal-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.rtl .ant-modal-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding: 0;
}

.rtl .ant-modal-footer {
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: left;
  border-radius: 0 0 4px 4px;
}

.rtl .ant-modal-footer button + button {
  margin-right: 8px;
  margin-bottom: 0;
}

.rtl .ant-modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(0, 0, 0, 0.65);
  height: 100%;
  z-index: 1000;
  filter: alpha(opacity=50);
}

.rtl .ant-confirm-body .ant-confirm-content {
  margin-right: 38px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 8px;
}

.rtl .ant-confirm-body > .anticon {
  font-size: 22px;
  margin-left: 16px;
  float: right;
}

.rtl .ant-confirm .ant-confirm-btns {
  margin-top: 24px;
  float: left;
}

.rtl .ant-confirm .ant-confirm-btns button + button {
  margin-right: 8px;
  margin-bottom: 0;
}

.rtl .ant-notification {
  margin-left: 24px;
}

.rtl .ant-notification-topleft,
.rtl .ant-notification-bottomleft {
  margin-right: 24px;
  margin-left: 0;
}

.rtl .ant-notification-topleft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.rtl .ant-notification-bottomleft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.rtl .ant-notification-topleft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.rtl .ant-notification-bottomleft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationleftFadeIn;
          animation-name: NotificationleftFadeIn;
}

.rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-left: 24px;
}

.rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 16px;
  margin-right: 24px;
  margin-bottom: 4px;
}

.rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  font-size: 14px;
}

.rtl .ant-notification-notice-icon {
  position: absolute;
  font-size: 24px;
  line-height: 24px;
  margin-right: 4px;
}

.rtl .ant-notification-notice-close {
  position: absolute;
  left: 22px !important;
  text-align: right;
  right: auto;
  top: 16px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}

.rtl .ant-notification-notice-btn {
  float: left;
  margin-top: 16px;
}

.rtl .ant-modal-confirm-btns {
  float: left;
}

.rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

@-webkit-keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    right: 384px;
  }
  100% {
    right: 0;
    opacity: 1;
  }
  .ant-col-pull-0 {
    left: auto;
  }
  .ant-col-xs-push-0 {
    right: auto;
  }
  .ant-col-xs-pull-0 {
    left: auto;
  }
  .ant-col-xs-offset-0 {
    margin-right: 0;
  }
  @media (min-width: 576px) {
    .ant-col-sm-1,
    .ant-col-sm-2,
    .ant-col-sm-3,
    .ant-col-sm-4,
    .ant-col-sm-5,
    .ant-col-sm-6,
    .ant-col-sm-7,
    .ant-col-sm-8,
    .ant-col-sm-9,
    .ant-col-sm-10,
    .ant-col-sm-11,
    .ant-col-sm-12,
    .ant-col-sm-13,
    .ant-col-sm-14,
    .ant-col-sm-15,
    .ant-col-sm-16,
    .ant-col-sm-17,
    .ant-col-sm-18,
    .ant-col-sm-19,
    .ant-col-sm-20,
    .ant-col-sm-21,
    .ant-col-sm-22,
    .ant-col-sm-23,
    .ant-col-sm-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-sm-push-24 {
      right: 100%;
    }
    .ant-col-sm-pull-24 {
      left: 100%;
    }
    .ant-col-sm-offset-24 {
      margin-right: 100%;
    }
    .ant-col-sm-push-23 {
      right: 95.83333333%;
    }
    .ant-col-sm-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-sm-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-sm-push-22 {
      right: 91.66666667%;
    }
    .ant-col-sm-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-sm-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-sm-push-21 {
      right: 87.5%;
    }
    .ant-col-sm-pull-21 {
      left: 87.5%;
    }
    .ant-col-sm-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-sm-push-20 {
      right: 83.33333333%;
    }
    .ant-col-sm-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-sm-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-sm-push-19 {
      right: 79.16666667%;
    }
    .ant-col-sm-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-sm-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-sm-push-18 {
      right: 75%;
    }
    .ant-col-sm-pull-18 {
      left: 75%;
    }
    .ant-col-sm-offset-18 {
      margin-right: 75%;
    }
    .ant-col-sm-push-17 {
      right: 70.83333333%;
    }
    .ant-col-sm-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-sm-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-sm-push-16 {
      right: 66.66666667%;
    }
    .ant-col-sm-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-sm-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-sm-push-15 {
      right: 62.5%;
    }
    .ant-col-sm-pull-15 {
      left: 62.5%;
    }
    .ant-col-sm-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-sm-push-14 {
      right: 58.33333333%;
    }
    .ant-col-sm-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-sm-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-sm-push-13 {
      right: 54.16666667%;
    }
    .ant-col-sm-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-sm-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-sm-push-12 {
      right: 50%;
    }
    .ant-col-sm-pull-12 {
      left: 50%;
    }
    .ant-col-sm-offset-12 {
      margin-right: 50%;
    }
    .ant-col-sm-push-11 {
      right: 45.83333333%;
    }
    .ant-col-sm-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-sm-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-sm-push-10 {
      right: 41.66666667%;
    }
    .ant-col-sm-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-sm-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-sm-push-9 {
      right: 37.5%;
    }
    .ant-col-sm-pull-9 {
      left: 37.5%;
    }
    .ant-col-sm-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-sm-push-8 {
      right: 33.33333333%;
    }
    .ant-col-sm-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-sm-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-sm-push-7 {
      right: 29.16666667%;
    }
    .ant-col-sm-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-sm-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-sm-push-6 {
      right: 25%;
    }
    .ant-col-sm-pull-6 {
      left: 25%;
    }
    .ant-col-sm-offset-6 {
      margin-right: 25%;
    }
    .ant-col-sm-push-5 {
      right: 20.83333333%;
    }
    .ant-col-sm-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-sm-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-sm-push-4 {
      right: 16.66666667%;
    }
    .ant-col-sm-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-sm-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-sm-push-3 {
      right: 12.5%;
    }
    .ant-col-sm-pull-3 {
      left: 12.5%;
    }
    .ant-col-sm-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-sm-push-2 {
      right: 8.33333333%;
    }
    .ant-col-sm-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-sm-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-sm-push-1 {
      right: 4.16666667%;
    }
    .ant-col-sm-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-sm-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-sm-push-0 {
      right: auto;
    }
    .ant-col-sm-pull-0 {
      left: auto;
    }
    .ant-col-sm-offset-0 {
      margin-right: 0;
    }
  }
  @media (min-width: 768px) {
    .ant-col-md-1,
    .ant-col-md-2,
    .ant-col-md-3,
    .ant-col-md-4,
    .ant-col-md-5,
    .ant-col-md-6,
    .ant-col-md-7,
    .ant-col-md-8,
    .ant-col-md-9,
    .ant-col-md-10,
    .ant-col-md-11,
    .ant-col-md-12,
    .ant-col-md-13,
    .ant-col-md-14,
    .ant-col-md-15,
    .ant-col-md-16,
    .ant-col-md-17,
    .ant-col-md-18,
    .ant-col-md-19,
    .ant-col-md-20,
    .ant-col-md-21,
    .ant-col-md-22,
    .ant-col-md-23,
    .ant-col-md-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-md-push-24 {
      right: 100%;
    }
    .ant-col-md-pull-24 {
      left: 100%;
    }
    .ant-col-md-offset-24 {
      margin-right: 100%;
    }
    .ant-col-md-push-23 {
      right: 95.83333333%;
    }
    .ant-col-md-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-md-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-md-push-22 {
      right: 91.66666667%;
    }
    .ant-col-md-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-md-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-md-push-21 {
      right: 87.5%;
    }
    .ant-col-md-pull-21 {
      left: 87.5%;
    }
    .ant-col-md-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-md-push-20 {
      right: 83.33333333%;
    }
    .ant-col-md-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-md-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-md-push-19 {
      right: 79.16666667%;
    }
    .ant-col-md-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-md-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-md-push-18 {
      right: 75%;
    }
    .ant-col-md-pull-18 {
      left: 75%;
    }
    .ant-col-md-offset-18 {
      margin-right: 75%;
    }
    .ant-col-md-push-17 {
      right: 70.83333333%;
    }
    .ant-col-md-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-md-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-md-push-16 {
      right: 66.66666667%;
    }
    .ant-col-md-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-md-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-md-push-15 {
      right: 62.5%;
    }
    .ant-col-md-pull-15 {
      left: 62.5%;
    }
    .ant-col-md-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-md-push-14 {
      right: 58.33333333%;
    }
    .ant-col-md-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-md-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-md-push-13 {
      right: 54.16666667%;
    }
    .ant-col-md-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-md-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-md-push-12 {
      right: 50%;
    }
    .ant-col-md-pull-12 {
      left: 50%;
    }
    .ant-col-md-offset-12 {
      margin-right: 50%;
    }
    .ant-col-md-push-11 {
      right: 45.83333333%;
    }
    .ant-col-md-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-md-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-md-push-10 {
      right: 41.66666667%;
    }
    .ant-col-md-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-md-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-md-push-9 {
      right: 37.5%;
    }
    .ant-col-md-pull-9 {
      left: 37.5%;
    }
    .ant-col-md-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-md-push-8 {
      right: 33.33333333%;
    }
    .ant-col-md-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-md-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-md-order-8 {
      order: 8;
    }
    .ant-col-md-push-7 {
      right: 29.16666667%;
    }
    .ant-col-md-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-md-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-md-push-6 {
      right: 25%;
    }
    .ant-col-md-pull-6 {
      left: 25%;
    }
    .ant-col-md-offset-6 {
      margin-right: 25%;
    }
    .ant-col-md-push-5 {
      right: 20.83333333%;
    }
    .ant-col-md-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-md-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-md-push-4 {
      right: 16.66666667%;
    }
    .ant-col-md-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-md-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-md-push-3 {
      right: 12.5%;
    }
    .ant-col-md-pull-3 {
      left: 12.5%;
    }
    .ant-col-md-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-md-push-2 {
      right: 8.33333333%;
    }
    .ant-col-md-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-md-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-md-push-1 {
      right: 4.16666667%;
    }
    .ant-col-md-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-md-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-md-push-0 {
      right: auto;
    }
    .ant-col-md-pull-0 {
      left: auto;
    }
    .ant-col-md-offset-0 {
      margin-right: 0;
    }
  }
  @media (min-width: 992px) {
    .ant-col-lg-1,
    .ant-col-lg-2,
    .ant-col-lg-3,
    .ant-col-lg-4,
    .ant-col-lg-5,
    .ant-col-lg-6,
    .ant-col-lg-7,
    .ant-col-lg-8,
    .ant-col-lg-9,
    .ant-col-lg-10,
    .ant-col-lg-11,
    .ant-col-lg-12,
    .ant-col-lg-13,
    .ant-col-lg-14,
    .ant-col-lg-15,
    .ant-col-lg-16,
    .ant-col-lg-17,
    .ant-col-lg-18,
    .ant-col-lg-19,
    .ant-col-lg-20,
    .ant-col-lg-21,
    .ant-col-lg-22,
    .ant-col-lg-23,
    .ant-col-lg-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-lg-push-24 {
      right: 100%;
    }
    .ant-col-lg-pull-24 {
      left: 100%;
    }
    .ant-col-lg-offset-24 {
      margin-right: 100%;
    }
    .ant-col-lg-push-23 {
      right: 95.83333333%;
    }
    .ant-col-lg-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-lg-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-lg-push-22 {
      right: 91.66666667%;
    }
    .ant-col-lg-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-lg-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-lg-push-21 {
      right: 87.5%;
    }
    .ant-col-lg-pull-21 {
      left: 87.5%;
    }
    .ant-col-lg-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-lg-push-20 {
      right: 83.33333333%;
    }
    .ant-col-lg-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-lg-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-lg-push-19 {
      right: 79.16666667%;
    }
    .ant-col-lg-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-lg-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-lg-push-18 {
      right: 75%;
    }
    .ant-col-lg-pull-18 {
      left: 75%;
    }
    .ant-col-lg-offset-18 {
      margin-right: 75%;
    }
    .ant-col-lg-push-17 {
      right: 70.83333333%;
    }
    .ant-col-lg-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-lg-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-lg-push-16 {
      right: 66.66666667%;
    }
    .ant-col-lg-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-lg-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-lg-push-15 {
      right: 62.5%;
    }
    .ant-col-lg-pull-15 {
      left: 62.5%;
    }
    .ant-col-lg-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-lg-push-14 {
      right: 58.33333333%;
    }
    .ant-col-lg-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-lg-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-lg-push-13 {
      right: 54.16666667%;
    }
    .ant-col-lg-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-lg-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-lg-push-12 {
      right: 50%;
    }
    .ant-col-lg-pull-12 {
      left: 50%;
    }
    .ant-col-lg-offset-12 {
      margin-right: 50%;
    }
    .ant-col-lg-push-11 {
      right: 45.83333333%;
    }
    .ant-col-lg-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-lg-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-lg-push-10 {
      right: 41.66666667%;
    }
    .ant-col-lg-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-lg-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-lg-push-9 {
      right: 37.5%;
    }
    .ant-col-lg-pull-9 {
      left: 37.5%;
    }
    .ant-col-lg-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-lg-push-8 {
      right: 33.33333333%;
    }
    .ant-col-lg-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-lg-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-lg-push-7 {
      right: 29.16666667%;
    }
    .ant-col-lg-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-lg-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-lg-push-6 {
      right: 25%;
    }
    .ant-col-lg-pull-6 {
      left: 25%;
    }
    .ant-col-lg-offset-6 {
      margin-right: 25%;
    }
    .ant-col-lg-push-5 {
      right: 20.83333333%;
    }
    .ant-col-lg-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-lg-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-lg-push-4 {
      right: 16.66666667%;
    }
    .ant-col-lg-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-lg-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-lg-push-3 {
      right: 12.5%;
    }
    .ant-col-lg-pull-3 {
      left: 12.5%;
    }
    .ant-col-lg-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-lg-push-2 {
      right: 8.33333333%;
    }
    .ant-col-lg-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-lg-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-lg-push-1 {
      right: 4.16666667%;
    }
    .ant-col-lg-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-lg-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-lg-push-0 {
      right: auto;
    }
    .ant-col-lg-pull-0 {
      left: auto;
    }
    .ant-col-lg-offset-0 {
      margin-right: 0;
    }
  }
  @media (min-width: 1200px) {
    .ant-col-xl-1,
    .ant-col-xl-2,
    .ant-col-xl-3,
    .ant-col-xl-4,
    .ant-col-xl-5,
    .ant-col-xl-6,
    .ant-col-xl-7,
    .ant-col-xl-8,
    .ant-col-xl-9,
    .ant-col-xl-10,
    .ant-col-xl-11,
    .ant-col-xl-12,
    .ant-col-xl-13,
    .ant-col-xl-14,
    .ant-col-xl-15,
    .ant-col-xl-16,
    .ant-col-xl-17,
    .ant-col-xl-18,
    .ant-col-xl-19,
    .ant-col-xl-20,
    .ant-col-xl-21,
    .ant-col-xl-22,
    .ant-col-xl-23,
    .ant-col-xl-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-xl-push-24 {
      right: 100%;
    }
    .ant-col-xl-pull-24 {
      left: 100%;
    }
    .ant-col-xl-offset-24 {
      margin-right: 100%;
    }
    .ant-col-xl-push-23 {
      right: 95.83333333%;
    }
    .ant-col-xl-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-xl-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-xl-push-22 {
      right: 91.66666667%;
    }
    .ant-col-xl-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-xl-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-xl-push-21 {
      right: 87.5%;
    }
    .ant-col-xl-pull-21 {
      left: 87.5%;
    }
    .ant-col-xl-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-xl-push-20 {
      right: 83.33333333%;
    }
    .ant-col-xl-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-xl-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-xl-push-19 {
      right: 79.16666667%;
    }
    .ant-col-xl-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-xl-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-xl-push-18 {
      right: 75%;
    }
    .ant-col-xl-pull-18 {
      left: 75%;
    }
    .ant-col-xl-offset-18 {
      margin-right: 75%;
    }
    .ant-col-xl-push-17 {
      right: 70.83333333%;
    }
    .ant-col-xl-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-xl-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-xl-push-16 {
      right: 66.66666667%;
    }
    .ant-col-xl-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-xl-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-xl-push-15 {
      right: 62.5%;
    }
    .ant-col-xl-pull-15 {
      left: 62.5%;
    }
    .ant-col-xl-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-xl-push-14 {
      right: 58.33333333%;
    }
    .ant-col-xl-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-xl-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-xl-push-13 {
      right: 54.16666667%;
    }
    .ant-col-xl-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-xl-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-xl-push-12 {
      right: 50%;
    }
    .ant-col-xl-pull-12 {
      left: 50%;
    }
    .ant-col-xl-offset-12 {
      margin-right: 50%;
    }
    .ant-col-xl-push-11 {
      right: 45.83333333%;
    }
    .ant-col-xl-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-xl-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-xl-push-10 {
      right: 41.66666667%;
    }
    .ant-col-xl-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-xl-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-xl-push-9 {
      right: 37.5%;
    }
    .ant-col-xl-pull-9 {
      left: 37.5%;
    }
    .ant-col-xl-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-xl-push-8 {
      right: 33.33333333%;
    }
    .ant-col-xl-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-xl-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-xl-push-7 {
      right: 29.16666667%;
    }
    .ant-col-xl-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-xl-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-xl-push-6 {
      right: 25%;
    }
    .ant-col-xl-pull-6 {
      left: 25%;
    }
    .ant-col-xl-offset-6 {
      margin-right: 25%;
    }
    .ant-col-xl-push-5 {
      right: 20.83333333%;
    }
    .ant-col-xl-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-xl-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-xl-push-4 {
      right: 16.66666667%;
    }
    .ant-col-xl-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-xl-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-xl-push-3 {
      right: 12.5%;
    }
    .ant-col-xl-pull-3 {
      left: 12.5%;
    }
    .ant-col-xl-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-xl-push-2 {
      right: 8.33333333%;
    }
    .ant-col-xl-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-xl-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-xl-push-1 {
      right: 4.16666667%;
    }
    .ant-col-xl-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-xl-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-xl-push-0 {
      right: auto;
    }
    .ant-col-xl-pull-0 {
      left: auto;
    }
    .ant-col-xl-offset-0 {
      margin-right: 0;
    }
    .ant-col-xl-order-0 {
      order: 0;
    }
  }
  @media (min-width: 1600px) {
    .ant-col-xxl-1,
    .ant-col-xxl-2,
    .ant-col-xxl-3,
    .ant-col-xxl-4,
    .ant-col-xxl-5,
    .ant-col-xxl-6,
    .ant-col-xxl-7,
    .ant-col-xxl-8,
    .ant-col-xxl-9,
    .ant-col-xxl-10,
    .ant-col-xxl-11,
    .ant-col-xxl-12,
    .ant-col-xxl-13,
    .ant-col-xxl-14,
    .ant-col-xxl-15,
    .ant-col-xxl-16,
    .ant-col-xxl-17,
    .ant-col-xxl-18,
    .ant-col-xxl-19,
    .ant-col-xxl-20,
    .ant-col-xxl-21,
    .ant-col-xxl-22,
    .ant-col-xxl-23,
    .ant-col-xxl-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-xxl-push-24 {
      right: 100%;
    }
    .ant-col-xxl-pull-24 {
      left: 100%;
    }
    .ant-col-xxl-offset-24 {
      margin-right: 100%;
    }
    .ant-col-xxl-push-23 {
      right: 95.83333333%;
    }
    .ant-col-xxl-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-xxl-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-xxl-push-22 {
      right: 91.66666667%;
    }
    .ant-col-xxl-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-xxl-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-xxl-push-21 {
      right: 87.5%;
    }
    .ant-col-xxl-pull-21 {
      left: 87.5%;
    }
    .ant-col-xxl-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-xxl-push-20 {
      right: 83.33333333%;
    }
    .ant-col-xxl-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-xxl-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-xxl-push-19 {
      right: 79.16666667%;
    }
    .ant-col-xxl-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-xxl-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-xxl-push-18 {
      right: 75%;
    }
    .ant-col-xxl-pull-18 {
      left: 75%;
    }
    .ant-col-xxl-offset-18 {
      margin-right: 75%;
    }
    .ant-col-xxl-push-17 {
      right: 70.83333333%;
    }
    .ant-col-xxl-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-xxl-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-xxl-push-16 {
      right: 66.66666667%;
    }
    .ant-col-xxl-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-xxl-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-xxl-push-15 {
      right: 62.5%;
    }
    .ant-col-xxl-pull-15 {
      left: 62.5%;
    }
    .ant-col-xxl-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-xxl-push-14 {
      right: 58.33333333%;
    }
    .ant-col-xxl-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-xxl-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-xxl-push-13 {
      right: 54.16666667%;
    }
    .ant-col-xxl-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-xxl-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-xxl-push-12 {
      right: 50%;
    }
    .ant-col-xxl-pull-12 {
      left: 50%;
    }
    .ant-col-xxl-offset-12 {
      margin-right: 50%;
    }
    .ant-col-xxl-push-11 {
      right: 45.83333333%;
    }
    .ant-col-xxl-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-xxl-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-xxl-push-10 {
      right: 41.66666667%;
    }
    .ant-col-xxl-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-xxl-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-xxl-push-9 {
      right: 37.5%;
    }
    .ant-col-xxl-pull-9 {
      left: 37.5%;
    }
    .ant-col-xxl-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-xxl-push-8 {
      right: 33.33333333%;
    }
    .ant-col-xxl-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-xxl-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-xxl-push-7 {
      right: 29.16666667%;
    }
    .ant-col-xxl-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-xxl-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-xxl-push-6 {
      right: 25%;
    }
    .ant-col-xxl-pull-6 {
      left: 25%;
    }
    .ant-col-xxl-offset-6 {
      margin-right: 25%;
    }
    .ant-col-xxl-order-6 {
      order: 6;
    }
    .ant-col-xxl-push-5 {
      right: 20.83333333%;
    }
    .ant-col-xxl-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-xxl-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-xxl-4 {
      display: block;
      box-sizing: border-box;
      width: 16.66666667%;
    }
    .ant-col-xxl-push-4 {
      right: 16.66666667%;
    }
    .ant-col-xxl-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-xxl-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-xxl-push-3 {
      right: 12.5%;
    }
    .ant-col-xxl-pull-3 {
      left: 12.5%;
    }
    .ant-col-xxl-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-xxl-push-2 {
      right: 8.33333333%;
    }
    .ant-col-xxl-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-xxl-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-xxl-push-1 {
      right: 4.16666667%;
    }
    .ant-col-xxl-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-xxl-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-xxl-push-0 {
      right: auto;
    }
    .ant-col-xxl-pull-0 {
      left: auto;
    }
    .ant-col-xxl-offset-0 {
      margin-right: 0;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    right: 16px;
  }
  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-right: 12px;
  }
  .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topleft,
  .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topright,
  .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topleft,
  .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topright {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomleft,
  .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomright,
  .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomleft,
  .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topleft,
  .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topright {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomleft,
  .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    left: 12px;
    top: 50%;
    margin-top: -7px;
    line-height: 14px;
    font-size: 12px;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-user-select: none;
            user-select: none;
  }
  .ant-calendar-picker-small .ant-calendar-picker-clear,
  .ant-calendar-picker-small .ant-calendar-picker-icon {
    left: 8px;
  }
  .ant-calendar {
    position: relative;
    outline: none;
    width: 280px;
    border: 1px solid #fff;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    line-height: 1.5;
  }
  .ant-calendar-header .ant-calendar-prev-century-btn,
  .ant-calendar-header .ant-calendar-prev-decade-btn,
  .ant-calendar-header .ant-calendar-prev-year-btn {
    right: 7px;
  }
  .ant-calendar-header .ant-calendar-next-century-btn,
  .ant-calendar-header .ant-calendar-next-decade-btn,
  .ant-calendar-header .ant-calendar-next-year-btn {
    left: 7px;
  }
  .ant-calendar-header .ant-calendar-prev-month-btn {
    right: 29px;
  }
  .ant-calendar-header .ant-calendar-prev-month-btn:after {
    content: '\2039';
  }
  .ant-calendar-header .ant-calendar-next-month-btn {
    left: 29px;
  }
  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
    position: relative;
    margin-left: 5px;
    padding-right: 5px;
  }
  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
    content: " ";
    position: absolute;
    top: -1px;
    right: 5px;
    width: 24px;
    height: 24px;
    border: 1px solid #bcbcbc;
    border-radius: 2px;
  }
  .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-calendar .ant-calendar-clear-btn {
    display: none;
    position: absolute;
    left: 5px;
    text-indent: -76px;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 7px;
    margin: 0;
  }
  .ant-calendar .ant-calendar-ok-btn > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn:hover > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn:active > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar-range-left {
    float: right;
  }
  .ant-calendar-range-left .ant-calendar-time-picker-inner {
    border-left: 1.5px solid #e8e8e8;
  }
  .ant-calendar-range-right {
    float: left;
  }
  .ant-calendar-range-right .ant-calendar-time-picker-inner {
    border-right: 1.5px solid #e8e8e8;
  }
  .ant-calendar-range-middle {
    position: absolute;
    right: 50%;
    width: 20px;
    margin-right: -132px;
    text-align: center;
    height: 34px;
    line-height: 34px;
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-right: -118px;
  }
  .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
    margin-right: -12px;
  }
  .ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-right: 0;
  }
  .ant-calendar-range .ant-calendar-input,
  .ant-calendar-range .ant-calendar-time-picker-input {
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all .3s;
    transition: all .3s;
    height: 24px;
    border: 0;
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-calendar-range .ant-calendar-in-range-cell:before {
    content: '';
    display: block;
    background: #e6f7ff;
    border-radius: 0;
    border: 0;
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 0;
    left: 0;
  }
  div.ant-calendar-range-quick-selector {
    text-align: right;
  }
  div.ant-calendar-range-quick-selector > a {
    margin-left: 8px;
  }
  .ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    margin-left: 8px;
  }
  .ant-calendar-time-picker-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    background-clip: padding-box;
    line-height: 1.5;
    overflow: hidden;
    width: 100%;
  }
  .ant-calendar-time-picker-select {
    float: right;
    font-size: 14px;
    border-left: 1px solid #e8e8e8;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    height: 226px;
  }
  .ant-calendar-time-picker-select:first-child {
    border-right: 0;
    margin-right: 0;
  }
  .ant-calendar-time-picker-select:last-child {
    border-left: 0;
  }
  .ant-calendar-time .ant-calendar-footer-btn {
    text-align: left;
  }
  .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
    float: right;
    margin: 0;
  }
  .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    display: inline-block;
    margin-left: 8px;
  }
  .ant-calendar-month-panel {
    position: absolute;
    top: 1px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    border-radius: 4px;
    background: #fff;
    outline: none;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    right: 7px;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
    content: '\AB';
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    left: 7px;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
    right: 29px;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after {
    content: '\2039';
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
    left: 29px;
  }
  .ant-calendar-year-panel {
    position: absolute;
    top: 1px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    border-radius: 4px;
    background: #fff;
    outline: none;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    right: 7px;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
    left: 7px;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
    right: 29px;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after {
    content: '\2039';
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
    left: 29px;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after {
    content: '\203A';
  }
  .ant-calendar-decade-panel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    border-radius: 4px;
    outline: none;
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    right: 7px;
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
    content: '\AB';
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
    left: 7px;
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
    right: 29px;
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
    left: 29px;
  }
  .ant-time-picker-panel-inner {
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    overflow: hidden;
    right: -2px;
  }
  .ant-time-picker-panel-clear-btn {
    position: absolute;
    left: 8px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 7px;
    margin: 0;
  }
  .ant-time-picker-panel-select {
    float: right;
    font-size: 14px;
    border-right: 1px solid #e8e8e8;
    box-sizing: border-box;
    width: 56px;
    overflow: hidden;
    position: relative;
    max-height: 192px;
  }
  .ant-time-picker-panel-select:first-child {
    border-right: 0;
    margin-right: 0;
  }
  .ant-time-picker-panel-select:last-child {
    border-left: 0;
  }
  .ant-time-picker-panel-select li {
    list-style: none;
    box-sizing: content-box;
    margin: 0;
    padding: 0 12px 0 0;
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: right;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topleft,
  .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topright,
  .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topleft,
  .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topright {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomleft,
  .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomright,
  .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomleft,
  .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topleft,
  .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topright {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomleft,
  .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-time-picker-icon {
    position: absolute;
    -webkit-user-select: none;
            user-select: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 14px;
    height: 14px;
    line-height: 14px;
    left: 11px;
    right: auto;
    color: rgba(0, 0, 0, 0.25);
    top: 50%;
    margin-top: -7px;
  }
  .ant-time-picker-small .ant-time-picker-icon {
    left: 7px;
  }
  .ant-divider-horizontal.ant-divider-with-text-left {
    display: table;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin: 16px 0;
  }
  .ant-divider-horizontal.ant-divider-with-text-left:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 5%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .ant-divider-horizontal.ant-divider-with-text-left:after {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 95%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .ant-divider-horizontal.ant-divider-with-text-left-inner-text {
    display: inline-block;
    padding: 0 10px;
  }
  .ant-divider-horizontal.ant-divider-with-text-right {
    display: table;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin: 16px 0;
  }
  .ant-divider-horizontal.ant-divider-with-text-right:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 95%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .ant-divider-horizontal.ant-divider-with-text-right:after {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 5%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .ant-divider-horizontal.ant-divider-with-text-right-inner-text {
    display: inline-block;
    padding: 0 10px;
  }
  .ant-dropdown-menu {
    text-align: right;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    position: absolute;
    left: 8px;
  }
  .ant-dropdown-menu-submenu-title {
    padding-left: 26px;
  }
  .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    top: 0;
    right: 100%;
    position: absolute;
    min-width: 100%;
    margin-right: 4px;
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
  }
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomleft,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomleft,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomright,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topleft,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topleft,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topright,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topright {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomleft,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topleft,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topright {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-form-item-required:before {
    display: inline-block;
    margin-left: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f5222d;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    right: 384px;
  }
  100% {
    right: 0;
    opacity: 1;
  }
  .ant-col-pull-0 {
    left: auto;
  }
  .ant-col-xs-push-0 {
    right: auto;
  }
  .ant-col-xs-pull-0 {
    left: auto;
  }
  .ant-col-xs-offset-0 {
    margin-right: 0;
  }
  @media (min-width: 576px) {
    .ant-col-sm-1,
    .ant-col-sm-2,
    .ant-col-sm-3,
    .ant-col-sm-4,
    .ant-col-sm-5,
    .ant-col-sm-6,
    .ant-col-sm-7,
    .ant-col-sm-8,
    .ant-col-sm-9,
    .ant-col-sm-10,
    .ant-col-sm-11,
    .ant-col-sm-12,
    .ant-col-sm-13,
    .ant-col-sm-14,
    .ant-col-sm-15,
    .ant-col-sm-16,
    .ant-col-sm-17,
    .ant-col-sm-18,
    .ant-col-sm-19,
    .ant-col-sm-20,
    .ant-col-sm-21,
    .ant-col-sm-22,
    .ant-col-sm-23,
    .ant-col-sm-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-sm-push-24 {
      right: 100%;
    }
    .ant-col-sm-pull-24 {
      left: 100%;
    }
    .ant-col-sm-offset-24 {
      margin-right: 100%;
    }
    .ant-col-sm-push-23 {
      right: 95.83333333%;
    }
    .ant-col-sm-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-sm-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-sm-push-22 {
      right: 91.66666667%;
    }
    .ant-col-sm-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-sm-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-sm-push-21 {
      right: 87.5%;
    }
    .ant-col-sm-pull-21 {
      left: 87.5%;
    }
    .ant-col-sm-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-sm-push-20 {
      right: 83.33333333%;
    }
    .ant-col-sm-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-sm-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-sm-push-19 {
      right: 79.16666667%;
    }
    .ant-col-sm-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-sm-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-sm-push-18 {
      right: 75%;
    }
    .ant-col-sm-pull-18 {
      left: 75%;
    }
    .ant-col-sm-offset-18 {
      margin-right: 75%;
    }
    .ant-col-sm-push-17 {
      right: 70.83333333%;
    }
    .ant-col-sm-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-sm-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-sm-push-16 {
      right: 66.66666667%;
    }
    .ant-col-sm-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-sm-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-sm-push-15 {
      right: 62.5%;
    }
    .ant-col-sm-pull-15 {
      left: 62.5%;
    }
    .ant-col-sm-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-sm-push-14 {
      right: 58.33333333%;
    }
    .ant-col-sm-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-sm-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-sm-push-13 {
      right: 54.16666667%;
    }
    .ant-col-sm-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-sm-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-sm-push-12 {
      right: 50%;
    }
    .ant-col-sm-pull-12 {
      left: 50%;
    }
    .ant-col-sm-offset-12 {
      margin-right: 50%;
    }
    .ant-col-sm-push-11 {
      right: 45.83333333%;
    }
    .ant-col-sm-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-sm-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-sm-push-10 {
      right: 41.66666667%;
    }
    .ant-col-sm-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-sm-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-sm-push-9 {
      right: 37.5%;
    }
    .ant-col-sm-pull-9 {
      left: 37.5%;
    }
    .ant-col-sm-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-sm-push-8 {
      right: 33.33333333%;
    }
    .ant-col-sm-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-sm-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-sm-push-7 {
      right: 29.16666667%;
    }
    .ant-col-sm-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-sm-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-sm-push-6 {
      right: 25%;
    }
    .ant-col-sm-pull-6 {
      left: 25%;
    }
    .ant-col-sm-offset-6 {
      margin-right: 25%;
    }
    .ant-col-sm-push-5 {
      right: 20.83333333%;
    }
    .ant-col-sm-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-sm-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-sm-push-4 {
      right: 16.66666667%;
    }
    .ant-col-sm-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-sm-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-sm-push-3 {
      right: 12.5%;
    }
    .ant-col-sm-pull-3 {
      left: 12.5%;
    }
    .ant-col-sm-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-sm-push-2 {
      right: 8.33333333%;
    }
    .ant-col-sm-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-sm-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-sm-push-1 {
      right: 4.16666667%;
    }
    .ant-col-sm-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-sm-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-sm-push-0 {
      right: auto;
    }
    .ant-col-sm-pull-0 {
      left: auto;
    }
    .ant-col-sm-offset-0 {
      margin-right: 0;
    }
  }
  @media (min-width: 768px) {
    .ant-col-md-1,
    .ant-col-md-2,
    .ant-col-md-3,
    .ant-col-md-4,
    .ant-col-md-5,
    .ant-col-md-6,
    .ant-col-md-7,
    .ant-col-md-8,
    .ant-col-md-9,
    .ant-col-md-10,
    .ant-col-md-11,
    .ant-col-md-12,
    .ant-col-md-13,
    .ant-col-md-14,
    .ant-col-md-15,
    .ant-col-md-16,
    .ant-col-md-17,
    .ant-col-md-18,
    .ant-col-md-19,
    .ant-col-md-20,
    .ant-col-md-21,
    .ant-col-md-22,
    .ant-col-md-23,
    .ant-col-md-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-md-push-24 {
      right: 100%;
    }
    .ant-col-md-pull-24 {
      left: 100%;
    }
    .ant-col-md-offset-24 {
      margin-right: 100%;
    }
    .ant-col-md-push-23 {
      right: 95.83333333%;
    }
    .ant-col-md-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-md-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-md-push-22 {
      right: 91.66666667%;
    }
    .ant-col-md-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-md-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-md-push-21 {
      right: 87.5%;
    }
    .ant-col-md-pull-21 {
      left: 87.5%;
    }
    .ant-col-md-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-md-push-20 {
      right: 83.33333333%;
    }
    .ant-col-md-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-md-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-md-push-19 {
      right: 79.16666667%;
    }
    .ant-col-md-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-md-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-md-push-18 {
      right: 75%;
    }
    .ant-col-md-pull-18 {
      left: 75%;
    }
    .ant-col-md-offset-18 {
      margin-right: 75%;
    }
    .ant-col-md-push-17 {
      right: 70.83333333%;
    }
    .ant-col-md-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-md-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-md-push-16 {
      right: 66.66666667%;
    }
    .ant-col-md-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-md-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-md-push-15 {
      right: 62.5%;
    }
    .ant-col-md-pull-15 {
      left: 62.5%;
    }
    .ant-col-md-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-md-push-14 {
      right: 58.33333333%;
    }
    .ant-col-md-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-md-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-md-push-13 {
      right: 54.16666667%;
    }
    .ant-col-md-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-md-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-md-push-12 {
      right: 50%;
    }
    .ant-col-md-pull-12 {
      left: 50%;
    }
    .ant-col-md-offset-12 {
      margin-right: 50%;
    }
    .ant-col-md-push-11 {
      right: 45.83333333%;
    }
    .ant-col-md-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-md-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-md-push-10 {
      right: 41.66666667%;
    }
    .ant-col-md-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-md-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-md-push-9 {
      right: 37.5%;
    }
    .ant-col-md-pull-9 {
      left: 37.5%;
    }
    .ant-col-md-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-md-push-8 {
      right: 33.33333333%;
    }
    .ant-col-md-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-md-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-md-order-8 {
      order: 8;
    }
    .ant-col-md-push-7 {
      right: 29.16666667%;
    }
    .ant-col-md-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-md-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-md-push-6 {
      right: 25%;
    }
    .ant-col-md-pull-6 {
      left: 25%;
    }
    .ant-col-md-offset-6 {
      margin-right: 25%;
    }
    .ant-col-md-push-5 {
      right: 20.83333333%;
    }
    .ant-col-md-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-md-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-md-push-4 {
      right: 16.66666667%;
    }
    .ant-col-md-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-md-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-md-push-3 {
      right: 12.5%;
    }
    .ant-col-md-pull-3 {
      left: 12.5%;
    }
    .ant-col-md-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-md-push-2 {
      right: 8.33333333%;
    }
    .ant-col-md-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-md-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-md-push-1 {
      right: 4.16666667%;
    }
    .ant-col-md-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-md-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-md-push-0 {
      right: auto;
    }
    .ant-col-md-pull-0 {
      left: auto;
    }
    .ant-col-md-offset-0 {
      margin-right: 0;
    }
  }
  @media (min-width: 992px) {
    .ant-col-lg-1,
    .ant-col-lg-2,
    .ant-col-lg-3,
    .ant-col-lg-4,
    .ant-col-lg-5,
    .ant-col-lg-6,
    .ant-col-lg-7,
    .ant-col-lg-8,
    .ant-col-lg-9,
    .ant-col-lg-10,
    .ant-col-lg-11,
    .ant-col-lg-12,
    .ant-col-lg-13,
    .ant-col-lg-14,
    .ant-col-lg-15,
    .ant-col-lg-16,
    .ant-col-lg-17,
    .ant-col-lg-18,
    .ant-col-lg-19,
    .ant-col-lg-20,
    .ant-col-lg-21,
    .ant-col-lg-22,
    .ant-col-lg-23,
    .ant-col-lg-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-lg-push-24 {
      right: 100%;
    }
    .ant-col-lg-pull-24 {
      left: 100%;
    }
    .ant-col-lg-offset-24 {
      margin-right: 100%;
    }
    .ant-col-lg-push-23 {
      right: 95.83333333%;
    }
    .ant-col-lg-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-lg-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-lg-push-22 {
      right: 91.66666667%;
    }
    .ant-col-lg-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-lg-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-lg-push-21 {
      right: 87.5%;
    }
    .ant-col-lg-pull-21 {
      left: 87.5%;
    }
    .ant-col-lg-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-lg-push-20 {
      right: 83.33333333%;
    }
    .ant-col-lg-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-lg-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-lg-push-19 {
      right: 79.16666667%;
    }
    .ant-col-lg-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-lg-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-lg-push-18 {
      right: 75%;
    }
    .ant-col-lg-pull-18 {
      left: 75%;
    }
    .ant-col-lg-offset-18 {
      margin-right: 75%;
    }
    .ant-col-lg-push-17 {
      right: 70.83333333%;
    }
    .ant-col-lg-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-lg-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-lg-push-16 {
      right: 66.66666667%;
    }
    .ant-col-lg-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-lg-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-lg-push-15 {
      right: 62.5%;
    }
    .ant-col-lg-pull-15 {
      left: 62.5%;
    }
    .ant-col-lg-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-lg-push-14 {
      right: 58.33333333%;
    }
    .ant-col-lg-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-lg-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-lg-push-13 {
      right: 54.16666667%;
    }
    .ant-col-lg-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-lg-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-lg-push-12 {
      right: 50%;
    }
    .ant-col-lg-pull-12 {
      left: 50%;
    }
    .ant-col-lg-offset-12 {
      margin-right: 50%;
    }
    .ant-col-lg-push-11 {
      right: 45.83333333%;
    }
    .ant-col-lg-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-lg-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-lg-push-10 {
      right: 41.66666667%;
    }
    .ant-col-lg-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-lg-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-lg-push-9 {
      right: 37.5%;
    }
    .ant-col-lg-pull-9 {
      left: 37.5%;
    }
    .ant-col-lg-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-lg-push-8 {
      right: 33.33333333%;
    }
    .ant-col-lg-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-lg-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-lg-push-7 {
      right: 29.16666667%;
    }
    .ant-col-lg-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-lg-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-lg-push-6 {
      right: 25%;
    }
    .ant-col-lg-pull-6 {
      left: 25%;
    }
    .ant-col-lg-offset-6 {
      margin-right: 25%;
    }
    .ant-col-lg-push-5 {
      right: 20.83333333%;
    }
    .ant-col-lg-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-lg-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-lg-push-4 {
      right: 16.66666667%;
    }
    .ant-col-lg-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-lg-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-lg-push-3 {
      right: 12.5%;
    }
    .ant-col-lg-pull-3 {
      left: 12.5%;
    }
    .ant-col-lg-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-lg-push-2 {
      right: 8.33333333%;
    }
    .ant-col-lg-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-lg-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-lg-push-1 {
      right: 4.16666667%;
    }
    .ant-col-lg-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-lg-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-lg-push-0 {
      right: auto;
    }
    .ant-col-lg-pull-0 {
      left: auto;
    }
    .ant-col-lg-offset-0 {
      margin-right: 0;
    }
  }
  @media (min-width: 1200px) {
    .ant-col-xl-1,
    .ant-col-xl-2,
    .ant-col-xl-3,
    .ant-col-xl-4,
    .ant-col-xl-5,
    .ant-col-xl-6,
    .ant-col-xl-7,
    .ant-col-xl-8,
    .ant-col-xl-9,
    .ant-col-xl-10,
    .ant-col-xl-11,
    .ant-col-xl-12,
    .ant-col-xl-13,
    .ant-col-xl-14,
    .ant-col-xl-15,
    .ant-col-xl-16,
    .ant-col-xl-17,
    .ant-col-xl-18,
    .ant-col-xl-19,
    .ant-col-xl-20,
    .ant-col-xl-21,
    .ant-col-xl-22,
    .ant-col-xl-23,
    .ant-col-xl-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-xl-push-24 {
      right: 100%;
    }
    .ant-col-xl-pull-24 {
      left: 100%;
    }
    .ant-col-xl-offset-24 {
      margin-right: 100%;
    }
    .ant-col-xl-push-23 {
      right: 95.83333333%;
    }
    .ant-col-xl-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-xl-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-xl-push-22 {
      right: 91.66666667%;
    }
    .ant-col-xl-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-xl-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-xl-push-21 {
      right: 87.5%;
    }
    .ant-col-xl-pull-21 {
      left: 87.5%;
    }
    .ant-col-xl-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-xl-push-20 {
      right: 83.33333333%;
    }
    .ant-col-xl-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-xl-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-xl-push-19 {
      right: 79.16666667%;
    }
    .ant-col-xl-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-xl-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-xl-push-18 {
      right: 75%;
    }
    .ant-col-xl-pull-18 {
      left: 75%;
    }
    .ant-col-xl-offset-18 {
      margin-right: 75%;
    }
    .ant-col-xl-push-17 {
      right: 70.83333333%;
    }
    .ant-col-xl-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-xl-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-xl-push-16 {
      right: 66.66666667%;
    }
    .ant-col-xl-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-xl-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-xl-push-15 {
      right: 62.5%;
    }
    .ant-col-xl-pull-15 {
      left: 62.5%;
    }
    .ant-col-xl-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-xl-push-14 {
      right: 58.33333333%;
    }
    .ant-col-xl-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-xl-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-xl-push-13 {
      right: 54.16666667%;
    }
    .ant-col-xl-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-xl-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-xl-push-12 {
      right: 50%;
    }
    .ant-col-xl-pull-12 {
      left: 50%;
    }
    .ant-col-xl-offset-12 {
      margin-right: 50%;
    }
    .ant-col-xl-push-11 {
      right: 45.83333333%;
    }
    .ant-col-xl-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-xl-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-xl-push-10 {
      right: 41.66666667%;
    }
    .ant-col-xl-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-xl-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-xl-push-9 {
      right: 37.5%;
    }
    .ant-col-xl-pull-9 {
      left: 37.5%;
    }
    .ant-col-xl-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-xl-push-8 {
      right: 33.33333333%;
    }
    .ant-col-xl-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-xl-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-xl-push-7 {
      right: 29.16666667%;
    }
    .ant-col-xl-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-xl-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-xl-push-6 {
      right: 25%;
    }
    .ant-col-xl-pull-6 {
      left: 25%;
    }
    .ant-col-xl-offset-6 {
      margin-right: 25%;
    }
    .ant-col-xl-push-5 {
      right: 20.83333333%;
    }
    .ant-col-xl-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-xl-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-xl-push-4 {
      right: 16.66666667%;
    }
    .ant-col-xl-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-xl-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-xl-push-3 {
      right: 12.5%;
    }
    .ant-col-xl-pull-3 {
      left: 12.5%;
    }
    .ant-col-xl-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-xl-push-2 {
      right: 8.33333333%;
    }
    .ant-col-xl-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-xl-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-xl-push-1 {
      right: 4.16666667%;
    }
    .ant-col-xl-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-xl-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-xl-push-0 {
      right: auto;
    }
    .ant-col-xl-pull-0 {
      left: auto;
    }
    .ant-col-xl-offset-0 {
      margin-right: 0;
    }
    .ant-col-xl-order-0 {
      order: 0;
    }
  }
  @media (min-width: 1600px) {
    .ant-col-xxl-1,
    .ant-col-xxl-2,
    .ant-col-xxl-3,
    .ant-col-xxl-4,
    .ant-col-xxl-5,
    .ant-col-xxl-6,
    .ant-col-xxl-7,
    .ant-col-xxl-8,
    .ant-col-xxl-9,
    .ant-col-xxl-10,
    .ant-col-xxl-11,
    .ant-col-xxl-12,
    .ant-col-xxl-13,
    .ant-col-xxl-14,
    .ant-col-xxl-15,
    .ant-col-xxl-16,
    .ant-col-xxl-17,
    .ant-col-xxl-18,
    .ant-col-xxl-19,
    .ant-col-xxl-20,
    .ant-col-xxl-21,
    .ant-col-xxl-22,
    .ant-col-xxl-23,
    .ant-col-xxl-24 {
      float: right;
      flex: 0 0 auto;
    }
    .ant-col-xxl-push-24 {
      right: 100%;
    }
    .ant-col-xxl-pull-24 {
      left: 100%;
    }
    .ant-col-xxl-offset-24 {
      margin-right: 100%;
    }
    .ant-col-xxl-push-23 {
      right: 95.83333333%;
    }
    .ant-col-xxl-pull-23 {
      left: 95.83333333%;
    }
    .ant-col-xxl-offset-23 {
      margin-right: 95.83333333%;
    }
    .ant-col-xxl-push-22 {
      right: 91.66666667%;
    }
    .ant-col-xxl-pull-22 {
      left: 91.66666667%;
    }
    .ant-col-xxl-offset-22 {
      margin-right: 91.66666667%;
    }
    .ant-col-xxl-push-21 {
      right: 87.5%;
    }
    .ant-col-xxl-pull-21 {
      left: 87.5%;
    }
    .ant-col-xxl-offset-21 {
      margin-right: 87.5%;
    }
    .ant-col-xxl-push-20 {
      right: 83.33333333%;
    }
    .ant-col-xxl-pull-20 {
      left: 83.33333333%;
    }
    .ant-col-xxl-offset-20 {
      margin-right: 83.33333333%;
    }
    .ant-col-xxl-push-19 {
      right: 79.16666667%;
    }
    .ant-col-xxl-pull-19 {
      left: 79.16666667%;
    }
    .ant-col-xxl-offset-19 {
      margin-right: 79.16666667%;
    }
    .ant-col-xxl-push-18 {
      right: 75%;
    }
    .ant-col-xxl-pull-18 {
      left: 75%;
    }
    .ant-col-xxl-offset-18 {
      margin-right: 75%;
    }
    .ant-col-xxl-push-17 {
      right: 70.83333333%;
    }
    .ant-col-xxl-pull-17 {
      left: 70.83333333%;
    }
    .ant-col-xxl-offset-17 {
      margin-right: 70.83333333%;
    }
    .ant-col-xxl-push-16 {
      right: 66.66666667%;
    }
    .ant-col-xxl-pull-16 {
      left: 66.66666667%;
    }
    .ant-col-xxl-offset-16 {
      margin-right: 66.66666667%;
    }
    .ant-col-xxl-push-15 {
      right: 62.5%;
    }
    .ant-col-xxl-pull-15 {
      left: 62.5%;
    }
    .ant-col-xxl-offset-15 {
      margin-right: 62.5%;
    }
    .ant-col-xxl-push-14 {
      right: 58.33333333%;
    }
    .ant-col-xxl-pull-14 {
      left: 58.33333333%;
    }
    .ant-col-xxl-offset-14 {
      margin-right: 58.33333333%;
    }
    .ant-col-xxl-push-13 {
      right: 54.16666667%;
    }
    .ant-col-xxl-pull-13 {
      left: 54.16666667%;
    }
    .ant-col-xxl-offset-13 {
      margin-right: 54.16666667%;
    }
    .ant-col-xxl-push-12 {
      right: 50%;
    }
    .ant-col-xxl-pull-12 {
      left: 50%;
    }
    .ant-col-xxl-offset-12 {
      margin-right: 50%;
    }
    .ant-col-xxl-push-11 {
      right: 45.83333333%;
    }
    .ant-col-xxl-pull-11 {
      left: 45.83333333%;
    }
    .ant-col-xxl-offset-11 {
      margin-right: 45.83333333%;
    }
    .ant-col-xxl-push-10 {
      right: 41.66666667%;
    }
    .ant-col-xxl-pull-10 {
      left: 41.66666667%;
    }
    .ant-col-xxl-offset-10 {
      margin-right: 41.66666667%;
    }
    .ant-col-xxl-push-9 {
      right: 37.5%;
    }
    .ant-col-xxl-pull-9 {
      left: 37.5%;
    }
    .ant-col-xxl-offset-9 {
      margin-right: 37.5%;
    }
    .ant-col-xxl-push-8 {
      right: 33.33333333%;
    }
    .ant-col-xxl-pull-8 {
      left: 33.33333333%;
    }
    .ant-col-xxl-offset-8 {
      margin-right: 33.33333333%;
    }
    .ant-col-xxl-push-7 {
      right: 29.16666667%;
    }
    .ant-col-xxl-pull-7 {
      left: 29.16666667%;
    }
    .ant-col-xxl-offset-7 {
      margin-right: 29.16666667%;
    }
    .ant-col-xxl-push-6 {
      right: 25%;
    }
    .ant-col-xxl-pull-6 {
      left: 25%;
    }
    .ant-col-xxl-offset-6 {
      margin-right: 25%;
    }
    .ant-col-xxl-order-6 {
      order: 6;
    }
    .ant-col-xxl-push-5 {
      right: 20.83333333%;
    }
    .ant-col-xxl-pull-5 {
      left: 20.83333333%;
    }
    .ant-col-xxl-offset-5 {
      margin-right: 20.83333333%;
    }
    .ant-col-xxl-4 {
      display: block;
      box-sizing: border-box;
      width: 16.66666667%;
    }
    .ant-col-xxl-push-4 {
      right: 16.66666667%;
    }
    .ant-col-xxl-pull-4 {
      left: 16.66666667%;
    }
    .ant-col-xxl-offset-4 {
      margin-right: 16.66666667%;
    }
    .ant-col-xxl-push-3 {
      right: 12.5%;
    }
    .ant-col-xxl-pull-3 {
      left: 12.5%;
    }
    .ant-col-xxl-offset-3 {
      margin-right: 12.5%;
    }
    .ant-col-xxl-push-2 {
      right: 8.33333333%;
    }
    .ant-col-xxl-pull-2 {
      left: 8.33333333%;
    }
    .ant-col-xxl-offset-2 {
      margin-right: 8.33333333%;
    }
    .ant-col-xxl-push-1 {
      right: 4.16666667%;
    }
    .ant-col-xxl-pull-1 {
      left: 4.16666667%;
    }
    .ant-col-xxl-offset-1 {
      margin-right: 4.16666667%;
    }
    .ant-col-push-0 {
      right: auto;
    }
    .ant-col-pull-0 {
      left: auto;
    }
    .ant-col-xxl-push-0 {
      right: auto;
    }
    .ant-col-xxl-pull-0 {
      left: auto;
    }
    .ant-col-xxl-offset-0 {
      margin-right: 0;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    right: 16px;
  }
  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-right: 12px;
  }
  .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topleft,
  .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topright,
  .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topleft,
  .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topright {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomleft,
  .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomright,
  .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomleft,
  .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topleft,
  .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topright {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomleft,
  .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    left: 12px;
    top: 50%;
    margin-top: -7px;
    line-height: 14px;
    font-size: 12px;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ant-calendar-picker-small .ant-calendar-picker-clear,
  .ant-calendar-picker-small .ant-calendar-picker-icon {
    left: 8px;
  }
  .ant-calendar {
    position: relative;
    outline: none;
    width: 280px;
    border: 1px solid #fff;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    line-height: 1.5;
  }
  .ant-calendar-header .ant-calendar-prev-century-btn,
  .ant-calendar-header .ant-calendar-prev-decade-btn,
  .ant-calendar-header .ant-calendar-prev-year-btn {
    right: 7px;
  }
  .ant-calendar-header .ant-calendar-next-century-btn,
  .ant-calendar-header .ant-calendar-next-decade-btn,
  .ant-calendar-header .ant-calendar-next-year-btn {
    left: 7px;
  }
  .ant-calendar-header .ant-calendar-prev-month-btn {
    right: 29px;
  }
  .ant-calendar-header .ant-calendar-prev-month-btn:after {
    content: '\2039';
  }
  .ant-calendar-header .ant-calendar-next-month-btn {
    left: 29px;
  }
  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
    position: relative;
    margin-left: 5px;
    padding-right: 5px;
  }
  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
    content: " ";
    position: absolute;
    top: -1px;
    right: 5px;
    width: 24px;
    height: 24px;
    border: 1px solid #bcbcbc;
    border-radius: 2px;
  }
  .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-calendar .ant-calendar-clear-btn {
    display: none;
    position: absolute;
    left: 5px;
    text-indent: -76px;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 7px;
    margin: 0;
  }
  .ant-calendar .ant-calendar-ok-btn > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn:hover > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn:active > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child:after,
  .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  .ant-calendar-range-left {
    float: right;
  }
  .ant-calendar-range-left .ant-calendar-time-picker-inner {
    border-left: 1.5px solid #e8e8e8;
  }
  .ant-calendar-range-right {
    float: left;
  }
  .ant-calendar-range-right .ant-calendar-time-picker-inner {
    border-right: 1.5px solid #e8e8e8;
  }
  .ant-calendar-range-middle {
    position: absolute;
    right: 50%;
    width: 20px;
    margin-right: -132px;
    text-align: center;
    height: 34px;
    line-height: 34px;
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-right: -118px;
  }
  .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
    margin-right: -12px;
  }
  .ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-right: 0;
  }
  .ant-calendar-range .ant-calendar-input,
  .ant-calendar-range .ant-calendar-time-picker-input {
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all .3s;
    transition: all .3s;
    height: 24px;
    border: 0;
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-calendar-range .ant-calendar-in-range-cell:before {
    content: '';
    display: block;
    background: #e6f7ff;
    border-radius: 0;
    border: 0;
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 0;
    left: 0;
  }
  div.ant-calendar-range-quick-selector {
    text-align: right;
  }
  div.ant-calendar-range-quick-selector > a {
    margin-left: 8px;
  }
  .ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    margin-left: 8px;
  }
  .ant-calendar-time-picker-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    background-clip: padding-box;
    line-height: 1.5;
    overflow: hidden;
    width: 100%;
  }
  .ant-calendar-time-picker-select {
    float: right;
    font-size: 14px;
    border-left: 1px solid #e8e8e8;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    height: 226px;
  }
  .ant-calendar-time-picker-select:first-child {
    border-right: 0;
    margin-right: 0;
  }
  .ant-calendar-time-picker-select:last-child {
    border-left: 0;
  }
  .ant-calendar-time .ant-calendar-footer-btn {
    text-align: left;
  }
  .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
    float: right;
    margin: 0;
  }
  .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    display: inline-block;
    margin-left: 8px;
  }
  .ant-calendar-month-panel {
    position: absolute;
    top: 1px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    border-radius: 4px;
    background: #fff;
    outline: none;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    right: 7px;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
    content: '\AB';
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    left: 7px;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
    right: 29px;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after {
    content: '\2039';
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
    left: 29px;
  }
  .ant-calendar-year-panel {
    position: absolute;
    top: 1px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    border-radius: 4px;
    background: #fff;
    outline: none;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    right: 7px;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
    left: 7px;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
    right: 29px;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after {
    content: '\2039';
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
    left: 29px;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after {
    content: '\203A';
  }
  .ant-calendar-decade-panel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    border-radius: 4px;
    outline: none;
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    right: 7px;
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
    content: '\AB';
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
    left: 7px;
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
    right: 29px;
  }
  .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
    left: 29px;
  }
  .ant-time-picker-panel-inner {
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    overflow: hidden;
    right: -2px;
  }
  .ant-time-picker-panel-clear-btn {
    position: absolute;
    left: 8px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 7px;
    margin: 0;
  }
  .ant-time-picker-panel-select {
    float: right;
    font-size: 14px;
    border-right: 1px solid #e8e8e8;
    box-sizing: border-box;
    width: 56px;
    overflow: hidden;
    position: relative;
    max-height: 192px;
  }
  .ant-time-picker-panel-select:first-child {
    border-right: 0;
    margin-right: 0;
  }
  .ant-time-picker-panel-select:last-child {
    border-left: 0;
  }
  .ant-time-picker-panel-select li {
    list-style: none;
    box-sizing: content-box;
    margin: 0;
    padding: 0 12px 0 0;
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: right;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topleft,
  .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topright,
  .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topleft,
  .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topright {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomleft,
  .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomright,
  .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomleft,
  .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topleft,
  .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topright {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomleft,
  .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-time-picker-icon {
    position: absolute;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 14px;
    height: 14px;
    line-height: 14px;
    left: 11px;
    right: auto;
    color: rgba(0, 0, 0, 0.25);
    top: 50%;
    margin-top: -7px;
  }
  .ant-time-picker-small .ant-time-picker-icon {
    left: 7px;
  }
  .ant-divider-horizontal.ant-divider-with-text-left {
    display: table;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin: 16px 0;
  }
  .ant-divider-horizontal.ant-divider-with-text-left:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 5%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .ant-divider-horizontal.ant-divider-with-text-left:after {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 95%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .ant-divider-horizontal.ant-divider-with-text-left-inner-text {
    display: inline-block;
    padding: 0 10px;
  }
  .ant-divider-horizontal.ant-divider-with-text-right {
    display: table;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin: 16px 0;
  }
  .ant-divider-horizontal.ant-divider-with-text-right:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 95%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .ant-divider-horizontal.ant-divider-with-text-right:after {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 5%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .ant-divider-horizontal.ant-divider-with-text-right-inner-text {
    display: inline-block;
    padding: 0 10px;
  }
  .ant-dropdown-menu {
    text-align: right;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    position: absolute;
    left: 8px;
  }
  .ant-dropdown-menu-submenu-title {
    padding-left: 26px;
  }
  .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    top: 0;
    right: 100%;
    position: absolute;
    min-width: 100%;
    margin-right: 4px;
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
  }
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomleft,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomleft,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomright,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topleft,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topleft,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topright,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topright {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomleft,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topleft,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topright {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-form-item-required:before {
    display: inline-block;
    margin-left: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f5222d;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationleftFadeIn {
  0% {
    opacity: 0;
    left: 384px;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes NotificationleftFadeIn {
  0% {
    opacity: 0;
    left: 384px;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

.rtl .ant-popover {
  right: 0;
  text-align: right;
}

.rtl .ant-popover-placement-top,
.rtl .ant-popover-placement-topleft,
.rtl .ant-popover-placement-topright {
  padding-bottom: 9px;
}

.rtl .ant-popover-placement-right,
.rtl .ant-popover-placement-rightTop,
.rtl .ant-popover-placement-rightBottom {
  padding-right: 9px;
}

.rtl .ant-popover-placement-bottom,
.rtl .ant-popover-placement-bottomleft,
.rtl .ant-popover-placement-bottomright {
  padding-top: 9px;
}

.rtl .ant-popover-placement-left,
.rtl .ant-popover-placement-leftTop,
.rtl .ant-popover-placement-leftBottom {
  padding-left: 9px;
}

.rtl .ant-popover-message-title {
  padding-right: 22px;
}

.rtl .ant-popover-buttons {
  text-align: left;
  margin-bottom: 4px;
}

.rtl .ant-popover-buttons button {
  margin-right: 8px;
}

.rtl .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-topleft > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-topright > .ant-popover-content > .ant-popover-arrow {
  bottom: 6px;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  right: 50%;
  -webkit-transform: translateX(50%) rotate(-45deg);
          transform: translateX(50%) rotate(-45deg);
}

.rtl .ant-popover-placement-topleft > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.rtl .ant-popover-placement-topright > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.rtl .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
}

.rtl .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.rtl .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.rtl .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-bottomleft > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-bottomright > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  box-shadow: 1px -1px 4px rgba(0, 0, 0, 0.06);
}

.rtl .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  right: 50%;
  -webkit-transform: translateX(50%) rotate(-45deg);
          transform: translateX(50%) rotate(-45deg);
}

.rtl .ant-popover-placement-bottomleft > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.rtl .ant-popover-placement-bottomright > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.rtl .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
}

.rtl .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.rtl .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.rtl .ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
}

.rtl .ant-progress-show-info .ant-progress-outer {
  padding-left: calc(2em + 8px);
  margin-left: calc(-2em - 8px);
}

.rtl .ant-progress-success-bg {
  background-color: #52c41a;
  position: absolute;
  top: 0;
  right: 0;
}

.rtl .ant-progress-text {
  word-break: normal;
  width: 2em;
  text-align: right;
  font-size: 1em;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  line-height: 1;
}

.rtl .ant-progress-status-active .ant-progress-bg:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
}

.rtl .ant-progress-circle .ant-progress-text {
  right: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
}

.rtl .ant-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-left: 8px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: inherit;
  cursor: pointer;
}

.rtl .ant-rate-star-first {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.rtl .ant-rate-text {
  margin-right: 8px;
  display: inline-block;
  font-size: 14px;
}

.rtl .ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-bottom: -7px;
}

.rtl .ant-slider-vertical .ant-slider-mark {
  top: 0;
  right: 12px;
  width: 18px;
  height: 100%;
}

.rtl .ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  white-space: nowrap;
}

.rtl .ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}

.rtl .ant-slider-vertical .ant-slider-dot {
  top: auto;
  right: 2px;
  margin-bottom: -4px;
}

.rtl .ant-slider-mark {
  position: absolute;
  top: 14px;
  right: 0;
  width: 100%;
  font-size: 14px;
}

.rtl .ant-slider-dot {
  position: absolute;
  top: -2px;
  margin-right: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e8e8e8;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rtl .ant-slider-dot:first-child {
  margin-right: -4px;
}

.rtl .ant-slider-dot:last-child {
  margin-right: -4px;
}

.rtl .ant-steps-item-icon {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  font-size: 16px;
  margin-left: 8px;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.rtl .ant-steps-item-tail {
  position: absolute;
  right: 0;
  width: 100%;
  top: 12px;
  padding: 0 10px;
}

.rtl .ant-steps-item-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  padding-left: 16px;
  position: relative;
  line-height: 32px;
}

.rtl .ant-steps-item-title:after {
  content: "";
  height: 1px;
  width: 9999px;
  background: #e8e8e8;
  display: block;
  position: absolute;
  top: 16px;
  right: 100%;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-left: 16px;
  white-space: nowrap;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-left: 0;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}

.rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 32px;
  top: 0;
  right: 0.5px;
  width: 32px;
  height: 32px;
}

.rtl .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-left: 12px;
}

.rtl .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-left: 0;
}

.rtl .ant-steps-small .ant-steps-item-title {
  font-size: 14px;
  line-height: 24px;
  padding-left: 12px;
}

.rtl .ant-steps-vertical .ant-steps-item-icon {
  float: right;
  margin-left: 16px;
}

.rtl .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  right: 16px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 38px 0 6px;
}

.rtl .ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
  position: absolute;
  right: 12px;
  top: 0;
  padding: 30px 0 6px;
}

@media (max-width: 480px) {
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: right;
    margin-left: 16px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    width: 1px;
    padding: 38px 0 6px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    right: 12px;
    top: 0;
    padding: 30px 0 6px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-title {
    line-height: 24px;
  }
}

.rtl .ant-steps-label-vertical .ant-steps-item-tail {
  padding: 0 24px;
  margin-right: 48px;
}

.rtl .ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-right: 36px;
}

.rtl .ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}

.rtl .ant-steps-label-vertical .ant-steps-item-description {
  text-align: right;
}

.rtl .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
}

.rtl .ant-steps-dot .ant-steps-item-icon {
  padding-left: 0;
  width: 8px;
  height: 8px;
  line-height: 8px;
  border: 0;
  margin-right: 67px;
  background: transparent;
}

.rtl .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
  width: 100%;
  height: 100%;
}

.rtl .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after {
  content: "";
  background: rgba(0, 0, 0, 0.001);
  width: 60px;
  height: 32px;
  position: absolute;
  top: -12px;
  right: -26px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-top: 8px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
  margin: 0;
  right: -9px;
  top: 2px;
  padding: 22px 0 4px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
}

.rtl .ant-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: right;
  border-radius: 4px 4px 0 0;
}

.rtl .ant-table-thead > tr > th {
  text-align: right;
}

.rtl .ant-table-thead > tr > th .anticon-filter,
.rtl .ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  right: auto;
  left: 0;
  margin-right: 0px;
  vertical-align: text-bottom;
}

.rtl .ant-table-thead > tr:first-child > th:first-child {
  text-align: center;
}

.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 20px !important;
  padding-left: 30px !important;
}

.rtl .ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
  margin-right: 4px;
}

.rtl .ant-table-thead > tr:first-child > th:first-child {
  border-top-right-radius: 4px;
}

.rtl .ant-table-thead > tr:first-child > th:last-child {
  border-top-left-radius: 4px;
}

.rtl .ant-table-footer:before {
  content: "";
  height: 1px;
  background: #fafafa;
  position: absolute;
  top: -1px;
  width: 100%;
  right: 0;
}

.rtl .ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #e8e8e8;
  padding-right: 16px;
  padding-left: 16px;
}

.rtl .ant-table-thead > tr > th.ant-table-selection-column-custom {
  padding-right: 16px;
  padding-left: 0;
}

.rtl .ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.rtl .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-left: 0;
}

.rtl .ant-table-loading .ant-table-spin-holder {
  height: 20px;
  line-height: 20px;
  right: 50%;
  top: 50%;
  margin-right: -30px;
  position: absolute;
}

.rtl .ant-table-column-sorter {
  position: relative;
  margin-right: 8px;
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-table-column-sorter-up:after,
.rtl .ant-table-column-sorter-down:after {
  position: absolute;
  content: "";
  height: 30px;
  width: 14px;
  right: 0;
}

.rtl table.ant-table-fixed {
  width: auto;
}

.rtl .ant-table-bordered .ant-table-header > table,
.rtl .ant-table-bordered .ant-table-body > table,
.rtl .ant-table-bordered .ant-table-fixed-left table,
.rtl .ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-left: 0;
  border-bottom: 0;
}

.rtl .ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-table-bordered .ant-table-thead > tr > th,
.rtl .ant-table-bordered .ant-table-tbody > tr > td {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-placeholder .anticon {
  margin-left: 4px;
}

.rtl .ant-table-pagination.ant-pagination {
  float: left;
  display: flex;
  flex-direction: row-reverse;
  direction: ltr;
}

.rtl .ant-table-filter-dropdown {
  min-width: 96px;
  margin-right: -8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-left: 0;
}

.rtl .ant-table-filter-dropdown-link.confirm {
  float: right;
}

.rtl .ant-table-filter-dropdown-link.clear {
  float: left;
}

.rtl .ant-table-selection-select-all-custom {
  margin-left: 4px !important;
}

.rtl .ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-right: -30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-left: 8px;
}

.rtl .ant-table-fixed-left,
.rtl .ant-table-fixed-right {
  position: absolute;
  top: 0;
  overflow: hidden;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  border-radius: 0;
}

.rtl .ant-table-fixed-left table,
.rtl .ant-table-fixed-right table {
  width: auto;
  background: #fff;
}

.rtl .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.rtl .ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}

.rtl .ant-table-fixed-left {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}

.rtl .ant-table-fixed-left .ant-table-body-inner {
  margin-left: -20px;
  padding-left: 20px;
}

.rtl .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-left: 0;
}

.rtl .ant-table-fixed-left,
.rtl .ant-table-fixed-left table {
  border-radius: 0 4px 0 0;
}

.rtl .ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-left-radius: 0;
}

.rtl .ant-table-fixed-right {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-fixed-right,
.rtl .ant-table-fixed-right table {
  border-radius: 4px 0 0 0;
}

.rtl .ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}

.rtl .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-right-radius: 0;
}

.rtl .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}

.rtl .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}

.rtl .ant-table-small.ant-table-bordered {
  border-left: 0;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-content {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-footer:before {
  display: none;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.rtl .ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-left: none;
}

.rtl .ant-tag {
  margin-left: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.rtl .ant-tag .anticon-cross {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333) rotate(0deg);
          transform: scale(0.83333) rotate(0deg);
  cursor: pointer;
  margin-right: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}

.rtl .ant-timeline-item-tail {
  position: absolute;
  right: 4px;
  top: 0.75em;
  height: 100%;
  border-right: 2px solid #e8e8e8;
}

.rtl .ant-timeline-item-head-custom {
  right: 5px;
  width: auto;
}

.rtl .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #e8e8e8;
  display: none;
}

.rtl .ant-transfer-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 32px;
  text-align: center;
}

.rtl .ant-transfer-list-header {
  right: 0;
  width: 100%;
}

.rtl .ant-transfer-list-header-title {
  position: absolute;
  left: 12px;
}

.rtl .ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  width: 100%;
}

.rtl .ant-transfer-list-content-item > span {
  padding-left: 0;
}

.rtl .ant-transfer-list-footer {
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.rtl .ant-select-tree-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
}

.rtl .ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
}

.rtl .ant-select-tree-checkbox-inner:after {
  right: 4.57142857px;
  border-right: 0;
}

.rtl .ant-select-tree-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
  content: " ";
  -webkit-transform: scale(1);
          transform: scale(1);
  position: absolute;
  right: 2.42857143px;
  top: 5.92857143px;
  width: 9.14285714px;
  height: 1.14285714px;
}

.rtl .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  -webkit-transform: rotate(-45deg) scale(1);
          transform: rotate(-45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.rtl .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-select-tree-checkbox-wrapper + span,
.rtl .ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-select-tree-icon__open {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-select-tree-icon__close {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #62c2c9;
  content: "";
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rtl .ant-tree-checkbox-inner:after {
  -webkit-transform: rotate(-45deg) scale(0);
          transform: rotate(-45deg) scale(0);
  position: absolute;
  right: 4.57142857px;
  top: 1.14285714px;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.rtl .ant-tree-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  content: " ";
  -webkit-transform: scale(1);
          transform: scale(1);
  position: absolute;
  right: 2.42857143px;
  top: 5.92857143px;
  width: 9.14285714px;
  height: 1.14285714px;
}

.rtl .ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  -webkit-transform: rotate(-45deg) scale(1);
          transform: rotate(-45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.rtl .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-tree-checkbox-wrapper + span,
.rtl .ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-tree-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-tree li span.ant-tree-icon_loading {
  position: absolute;
  right: 0;
  top: 1px;
  background: #fff;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rtl .ant-tree-icon__open {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree-icon__close {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree.ant-tree-show-line li:not(:last-child):before {
  content: " ";
  width: 1px;
  border-right: 1px solid #d9d9d9;
  height: 100%;
  position: absolute;
  right: 12px;
  margin: 22px 0;
}

.rtl .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #d9d9d9;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-left: 8px;
  margin-bottom: 8px;
  display: table;
}

.rtl .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 22px;
  width: 100%;
  display: none;
}

.rtl .ant-upload-list-item .anticon-cross {
  left: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}

.rtl .ant-upload-list-item-error .anticon-cross {
  opacity: 1;
  color: #f5222d !important;
}

.rtl .ant-upload-list-item-progress {
  line-height: 0;
  font-size: 14px;
  position: absolute;
  width: 100%;
  bottom: -12px;
  padding-right: 26px;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-thumbnail,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  right: 8px;
  text-align: center;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-icon,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 36px;
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -18px;
  margin-right: -18px;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-name,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 8px 0 0;
  line-height: 44px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-right: 48px;
  padding-left: 8px;
  max-width: 100%;
  display: inline-block;
  box-sizing: border-box;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-progress,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 56px;
  margin-top: 0;
  bottom: 14px;
  width: calc(100% - 24px);
}

.rtl .ant-upload-list-picture .anticon-cross,
.rtl .ant-upload-list-picture-card .anticon-cross {
  position: absolute;
  left: 8px;
  top: 8px;
  line-height: 1;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item {
  float: right;
  width: 104px;
  height: 104px;
  margin: 0 0 8px 8px;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  right: 50%;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  bottom: 32px;
}

.bg_gray {
  padding: 20px 5%;
}

.profile_container {
  background: #fff;
  padding: 20px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .profile_container {
    overflow-y: auto;
    height: calc(100vh - 65px);
  }
}

.profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
  text-align: left !important;
}

body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
  text-align: right !important;
}

@media only screen and (max-width: 991px) {
  .profile_container .personal-info-input {
    flex-direction: column;
  }
  .profile_container .personal-info-input > div {
    flex: 1 1;
    margin-right: 0 !important;
    width: 100%;
  }
  .profile_container .personal-info-input > div.pt-3 {
    padding-top: 20px;
  }
  .profile_container .personal-info-input > div:not(:last-of-type) .ant-form-item {
    margin-bottom: 10px;
  }
  .profile_container .personal-info-input > div:last-of-type .ant-form-item {
    margin-bottom: 20px;
  }
}

.profile_container .react-tel-input .selected-flag .flag {
  right: 18px !important;
}

@media only screen and (max-width: 639px) {
  .profile_container .flex-center-between {
    display: block !important;
  }
  .profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
    padding-left: 40px !important;
  }
  body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
    padding-left: 0px !important;
    padding-right: 40px !important;
  }
}

.profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
  padding-left: 40px !important;
}

body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
  padding-left: 0px !important;
  padding-right: 40px !important;
}

@media only screen and (max-width: 360px) {
  .profile_container .has-success.has-feedback .ant-form-item-children-icon svg, .profile_container .has-warning.has-feedback .ant-form-item-children-icon svg, .profile_container .has-error.has-feedback .ant-form-item-children-icon svg, .profile_container .is-validating.has-feedback .ant-form-item-children-icon svg {
    right: 18px !important;
    left: unset !important;
  }
}

.profile_container .profile_title {
  font-size: 24px;
  font-weight: bold;
  color: #242424;
}

.profile_container .personal-info-input > div {
  flex: 1 1;
}

.profile_container .personal-info-input > div:not(:last-of-type) {
  margin-right: 40px;
}

body.rtl .profile_container .personal-info-input > div:not(:last-of-type) {
  margin-left: 40px !important;
  margin-right: 0;
}

.profile_container .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}

.profile_container .avatar-upload .avatar-edit {
  position: absolute;
  right: 43px;
  z-index: 1;
  top: 10px;
}

.profile_container .avatar-upload .avatar-edit input {
  display: none;
}

.profile_container .avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.profile_container .avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.profile_container .avatar-upload .avatar-edit input + label:after {
  content: url(/static/media/camera.9d2d7032.svg);
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.profile_container .avatar-upload .avatar-preview {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.profile_container .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile_container #imagePreview {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  left: -6px;
  top: -5px;
}

.profile_container .profile_subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #242424;
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
}

body.rtl .profile_container .profile_subtitle {
  margin-left: 10px;
  margin-right: 0 !important;
}

.profile_container hr {
  width: 83%;
  margin-left: auto;
  display: inline-block;
}

.profile_container .subtitle_holder {
  display: flex;
  align-items: baseline;
}

.profile_container .w-35 {
  width: 35%;
  min-width: 248px;
}

.profile_container .pt-3 {
  padding-top: 30px;
}

.profile_container .line {
  height: 1px;
  background: #dddddd;
  width: 100%;
}

.profile_container .align_center {
  display: flex;
  align-items: center;
}

.profile_container .deactivate_modal .ant-modal-footer {
  border: none;
}

.profile_container .deactivate_modal .ant-modal-header {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
}

.profile_container .deactivate_modal .ant-modal-content {
  padding: 50px 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.profile_container .deactivate_modal .ant-modal-title {
  font-size: 22px;
  font-weight: bold;
  color: #242424;
}

.profile_container .ant-upload.ant-upload-select-picture-card img {
  height: 100px !important;
}

.changPass_modal .ant-modal-header {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
}

.changPass_modal .ant-modal-title {
  font-size: 22px;
  font-weight: bold;
  color: #242424;
}

.changPass_modal .ant-modal-content {
  padding: 50px 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .changPass_modal .ant-modal-content {
    padding: 50px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .changPass_modal .ant-modal-content {
    padding: 30px 10px;
  }
  .changPass_modal .ant-modal-content .ant-modal-body {
    padding-bottom: 0;
  }
}

.changPass_modal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 35px;
}

body.rtl .changPass_modal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 35px !important;
  right: auto !important;
}

.changPass_modal .ant-modal-footer {
  border: none;
}

.changPass_modal .ant-form-item-control.has-error .ant-form-explain {
  font-size: 11px !important;
  line-height: 1.2;
  padding-top: 6px;
}

body.rtl .changPass_modal .ant-form-item-control.has-error .ant-form-explain {
  line-height: 1 !important;
}

.changPass_modal .has-success.has-feedback .ant-form-item-children-icon, .changPass_modal .has-warning.has-feedback .ant-form-item-children-icon, .changPass_modal .has-error.has-feedback .ant-form-item-children-icon, .changPass_modal .is-validating.has-feedback .ant-form-item-children-icon {
  top: 62% !important;
  right: 3px !important;
}

body.rtl .changPass_modal .has-success.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .has-warning.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .has-error.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .is-validating.has-feedback .ant-form-item-children-icon {
  right: auto !important;
  left: 3px;
}

body.rtl .changPass_modal .ant-form-item-control .ant-form-explain {
  white-space: unset !important;
  bottom: -25px !important;
}

@media only screen and (max-width: 360px) {
  body.rtl .changPass_modal .ant-form-item-control .ant-form-explain {
    bottom: -29px !important;
    font-size: 10px !important;
  }
}

.changPass_modal .ant-form-item {
  margin-bottom: 45px !important;
}

.timer_holder {
  position: relative;
  display: flex;
  justify-content: center;
}

.timer_holder #countdown {
  position: relative;
  margin: auto;
  height: 80px;
  width: 80px;
  text-align: center;
}

.timer_holder #countdown-number {
  height: 20px;
}

.timer_holder svg {
  position: absolute;
  top: 0;
  width: 80px;
  height: 80px;
  -webkit-transform: rotateY(-180deg) rotateZ(-90deg);
          transform: rotateY(-180deg) rotateZ(-90deg);
}

.timer_holder svg .dinamic_circle {
  stroke-dasharray: 186px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: #fc5646;
  fill: none;
  -webkit-animation: countdown 60s linear 1 backwards;
          animation: countdown 60s linear 1 backwards;
}

@-webkit-keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 186px;
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 186px;
  }
}

.timer_holder svg .static_circle {
  stroke-dasharray: 186px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: #b7b7b7;
  fill: #f2f2f2;
  background: #f2f2f2 !important;
}

.timer_holder .contant_holder {
  color: #565656;
  display: inline-block;
  line-height: 60px;
  position: relative;
  z-index: 1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.terms-wrapper section {
  width: 90%;
  margin: 2rem auto;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.84;
  letter-spacing: normal;
  text-align: start;
  color: #242430;
}

.terms-wrapper section p {
  height: 56px;
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: start;
  color: #242430;
}

.password_changed .ant-modal-header {
  display: none;
}

.password_changed .ant-modal-content {
  padding: 5% 5%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.password_changed .ant-modal-footer {
  border: none;
  display: flex;
  justify-content: center;
}

.password_changed .ant-modal-footer > div > :first-child {
  display: none;
}

.password_changed .ant-modal-footer .ant-btn-primary {
  background: #62c2c9;
}

.change_password .ant-modal-header {
  display: none;
}

.change_password .ant-modal-content {
  padding: 5% 5%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.change_password .ant-modal-footer {
  border: none;
}

.change_password .ant-modal-footer .ant-btn-primary {
  background: #62c2c9;
}

.mail {
  color: #174586;
  font-size: 15px;
  font-weight: 500;
}

.size20 {
  font-size: 19px;
  font-weight: 500;
}

.natificatin_holder .ant-table-pagination.ant-pagination {
  display: none;
}

.natificatin_holder .ant-checkbox-wrapper {
  display: none;
}

.natificatin_holder .ant-table-thead > tr > th {
  background: #fff;
}

.natificatin_holder .ant-table-thead > tr > th.ant-table-selection-column, .natificatin_holder .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.natificatin_holder .ant-table-thead > tr:first-child > th:last-child > :first-child {
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
  color: #3967a9;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.natificatin_holder .notifi {
  color: #242424;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.natificatin_holder .min {
  font-size: 10px;
  font-weight: bold;
  color: #565656;
}

.natificatin_holder .unread {
  width: 10px;
  height: 10px;
  background-color: #3967a9;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 2px;
}

.natificatin_holder .ant-table-row {
  background-color: rgba(57, 103, 169, 0.09);
}

.natificatin_holder .ml-2 {
  margin-left: 20px;
}

.natificatin_holder tr td {
  padding: 0;
}

.natificatin_holder tr .notify-row {
  padding: 16px;
  background-color: rgba(57, 103, 169, 0.09);
}

.natificatin_holder tr .notify-row.seen {
  background-color: #ffffff;
}

.natificatin_holder tr .notify-row.seen .notifi .unread {
  display: none;
}

.natificatin_holder .space_between {
  display: flex;
  justify-content: space-between;
}

.h-100 {
  height: 100%;
}

.small_space_r {
  margin-right: 10px;
}

body.rtl .small_space_r {
  margin-left: 10px;
  margin-right: 0 !important;
}

@media only screen and (max-width: 575px) {
  .add-station-modal .ant-modal {
    width: 100% !important;
  }
  .add-station-modal .ant-modal .ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.add-station-modal .ant-modal-footer {
  padding: 20px 74px !important;
}

.add-station-modal .ant-modal-footer > div button:first-of-type:hover, .add-station-modal .ant-modal-footer > div button:first-of-type:focus, .add-station-modal .ant-modal-footer > div button:first-of-type:active {
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
}

.add-station-modal .ant-modal-body {
  padding: 24px 75px !important;
}

@media only screen and (max-width: 575px) {
  .add-station-modal .ant-modal-body {
    padding: 5px !important;
  }
}

.add-station-modal .ant-select.ant-select-open .ant-select-arrow svg {
  color: #444;
}

.add-station-modal .map-wrapper > div > div {
  width: 90% !important;
  position: relative !important;
}

@media only screen and (max-width: 575px) {
  .add-station-modal .map-wrapper > div > div {
    width: 100% !important;
    margin-bottom: 30px;
  }
}

.add-station-modal .map-wrapper > div > div > div {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}

.add-station-modal .map-wrapper .ant-form-item .ant-input {
  font-weight: 600;
}

.add-station-modal .map-wrapper .ant-form-item .ant-form-item-label label {
  color: #4b4b4b !important;
  font-weight: normal;
}

.add-station-modal .map-wrapper input {
  position: absolute;
  top: 11px;
  left: 9px;
  border-radius: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
  border: none;
  padding: 5px 10px;
  width: 60%;
}

.add-station-modal .map-wrapper input::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 16px;
}

body.rtl .add-station-modal .map-wrapper input {
  right: 9px;
  left: auto;
}

.add-station-modal .editble-grid-wrapper {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  max-height: 350px;
  overflow-y: scroll;
}

.add-station-modal .editble-grid-wrapper.isEmpty {
  min-height: 200px;
}

.add-station-modal .editble-grid-wrapper .ant-table-thead > tr > th {
  background: transparent;
  text-align: center;
  border-bottom: 1px solid #cbcbcb !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: transparent;
}

.add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-thead > tr > th,
.add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #cbcbcb !important;
  padding: 8px !important;
  font-weight: bold;
}

.add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-thead > tr > th:last-child,
.add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none !important;
}

.add-station-modal .editble-grid-wrapper .ant-table table {
  border: none !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.add-station-modal .editble-grid-wrapper th {
  font-size: 15px;
  font-weight: 500;
  color: #7e7e7e;
}

.add-station-modal .editble-grid-wrapper td {
  text-align: center !important;
  color: #404040;
}

.add-station-modal .editble-grid-wrapper td a {
  margin: 0px 11px;
}

.add-station-modal .editble-grid-wrapper td a i {
  font-size: 18px;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection {
  border: none !important;
  padding: 0 !important;
  height: 20px;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-arrow {
  top: 87%;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-selection-selected-value {
  padding-right: 44px !important;
}

body.rtl .add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-selection-selected-value {
  padding-left: 44px !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-placeholder {
  top: 107px !important;
  border: none;
}

body.rtl .add-station-modal .editble-grid-wrapper .ant-table-placeholder {
  border: none;
}

.pricing-settings-wrapper {
  margin: 25px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 575px) {
  .pricing-settings-wrapper {
    margin: 25px 0px;
  }
}

.pricing-settings-wrapper.form-editable .grid-holder > div:nth-last-child(-n + 3) {
  border-bottom: 1.5px solid #cbcbcb;
}

.pricing-settings-wrapper .grid-holder {
  padding: 10px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 575px) {
  .pricing-settings-wrapper .grid-holder {
    padding: 10px 0px;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

.pricing-settings-wrapper .grid-holder > div {
  min-height: 60px;
  text-align: center;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1.5px solid #cbcbcb;
  border-right: 1.5px solid #cbcbcb;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .pricing-settings-wrapper .grid-holder > div {
    font-size: 12px;
  }
}

body.rtl .pricing-settings-wrapper .grid-holder > div {
  border-right: 0;
  border-left: 1.5px solid #cbcbcb;
  /* @include mq('phablet'){
          border-left: unset;
        } */
}

.pricing-settings-wrapper .grid-holder > div:nth-child(1), .pricing-settings-wrapper .grid-holder > div:nth-child(4), .pricing-settings-wrapper .grid-holder > div:nth-child(7) {
  color: #25ae81;
  justify-content: flex-start;
}

.pricing-settings-wrapper .grid-holder > div:nth-child(-n + 3) {
  color: #404040;
}

.pricing-settings-wrapper .grid-holder > div:nth-child(3n) {
  border-right: 0;
}

body.rtl .pricing-settings-wrapper .grid-holder > div:nth-child(3n) {
  border-left: 0;
}

.pricing-settings-wrapper .grid-holder > div:nth-last-child(-n + 3) {
  border-bottom: 0;
}

.pricing-settings-wrapper .grid-holder .ant-form-item {
  margin-bottom: 0;
  width: 150px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number {
  position: relative;
  border: 0;
  margin: 0;
  opacity: 0.42;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number.ant-input-number-disabled {
  background-color: #fff;
  opacity: 1;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number.ant-input-number-disabled:after {
  opacity: 1;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number input {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number input[disabled] {
  background-color: #fff;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number:after {
  content: "L.E";
  position: absolute;
  right: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  background-color: #fff;
}

body.rtl .pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number:after {
  content: "ج.م";
  right: unset;
  left: 22px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number + .warning {
  position: absolute;
  bottom: -20px;
  width: 100%;
  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d83025;
  font-size: 14px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span i {
  color: #000;
  font-size: 18px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span.ant-input-number-handler-down-disabled i, .pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span.ant-input-number-handler-up-disabled i {
  color: #b5b5b5;
}

.pricing-settings-wrapper .grid-holder .edit-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

body.rtl .pricing-settings-wrapper .grid-holder .edit-btn {
  right: auto;
  left: 0;
}

body.rtl .pricing-settings-wrapper .ant-input-number-input-wrap .ant-input-number-input {
  text-align: center !important;
}

.no-session-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65vh;
}

.add-charger-heading--btn {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.79px;
  color: #008539;
}

body.rtl .charges-info-tab-wrapper {
  direction: rtl;
}

.charges-info-tab-wrapper .ant-table-title {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 1px;
}

.charges-info-tab-wrapper .add-charger__footer {
  display: flex;
  justify-content: flex-end;
}

.charges-info-tab-wrapper .add-charger__footer .ant-pagination {
  width: 50%;
  text-align: center;
}

body.rtl .charges-info-tab-wrapper .add-charger__footer .ant-pagination {
  margin-left: auto;
}

.charges-info-tab-wrapper .no-charger-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px 75px;
}

@media only screen and (max-width: 575px) {
  .charges-info-tab-wrapper .no-charger-holder img {
    width: 100%;
  }
}

.charges-info-tab-wrapper .no-charger-holder p {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  text-align: center;
  margin-top: 31px;
}

.charges-info-tab-wrapper .add_charger_button_holder {
  display: flex;
  justify-content: flex-end;
}

.charges-info-tab-wrapper .add_charger_button_holder button {
  margin-top: 10px;
}

.charges-info-tab-wrapper .add-conntectors-table table {
  background-color: #f5f5f5;
}

.charges-info-tab-wrapper .add-conntectors-table .ant-table-tbody tr:last-child .ant-table-selection-column {
  visibility: hidden;
}

.charges-info-tab-wrapper .chargers-connectors-wrapper .grid-holder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  margin: 16px;
}

.charges-info-tab-wrapper .chargers-connectors-wrapper .grid-holder > div {
  position: relative;
  overflow: hidden auto;
}

.charges-info-tab-wrapper .chargers-connectors-wrapper .grid-holder > div.active {
  box-shadow: 0 -2px 21px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
}

.charges-info-tab-wrapper .chargers-connectors-wrapper .grid-holder > div.active .charger-card-wrapper {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.charges-info-tab-wrapper .chargers-connectors-wrapper .grid-holder > div.active .connectors-card-wrapper {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

@media only screen and (max-width: 1247px) {
  .charges-info-tab-wrapper .chargers-connectors-wrapper .grid-holder {
    grid-template-columns: 1fr 1fr;
  }
}

.charges-info-tab-wrapper .charger-card-wrapper {
  border: solid 0.5px #eaeaea;
  background-color: #f5f5f5;
  padding: 10px 15px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.charges-info-tab-wrapper .charger-card-wrapper .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  min-height: 35px;
  border-bottom: solid 0.5px #eaeaea;
  margin-bottom: 5px;
}

.charges-info-tab-wrapper .charger-card-wrapper .card-header .card-header__title {
  font-weight: bold;
  letter-spacing: 0.62px;
  color: #01401c;
  font-size: 14px;
}

.charges-info-tab-wrapper .charger-card-wrapper .card-header .card-header__btns {
  display: flex;
}

.charges-info-tab-wrapper .charger-card-wrapper .card-header button {
  font-size: 14px;
  letter-spacing: 0.79px;
  color: #008539;
}

.charges-info-tab-wrapper .charger-card-wrapper .card-header button.edit-btn {
  color: #808080;
}

.charges-info-tab-wrapper .charger-card-wrapper .card-header button.delete-btn {
  color: #d83025;
}

.charges-info-tab-wrapper .charger-card-wrapper .card-header button:disabled {
  opacity: 0.45;
  pointer-events: none;
}

.charges-info-tab-wrapper .charger-card-wrapper .charger-detailes-holder > div {
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.charges-info-tab-wrapper .charger-card-wrapper .charger-detailes-holder > div h4 {
  font-size: 16px;
  font-weight: normal;
  color: #747474;
  width: 40%;
}

body.rtl .charges-info-tab-wrapper .charger-card-wrapper .charger-detailes-holder > div h4 {
  text-align: right;
}

.charges-info-tab-wrapper .charger-card-wrapper .charger-detailes-holder > div h4 span {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.42;
  color: #747474;
}

.charges-info-tab-wrapper .charger-card-wrapper .charger-detailes-holder > div h5 {
  font-size: 16px;
  font-weight: 500;
  color: #4b4b4b;
  width: 60%;
  display: flex;
  align-items: center;
}

.charges-info-tab-wrapper .charger-card-wrapper .charger-detailes-holder > div h5 .connector-status__option {
  margin-left: 0;
}

body.rtl .charges-info-tab-wrapper .charger-card-wrapper .charger-detailes-holder > div h5 .connector-status__option {
  margin-right: 0;
  margin-left: 5px;
}

.charges-info-tab-wrapper .charger-card-wrapper .card-footer {
  text-align: center;
  margin-top: 20px;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper {
  position: absolute;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  top: 0;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-thead > tr > th,
.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-tbody > tr > td {
  padding: 0 10px;
  background-color: #f5f5f5 !important;
  border-color: #eaeaea;
  text-align: center;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-thead > tr > th:nth-child(1),
.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-tbody > tr > td:nth-child(1) {
  padding: 0 !important;
  width: 2%;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-thead > tr > th {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.75px;
  color: #747474;
  padding: 0 10px;
  padding-bottom: 5px;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-thead > tr > th:first-of-type > div {
  visibility: hidden;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-tbody > tr > td {
  border: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: 0.62px;
  color: #4b4b4b;
  padding: 10px;
  border-left: 1px solid #eaeaea;
  width: 32%;
}

body.rtl .charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-tbody > tr > td {
  border-right: 1px solid #eaeaea;
  border-left: 0;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-tbody > tr > td:nth-child(1), .charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-tbody > tr > td:nth-child(2) {
  border-left: 0;
}

body.rtl .charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-tbody > tr > td:nth-child(1), body.rtl .charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-table-tbody > tr > td:nth-child(2) {
  border-right: 0;
  border-left: 1px solid #eaeaea;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .ant-checkbox-wrapper .ant-checkbox-inner {
  border-width: 1px;
  border-color: #d5d0d0;
  width: 14px;
  height: 14px;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .connector-status__option {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #2dbb54;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .connector-status__option.connector-status__option--unavailable {
  background-color: #d83025;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .comment-wrapper {
  border-top: 1px solid #eaeaea;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .comment-wrapper h4 {
  font-size: 17px;
  font-weight: normal;
  line-height: 1.29;
  color: #747474;
  margin-bottom: 10px;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .comment-wrapper div {
  border-radius: 4px;
  border: solid 1px #e7e7e7;
  background-color: #fff;
  resize: none;
  padding: 5px !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: 0.62px;
  color: #4b4b4b;
  min-height: 170px;
  word-break: break-word;
  width: 100%;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .comment-wrapper div:-moz-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: 0.62px;
  color: #aaaaaa;
  text-indent: 6px;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .comment-wrapper div::-moz-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: 0.62px;
  color: #aaaaaa;
  text-indent: 6px;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .comment-wrapper div:-ms-input-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: 0.62px;
  color: #aaaaaa;
  text-indent: 6px;
}

.charges-info-tab-wrapper .charger-card-wrapper.connectors-card-wrapper .comment-wrapper div::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: 0.62px;
  color: #aaaaaa;
  text-indent: 6px;
}

.charges-info-tab-wrapper .charger-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
}

.charges-info-tab-wrapper .charger-header h6 {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.62px;
  color: #01401c;
}

.charges-info-tab-wrapper .charger-header .btns-action-holder button {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.79px;
  color: #008539;
  outline: none !important;
}

.charges-info-tab-wrapper .charger-header .btns-action-holder button.edit-btn {
  color: #808080;
}

.charges-info-tab-wrapper .charger-header .btns-action-holder button.delete-btn {
  color: #d83025;
}

.charges-info-tab-wrapper .charger-header .btns-action-holder button:disabled {
  opacity: 0.45;
  pointer-events: none;
}

.charges-info-tab-wrapper .ant-table-thead > tr > th,
.charges-info-tab-wrapper .ant-table-tbody > tr > td {
  padding: 0 10px;
  background-color: #f5f5f5 !important;
  border-color: #eaeaea;
}

.charges-info-tab-wrapper .ant-table-thead > tr > th {
  font-size: 17px;
  font-weight: normal;
  letter-spacing: 0.75px;
  color: #747474;
  padding: 7px 10px;
}

.charges-info-tab-wrapper .ant-table-thead > tr > th:first-of-type > div {
  visibility: hidden;
}

.charges-info-tab-wrapper .ant-table-tbody > tr > td {
  border: 0;
}

.charges-info-tab-wrapper .ant-checkbox-wrapper .ant-checkbox-inner {
  border-width: 1px;
  border-color: #d5d0d0;
}

.charges-info-tab-wrapper form .comment-wrapper {
  margin-bottom: 20px;
  border-top: 1px solid #eaeaea;
  padding-top: 8px;
}

.charges-info-tab-wrapper form .comment-wrapper h5 {
  font-size: 17px;
  font-weight: normal;
  line-height: 1.29;
  color: #747474;
  margin-bottom: 10px;
}

body.rtl .charges-info-tab-wrapper form .comment-wrapper h5 {
  text-align: right;
}

.charges-info-tab-wrapper form .comment-wrapper textarea {
  border-radius: 4px;
  border: solid 1px #e7e7e7 !important;
  background-color: #ffffff !important;
  resize: none;
  padding: 5px !important;
}

.charges-info-tab-wrapper form .comment-wrapper textarea:-moz-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: 0.62px;
  color: #aaaaaa;
  text-indent: 6px;
}

.charges-info-tab-wrapper form .comment-wrapper textarea::-moz-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: 0.62px;
  color: #aaaaaa;
  text-indent: 6px;
}

.charges-info-tab-wrapper form .comment-wrapper textarea:-ms-input-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: 0.62px;
  color: #aaaaaa;
  text-indent: 6px;
}

.charges-info-tab-wrapper form .comment-wrapper textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: 0.62px;
  color: #aaaaaa;
  text-indent: 6px;
}

.charges-info-tab-wrapper form .ant-form-item {
  margin-bottom: 0;
}

.charges-info-tab-wrapper form .ant-form-item input {
  padding-top: 0 !important;
  border-bottom: solid 1px #cacaca;
  height: 30px;
  background-color: #fff !important;
  text-indent: 6px;
}

.charges-info-tab-wrapper form .ant-form-item input:hover {
  border-bottom: 0 !important;
}

.charges-info-tab-wrapper form .ant-form-item input:-moz-placeholder {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.62px;
  color: #c4c4c4;
}

.charges-info-tab-wrapper form .ant-form-item input::-moz-placeholder {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.62px;
  color: #c4c4c4;
}

.charges-info-tab-wrapper form .ant-form-item input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.62px;
  color: #c4c4c4;
}

.charges-info-tab-wrapper form .ant-form-item input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.62px;
  color: #c4c4c4;
}

.charges-info-tab-wrapper form .ant-form-item .ant-select .ant-select-selection {
  border-bottom: 0 !important;
  padding-top: 10px !important;
}

.charges-info-tab-wrapper form .ant-form-item .ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.62px;
  color: #aaaaaa;
}

.charges-info-tab-wrapper form .ant-form-item .ant-select .ant-select-selection .ant-select-arrow {
  color: #000000;
}

.connector-status__option {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #2dbb54;
}

.connector-status__option.connector-status__option--unavailable {
  background-color: #d83025;
}

.add-charger-wrapper {
  padding: 0 15px 30px 15px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 575px) {
  .add-charger-wrapper {
    padding: 0px;
  }
}

.add-charger-wrapper .add-charger__header {
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.62px;
  color: #01401c;
}

.add-charger-wrapper .ant-form-item-control.has-error .ant-input {
  border: solid 0.5px #d83025 !important;
}

.add-charger-wrapper .ant-form-item-control.has-error .ant-form-explain {
  position: absolute;
  top: 7px;
  right: 15px;
  font-size: 16px;
  font-weight: normal;
  color: #d83025;
}

body.rtl .add-charger-wrapper .ant-form-item-control.has-error .ant-form-explain {
  left: 15px;
  right: auto;
}

.add-charger-wrapper .form-holder h4 {
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #747474;
  margin-bottom: 14px;
}

body.rtl .add-charger-wrapper .form-holder h4 {
  text-align: right;
}

.add-charger-wrapper .form-holder h4 span {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.42;
  color: #747474;
  margin: 0px 2px;
}

.add-charger-wrapper .form-holder .ant-form-item input:disabled {
  background-color: transparent !important;
}

.add-charger-wrapper .form-holder > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.add-charger-wrapper .form-holder > div .ant-select .ant-select-selection {
  height: 33px;
  padding-top: 0 !important;
  background-color: #fff;
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.add-charger-wrapper .form-holder > div .ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.62px;
  color: #c4c4c4;
}

body.rtl .ant-popover {
  right: unset !important;
}

.add-station-modal-v2 .ant-modal-header {
  padding-bottom: 13px;
}

.add-station-modal-v2 .ant-modal-body {
  padding-top: 0 !important;
}

body.rtl .add-station-modal-v2 .add-station__tabs {
  direction: ltr;
}

body.rtl .add-station-modal-v2 .add-station__tabs .ant-tabs-content {
  direction: ltr;
}

.add-station-modal-v2 .add-station__tabs .ant-tabs-nav {
  width: 100%;
}

body.rtl .add-station-modal-v2 .add-station__tabs .ant-tabs-nav {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.add-station-modal-v2 .add-station__tabs .ant-tabs-nav .ant-tabs-tab {
  width: 50%;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.79px;
  color: #1d1f1f;
  font-family: lato;
}

@media only screen and (max-width: 575px) {
  .add-station-modal-v2 .add-station__tabs .ant-tabs-nav .ant-tabs-tab {
    font-size: 11px;
  }
}

body.rtl .add-station-modal-v2 .add-station__tabs .ant-tabs-nav .ant-tabs-tab {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  font-family: Cairo, lato;
}

.add-station-modal-v2 .add-station__tabs .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 900;
  color: #62c2c9;
}

.add-station-modal-v2 .add-station__tabs .ant-tabs-ink-bar {
  height: 3px !important;
  background-color: #62c2c9;
}

.add-station-modal-v2 .add-station__img-col {
  position: relative;
  padding-left: 15px;
}

body.rtl .add-station-modal-v2 .add-station__img-col {
  display: flex;
  justify-content: flex-end;
}

.add-station-modal-v2 .add-station__img-col .ant-upload.ant-upload-select-picture-card {
  border-radius: 4px !important;
  width: 241px !important;
  height: 241px !important;
}

.add-station-modal-v2 .add-station__img-col .ant-upload.ant-upload-select-picture-card img {
  border-radius: 4px !important;
}

.add-station-modal-v2 .add-station__img-col .add-station__img {
  margin-bottom: 10px;
}

.add-station-modal-v2 .add-station__img-col .add-station__img .add-station__img__button {
  width: 136px;
  height: 31px;
  background-color: rgba(255, 255, 255, 0.68);
  font-size: 14px;
  color: #141414;
  border-radius: 18px;
  position: absolute;
  top: 15px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.add-station-modal-v2 .add-station__img-col .add-station__img .add-station__img__button:focus {
  outline: none;
}

body.rtl .add-station-modal-v2 .add-station__img-col .add-station__img .add-station__img__button {
  right: 20px;
  left: unset !important;
}

.add-station-modal-v2 .num-charg-card {
  width: 241px;
  min-height: 158px;
  border: solid 0.5px #eaeaea;
  background-color: #f5f5f5;
  margin-top: 20px;
}

.add-station-modal-v2 .num-charg-card .num-charg-card__rule {
  display: flex;
  padding: 9px 6px;
  border-bottom: solid 0.5px #eaeaea;
  position: relative;
}

body.rtl .add-station-modal-v2 .num-charg-card .num-charg-card__rule img {
  position: absolute;
  right: 4px;
  top: 20px;
}

.add-station-modal-v2 .num-charg-card .num-charg-card__rule__pragraph {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.31;
  color: #525252;
  word-break: break-word;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
}

.add-station-modal-v2 .num-charg-card .num-chargers,
.add-station-modal-v2 .num-charg-card .num-out-duty {
  display: flex;
  padding: 13px 6px;
  justify-content: space-between;
  align-items: center;
}

.add-station-modal-v2 .num-charg-card .num-chargers__title,
.add-station-modal-v2 .num-charg-card .num-out-duty__title {
  font-size: 14px;
  line-height: 1.36;
  color: #747474;
}

.add-station-modal-v2 .num-charg-card .num-out-duty__num,
.add-station-modal-v2 .num-charg-card .num-chargers__num {
  font-size: 18px;
  font-weight: 600;
  color: #008539;
}

.add-station-modal-v2 .num-charg-card .num-out-duty__num {
  color: #c30707 !important;
}

body.rtl .add-station-modal-v2 .ant-tabs-content {
  direction: rtl;
}

.add-station-modal-v2 .add-station__map-col {
  position: relative;
}

.add-station-modal-v2 .add-station__map-col .add-station__map {
  position: relative;
  height: 266px;
}

.add-station-modal-v2 .add-station__map-col .add-station__map > div > div > div {
  height: 100% !important;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

body.rtl .add-station-modal-v2 .add-station__map-col .ant-form-item .ant-input::-webkit-input-placeholder {
  text-align: right;
}

body.rtl .add-station-modal-v2 .add-station__map-col .ant-form-item .ant-input::-moz-placeholder {
  text-align: right;
}

body.rtl .add-station-modal-v2 .add-station__map-col .ant-form-item .ant-input::-ms-input-placeholder {
  text-align: right;
}

body.rtl .add-station-modal-v2 .add-station__map-col .ant-form-item .ant-input::placeholder {
  text-align: right;
}

.add-station-modal-v2 .add-station__map-col .autocomplete {
  position: absolute;
  top: 13px;
  left: 14px;
  width: 300px;
  height: 34px;
  border: solid 0.5px #e2e2e2;
  border-radius: 4px;
  background: url(/static/media/search.68b3a0f1.svg) no-repeat 8px center;
  background-color: white;
  padding-left: 32px;
}

.add-station-modal-v2 .info-window-wrapper h5 {
  font-size: 10px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 7px;
}

.add-station-modal-v2 .info-window-wrapper .address-holder {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 8px;
  margin-bottom: 4px;
}

.add-station-modal-v2 .info-window-wrapper .address-holder address {
  font-size: 10px;
  font-weight: normal;
  color: #5d5d5d;
}

.add-station-modal-v2 .info-window-wrapper button {
  width: 58px;
  height: 18px;
  border-radius: 9px;
  background-color: #008539;
  font-size: 11px;
  font-weight: normal;
  color: #ffffff;
}

.add-station-modal-v2 .info-window-wrapper button:disabled {
  opacity: 0.3;
}

.add-station-modal-v2 .info-window-wrapper .info-window__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-station-modal-v2 .info-window-wrapper .info-window__footer p {
  font-size: 10px;
  line-height: 1.4;
  color: #008539;
  display: flex;
  align-items: center;
}

.add-station-modal-v2 .info-window-wrapper .info-window__footer p.unavailable-alert {
  color: #d83025;
}

.add-station-modal-v2 .gm-style-iw {
  width: 210px;
}

.connectors_section .connectors_header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.connectors_section .connectors_header h4 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #62c2c9;
}

.connectors_section .connectors_header button {
  font-size: 14px;
}

.connectors_section .base_table {
  height: calc(100vh - 365px);
  border: solid 1px #e2e2e2;
  width: 100%;
}

.connectors_section .base_table .ant-table-content {
  height: calc(100vh - 365px);
}

.connectors_section .base_table .ant-table-content .ant-table-tbody > tr > td {
  background-color: unset !important;
}

.connectors_section .base_table .ant-table-content .ant-table-thead > tr > th {
  background-color: #fff !important;
}

.connectors_section .base_table .ant-table-content .ant-table-thead > tr > th div {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #1d1f1f;
}

.connectors_section .base_table .ant-table-content .ant-table-thead > tr > th:first-of-type > div {
  visibility: visible;
}

.connectors_section .base_table .ant-table-content .ant-table-tbody tr:nth-child(2n) {
  background-color: #f5f5f5 !important;
}

.connectors_section .base_table .ant-table-content .ant-table-tbody tr:nth-child(2n + 1) {
  background-color: #fff !important;
}

.connectors_section .base_table .ant-form-item {
  height: 49px;
  margin-bottom: 5px;
}

.connectors_section .base_table .ant-form-item .ant-input {
  height: 35px;
  color: #62c2c9;
  border-bottom: unset !important;
}

.connectors_section .base_table .ant-form-item .ant-select .ant-select-selection {
  height: 35px;
  padding-top: 12px !important;
  color: #62c2c9;
  border-bottom: unset !important;
}

.connectors_section .base_table .ant-form-item .ant-select .ant-select-selection .ant-select-arrow {
  top: 80%;
  right: 0px;
}

.ant-drawer > button {
  background: transparent !important;
  color: black !important;
  border: none;
  box-shadow: none;
  outline: none !important;
}

.ant-drawer > button:focus, .ant-drawer > button:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-drawer > button:hover {
  color: black !important;
}

.ant-drawer .empty-state {
  margin-top: 25px;
}

.request-info-wrapper > div > div {
  min-height: 30px;
}

.request-info-wrapper .request-actions-wrapper {
  display: flex;
  justify-content: center;
}

.request-info-wrapper .request-actions-wrapper button {
  padding-left: 40px;
  padding-right: 40px;
  margin: auto 15px;
}

@media only screen and (max-width: 991px) {
  .view-profile-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.view-profile-drawer .ant-drawer-close .anticon {
  color: #c30c00 !important;
}

body.rtl .view-profile-drawer .ant-drawer-close {
  right: unset !important;
  left: 0;
}

.view-profile-drawer .ant-drawer-content-wrapper {
  -webkit-transition: width 0.7s ease 0s, -webkit-transform 0.7s ease 0s;
  transition: width 0.7s ease 0s, -webkit-transform 0.7s ease 0s;
  transition: transform 0.7s ease 0s, width 0.7s ease 0s;
  transition: transform 0.7s ease 0s, width 0.7s ease 0s, -webkit-transform 0.7s ease 0s;
}

.view-profile-drawer .ant-drawer-body {
  padding: 0 !important;
}

.view-profile-drawer .customer-profile-content .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #eef1f4;
  position: relative;
}

.view-profile-drawer .customer-profile-content .header .user-image {
  width: 87px;
  height: 87px;
  min-width: 87px;
  max-width: 87px;
  min-height: 87px;
  max-height: 87px;
  border-radius: 50%;
}

.view-profile-drawer .customer-profile-content .header .expand {
  position: absolute;
  top: 16px;
  left: 53px;
  cursor: pointer;
}

body.rtl .view-profile-drawer .customer-profile-content .header .expand {
  right: 47px;
  left: unset;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

@media only screen and (max-width: 991px) {
  .view-profile-drawer .customer-profile-content .header .expand {
    display: none;
  }
}

.view-profile-drawer .customer-profile-content .header .back-button {
  position: absolute;
  top: 17px;
  left: 25px;
  font-size: 17px;
  cursor: pointer;
}

body.rtl .view-profile-drawer .customer-profile-content .header .back-button {
  right: 25px;
  left: unset;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.view-profile-drawer .customer-profile-content .header .name.without-avatar {
  margin-top: 16px;
  font-size: 20px;
  align-self: flex-start;
}

body.rtl .view-profile-drawer .customer-profile-content .ant-tabs-nav-container {
  -webkit-transform: scaleX(-1) !important;
          transform: scaleX(-1) !important;
}

body.rtl .view-profile-drawer .customer-profile-content .content {
  text-align: right;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-bar {
  padding: 0 12px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .ant-tabs-bar {
  text-align: left;
}

.view-profile-drawer .customer-profile-content .content .drawer-tabs.ant-tabs > .ant-tabs-top-bar .ant-tabs-nav {
  display: flex;
  align-items: center;
  justify-content: start;
}

body.rtl .view-profile-drawer .customer-profile-content .content .drawer-tabs.ant-tabs > .ant-tabs-top-bar .ant-tabs-nav {
  display: inline-flex !important;
  /*  > div{
                   display: flex;
                   flex-direction: row-reverse;
                } */
}

.view-profile-drawer .customer-profile-content .content .drawer-tabs.ant-tabs > .ant-tabs-top-bar .ant-tabs-nav .ant-tabs-tab-active {
  color: #2db2bb;
  font-weight: bold;
}

body.rtl .view-profile-drawer .customer-profile-content .content .drawer-tabs.ant-tabs > .ant-tabs-top-bar .ant-tabs-nav-container {
  -webkit-transform: scaleX(-1) !important;
          transform: scaleX(-1) !important;
}

body.rtl .view-profile-drawer .customer-profile-content .content .drawer-tabs.ant-tabs > .ant-tabs-top-bar .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
}

.view-profile-drawer .customer-profile-content .content .drawer-tabs.ant-tabs > .ant-tabs-top-bar .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  color: #2db2bb;
}

body.rtl .view-profile-drawer .customer-profile-content .content .drawer-tabs.ant-tabs > .ant-tabs-top-bar .ant-tabs-nav .ant-tabs-tab {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.view-profile-drawer .customer-profile-content .content .drawer-tabs.ant-tabs > .ant-tabs-top-bar .ant-tabs-ink-bar {
  background: #2db2bb !important;
  /* body.rtl &{
                 right: auto;
                 left: 0;
              } */
}

.view-profile-drawer .customer-profile-content .content h4 {
  font-size: 16px;
  font-weight: bold;
  color: #2db2bb;
  line-height: 1.31;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  margin-bottom: 10px;
}

.view-profile-drawer .customer-profile-content .content h4.without-border {
  border-bottom: none;
}

.view-profile-drawer .customer-profile-content .content .buttons-group button:first-of-type {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
}

.view-profile-drawer .customer-profile-content .content .comment-textArea {
  margin-bottom: 15px;
  overflow-y: scroll;
}

.view-profile-drawer .customer-profile-content .content .comment-textArea p {
  color: #444444;
  font-size: 16px;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(1)::before {
  content: url(/static/media/email.edf79db8.svg);
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(1)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(2)::before {
  content: url(/static/media/phone.628e6962.svg);
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(2)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(3)::before {
  content: url(/static/media/user.6198b193.svg);
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(3)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(4)::before {
  content: url(/static/media/gender.af63afd3.svg);
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(4)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 .detail__content--wrapper {
  display: flex;
  flex-wrap: wrap;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 .detail__content--wrapper {
  direction: rtl;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(1) > div:first-child {
  margin-right: 10px;
  margin-left: 35px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(1) > div:first-child {
  margin-left: 10px;
  margin-right: 35px;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(1) > div:first-child::before {
  content: url(/static/media/car.6aaa15bc.svg);
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(1) > div:first-child::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(2) > div:first-child {
  margin-right: 10px;
  margin-left: 35px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(2) > div:first-child {
  margin-left: 10px;
  margin-right: 35px;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(2) > div:first-child::before {
  content: url(/static/media/battery.bf596d2c.svg);
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(2) > div:first-child::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(3) > div:first-child {
  margin-right: 10px;
  margin-left: 35px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(3) > div:first-child {
  margin-left: 10px;
  margin-right: 35px;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(3) > div:first-child::before {
  content: url(/static/media/driving-license.1f191997.svg);
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 > div:nth-child(3) > div:first-child::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .gray {
  color: #494848;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header {
  margin: auto 19px 19px 19px;
  border-radius: 15px;
  box-shadow: -5px -2px 10px 0 #ffffff;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button {
  margin: auto 5px;
  border-radius: 4px;
  width: 82px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button img {
  margin: 4px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:first-of-type {
  background-color: #08d26f;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:first-of-type:focus, .view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:first-of-type:active {
  background-color: #08d26f;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:first-of-type img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:last-of-type {
  background-color: #d83025;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:last-of-type:focus, .view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:last-of-type:active {
  background-color: #d83025;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:focus, .view-profile-drawer .customer-profile-content .wallet-tab .wallet-header .wallet-balance__transaction-buttons button:active {
  color: #fff;
}

.view-profile-drawer .customer-profile-content .wallet-tab .wallet-header__subtitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.18;
  color: #01401c;
  padding: 0px 19px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs {
  -webkit-margin-start: 23px;
          margin-inline-start: 23px;
  -webkit-margin-end: 37px;
          margin-inline-end: 37px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar {
  text-align: center !important;
  border-bottom: none;
  margin: 10px 0px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav {
  border: 1px solid #b2b2b2;
  margin-bottom: 2px;
  border-radius: 10px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-ink-bar {
  display: none !important;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  width: 115px;
  margin: auto;
  padding: 9px 16px;
  color: #474747;
}

body.rtl .view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  -webkit-transform: scaleX(-1) !important;
          transform: scaleX(-1) !important;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab:first-of-type {
  border-top-left-radius: 10px;
  border-right: 1px solid #b2b2b2;
  border-bottom-left-radius: 10px;
}

body.rtl .view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab:first-of-type {
  border-top-left-radius: unset;
  border-right: unset;
  border-bottom-left-radius: unset;
  border-top-right-radius: 10px;
  border-left: 1px solid #b2b2b2;
  border-bottom-right-radius: 10px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab:last-of-type {
  border-top-right-radius: 10px;
  border-left: 1px solid #b2b2b2;
  border-bottom-right-radius: 10px;
}

body.rtl .view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab:last-of-type {
  border-top-left-radius: 10px;
  border-right: 1px solid #b2b2b2;
  border-bottom-left-radius: 10px;
  border-top-right-radius: unset;
  border-left: unset;
  border-bottom-right-radius: unset;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transaction-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: none;
  background-image: -webkit-linear-gradient(1deg, #01401c 0%, #2dbb54 153%);
  background-image: linear-gradient(89deg, #01401c 0%, #2dbb54 153%);
  color: #fff;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transactions {
  -webkit-margin-start: 14px;
          margin-inline-start: 14px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transactions .ant-timeline:not(:only-child) {
  border: none;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transactions .ant-timeline .transaction-date {
  -webkit-margin-start: -14px;
          margin-inline-start: -14px;
}

.view-profile-drawer .customer-profile-content .wallet-tab .transactions .ant-timeline .transaction-date::after {
  content: '';
  display: inline-block;
  height: 1px;
  width: calc(80% - 26px);
  background: #adadad;
}

.view-profile-drawer .ant-tabs {
  direction: ltr;
}

.view-profile-drawer .license-img {
  align-items: flex-start;
}

.view-profile-drawer .license-img img {
  width: 130px;
}

.view-profile-drawer .license-img img:first-of-type {
  margin-right: 10px;
}

.view-profile-drawer .license-img img:last-of-type {
  margin-left: 10px;
  width: 17px;
}

.view-profile-drawer .card-status-holder {
  display: flex;
  flex-direction: column;
}

.view-profile-drawer .card-status-holder > label {
  margin-bottom: 15px;
}

.view-profile-drawer .send-comment {
  display: flex;
  margin-top: 30px;
  padding: 0 20px;
  justify-content: flex-end;
}

.view-profile-drawer .send-comment button {
  -webkit-margin-start: 5px;
          margin-inline-start: 5px;
}

.view-profile-drawer .min-h-80 {
  min-height: 80px;
}

.view-profile-drawer .ant-row {
  overflow: hidden scroll;
}

.view-profile-drawer .details-wrapper div {
  min-height: 21px;
}

body.rtl .ant-drawer-left .ant-drawer-content-wrapper, body.rtl
.ant-drawer-right .ant-drawer-content-wrapper {
  right: unset;
  left: 0;
}

.ant-drawer-content-wrapper {
  -webkit-transition: width 0.7s ease 0s;
  transition: width 0.7s ease 0s;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0 !important;
}

.ant-drawer-mask {
  background-color: transparent !important;
}

.cancel-modal.transaction-flow.deduct .ant-modal-header .ant-modal-title {
  color: #d83025;
}

.cancel-modal.transaction-flow.add .ant-modal-header .ant-modal-title {
  color: #008539;
}

.cancel-modal.transaction-flow .ant-modal-header {
  border-bottom: none;
}

.cancel-modal.transaction-flow .ant-modal-header .ant-modal-title {
  font-size: 17px;
  font-weight: 600;
  display: flex;
}

.cancel-modal.transaction-flow .ant-modal-footer {
  border-top: none;
}

.cancel-modal.transaction-flow .ant-modal-body {
  padding: 0px 0px 20px 0px !important;
}

.cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair {
  font-family: Pridi;
  height: 46px;
  background-color: #e6e6e6;
  display: flex;
  -webkit-padding-start: 26px;
          padding-inline-start: 26px;
  align-items: center;
  position: relative;
}

.cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair .transaction-flow__flair__title {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.36;
}

.cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair .transaction-flow__flair__title span {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.31;
}

.cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair .transaction-flow__flair__title i {
  color: #4d4d4d;
  -webkit-margin-end: 5px;
          margin-inline-end: 5px;
}

@media only screen and (max-width: 767px) {
  .cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair .transaction-flow__flair__title {
    font-size: 11px;
    display: flex;
    max-width: 60%;
    align-items: center;
  }
  .cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair .transaction-flow__flair__title span {
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair time {
  position: absolute;
  right: 26px;
}

body.rtl .cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair time {
  left: 26px;
}

@media only screen and (max-width: 767px) {
  .cancel-modal.transaction-flow .ant-modal-body .transaction-flow__flair time {
    font-size: 10px;
  }
}

.cancel-modal.transaction-flow .ant-modal-body .transaction-flow__body {
  margin: 30px 0px;
  -webkit-padding-start: 44px;
          padding-inline-start: 44px;
  -webkit-padding-end: 44px;
          padding-inline-end: 44px;
}

.delivery_info_row {
  height: 80px;
  max-height: unset !important;
  min-height: unset !important;
  overflow: unset !important;
}

.delivery_info_row .ant-row {
  overflow: unset;
}

@media only screen and (max-width: 575px) {
  .delivery_info_row {
    height: 160px;
  }
}

.delivery_info_row.customer_card {
  height: 120px;
}

@media only screen and (max-width: 575px) {
  .delivery_info_row.customer_card {
    height: 200px;
  }
}

.delivery_info_row .ant-calendar-picker {
  width: 100%;
}

body.rtl .delivery_info_row .ant-input {
  text-align: right;
}

body.rtl .delivery_info_row .ant-input::-webkit-input-placeholder {
  text-align: right;
}

body.rtl .delivery_info_row .ant-input::-moz-placeholder {
  text-align: right;
}

body.rtl .delivery_info_row .ant-input::-ms-input-placeholder {
  text-align: right;
}

body.rtl .delivery_info_row .ant-input::placeholder {
  text-align: right;
}

.customer_charging_card {
  height: 81px;
  max-height: unset !important;
  min-height: unset !important;
  overflow: unset !important;
  margin: 13px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #eef7fb;
}

.customer_charging_card .customer_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.customer_charging_card .customer_col .header_word {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #9f9f9f;
}

.customer_charging_card .customer_col .value_word {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #62c2c9;
}

.customer_charging_card .customer_col button {
  width: 70px;
}

.add_customer_card_holder {
  display: flex;
  justify-content: flex-end;
  margin: 13px;
}

.add_edit_customer_card .ant-modal-close {
  color: #c30707;
}

.add_edit_customer_card .ant-modal-header, .add_edit_customer_card .ant-modal-footer {
  border: unset;
}

.add_edit_customer_card .ant-modal-footer {
  margin-top: 25px;
}

.add_edit_customer_card .ant-modal-body {
  min-height: 250px;
}

.add_edit_customer_card .ant-modal-title {
  color: #62c2c9;
  font-size: 17px;
  font-weight: 600;
}

.popup_img_modal_wrapper {
  position: relative;
}

.popup_img_modal_wrapper .licence_img {
  width: 130px !important;
  height: 130px !important;
  object-fit: cover;
  margin: 0px 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #000;
  position: relative;
}

.popup_img_modal_wrapper .download_img {
  position: absolute;
  bottom: 1px;
  right: 10px;
  background-color: white;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 3px 0px;
}

.popup_image_modal .ant-modal-body {
  padding: 5px;
}

.popup_image_modal .ant-modal-footer {
  padding: unset;
}

.popup_image_modal .ant-modal-close-x {
  color: #b10505;
}

body.rtl .content.card_details .ant-tabs-tab {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.charging__card--list {
  height: calc(100vh - 335px);
  overflow: auto;
}

.account-auth-wrapper {
  background: url(/static/media/bannerweb.060e6d76.png) no-repeat;
  background-size: cover;
}

.account-auth-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 100%;
}

.account-auth-wrapper .account-auth-content {
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 3%;
  width: 90%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-auth-wrapper .account-auth-content .auth-content-holder {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(255, 255, 255, 0.58);
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 580px;
  height: 75vh;
  z-index: 5;
}

.account-auth-wrapper .account-auth-content .auth-content-holder .have-account-holder {
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: normal;
  line-height: 0.77;
  color: #4e4e4e;
  margin-top: 30px;
}

.account-auth-wrapper .account-auth-content .auth-content-holder .have-account-holder button {
  font-size: 13px;
  font-weight: normal;
  line-height: 0.77;
  color: #16adb8;
  margin: 0;
}

@media only screen and (max-width: 991px) {
  .account-auth-wrapper .account-auth-content {
    width: 90%;
  }
  .account-auth-wrapper .account-auth-content .auth-content-holder {
    -webkit-transform: none !important;
            transform: none !important;
    min-height: auto;
    height: 86vh;
  }
  .account-auth-wrapper .account-auth-content .auth-content-holder > div {
    width: 100%;
    padding: 70px 50px;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 991px) and (max-width: 575px) {
  .account-auth-wrapper .account-auth-content .auth-content-holder > div {
    padding: 40px 25px;
    overflow: auto;
  }
}

@media only screen and (max-width: 991px) {
  .account-auth-wrapper .account-auth-content .auth-content-holder > div h1 {
    font-size: 25px;
    font-weight: bold;
    white-space: normal;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 0;
  }
  .account-auth-wrapper .account-auth-content .have-account-holder {
    display: flex !important;
  }
}

.account-auth-wrapper .signin-up-wrapper:after {
  content: "";
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 555;
  pointer-events: none;
}

@media only screen and (max-width: 991px) {
  .account-auth-wrapper .signin-up-wrapper:after {
    background: none;
  }
}

body.rtl .account-auth-wrapper .signin-up-wrapper:after {
  right: 0;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .login-form-forgot-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.account-auth-wrapper .signin-up-wrapper .login-form-forgot-holder > label {
  padding: 0;
  font-size: 16px;
  color: #000000;
  line-height: 1.19;
  font-weight: normal;
}

.account-auth-wrapper .signin-up-wrapper .login-form-forgot-holder > label .ant-checkbox-inner {
  border-radius: 4px;
  border: solid 1px #000000;
}

@media only screen and (max-width: 767px) {
  .account-auth-wrapper .signin-up-wrapper .login-form-forgot-holder {
    flex-direction: column;
    margin: 15px 0;
  }
}

.account-auth-wrapper .signin-up-wrapper .login-form-forgot {
  font-size: 16px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin: 0;
  text-decoration: underline;
}

.account-auth-wrapper .signin-up-wrapper .form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 85px;
  position: absolute;
  top: 0;
  height: 100%;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.account-auth-wrapper .signin-up-wrapper .form-container form {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.account-auth-wrapper .signin-up-wrapper .form-container form button {
  font-size: 19px;
  margin-top: 50px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .tel-wrapper .react-tel-input input {
  text-indent: 0px !important;
  padding-top: 16px !important;
}

.account-auth-wrapper .signin-up-wrapper .form-container h1 {
  margin-bottom: 20px;
  font-size: 30px;
  padding-top: 10px;
  font-weight: bold;
  line-height: 0.61;
  color: #16adb8;
  white-space: nowrap;
}

.account-auth-wrapper .signin-up-wrapper .form-container .social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item {
  margin-bottom: 20px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item .ant-calendar-picker {
  width: 100%;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  font-size: 20px;
  right: auto;
  left: 12px;
  display: flex;
  align-items: center;
  width: 20px;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  left: auto;
  right: 12px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 40px !important;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item input {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper {
  border-bottom: 1px solid #dadce0 !important;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .anticon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  margin-top: 2px;
  bottom: 0;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .anticon svg {
  display: none;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .anticon:after {
  content: url(/static/media/gender.42321d49.svg);
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group {
  width: 100%;
  text-align: left;
  padding-left: 45px;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group {
  text-align: right;
  padding-right: 45px;
  padding-left: 0;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  top: 3.2px;
  left: 3.2px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430 !important;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430;
  display: inline-flex;
  align-items: center;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border: solid 0.8px #4e4d4d;
  width: 16px;
  height: 16px;
}

.account-auth-wrapper .signin-up-wrapper .form-container .ant-form-item.gender-wrapper .ant-form-explain {
  position: absolute;
}

.account-auth-wrapper .signin-up-wrapper .sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.account-auth-wrapper .signin-up-wrapper .sign-in-container .password-input-holder .anticon svg {
  display: none;
}

.account-auth-wrapper .signin-up-wrapper .sign-in-container .password-input-holder .anticon:after {
  content: url(/static/media/password.a3e7763a.svg);
}

body.rtl .account-auth-wrapper .signin-up-wrapper .sign-in-container {
  right: 0;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .sign-up-container {
  right: 0;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .sign-up-container .password-input-holder .anticon:not(.anticon-eye-invisible):not(.anticon-eye) svg {
  display: none;
}

.account-auth-wrapper .signin-up-wrapper .sign-up-container .password-input-holder .anticon:not(.anticon-eye-invisible):not(.anticon-eye):after {
  content: url(/static/media/password.a3e7763a.svg);
}

@media only screen and (max-width: 767px) {
  .account-auth-wrapper .signin-up-wrapper .sign-up-container .gender-wrapper + button {
    margin-top: 30px;
  }
}

.account-auth-wrapper .signin-up-wrapper .overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  z-index: 10;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-container {
  right: 50%;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container h1 {
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.64px;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container p {
  font-size: 19px;
  font-weight: normal;
  line-height: 1.53;
  text-align: center;
  color: #ffffff;
  padding-top: 25px;
  padding-bottom: 50px;
  width: 75%;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container button {
  text-transform: none;
  -webkit-transition: -webkit-transform 80ms ease-in;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container button:active {
  -webkit-transform: scale(0.92);
          transform: scale(0.92);
}

.account-auth-wrapper .signin-up-wrapper .overlay-container button:focus {
  outline: none;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container button.ghost {
  background-color: transparent;
  border-color: #ffffff;
  height: 52px;
  border-radius: 26px;
  border: solid 2px #ffffff;
  padding: 5px 75px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-container button.ghost {
  line-height: 20px;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container .overlay {
  background: #16adb8;
  background-image: -webkit-linear-gradient(223deg, #65cad1 84%, #16adb8 19%);
  background-image: linear-gradient(227deg, #65cad1 84%, #16adb8 19%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-container .overlay {
  right: -100%;
  left: auto;
}

.account-auth-wrapper .signin-up-wrapper .overlay-container .overlay .overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .account-auth-wrapper .signin-up-wrapper .overlay-container {
    display: none;
  }
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-in-container {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  z-index: 1;
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-in-container {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-up-container {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: show 0.6s forwards;
          animation: show 0.6s forwards;
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-up-container {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

@media only screen and (max-width: 991px) {
  .account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-up-container {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation: none;
            animation: none;
  }
  body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .sign-up-container {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-container {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-container {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-left {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-left {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-right {
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
}

body.rtl .account-auth-wrapper .signin-up-wrapper.right-panel-active .overlay-right {
  -webkit-transform: translateX(-20%);
          transform: translateX(-20%);
}

.account-auth-wrapper .signin-up-wrapper .overlay-left {
  -webkit-transform: translateX(-20%);
          transform: translateX(-20%);
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-left {
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
}

.account-auth-wrapper .signin-up-wrapper .overlay-right {
  right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

body.rtl .account-auth-wrapper .signin-up-wrapper .overlay-right {
  left: 0;
  right: auto;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.account-auth-wrapper .signin-up-wrapper .social-container {
  margin: 20px 0;
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
    z-index: 1;
  }
  50% {
    opacity: 0;
    z-index: 1;
  }
  51% {
    opacity: 1;
    z-index: 5;
  }
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    z-index: 1;
  }
  50% {
    opacity: 0;
    z-index: 1;
  }
  51% {
    opacity: 1;
    z-index: 5;
  }
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.register-info-wrapper {
  padding-top: 100px;
  padding-bottom: 40px;
}

.register-info-wrapper * {
  font-family: Pridi;
}

.register-info-wrapper .ant-input:-moz-placeholder {
  font-weight: 400;
  font-size: 17px;
}

.register-info-wrapper .ant-input::-moz-placeholder {
  font-weight: 400;
  font-size: 17px;
}

.register-info-wrapper .ant-input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 17px;
}

.register-info-wrapper .ant-input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 17px;
}

.register-info-wrapper .ant-select-selection__placeholder {
  font-weight: 400 !important;
  font-size: 17px !important;
}

.register-info-wrapper .content {
  width: 40%;
  margin: auto;
}

.register-info-wrapper .content h2 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -1.25px;
  color: #16adb8;
  text-align: center;
  margin-bottom: 20px;
}

.register-info-wrapper .content h4 {
  font-size: 19px;
  font-weight: normal;
  line-height: 1.53;
  text-align: center;
  color: #716e6e;
  padding-bottom: 10px;
}

.register-info-wrapper .content .form-holder {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1)::after {
  content: url(/static/media/car.262f767f.svg);
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(1)::after {
  top: 22px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2)::after {
  content: url(/static/media/cartype.100bb937.svg);
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(2)::after {
  top: 22px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3)::after {
  content: url(/static/media/battery.bc5ce98e.svg);
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(3)::after {
  top: 22px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4)::after {
  content: url(/static/media/license.3af11921.svg);
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(4)::after {
  top: 22px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered,
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.register-info-wrapper .content .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5)::after {
  content: url(/static/media/address.35728844.svg);
  position: absolute;
  top: 23px;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5)::after {
  top: 27px !important;
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5) .ant-form-extra {
  font-size: 14px;
  font-weight: normal;
  padding: 0 30px;
  color: #5d71aa;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5) .ant-form-extra {
    padding: 0;
  }
}

.register-info-wrapper .content .form-holder > .ant-form-item:nth-child(5)::after {
  top: 22px !important;
}

.register-info-wrapper .btns-action-holder {
  padding: 50px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.register-info-wrapper .btns-action-holder a {
  font-size: 21px;
  font-weight: normal;
  color: #575758;
}

@media only screen and (max-width: 767px) {
  .register-info-wrapper .btns-action-holder a {
    font-size: 15px;
    color: #4e4e4e;
  }
}

@media only screen and (max-width: 991px) {
  .register-info-wrapper .content {
    width: 95%;
  }
  .register-info-wrapper .content h2 {
    font-size: 21px;
    font-weight: 500;
  }
  .register-info-wrapper .content h4 {
    font-size: 15px;
    font-weight: normal;
  }
  .register-info-wrapper .content .form-holder {
    padding-left: 25px;
    padding-right: 25px;
  }
  .register-info-wrapper .btns-action-holder {
    padding: 30px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .register-info-wrapper .btns-action-holder button {
    margin: 0;
    margin-bottom: 15px;
  }
}

.scale-down, .home-content .statistics-wrapper .content, .home-content .charging-card-wrapper .content, .home-content .work-wrapper .content .steps-wrapper .step-holder .content, .home-content .chargings-article-wrapper .content {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.scale-down-9, .account-auth-wrapper .account-auth-content .auth-content-holder, .home-content .work-wrapper .content {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.home-content .hero-wrapper {
  display: flex;
  padding-top: 25px;
  margin-top: 70px;
  position: relative;
  height: calc(100vh - 70px);
  -webkit-transform-origin: left;
          transform-origin: left;
}

.home-content .hero-wrapper > div {
  width: 50%;
}

.home-content .hero-wrapper > div:first-of-type img {
  position: absolute;
  top: 0;
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-name: flowingMove;
          animation-name: flowingMove;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}

.home-content .hero-wrapper > div:first-of-type .content {
  width: 70%;
  margin-left: auto;
  margin-top: 210px;
  position: relative;
}

@media only screen and (max-width: 1247px) {
  .home-content .hero-wrapper > div:first-of-type .content {
    margin-top: 180px !important;
  }
}

.home-content .hero-wrapper > div:first-of-type .content h2 {
  font-size: 23px;
  font-weight: 600;
  line-height: 1.55;
  color: #16adb8;
}

@media only screen and (max-width: 1023px) {
  .home-content .hero-wrapper > div:first-of-type .content h2 {
    font-size: 25px !important;
    margin-bottom: 10px;
  }
}

.home-content .hero-wrapper > div:first-of-type .content h3 {
  font-size: 25px;
  font-weight: 600;
  line-height: 1.54;
  color: #242430;
  margin-top: -10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1023px) {
  .home-content .hero-wrapper > div:first-of-type .content h3 {
    font-size: 20px !important;
  }
}

.home-content .hero-wrapper > div:first-of-type .content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  padding-bottom: 41px;
  margin-top: 10px;
  width: 90%;
}

.home-content .hero-wrapper > div:first-of-type .content p + div {
  margin: 0 !important;
}

.home-content .hero-wrapper > div:last-of-type {
  display: flex;
  align-items: flex-end;
}

.home-content .hero-wrapper > div:last-of-type img {
  position: relative;
  margin-left: 120%;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: carMove;
          animation-name: carMove;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  left: 30px;
  min-height: 400px;
}

.home-content .hero-wrapper > div:last-of-type img:hover {
  -webkit-transform: translateX(25px);
          transform: translateX(25px);
}

@media only screen and (max-width: 991px) {
  .home-content .hero-wrapper > div:last-of-type img {
    -webkit-animation-delay: unset !important;
            animation-delay: unset !important;
  }
}

@media only screen and (max-width: 991px) {
  .home-content .hero-wrapper {
    flex-direction: column-reverse;
    -webkit-transform-origin: unset;
            transform-origin: unset;
    justify-content: center;
    height: auto !important;
  }
  .home-content .hero-wrapper > div {
    width: 100% !important;
  }
  .home-content .hero-wrapper > div:first-of-type .content {
    text-align: center !important;
    width: 100%;
    margin-top: 50px !important;
  }
  .home-content .hero-wrapper > div:first-of-type .content h2 {
    font-size: 32px;
    letter-spacing: 0.42px;
  }
  .home-content .hero-wrapper > div:first-of-type .content h3 {
    font-size: 32px;
    letter-spacing: -0.16px;
  }
  .home-content .hero-wrapper > div:first-of-type .content p {
    margin: auto;
    font-size: 15px;
    line-height: 1.6;
  }
  .home-content .hero-wrapper > div:first-of-type img {
    display: none;
  }
  .home-content .hero-wrapper > div:last-of-type img {
    margin: 0;
    left: 0;
    min-height: auto;
    width: 100%;
  }
}

body.rtl .home-content .hero-wrapper > div {
  width: 50%;
}

body.rtl .home-content .hero-wrapper > div:first-of-type img {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

body.rtl .home-content .hero-wrapper > div:first-of-type .content {
  margin-left: 0;
  margin-right: auto;
}

@media only screen and (max-width: 991px) {
  body.rtl .home-content .hero-wrapper > div:first-of-type .content {
    text-align: center !important;
  }
}

body.rtl .home-content .hero-wrapper > div:last-of-type img {
  right: 30px;
  left: auto;
  margin-right: 120%;
  margin-left: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  -webkit-animation-name: carMoveRtl;
          animation-name: carMoveRtl;
}

@media only screen and (max-width: 767px) {
  body.rtl .home-content .hero-wrapper > div:last-of-type img {
    right: 3px !important;
  }
}

.home-content .statistics-wrapper {
  background: url(/static/media/statistics-bg.f8d7db35.png) no-repeat;
  background-size: cover;
  height: 450px;
  background-attachment: fixed;
  background-position: center top;
}

body.rtl .home-content .statistics-wrapper {
  background-position: top left;
  -webkit-transition: none !important;
  transition: none !important;
  -webkit-animation: none !important;
          animation: none !important;
}

.home-content .statistics-wrapper .content {
  color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-content .statistics-wrapper .content > div {
  width: 45%;
}

.home-content .statistics-wrapper .content > div h2 {
  font-size: 54px;
  font-weight: 600;
  line-height: 1.54;
  color: #ffffff;
}

.home-content .statistics-wrapper .content > div h2 p {
  display: inline-block;
  font-size: 54px;
  font-weight: 500;
  line-height: 1.54;
  letter-spacing: 3.56px;
  color: #ffffff;
  min-width: 160px;
}

.home-content .statistics-wrapper .content > div h2 p.save-money {
  min-width: 90px;
}

.home-content .statistics-wrapper .content > div h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.54;
  text-transform: none;
  color: #16adb8;
  margin-top: -5px;
  padding-bottom: 20px;
}

.home-content .statistics-wrapper .content > div p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
}

.home-content .statistics-wrapper .content-statistic {
  width: 70%;
  min-width: 440px;
}

.home-content .statistics-wrapper .content-statistic h2 {
  display: flex;
  flex-direction: column;
  font-size: 51px;
  font-weight: 600;
  line-height: 1.22;
}

.home-content .statistics-wrapper .content-statistic h2 span {
  margin-bottom: -13px;
}

@media only screen and (max-width: 379px) {
  .home-content .statistics-wrapper .content-statistic h2 {
    font-size: 38px !important;
  }
}

.home-content .statistics-wrapper .content-statistic p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.home-content .statistics-wrapper .content-statistic button {
  margin-top: 24px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(16%, #16adb8), color-stop(115%, #5db92c));
  background-image: -webkit-linear-gradient(left, #16adb8 16%, #5db92c 115%);
  background-image: linear-gradient(to right, #16adb8 16%, #5db92c 115%);
}

@media only screen and (max-width: 991px) {
  .home-content .statistics-wrapper .content-statistic {
    width: 100% !important;
    min-width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .home-content .statistics-wrapper {
    height: 100vh;
  }
  .home-content .statistics-wrapper .content {
    flex-direction: column;
    justify-content: center;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    padding: 0 20px;
  }
  .home-content .statistics-wrapper .content > div {
    width: 100%;
    text-align: center !important;
  }
  body.rtl .home-content .statistics-wrapper .content > div {
    text-align: center !important;
  }
  .home-content .statistics-wrapper .content > div img {
    width: 350px;
  }
  .home-content .statistics-wrapper .content > div h2 {
    font-size: 45px;
  }
  .home-content .statistics-wrapper .content > div h2 p {
    font-size: 45px;
  }
  .home-content .statistics-wrapper .content > div h4 {
    font-size: 20px;
  }
  .home-content .statistics-wrapper .content > div h4 + p {
    font-size: 14px;
    line-height: 1.71;
  }
}

.home-content .charging-card-wrapper {
  height: 350px;
  background-color: #ffffff;
}

.home-content .charging-card-wrapper .content {
  width: 100%;
  max-width: 1300px;
  height: 520px;
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.27);
  background-color: #ffffff;
  display: flex;
  margin: auto;
  position: relative;
  top: -70px;
  bottom: -70px;
}

.home-content .charging-card-wrapper .content > div:first-of-type {
  width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 60px;
}

.home-content .charging-card-wrapper .content > div:first-of-type h2 {
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  color: #242430;
}

.home-content .charging-card-wrapper .content > div:first-of-type h2 span {
  display: block;
  color: #16adb8;
}

.home-content .charging-card-wrapper .content > div:first-of-type p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.49;
  text-align: left;
  color: #242430;
  padding-top: 20px;
  padding-bottom: 30px;
}

body.rtl .home-content .charging-card-wrapper .content > div:first-of-type p {
  text-align: right;
}

.home-content .charging-card-wrapper .content > div:first-of-type button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.home-content .charging-card-wrapper .content > div:last-of-type {
  width: 60%;
}

.home-content .charging-card-wrapper .content > div:last-of-type img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .home-content .charging-card-wrapper {
    height: 90vh;
  }
  .home-content .charging-card-wrapper .content {
    flex-direction: column;
    top: 0;
    bottom: 0;
    height: 100%;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
  .home-content .charging-card-wrapper .content > div {
    width: 100% !important;
    height: 50%;
  }
  .home-content .charging-card-wrapper .content > div:first-of-type {
    padding: 30px;
    text-align: center;
    align-items: center;
  }
  .home-content .charging-card-wrapper .content > div:first-of-type h2 {
    font-size: 32px;
    text-align: center;
    line-height: 1.22;
    letter-spacing: 0.8px;
  }
  .home-content .charging-card-wrapper .content > div:first-of-type p {
    font-size: 14px;
    line-height: 1.36;
    text-align: center;
  }
}

.home-content .work-wrapper {
  min-height: 100vh;
  background-color: #eaefe8;
  padding: 10% 6% 0 6%;
  height: auto;
}

.home-content .work-wrapper.animation-active .slide-right {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  visibility: visible;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.home-content .work-wrapper.animation-active .slide-left {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  visibility: visible;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.home-content .work-wrapper.animation-active .slide-top {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.home-content .work-wrapper.animation-active .slide-bottom {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.home-content .work-wrapper.animation-active .rotate-y {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
}

.home-content .work-wrapper.animation-active .charger-img-holder > div {
  top: 0 !important;
}

.home-content .work-wrapper .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-content .work-wrapper .content h2 {
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  color: #242430;
  text-align: center;
  padding-bottom: 30px;
}

.home-content .work-wrapper .content h2 span {
  display: inline-block;
  margin-top: -16px;
  font-weight: 600;
  color: #16adb8;
  line-height: 1;
}

body.rtl .home-content .work-wrapper .content h2 span {
  margin-top: 12px;
}

@media only screen and (max-width: 575px) {
  .home-content .work-wrapper .content h2 span {
    margin-top: -6px;
  }
  body.rtl .home-content .work-wrapper .content h2 span {
    margin-top: -1px !important;
  }
}

.home-content .work-wrapper .content .steps-wrapper {
  display: flex;
  justify-content: space-between;
}

.home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.55;
  color: #16adb8;
}

@media only screen and (max-width: 575px) {
  .home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 {
    font-size: 20px;
  }
}

.home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 span {
  width: 32px;
  height: 32px;
  display: inline-flex;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.55;
  color: #242430;
  border-radius: 50%;
  margin-right: 16px;
}

body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .content h5 span {
  margin-left: 16px;
  margin-right: 0;
}

.home-content .work-wrapper .content .steps-wrapper .step-holder .content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  margin-top: 10px;
  margin-right: 130px;
}

body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .content p {
  margin-left: 130px;
  margin-right: 0;
  line-height: 1.6;
}

@media only screen and (max-width: 575px) {
  body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .content p {
    margin-left: 0;
  }
}

.home-content .work-wrapper .content .steps-wrapper .step-holder .img-holder {
  display: flex;
  justify-content: flex-end;
}

body.rtl .home-content .work-wrapper .content .steps-wrapper .step-holder .img-holder {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  justify-content: flex-start;
}

.home-content .work-wrapper .content .steps-wrapper .step-holder img {
  position: relative;
  top: -60px;
}

@media only screen and (max-width: 575px) {
  .home-content .work-wrapper .content .steps-wrapper .step-holder img {
    top: 0;
    width: 90px;
    min-width: 90px;
    max-height: 90px;
    object-fit: content;
  }
}

.home-content .work-wrapper .content .steps-wrapper > div:not(.charger-img-holder) {
  width: 35%;
  margin-top: 60px;
}

.home-content .work-wrapper .content .steps-wrapper > div.charger-img-holder {
  width: 322px;
  min-width: 322px;
  max-width: 322px;
  min-height: 596px;
  max-height: 596px;
  position: relative;
  overflow: hidden;
  -webkit-transform: scale(0.88);
          transform: scale(0.88);
}

.home-content .work-wrapper .content .steps-wrapper > div.charger-img-holder > div {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-size: cover;
  -webkit-transition: all ease-in-out 0.6s;
  transition: all ease-in-out 0.6s;
}

.home-content .work-wrapper .content .steps-wrapper > div.charger-img-holder > div:first-of-type {
  left: 0;
  top: 100%;
  background-position: 0%;
  background-image: url(/static/media/Charger.c56693e4.png);
}

.home-content .work-wrapper .content .steps-wrapper > div.charger-img-holder > div:last-of-type {
  left: 50%;
  top: -100%;
  background-position: -100%;
  background-image: url(/static/media/Charger.c56693e4.png);
}

.home-content .work-wrapper .content .steps-wrapper > div:first-of-type {
  padding-right: 30px;
}

.home-content .work-wrapper .content .steps-wrapper > div:last-of-type {
  padding-left: 30px;
}

@media only screen and (max-width: 991px) {
  .home-content .work-wrapper {
    min-height: 75vh;
  }
  .home-content .work-wrapper .steps-wrapper > div {
    width: 50% !important;
    margin-top: 30px !important;
  }
  .home-content .work-wrapper .charger-img-holder {
    display: none !important;
  }
}

@media only screen and (max-width: 575px) {
  .home-content .work-wrapper .content {
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
  .home-content .work-wrapper .content h2 {
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-content .work-wrapper .content h2 span {
    margin-right: 8px;
  }
  body.rtl .home-content .work-wrapper .content h2 span {
    margin-right: 0;
    margin-left: 8px;
  }
  .home-content .work-wrapper .content h5,
  .home-content .work-wrapper .content p {
    font-weight: 400 !important;
    padding-top: 10px;
  }
  .home-content .work-wrapper .content .steps-wrapper {
    flex-direction: column;
  }
  .home-content .work-wrapper .content .steps-wrapper > div {
    width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
  }
  .home-content .work-wrapper .content .steps-wrapper .step-holder {
    margin-bottom: 5px !important;
    display: flex;
    align-items: center;
  }
  .home-content .work-wrapper .content .steps-wrapper .step-holder p {
    margin-right: 0 !important;
  }
}

.home-content .chargings-article-wrapper {
  padding: 90px 75px 90px 40px;
  background-color: #ffffff;
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .home-content .chargings-article-wrapper {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .home-content .chargings-article-wrapper {
    padding: 30px 0 !important;
  }
}

.home-content .chargings-article-wrapper .content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-content .chargings-article-wrapper .content > div {
  width: 47%;
  will-change: transform, opacity;
}

.home-content .chargings-article-wrapper .content > div:first-of-type {
  position: relative;
  height: auto;
  max-width: 650px;
}

.home-content .chargings-article-wrapper .content > div:first-of-type img {
  max-width: 100%;
}

.home-content .chargings-article-wrapper .content > div:first-of-type img.img-stay {
  position: absolute;
  left: 0;
  -webkit-animation-name: slideimgsAnimation-left;
          animation-name: slideimgsAnimation-left;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}

@-webkit-keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    z-index: 10;
  }
  50% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    left: -140%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
}

@keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    z-index: 10;
  }
  50% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    left: -140%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
}

body.rtl .home-content .chargings-article-wrapper .content > div:first-of-type img.img-stay {
  right: 0;
  left: auto;
  -webkit-animation-name: slideimgsAnimation-right;
          animation-name: slideimgsAnimation-right;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}

@-webkit-keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    z-index: 10;
  }
  50% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    right: -140%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    z-index: 10;
  }
  50% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    right: -140%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

.home-content .chargings-article-wrapper .content > div:first-of-type img.img-move {
  position: absolute;
  left: 0;
  z-index: 5;
  -webkit-animation-name: slideimgsAnimation-left;
          animation-name: slideimgsAnimation-left;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

@keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    left: -140%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    z-index: 44;
  }
}

body.rtl .home-content .chargings-article-wrapper .content > div:first-of-type img.img-move {
  -webkit-animation-name: slideimgsAnimation-right;
          animation-name: slideimgsAnimation-right;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  right: 0;
  left: auto;
}

@keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    right: -140%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    z-index: 44;
  }
}

.home-content .chargings-article-wrapper .content > div:last-of-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 120px;
}

@media only screen and (max-width: 991px) {
  .home-content .chargings-article-wrapper .content > div:last-of-type {
    margin: 0 !important;
  }
}

body.rtl .home-content .chargings-article-wrapper .content > div:last-of-type {
  margin-right: 120px;
  margin-left: 0;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article {
  margin-bottom: 20px;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h2 {
  font-size: 54px;
  font-weight: 600;
  line-height: 1.54;
  color: #242430;
  white-space: nowrap;
}

@media only screen and (max-width: 1199px) {
  .home-content .chargings-article-wrapper .content > div:last-of-type article h2 {
    font-size: 35px;
  }
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h2 span {
  display: inline-block;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.54;
  text-align: left;
  color: #16adb8;
  width: 80px;
}

@media only screen and (max-width: 1199px) {
  .home-content .chargings-article-wrapper .content > div:last-of-type article h2 span {
    font-size: 47px;
  }
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h2 span.customer-counter {
  width: 115px;
}

body.rtl .home-content .chargings-article-wrapper .content > div:last-of-type article h2 span {
  text-align: right;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h2 i {
  font-size: 91px;
  font-weight: 300;
  line-height: 1.55;
  color: #242430;
}

@media only screen and (max-width: 1199px) {
  .home-content .chargings-article-wrapper .content > div:last-of-type article h2 i {
    font-size: 65px;
  }
}

.home-content .chargings-article-wrapper .content > div:last-of-type article h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.54;
  color: #16adb8;
}

.home-content .chargings-article-wrapper .content > div:last-of-type article p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  color: #242430;
}

@media only screen and (max-width: 1023px) {
  .home-content .chargings-article-wrapper .content .centerd-slider {
    display: block !important;
  }
}

@media only screen and (max-width: 991px) {
  .home-content .chargings-article-wrapper .content .centerd-slider {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .home-content .chargings-article-wrapper {
    padding: 80px 40px;
    background: url(/static/media/bannerweb.060e6d76.png) no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
  }
  .home-content .chargings-article-wrapper:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.82);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .home-content .chargings-article-wrapper .content {
    -webkit-transform: none;
            transform: none;
  }
  .home-content .chargings-article-wrapper .content > div {
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .home-content .chargings-article-wrapper .content > div:first-of-type {
    display: none;
  }
  .home-content .chargings-article-wrapper .content > div article {
    text-align: center;
  }
  .home-content .chargings-article-wrapper .content > div article h2 {
    font-size: 25px !important;
    color: #FFFF !important;
    white-space: normal !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-content .chargings-article-wrapper .content > div article h2 span {
    font-size: 25px !important;
    color: #FFFF !important;
    width: 70px !important;
  }
  .home-content .chargings-article-wrapper .content > div article h2 i {
    display: none !important;
  }
  .home-content .chargings-article-wrapper .content > div article h4 {
    font-size: 17px !important;
  }
  .home-content .chargings-article-wrapper .content > div article p {
    text-align: center !important;
    padding-top: 20px;
  }
}

body.rtl .home-content .chargings-article-wrapper {
  padding: 90px 40px 90px 75px;
}

.home-content .nearest-station-wrapper {
  min-height: 65vh;
  height: 500px;
}

.home-content .nearest-station-wrapper .content {
  display: flex;
  height: 100%;
}

.home-content .nearest-station-wrapper .content > div {
  width: 50%;
}

.home-content .nearest-station-wrapper .content .station-info-holder {
  padding: 50px 90px 0 90px;
  background: url(/static/media/nearest-station-bg.44d839b8.svg) no-repeat;
  box-shadow: 3px -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #eaefe8;
  position: relative;
}

.home-content .nearest-station-wrapper .content .station-info-holder h2 {
  margin-top: 80px;
  font-size: 43px;
  font-weight: 600;
  line-height: 1.53;
  color: #242430;
  text-align: center;
}

.home-content .nearest-station-wrapper .content .station-info-holder p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  color: #242430;
  width: 80%;
  margin: auto;
  padding-top: 10px;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 30px;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header h4 {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.52;
  color: #454545;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  border: solid 1px #707070;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header .ant-checkbox-wrapper .ant-checkbox + span {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  text-align: left;
  color: #454545;
}

body.rtl .home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .set-location-header .ant-checkbox-wrapper .ant-checkbox + span {
  text-align: right;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input {
  height: 40px;
  padding-top: 0 !important;
  font-size: 16px;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input:-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #b4b4b4;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input::-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #b4b4b4;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #b4b4b4;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .ant-input-search .ant-input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #b4b4b4;
}

.home-content .nearest-station-wrapper .content .station-info-holder .set-location-holder .anticon-search {
  display: none;
}

.home-content .nearest-station-wrapper .content .map-holder {
  position: relative;
}

.home-content .nearest-station-wrapper .content .map-holder iframe,
.home-content .nearest-station-wrapper .content .map-holder .mapouter,
.home-content .nearest-station-wrapper .content .map-holder .gmap_canvas {
  width: 100% !important;
  height: 100% !important;
}

.home-content .nearest-station-wrapper .find-station-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.52;
  text-align: center;
  color: #ffffff;
  height: 70px;
  border-radius: 44px;
  background-color: #16adb8;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 35px;
}

@media only screen and (max-width: 991px) {
  .home-content .nearest-station-wrapper {
    min-height: auto;
    height: auto;
  }
  .home-content .nearest-station-wrapper .find-station-btn {
    width: 100%;
    position: relative;
    font-size: 16px;
    font-weight: normal;
    height: 60px;
    border-radius: 0;
    margin-bottom: 0;
    border: 0;
  }
  .home-content .nearest-station-wrapper .content {
    flex-direction: column;
  }
  .home-content .nearest-station-wrapper .content > div {
    width: 100%;
  }
  .home-content .nearest-station-wrapper .content > div:first-of-type {
    padding: 25px 30px 60px 30px;
  }
  .home-content .nearest-station-wrapper .content > div:first-of-type h2 {
    font-size: 32px;
    font-weight: 500;
  }
  .home-content .nearest-station-wrapper .content > div:first-of-type p {
    font-size: 14px;
    font-weight: normal;
    width: 100%;
  }
  .home-content .nearest-station-wrapper .content > div:first-of-type .set-location-header {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .home-content .nearest-station-wrapper .content > div:last-of-type {
    min-height: 400px;
    height: 400px;
  }
}

.about-us-wrapper .about-us-content .hero-wrapper {
  display: flex;
  align-items: center;
  padding-top: 25px;
  position: relative;
  height: calc(100vh - 70px);
  min-height: 500px;
  overflow: hidden;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1) {
  width: 27%;
  align-self: flex-end;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1) .img-holder {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-animation: move-up 1.6s ease 0.8s 1 forwards;
          animation: move-up 1.6s ease 0.8s 1 forwards;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1) .img-holder img {
  max-width: 100%;
  height: auto;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1) .img-holder img {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) {
  width: 50%;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h2 {
  font-size: 44px;
  font-weight: 600;
  line-height: 1.55;
  text-align: left;
  color: #16adb8;
  display: flex;
  align-items: center;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h2 span {
  font-size: 60px;
  margin: 0 4px;
  margin-top: -10px;
}

@media only screen and (max-width: 575px) {
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h2 span {
    font-size: 48px;
    margin-top: -6px;
  }
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h2 {
  text-align: right;
  direction: ltr;
  justify-content: flex-end;
  display: flex;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.57;
  text-align: left;
  color: #242430;
  padding-top: 20px;
  padding-bottom: 20px;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h5 {
  text-align: right;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h5 p {
  display: inline-block;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.49;
  text-align: left;
  color: #16adb8;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) h5 p {
  text-align: right;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder {
  right: -110%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: relative;
  -webkit-animation: rotate-left 0.7s ease 0.5s 1 forwards;
          animation: rotate-left 0.7s ease 0.5s 1 forwards;
  -webkit-transition: margin-bottom ease-in-out 1s;
  transition: margin-bottom ease-in-out 1s;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder {
  left: -110%;
  right: auto;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  -webkit-animation-name: rotate-left-rtl;
          animation-name: rotate-left-rtl;
}

body.rtl .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder img {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder img {
  max-width: 100%;
  height: auto;
}

.about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) .img-holder:hover {
  -webkit-transition: margin-bottom ease-in-out 0.3s;
  transition: margin-bottom ease-in-out 0.3s;
  margin-bottom: -40px;
}

@media only screen and (max-width: 991px) {
  .about-us-wrapper .about-us-content .hero-wrapper {
    -webkit-transform: none;
            transform: none;
    padding: 30px;
    position: relative;
    height: calc(100vh - 180px);
    padding-top: 90px;
    align-items: flex-start;
  }
  .about-us-wrapper .about-us-content .hero-wrapper::after {
    content: "";
    background: url(/static/media/businessline-chargers.b29074b4.png) no-repeat;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    width: 155px;
    height: 377px;
    background-size: cover;
    pointer-events: none;
    zoom: 0.9;
  }
  .about-us-wrapper .about-us-content .hero-wrapper::before {
    content: "";
    background: url(/static/media/mission-bg-leaves.2d1b68f4.png) no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    width: 170px;
    height: 123px;
    background-size: cover;
    pointer-events: none;
    zoom: 0.9;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(1), .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(3) {
    display: none;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) {
    width: 100% !important;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) article {
    text-align: center;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) article h2 {
    font-size: 32px;
    font-weight: 600;
    text-align: center !important;
  }
  .about-us-wrapper .about-us-content .hero-wrapper > div:nth-child(2) article h5 {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    line-height: 1.6;
  }
}

@media only screen and (max-width: 991px) and (max-width: 575px) {
  .about-us-wrapper .about-us-content .hero-wrapper {
    padding-top: 90px;
  }
}

.about-us-wrapper .about-us-content .our-stations-wrapper {
  height: 100vh;
  background: url(/static/media/our-stations-bg.25849c28.png) no-repeat;
  background-size: cover;
  background-position: center left;
  background-attachment: fixed;
  overflow: hidden;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .centerd-slider {
  max-width: 650px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.about-us-wrapper .about-us-content .our-stations-wrapper .centerd-slider .carousel-slider {
  overflow: unset !important;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .centerd-slider .carousel-slider .control-dots {
  bottom: -35px !important;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .centerd-slider .carousel-slider .control-dots .dot {
  background: #16adb8 !important;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .centerd-slider .slick-dots {
  top: 20px;
}

@media only screen and (max-width: 1300px) {
  .about-us-wrapper .about-us-content .our-stations-wrapper .centerd-slider .slick-dots {
    top: -30px;
  }
}

@media only screen and (max-width: 1200px) {
  .about-us-wrapper .about-us-content .our-stations-wrapper .centerd-slider .slick-dots {
    top: -70px;
  }
}

@media only screen and (max-width: 1100px) {
  .about-us-wrapper .about-us-content .our-stations-wrapper .centerd-slider .slick-dots {
    top: -110px;
  }
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.76);
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div {
  width: 47%;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type h2 {
  font-size: 47px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  text-align: center;
  color: #16adb8;
  margin-bottom: 35px;
  text-transform: none;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type h4 {
  width: 81%;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  text-align: center;
  color: #ffffff;
  text-transform: none;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  will-change: transform;
  margin-right: 10%;
}

body.rtl .about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider {
  margin-left: 10%;
  margin-right: unset;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img {
  max-width: 100%;
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img.img-stay {
  position: absolute;
  right: 0;
  -webkit-animation-name: slideimgsAnimation-right;
          animation-name: slideimgsAnimation-right;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}

@keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    z-index: 10;
  }
  50% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    right: -140%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

body.rtl .about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img.img-stay {
  left: 0;
  right: auto;
  -webkit-animation-name: slideimgsAnimation-left;
          animation-name: slideimgsAnimation-left;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
}

@keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    z-index: 10;
  }
  50% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    left: -140%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
}

.about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img.img-move {
  position: absolute;
  right: 0;
  z-index: 5;
  -webkit-animation-name: slideimgsAnimation-right;
          animation-name: slideimgsAnimation-right;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

@keyframes slideimgsAnimation-right {
  0% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    right: -140%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    z-index: 44;
  }
}

body.rtl .about-us-wrapper .about-us-content .our-stations-wrapper .content > div .our-stations-slider img.img-move {
  left: 0;
  right: auto;
  -webkit-animation-name: slideimgsAnimation-left;
          animation-name: slideimgsAnimation-left;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

@keyframes slideimgsAnimation-left {
  0% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    left: -140%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    z-index: 44;
  }
}

@media only screen and (max-width: 991px) {
  .about-us-wrapper .about-us-content .our-stations-wrapper {
    height: 80vh;
    background-position: center;
  }
  .about-us-wrapper .about-us-content .our-stations-wrapper .content > div {
    width: 100% !important;
  }
  .about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type h2 {
    font-size: 32px;
    font-weight: 600;
  }
  .about-us-wrapper .about-us-content .our-stations-wrapper .content > div:first-of-type h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33;
    width: 85%;
  }
  .about-us-wrapper .about-us-content .our-stations-wrapper .content > div:last-of-type {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .about-us-wrapper .about-us-content .our-stations-wrapper {
    height: 90vh !important;
  }
}

.about-us-wrapper .about-us-content .our-partners-wrapper {
  height: 50vh;
  min-height: 450px;
  padding-top: 40px;
  overflow: hidden;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content h2 {
  display: flex;
  align-items: center;
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #242430;
  position: relative;
  padding: 0 50px;
}

@media only screen and (max-width: 991px) {
  .about-us-wrapper .about-us-content .our-partners-wrapper .content h2 {
    font-size: 32px;
    font-weight: 600;
  }
  .about-us-wrapper .about-us-content .our-partners-wrapper .content h2 p {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .about-us-wrapper .about-us-content .our-partners-wrapper .content h2:before {
    content: "";
    position: absolute;
    bottom: -20px;
    width: 33.333%;
    height: 3px;
    background-color: #16adb8;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 22;
  }
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content h2 p {
  margin-right: 15px;
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #16adb8;
}

body.rtl .about-us-wrapper .about-us-content .our-partners-wrapper .content h2 p {
  margin-left: 15px;
  margin-right: 0;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content h2:after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 3px;
  background-color: #efefef;
  left: 0;
  right: 0;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content h2:before {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 33.333%;
  height: 3px;
  background-color: #16adb8;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 22;
  display: none;
}

@media only screen and (max-width: 1023px) {
  .about-us-wrapper .about-us-content .our-partners-wrapper .content h2:before {
    display: block;
  }
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content .slider-wrapper {
  width: 100%;
  margin-top: 50px;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content .slider-wrapper .slick-list .slick-track > div {
  text-align: center;
  min-height: 250px;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-wrapper .about-us-content .our-partners-wrapper .content .slider-wrapper .slick-list .slick-track > div img {
  width: 100%;
  height: 100%;
  max-width: 200px;
}

.contact-section-wrapper .contact-holder:after {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
}

.contact-section-wrapper .contact-holder .infinty-info-holder {
  display: flex;
  position: relative;
  margin: 0 10%;
  top: -77px;
  top: 72px;
  z-index: 5;
}

@media only screen and (max-width: 1247px) {
  .contact-section-wrapper .contact-holder .infinty-info-holder {
    margin: 0 5%;
  }
}

@media only screen and (max-width: 767px) {
  .contact-section-wrapper .contact-holder .infinty-info-holder {
    margin: 0 1%;
  }
}

.contact-section-wrapper .contact-holder .infinty-info-holder > div {
  height: 144px;
  background-image: -webkit-linear-gradient(359deg, #16adb8 3%, #86d958 66%);
  background-image: linear-gradient(91deg, #16adb8 3%, #86d958 66%);
  flex: 1 1;
  width: 33.33333%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  justify-content: flex-start;
  padding: 30px;
}

.contact-section-wrapper .contact-holder .infinty-info-holder > div .icon-holder {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: 22px;
}

body.rtl .contact-section-wrapper .contact-holder .infinty-info-holder > div .icon-holder {
  margin-left: 22px;
  margin-right: 0;
}

.contact-section-wrapper .contact-holder .infinty-info-holder > div h4 {
  font-size: 19px;
  font-weight: 500;
  line-height: 1.14;
  text-align: left;
  color: #ffffff;
}

body.rtl .contact-section-wrapper .contact-holder .infinty-info-holder > div h4 {
  text-align: right;
}

.contact-section-wrapper .contact-holder .infinty-info-holder > div h4 a {
  color: #ffffff;
  font-weight: 500;
}

.contact-section-wrapper .contact-holder .infinty-social-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  display: none;
}

.contact-section-wrapper .contact-holder .infinty-social-holder .socia-holder {
  margin-top: 35px;
}

.contact-section-wrapper .contact-holder .infinty-social-holder .socia-holder > a {
  margin-right: 27px;
}

.contact-section-wrapper .contact-holder .infinty-social-holder .socia-holder > a:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 991px) {
  .contact-section-wrapper .contact-holder .infinty-info-holder {
    flex-direction: column;
    top: 30px;
  }
  .contact-section-wrapper .contact-holder .infinty-info-holder > div {
    width: 100%;
    margin-bottom: 18px;
    padding: 20px;
  }
  .contact-section-wrapper .contact-holder .infinty-info-holder > div h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.45px;
  }
}

.how-work-wrapper .how-work-content .how-video-wrapper {
  position: relative;
  margin-bottom: 50px;
}

.how-work-wrapper .how-work-content .how-video-wrapper video {
  object-fit: fill;
  height: auto !important;
}

@supports (object-fit: cover) {
  .how-work-wrapper .how-work-content .how-video-wrapper video {
    height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .how-work-wrapper .how-work-content .how-video-wrapper {
    height: 60vh;
    margin-bottom: 25px;
  }
  .how-work-wrapper .how-work-content .how-video-wrapper video {
    height: 100% !important;
  }
}

.how-work-wrapper .how-work-content .how-video-wrapper video[poster] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.how-work-wrapper .how-work-content .how-video-wrapper .play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}

.how-work-wrapper .how-work-content .how-video-wrapper .play.not-visible {
  visibility: hidden;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper {
  min-height: 100vh;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .steps-title {
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  margin-top: -2px;
  color: #242430;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  padding-bottom: 0 !important;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .steps-title span {
  color: #16adb8 !important;
  font-size: 51px;
  font-weight: 600;
  line-height: 1;
  padding: 0 20px;
}

@media only screen and (max-width: 991px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .steps-title span {
    font-size: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .steps-title {
    font-size: 40px;
  }
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps {
  width: 90%;
  margin: auto;
  padding: 25px 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 25px;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps > .centerd-slider {
  max-width: 650px;
}

@media only screen and (max-width: 639px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps {
    width: 90% !important;
    margin: unset !important;
  }
}

@media only screen and (max-width: 1300px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .slick-dots {
    top: -30px;
  }
}

@media only screen and (max-width: 1200px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .slick-dots {
    top: -90px;
  }
}

@media only screen and (max-width: 1100px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .slick-dots {
    top: -125px;
  }
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder {
  display: flex;
  align-items: center;
  width: 73%;
  margin: auto;
  margin-bottom: 20px;
  height: 100px;
  padding: 20px;
  box-sizing: content-box;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content {
  width: 74%;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.55;
  color: #16adb8;
}

@media only screen and (max-width: 575px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 {
    font-size: 19px;
  }
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 span {
  width: 32px;
  height: 32px;
  display: inline-flex;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.55;
  color: #242430;
  border-radius: 50%;
  margin-right: 16px;
}

body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content h5 span {
  margin-left: 16px;
  margin-right: 0;
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  margin-top: 10px;
}

@media only screen and (max-width: 639px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content p {
    margin-right: 20px !important;
  }
  body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder .content p {
    margin-left: 20px !important;
    margin-right: 0 !important;
  }
}

.how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder img {
  position: relative;
  top: -13px;
  width: 115px;
}

body.rtl .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder img {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

@media only screen and (max-width: 639px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder img {
    width: 90px !important;
    min-width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder {
    width: 100% !important;
  }
}

@media only screen and (max-width: 639px) {
  .how-work-wrapper .how-work-content .how-work-steps-wrapper .how-work-steps .step-holder {
    padding-left: 20px !important;
  }
}

.how-work-wrapper .contact-section-wrapper {
  margin-top: 0 !important;
}

.slide-right {
  opacity: 0;
  -webkit-transform: translateX(-50px);
          transform: translateX(-50px);
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-left {
  opacity: 0;
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-top {
  opacity: 0;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.slide-bottom {
  opacity: 0;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.rotate-y {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateY(90deg);
          transform: rotateY(90deg);
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

@-webkit-keyframes rotate-left {
  0% {
    right: -110%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes rotate-left {
  0% {
    right: -110%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@-webkit-keyframes rotate-left-rtl {
  0% {
    left: -110%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  100% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes rotate-left-rtl {
  0% {
    left: -110%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  100% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@-webkit-keyframes move-up {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(35%);
            transform: translateY(35%);
    opacity: 1;
  }
}

@keyframes move-up {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(35%);
            transform: translateY(35%);
    opacity: 1;
  }
}

@-webkit-keyframes flowingMove {
  from {
    top: 0;
  }
  to {
    top: 120px;
  }
}

@keyframes flowingMove {
  from {
    top: 0;
  }
  to {
    top: 120px;
  }
}

@-webkit-keyframes carMove {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0;
  }
}

@keyframes carMove {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0;
  }
}

@-webkit-keyframes carMoveRtl {
  from {
    margin-right: 100%;
  }
  to {
    margin-right: 0;
  }
}

@keyframes carMoveRtl {
  from {
    margin-right: 100%;
  }
  to {
    margin-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  @-webkit-keyframes carMoveRtl {
    from {
      margin-right: -100%;
    }
    to {
      margin-right: 0;
    }
  }
  @keyframes carMoveRtl {
    from {
      margin-right: -100%;
    }
    to {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 991px) {
  @-webkit-keyframes carMove {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0;
    }
  }
  @keyframes carMove {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0;
    }
  }
}

@-webkit-keyframes chargingMove {
  0% {
    left: -120%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  90% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes chargingMove {
  0% {
    left: -120%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  90% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    left: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@-webkit-keyframes chargingMoveRtl {
  0% {
    right: -120%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  90% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes chargingMoveRtl {
  0% {
    right: -120%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  90% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    right: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

.centerd-slider .carousel-slider {
  overflow: unset !important;
}

.centerd-slider .carousel-slider .control-dots {
  bottom: -35px !important;
}

.centerd-slider .carousel-slider .control-dots .dot {
  background: #16adb8 !important;
}

.centerd-slider .slick-track {
  top: 45px !important;
  height: 100%;
}

.centerd-slider .slick-active {
  opacity: 1 !important;
  position: relative;
  top: -30px;
  border-radius: 4px;
  height: 500px;
  overflow: hidden !important;
}

.centerd-slider .slick-active img {
  -webkit-transform: scaleY(1.25);
          transform: scaleY(1.25);
  width: 100%;
}

.centerd-slider .slick-slide {
  height: 100%;
  height: 500px;
  overflow: hidden visible;
}

.centerd-slider .slick-slide > div {
  opacity: 0.47;
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
}

.centerd-slider .slick-slide.slick-active > div {
  opacity: 1;
  -webkit-backdrop-filter: blur(0);
          backdrop-filter: blur(0);
}

.centerd-slider .slick-slide img {
  width: 100%;
}

.centerd-slider .slick-dots {
  position: relative;
  top: -18px;
}

.centerd-slider .slick-dots li button {
  width: 12px !important;
  height: 12px !important;
  background-color: #e4e4e4;
  border-radius: 50% !important;
}

.centerd-slider .slick-dots li.slick-active button {
  width: 12px !important;
  height: 12px !important;
  background-color: #16adb8 !important;
  border-radius: 50% !important;
}

.centerd-slider .slick-dots .slick-active {
  top: 0 !important;
}

@media only screen and (max-width: 1023px) {
  .centerd-slider {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .steps-wrapper {
    width: 100% !important;
  }
}

.gradient-fill {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(16%, #62c2c9), color-stop(115%, #1c9fa8)) !important;
  background-image: -webkit-linear-gradient(left, #62c2c9 16%, #1c9fa8 115%) !important;
  background-image: linear-gradient(to right, #62c2c9 16%, #1c9fa8 115%) !important;
}

.ev-box-wrapper {
  min-height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 50px 10px;
}

.ev-box-wrapper .ev-box-logo {
  margin-bottom: 40px;
  -webkit-transform: scale(0.65);
          transform: scale(0.65);
}

.ev-box-wrapper .start-img {
  position: absolute;
  left: 15px;
  top: 25px;
}

body.rtl .ev-box-wrapper .start-img {
  right: 15px;
  left: auto;
}

@media only screen and (max-width: 991px) {
  .ev-box-wrapper .start-img {
    opacity: 0.3;
  }
}

.ev-box-wrapper .end-img {
  position: absolute;
  right: 0;
  top: 0;
}

body.rtl .ev-box-wrapper .end-img {
  left: 0;
  right: auto;
}

.ev-box-wrapper p {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.81;
  text-align: center;
  color: #242430;
  width: 60%;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .ev-box-wrapper p {
    width: 75%;
  }
}

@media only screen and (max-width: 639px) {
  .ev-box-wrapper p {
    width: 95%;
  }
  .ev-box-wrapper p .start-img {
    display: none;
  }
}

.station-search-wrapper {
  background-color: #fff;
  /******************************* shared component ****************************/
}

.station-search-wrapper .grid-wrapper {
  position: relative;
  min-height: 500px;
}

.station-search-wrapper .grid-wrapper .tiles-grid-wrapper {
  padding: 30px;
}

.station-search-wrapper .grid-wrapper .tiles-grid-wrapper .tiles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 18px;
}

@media only screen and (max-width: 575px) {
  .station-search-wrapper .grid-wrapper .tiles-grid-wrapper {
    padding: 30px 10px !important;
  }
  .station-search-wrapper .grid-wrapper .tiles-grid-wrapper .tiles-grid {
    grid-template-columns: 1fr;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper {
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper {
    padding: 30px 10px;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder {
  position: relative;
  min-height: 500px;
  height: 85vh;
  background-color: #fff;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .map-holder > div > div {
  width: 96% !important;
  height: 96% !important;
  margin: auto !important;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .map-holder > div > div > div {
  height: 100% !important;
  bottom: 0 !important;
  right: 0 !important;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

@media only screen and (max-width: 639px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider {
    bottom: -35px !important;
  }
}

@media only screen and (max-width: 575px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider {
    bottom: -44px !important;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .slick-track > .slick-slide > div {
  margin-left: 10px;
  margin-right: 10px;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-detailes {
  width: 57%;
}

@media only screen and (max-width: 399px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-detailes {
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 379px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-detailes {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-detailes {
    width: 100% !important;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-img-holder {
  width: 43%;
}

@media only screen and (max-width: 575px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-img-holder img {
    display: none !important;
  }
}

@media only screen and (max-width: 379px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-img-holder {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-img-holder {
    min-width: unset !important;
    max-width: unset !important;
  }
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-card-holder {
  width: 100%;
  max-width: 410px !important;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .station-card-holder .station-detailes .station-detailes-holder > div h6 {
  white-space: nowrap;
}

.station-search-wrapper .grid-wrapper .map-grid-wrapper .map-content-holder .stations-slider .slick-track {
  min-width: 460px !important;
}

.station-search-wrapper .station-more-detailes-wrpper {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  margin: 100px 30px 25px 30px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 5;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0);
          transform: scale(0);
}

@media only screen and (max-width: 767px) {
  .station-search-wrapper .station-more-detailes-wrpper {
    margin: 45px 4px 25px 4px !important;
  }
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .station-more-detailes-wrpper {
    margin: 18px 4px 25px 4px !important;
    z-index: 99;
  }
}

.station-search-wrapper .station-more-detailes-wrpper.active {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.station-search-wrapper .station-more-detailes-wrpper .content {
  height: 100%;
  padding: 20px;
  padding-bottom: 15px;
  position: relative;
}

.station-search-wrapper .station-more-detailes-wrpper .content > .anticon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #c62f26;
  font-size: 22px;
  cursor: pointer;
  z-index: 22;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header {
  display: flex;
  height: 170px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .img-holder {
  width: 185px;
  height: 170px;
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .station-search-wrapper .station-more-detailes-wrpper .content > header .img-holder {
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .station-more-detailes-wrpper .content > header .img-holder {
    display: none;
  }
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder {
  padding-right: 8%;
  border-bottom: 1px solid #c3c3c3;
  flex: 1 1;
}

@media only screen and (max-width: 767px) {
  .station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder {
    padding-right: 5px !important;
    border-bottom: none !important;
  }
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.77px;
  color: #000000;
  margin-bottom: 15px;
  padding-top: 8px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div h6 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.58px;
  color: #575757;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div h6 img {
  margin-right: 5px;
}

body.rtl .station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div h6 img {
  margin-left: 5px;
  margin-right: 0;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div > a,
.station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div address {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.58px;
  color: #000000;
  padding-left: 5px;
}

@media only screen and (max-width: 767px) {
  .station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div > a,
  .station-search-wrapper .station-more-detailes-wrpper .content > header .station-info-holder > div address {
    font-size: 10px;
  }
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  position: relative;
  border-bottom: 1px solid #c3c3c3;
  flex: 1 1;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  width: 1px;
  height: 90%;
  background-color: #c3c3c3;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div h6 {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.58px;
  color: #16adb8;
  min-width: 120px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div h6 img {
  margin-right: 3px;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div p {
  display: flex;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.58px;
  text-align: left;
  color: #000000;
}

.station-search-wrapper .station-more-detailes-wrpper .content > header .chargers-info-holder > div p span {
  padding-left: 6px;
  padding-right: 4px;
  font-weight: 500;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper {
  height: calc(100% - 170px);
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper header h3 {
  font-size: 22px;
  font-weight: 500;
  color: #393939;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper header button {
  width: 128px;
  height: 39px;
  border-radius: 21px;
  background-image: -webkit-linear-gradient(1deg, #16adb8 1%, #86d958 100%);
  background-image: linear-gradient(89deg, #16adb8 1%, #86d958 100%);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.58px;
  color: #ffffff;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper .map-holder {
  height: calc(100% - 80px);
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper .map-holder > div {
  height: 100%;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper .map-holder > div > div {
  position: relative !important;
}

.station-search-wrapper .station-more-detailes-wrpper .content .map-wrapper .map-holder > div > div > div {
  height: 100% !important;
}

.station-search-wrapper .grid-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 70px;
  margin: 60px 30px 0px 30px;
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .grid-header {
    display: block !important;
    height: auto !important;
    margin: 60px 10px 0px 10px;
  }
}

.station-search-wrapper .grid-header > div:first-of-type {
  display: flex;
  flex-direction: column;
}

.station-search-wrapper .grid-header > div:first-of-type h4 {
  font-size: 19px;
  font-weight: 500;
  color: #393939;
}

.station-search-wrapper .grid-header > div:first-of-type p {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  color: #808080;
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .grid-header > div:first-of-type {
    padding-bottom: 15px !important;
  }
}

.station-search-wrapper .grid-header > div:last-of-type {
  display: flex;
  height: 100%;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
}

.station-search-wrapper .grid-header > div:last-of-type button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.42;
  color: #808080;
  outline: none !important;
}

.station-search-wrapper .grid-header > div:last-of-type button:first-of-type {
  border-right: 1px solid #e8e8e8;
}

body.rtl .station-search-wrapper .grid-header > div:last-of-type button:first-of-type {
  border-left: 1px solid #e8e8e8;
  border-right: none;
}

.station-search-wrapper .grid-header > div:last-of-type button .anticon {
  margin-right: 17px;
}

body.rtl .station-search-wrapper .grid-header > div:last-of-type button .anticon {
  margin-left: 17px;
  margin-right: 0;
}

.station-search-wrapper .grid-header > div:last-of-type button.active {
  color: #16adb8;
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .grid-header > div:last-of-type {
    width: 100% !important;
    height: 45px !important;
  }
  .station-search-wrapper .grid-header > div:last-of-type button {
    width: 50% !important;
  }
}

.station-search-wrapper .station-card-holder {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 13px 15px;
}

body.rtl .station-search-wrapper .station-card-holder {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .station-card-holder {
    padding: 10px !important;
  }
}

.station-search-wrapper .station-card-holder .station-img-holder img {
  width: 165px;
  height: 165px;
  object-fit: contain;
}

@media only screen and (max-width: 575px) {
  .station-search-wrapper .station-card-holder .station-img-holder {
    width: 130px;
    max-width: 130px;
    min-width: 130px;
  }
  .station-search-wrapper .station-card-holder .station-img-holder img {
    object-fit: cover;
  }
}

@media only screen and (max-width: 575px) {
  .station-search-wrapper .station-card-holder .station-img-holder {
    display: none !important;
  }
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .station-card-holder .station-img-holder {
    display: none !important;
  }
}

.station-search-wrapper .station-card-holder .station-detailes {
  padding-left: 12px;
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  flex-wrap: nowrap;
}

.station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 {
  margin-right: 6px;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.42px;
  color: #5e5e5e;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 {
    max-width: unset;
    white-space: normal;
    align-items: flex-start;
  }
}

.station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 img {
  margin-right: 6px;
}

body.rtl .station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 {
  margin-left: 6px;
  margin-right: 0;
}

body.rtl .station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div h6 img {
  margin-left: 8px;
  margin-right: 0;
}

.station-search-wrapper .station-card-holder .station-detailes .station-detailes-holder > div p {
  font-size: 15px;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  font-weight: 200;
  color: #16adb8;
}

.station-search-wrapper .station-card-holder .station-detailes button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 90px;
  height: 26px;
  border-radius: 21px;
  background-image: -webkit-linear-gradient(1deg, #16adb8 1%, #5db92c 100%);
  background-image: linear-gradient(89deg, #16adb8 1%, #5db92c 100%);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.58px;
  color: #ffffff;
  align-self: center;
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .station-card-holder .station-detailes button {
    width: 115px;
    height: 33px;
    font-size: 15px;
  }
}

body.rtl .station-search-wrapper .station-card-holder .station-detailes {
  padding-right: 12px;
  padding-left: 0;
}

.station-search-wrapper .station-card-holder h5 {
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: 0.64px;
  font-weight: 500;
  letter-spacing: 0.64px;
  color: #393939;
  margin-bottom: 6px;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

@media only screen and (max-width: 479px) {
  .station-search-wrapper .station-card-holder h5 {
    align-self: center;
  }
}

.search-header-wrapper {
  height: 25vh;
  max-height: 320px;
  margin-top: 70px;
  background: #fff;
  background-size: cover;
  background-position: center;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .search-header-wrapper {
    height: auto !important;
  }
}

.search-header-wrapper .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 35px;
  height: 100%;
  z-index: 5;
}

.search-header-wrapper .titles-holder {
  text-align: center;
}

.search-header-wrapper .titles-holder h1 {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.56;
  color: #242430;
}

@media only screen and (max-width: 767px) {
  .search-header-wrapper .titles-holder h1 {
    font-size: 35px !important;
  }
}

.search-header-wrapper .titles-holder p {
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.53;
  color: #242430;
}

@media only screen and (max-width: 767px) {
  .search-header-wrapper .titles-holder p {
    font-size: 25px !important;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 399px) {
  .search-header-wrapper .titles-holder p {
    font-size: 17px !important;
  }
}

.search-header-wrapper .search-input-holder {
  width: 90vw;
  max-width: 870px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 57px;
  border-radius: 53px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 14px 20px 14px 25px;
}

@media only screen and (max-width: 479px) {
  .search-header-wrapper .search-input-holder {
    max-width: 360px !important;
    height: 56px;
    padding: 14px 8px 14px 12px !important;
  }
}

@media only screen and (max-width: 360px) {
  .search-header-wrapper .search-input-holder {
    max-width: 310px !important;
    height: 40px;
    bottom: -24px;
  }
}

@media only screen and (max-width: 360px) {
  .search-header-wrapper .search-input-holder .ant-select-selection-selected-value {
    font-size: 15px !important;
  }
}

.search-header-wrapper .search-input-holder .select-city-holder {
  width: 140px;
}

.search-header-wrapper .search-input-holder .select-city-holder .ant-select-selection {
  padding-top: 0 !important;
  height: 33px;
  border-radius: 0 !important;
  color: #414141;
  font-size: 19px;
  font-weight: 400;
  border-bottom: 0 !important;
}

.search-header-wrapper .search-input-holder .select-city-holder .ant-select-selection .ant-select-arrow {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-top: 0;
}

.search-header-wrapper .search-input-holder .ant-input-group-compact {
  display: flex;
  align-items: center;
}

.search-header-wrapper .search-input-holder .ant-input {
  padding-top: 0 !important;
  height: 33px !important;
  border-bottom: 0 !important;
  border-left: 1px solid #ddd;
  padding-left: 36px !important;
  padding-right: 10px !important;
  background: url(/static/media/search.75d55897.svg) no-repeat;
  background-position: 12px 9px;
  width: 97%;
}

.search-header-wrapper .search-input-holder .ant-input + .ant-input-suffix {
  display: none;
}

@media only screen and (max-width: 991px) {
  .search-header-wrapper .search-input-holder .ant-input {
    border: 0 !important;
    border-bottom: 0 !important;
  }
}

body.rtl .search-header-wrapper .search-input-holder .ant-input {
  border-right: 1px solid #ddd;
  border-left: none;
  padding-right: 36px !important;
  padding-left: 10px !important;
  background-position-x: calc(100% - 12px);
}

.search-header-wrapper .search-input-holder .ant-input:-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.11px;
  text-align: left;
  color: #989898;
}

body.rtl .search-header-wrapper .search-input-holder .ant-input:-moz-placeholder {
  text-align: right;
}

.search-header-wrapper .search-input-holder .ant-input::-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.11px;
  text-align: left;
  color: #989898;
}

body.rtl .search-header-wrapper .search-input-holder .ant-input::-moz-placeholder {
  text-align: right;
}

.search-header-wrapper .search-input-holder .ant-input:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.11px;
  text-align: left;
  color: #989898;
}

body.rtl .search-header-wrapper .search-input-holder .ant-input:-ms-input-placeholder {
  text-align: right;
}

.search-header-wrapper .search-input-holder .ant-input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.11px;
  text-align: left;
  color: #989898;
}

body.rtl .search-header-wrapper .search-input-holder .ant-input::-webkit-input-placeholder {
  text-align: right;
}

.search-header-wrapper .search-input-holder .ant-input-search-button {
  width: 125px;
  height: 40px;
  border-radius: 53px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(16%, #62c2c9), color-stop(115%, #16adb8));
  background-image: -webkit-linear-gradient(left, #62c2c9 16%, #16adb8 115%);
  background-image: linear-gradient(to right, #62c2c9 16%, #16adb8 115%);
  font-size: 19px;
  font-weight: 400;
  color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3px;
}

@media only screen and (max-width: 767px) {
  .search-header-wrapper .search-input-holder .ant-input-search-button {
    width: 98px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 360px) {
  .search-header-wrapper .search-input-holder .ant-input-search-button {
    width: 71px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
}

.search-header-wrapper .search-input-holder .prefix-icon-holder {
  margin-right: 10px;
  height: 30px;
}

body.rtl .search-header-wrapper .search-input-holder .prefix-icon-holder {
  margin-left: 10px;
  margin-right: 0;
}

.request-card-wrapper * {
  font-family: Pridi;
}

.request-card-wrapper .search-header-wrapper {
  background: #fff;
  background-attachment: fixed;
  background-position: top;
  margin-top: 70px;
}

.request-card-wrapper > .content {
  padding: 6px 20% 20px 20%;
  margin-top: -30px;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .request-card-wrapper > .content {
    padding: 20px !important;
  }
}

.request-card-wrapper > .content .ask-card-sginin-holder {
  background-color: #ffffff;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 12px;
  margin-bottom: 22px;
  position: relative;
}

.request-card-wrapper > .content .ask-card-sginin-holder h4 {
  font-size: 21px;
  font-weight: normal;
  line-height: 1.19;
  color: #242430;
  margin-bottom: 30px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .request-card-wrapper > .content .ask-card-sginin-holder h4 {
    font-size: 15px !important;
    padding: 0 12px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
}

.request-card-wrapper > .content .ask-card-sginin-holder .anticon {
  position: absolute;
  right: 12px;
  top: 12px;
  color: #d83025;
  font-size: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .request-card-wrapper > .content .ask-card-sginin-holder .anticon {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .request-card-wrapper > .content .ask-card-sginin-holder .gradient-fill {
    min-height: 40px !important;
    font-size: 13px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper {
  background-color: #fff;
  padding: 20px 10%;
  padding-top: 30px;
}

@media only screen and (max-width: 479px) {
  .request-card-wrapper > .content .charging-card-form-wrapper {
    padding: 20px !important;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper h2 {
  font-size: 19px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  text-align: center;
}

@media only screen and (max-width: 479px) {
  .request-card-wrapper > .content .charging-card-form-wrapper h2 {
    font-size: 18px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper h4 {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  color: #242430;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 479px) {
  .request-card-wrapper > .content .charging-card-form-wrapper h4 {
    font-size: 18px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper .request-reject-reason {
  color: #d83025;
  font-weight: normal;
  text-align: center;
  margin-top: 25px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item {
  margin-bottom: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-calendar-picker {
  width: 100%;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  font-size: 20px;
  right: auto;
  left: 12px;
  display: flex;
  align-items: center;
  width: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 40px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item input {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper {
  border-bottom: 1px solid #dadce0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  margin-top: 2px;
  bottom: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon svg {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon:after {
  content: url(/static/media/gender.42321d49.svg);
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-form-explain {
  position: absolute;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  width: 100%;
  text-align: left;
  padding-left: 45px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  text-align: right;
  padding-right: 45px;
  padding-left: 0;
}

@media only screen and (max-width: 991px) {
  .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
    padding-left: 5px !important;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  top: 3.2px;
  left: 3.2px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  right: 3.2px;
  left: auto;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430;
  display: inline-flex;
  align-items: center;
  margin: 0 30px;
  margin-bottom: 4px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border: solid 0.8px #4e4d4d;
  width: 16px;
  height: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder {
  position: relative;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(1)::after {
  content: url(/static/media/car.262f767f.svg);
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  content: url(/static/media/cartype.100bb937.svg);
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(3)::after {
  content: url(/static/media/battery.bc5ce98e.svg);
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(4)::after {
  content: url(/static/media/license.3af11921.svg);
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .ant-form-extra {
  font-size: 14px;
  line-height: 2.07;
  color: #5d71aa;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-checkbox + span {
  font-weight: normal !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item {
  margin-bottom: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-calendar-picker {
  width: 100%;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  font-size: 20px;
  right: auto;
  left: 12px;
  display: flex;
  align-items: center;
  width: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
  left: 0 !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  right: 0 !important;
  width: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 28px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item input {
  padding-right: 28px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper {
  border-bottom: 1px solid #dadce0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  margin-top: 2px;
  bottom: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon svg {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon:after {
  content: url(/static/media/gender.42321d49.svg);
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-form-explain {
  position: absolute;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  width: 100%;
  text-align: left;
  padding-left: 45px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  text-align: right;
  padding-right: 45px;
  padding-left: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  top: 3.2px;
  left: 3.2px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  right: 2px;
  left: auto;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430;
  display: inline-flex;
  align-items: center;
  margin: 0 30px;
  margin-bottom: 4px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border: solid 0.8px #4e4d4d;
  width: 16px;
  height: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder + div .gradient-light-fill {
  min-height: 40px !important;
  font-size: 13px !important;
}

.user-profile-wrapper {
  background: #f5f5f5;
  min-height: calc(100vh);
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs-nav {
    display: flex !important;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper {
    height: 100vh;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.user-profile-wrapper .tabs_wrapper {
  padding: 32px 6%;
  padding-top: 100px;
}

.user-profile-wrapper .insufficient_balance_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #d83025;
  background-color: #ffffff;
  margin-right: 6px;
  margin-bottom: 17px;
  padding: 0px 5px;
}

body.rtl .user-profile-wrapper .insufficient_balance_holder {
  margin-right: unset;
  margin-left: 6px;
}

.user-profile-wrapper .insufficient_balance_holder .insufficientbalance_word {
  margin: 11px  25px;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
  color: #db4a41;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .insufficient_balance_holder .insufficientbalance_word {
    margin: 5px;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
  }
}

.user-profile-wrapper .user-profile-tabs {
  background: #f5f5f5;
  height: 100%;
  /*   .ant-tabs-tab {
      text-align: center !important;
      margin: 0 0 10px 0 !important;
      padding: 11px 24px !important;
      color: #4b4b4b !important;
      font-size: 19px;
      line-height: 0.68;
      font-family: Pridi;
      @include mq("phablet") {
        padding: 13px 24px !important;
        padding-bottom: 4px !important;
        font-size: 16px;
      }
    } */
}

body.rtl .user-profile-wrapper .user-profile-tabs .ant-tabs-bar {
  -webkit-transform: scaleX(-1) !important;
          transform: scaleX(-1) !important;
}

body.rtl .user-profile-wrapper .user-profile-tabs .ant-tabs-bar .ant-tabs-tab {
  -webkit-transform: scaleX(-1) !important;
          transform: scaleX(-1) !important;
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-tabpane-inactive {
  display: none !important;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .user-profile-tabs.insufficient .ant-tabs-content {
    height: calc(100vh - 310px) !important;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .user-profile-tabs {
    height: unset;
  }
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar {
  margin-right: 15px;
  background: white;
  border: none;
  width: 260px;
  max-width: 260px;
  padding: 22px 0;
}

body.rtl .user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar {
  margin-left: 15px !important;
  margin-right: 0 !important;
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  width: 100%;
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: center !important;
  margin: 0 0 10px 0 !important;
  padding: 11px 24px !important;
  color: #4b4b4b;
  font-size: 19px;
  line-height: 0.68;
  font-family: Pridi;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar .ant-tabs-tab {
    padding: 13px 24px !important;
    padding-bottom: 4px !important;
    font-size: 16px;
  }
  body.rtl .user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar .ant-tabs-tab {
    -webkit-transform: scaleX(-1) !important;
            transform: scaleX(-1) !important;
  }
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar > .ant-tabs-top-bar {
  background: white;
  border-bottom: none;
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar > .ant-tabs-content {
  background: white;
  border: none;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .user-profile-tabs .ant-tabs-left-bar > .ant-tabs-content {
    width: 90%;
    margin: auto !important;
    display: block !important;
    height: unset !important;
    overflow-y: unset !important;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .user-profile-tabs .ant-tabs-content-animated {
    flex-direction: column;
    display: flex !important;
  }
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-top-bar {
  background: white;
  border-bottom: none;
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-content {
  background: white;
  border: none;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .user-profile-tabs .ant-tabs-content {
    padding: 10px 0 !important;
    margin-left: 0px !important;
    height: calc(100vh - 210px) !important;
    overflow-y: auto !important;
  }
  body.rtl .user-profile-wrapper .user-profile-tabs .ant-tabs-content {
    margin-left: unset !important;
  }
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-nav {
  width: 100%;
}

.user-profile-wrapper .user-profile-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background-color: rgba(92, 174, 48, 0.16);
  color: black !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .user-profile-tabs .ant-tabs-nav .ant-tabs-tab-active {
    align-items: center;
    justify-content: center;
  }
  body.rtl .user-profile-wrapper .user-profile-tabs .ant-tabs-nav .ant-tabs-tab-active {
    direction: ltr;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .user-profile-tabs {
    padding: 0px !important;
  }
}

.user-profile-wrapper .personal-info-holder .border-bottom {
  border-bottom: 1px solid #dadce0;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked + span {
  color: #242430 !important;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner {
  width: 15px;
  height: 15px;
  border-color: #16adb8;
  border-radius: 50%;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner:after {
  background-color: #16adb8;
}

.user-profile-wrapper .personal-info-holder .ant-radio-inner::after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
}

body.rtl .user-profile-wrapper .personal-info-holder .ant-radio-inner::after {
  right: 2px;
  left: auto;
}

.user-profile-wrapper .personal-info-holder .form-holder {
  position: relative;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .personal-info-holder .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(1)::after {
  content: url(/static/media/user.de7f07ad.svg);
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(2)::after {
  content: url(/static/media/user1.de7f07ad.svg);
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(3)::after {
  content: url(/static/media/email.232050c3.svg);
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  content: url(/static/media/phone.b31f74ca.svg);
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  content: url(/static/media/gender.42321d49.svg);
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(6)::after {
  content: url(/static/media/address.35728844.svg);
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

@media only screen and (max-width: 399px) {
  .user-profile-wrapper .personal-info-holder .resp-m-0 {
    margin: 0 !important;
  }
  .user-profile-wrapper .personal-info-holder div:nth-child(2) {
    padding: 15px;
  }
}

@media only screen and (max-width: 379px) {
  .user-profile-wrapper .personal-info-holder div:nth-child(2) {
    padding: unset;
  }
}

.user-profile-wrapper ::-webkit-scrollbar-thumb {
  background: transparent !important;
}

.user-profile-wrapper .header {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  background: white;
  top: 0;
  z-index: 9;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .header {
    top: -15px;
  }
}

.user-profile-wrapper .car-info-tab {
  height: 100%;
}

.user-profile-wrapper .carInfo-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(1) {
  flex: 0 0 auto;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(2) {
  flex: 1 1 auto;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(3) {
  flex: 0 0 auto;
}

.user-profile-wrapper .carInfo-wrapper .ant-select-selection__rendered {
  margin-left: 30px;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .ant-select-selection__rendered {
  margin-left: 0 !important;
  margin-right: 30px;
}

.user-profile-wrapper .carInfo-wrapper .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .ant-input {
  padding-left: 0 !important;
  padding-right: 30px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
  position: relative;
  border-right: solid 0.5px #adadad;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
  border-right: none;
  border-left: solid 0.5px #adadad;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(1)::after {
  content: url(/static/media/car.262f767f.svg);
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  content: url(/static/media/cartype.100bb937.svg);
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3)::after {
  content: url(/static/media/battery.bc5ce98e.svg);
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(4)::after {
  content: url(/static/media/license.3af11921.svg);
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
    border: none !important;
    padding: 0 !important;
    margin-bottom: 20px;
  }
}

.user-profile-wrapper .carInfo-wrapper .box-holder {
  border-radius: 4px;
  border: solid 0.5px #adadad;
  margin: 16px 0;
}

.user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
  color: #5d71aa;
  margin-top: -5px;
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
    font-size: 14px !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .box-holder .ant-form-item {
  margin-bottom: 5px !important;
}

.user-profile-wrapper .carInfo-wrapper .box-holder .delete-box-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #d83025;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .resp-handel {
    padding-left: 0 !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
  border-radius: 0 !important;
  border: dashed 0.5px #707070 !important;
  width: 180px !important;
  position: relative;
}

@media only screen and (max-width: 1247px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 140px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 104px !important;
  }
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 140px !important;
  }
}

@media only screen and (max-width: 767px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 140px !important;
  }
}

@media only screen and (max-width: 399px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 115px !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card img {
  border-radius: 0 !important;
}

.user-profile-wrapper .border-shadow {
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.16);
}

.user-profile-wrapper .request-info {
  min-height: 50vh;
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .request-info .ant-table-wrapper {
    overflow: auto;
  }
  .user-profile-wrapper .request-info .ant-table-wrapper .ant-spin-nested-loading {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 991px;
    overflow: auto;
  }
}

.user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th,
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td {
  text-align: center;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td {
  border-left: none !important;
}

.user-profile-wrapper .request-info .inprogress-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 2px rgba(112, 112, 112, 0.17);
  margin: auto;
}

.user-profile-wrapper .request-info .ant-table-thead > tr > th {
  background: white !important;
  padding: 4px 0 11px;
}

.user-profile-wrapper .request-info .ant-table table {
  border: none !important;
  overflow: hidden;
}

.user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:last-of-type,
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:last-of-type {
  border-right: none !important;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:last-of-type, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:last-of-type {
  border-left: none !important;
  border-right: 1px solid #e8e8e8 !important;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:first-of-type, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:first-of-type {
  border-right: none !important;
}

.user-profile-wrapper .request-info .ant-table-tbody > tr:last-of-type > td {
  border-bottom: none !important;
}

.user-profile-wrapper .request-info .ant-table-content {
  border-radius: 4px;
  border: solid 0.5px #e8e8e8;
  padding: 12px;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
  content: url(/static/media/clock.78bbd2d2.svg);
  position: absolute;
  top: -3px;
  left: 19px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
  right: 19px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 19px;
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 19px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
  content: url(/static/media/creditCard.d39aea50.svg);
  position: absolute;
  top: -3px;
  left: 19px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
  right: 19px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 19px;
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 19px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  content: url(/static/media/location.57668815.svg);
  position: absolute;
  top: -3px;
  left: 19px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  right: 19px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 19px;
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 19px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f5f5f5;
}

.user-profile-wrapper .request-info tr td:not(:nth-child(3)) {
  font-size: 16px;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.need-action {
  color: #16adb8;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.new {
  color: #1b6bba;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.in-progress {
  color: #98681b;
}

.user-profile-wrapper .request-info.no-requests .ant-table-wrapper {
  display: none;
}

.user-profile-wrapper .request-info.no-requests .empty-requests {
  display: block !important;
}

.user-profile-wrapper .request-info.no-requests .empty-requests h3 {
  color: #a3a3a3;
  font-family: Pridi;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .request-info.no-requests .empty-requests img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .request-info.no-requests .empty-requests div:nth-child(2) {
    padding: 13px !important;
  }
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .request-info .ant-table-pagination.ant-pagination {
    float: left;
    align-self: unset !important;
  }
}

.user-profile-wrapper .request-info .request-info-row-action {
  cursor: pointer;
}

.user-profile-wrapper .request-info .request-info-row-action span {
  height: 25px;
  width: 25px;
  border: 2px solid #70707021;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.user-profile-wrapper .request-info .request-info-row-action_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-profile-wrapper .request-info .request-info-row-action_wrapper .request-info-row-action {
  border: 1px solid grey;
  border-radius: 50%;
  padding: 5px;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
}

.user-profile-wrapper .request-info .ant-spin-container {
  display: flex;
  flex-direction: column;
}

.user-profile-wrapper .request-info .ant-spin-container .ant-table-pagination.ant-pagination {
  align-self: center;
}

.cursor {
  cursor: pointer;
}

.cancel-modal p {
  color: #383737;
  line-height: 1.2;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
}

.cancel-modal .anticon {
  color: #d83025;
}

.cancel-modal .ant-modal-body {
  padding: 60px 24px 24px !important;
}

.changePassModal .ant-modal-header {
  border: none;
  text-align: center;
  padding-top: 30px;
}

.changePassModal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #383737;
}

.changePassModal .ant-modal-body {
  padding-top: 0 !important;
}

.changePassModal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 35px !important;
}

body.rtl .changePassModal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 35px !important;
  right: unset !important;
  top: 35px;
}

body.rtl .changePassModal .ant-input-password.ant-input-affix-wrapper + .ant-form-item-children-icon .anticon-close-circle svg {
  top: 18px;
}

.changePassModal .linear-green {
  background: -webkit-linear-gradient(179deg, #58d9ce 100%, #16adb8 0%) !important;
  background: linear-gradient(271deg, #58d9ce 100%, #16adb8 0%) !important;
}

.changePassModal .ant-form-item {
  margin-bottom: 5px !important;
}

.changePassModal .ant-modal-close-x {
  color: #d83025;
}

.changePassModal .form-holder {
  position: relative;
}

.changePassModal .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .changePassModal .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.changePassModal .form-holder .ant-form-item::after {
  content: url(/static/media/password.a3e7763a.svg);
  position: absolute;
  top: 25px;
}

.fixed-footer {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
}

@media only screen and (max-width: 575px) {
  .fixed-footer {
    bottom: -25px;
  }
}

@media only screen and (max-width: 575px) {
  .fixed-footer {
    justify-content: center !important;
  }
}

.ant-table-fixed-left {
  left: 12px !important;
  top: 12px !important;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
  padding-right: 0 !important;
}

.contact-us-wrapper .contactus-form-wrapper {
  height: calc(100vh - 70px);
  margin-top: 140px;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper {
    padding: 0 !important;
    margin-top: 0;
    height: 108vh;
    min-height: 770px;
    background: url(/static/media/Contact-Us-Page.8c53d0fd.png) no-repeat;
    background-attachment: fixed;
    background-position: top;
    background-position: top;
    background-size: cover;
    position: relative;
  }
}

.contact-us-wrapper .contactus-form-wrapper .helpImg-holder {
  flex: 1 1;
  position: relative;
  text-align: center;
  height: 400px;
  margin-top: -80px;
}

.contact-us-wrapper .contactus-form-wrapper .helpImg-holder img {
  width: 100%;
  border-radius: 5px;
}

.contact-us-wrapper .contactus-form-wrapper .helpImg-holder .text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #f7f7f7;
  line-height: 1.24;
  font-size: 42px;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .helpImg-holder {
    display: none;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder {
  flex: 1 1;
  position: relative;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder {
    margin-top: 70px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-select-selection-selected-value {
    color: #FFF;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .title {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  color: #242430;
  text-align: center;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .title {
    color: white !important;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .sub-title {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  padding-top: 8px;
  display: none;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .sub-title {
    display: block !important;
  }
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder ::-webkit-input-placeholder {
    color: white !important;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder ::-moz-placeholder {
    color: white !important;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder ::-ms-input-placeholder {
    color: white !important;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder ::placeholder {
    color: white !important;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder button {
  border: none !important;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form {
  width: 80%;
  margin: auto;
  position: relative;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form {
    margin-top: 40px;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-input {
  padding-left: 30px !important;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-input {
    color: #FFF;
  }
}

body.rtl .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(1)::after {
  content: url(/static/media/user.de7f07ad.svg);
  position: absolute;
  top: 25px;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after, .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
  display: none;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(2)::after {
  content: url(/static/media/email.232050c3.svg);
  position: absolute;
  top: 25px;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after, .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
  display: none;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
  content: url(/static/media/phone.b31f74ca.svg);
  position: absolute;
  top: 25px;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after, .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
  display: none;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
  content: url(/static/media/request.d46c678d.svg);
  position: absolute;
  top: 25px;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after, .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
  display: none;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(1)::after {
    content: url(/static/media/user-white.4310a721.svg);
    position: absolute;
    top: 25px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
    display: none;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(2)::after {
    content: url(/static/media/mail-white.23acc7e7.svg);
    position: absolute;
    top: 25px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
    display: none;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(3)::after {
    content: url(/static/media/phone-white.b31f74ca.svg);
    position: absolute;
    top: 25px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
    display: none;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
    content: url(/static/media/request-white.7d64efee.svg);
    position: absolute;
    top: 25px;
  }
  .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .ant-form-item:nth-child(4)::after {
    display: none;
  }
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form textarea.ant-input {
  padding-left: 0 !important;
}

body.rtl .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form textarea.ant-input {
  padding-right: 0 !important;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .react-tel-input input[type=tel] {
  padding-left: 40px !important;
}

body.rtl .contact-us-wrapper .contactus-form-wrapper .form-holder .ant-form .react-tel-input input[type=tel] {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.contact-us-wrapper .contactus-form-wrapper .form-holder button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 128px;
  height: 39px;
  border-radius: 21px;
  background-image: -webkit-linear-gradient(1deg, #62c2c9 1%, #16adb8 100%);
  background-image: linear-gradient(89deg, #62c2c9 1%, #16adb8 100%);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.58px;
  color: #ffffff;
  margin: auto;
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contactus-form-wrapper .overlay-black {
    background: rgba(0, 0, 0, 0.47);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.contact-us-wrapper .contactus-form-wrapper .react-tel-input .selected-flag {
  padding: 0 !important;
}

.contact-us-wrapper .contactus-form-wrapper textarea {
  resize: none;
}

.contact-us-wrapper .contact-details-wrapper {
  height: 70vh;
}

@media only screen and (max-width: 767px) {
  .contact-us-wrapper .contact-details-wrapper {
    height: 100vh;
  }
  .contact-us-wrapper .contact-details-wrapper > div:first-child {
    padding-top: 250px;
  }
}

.contact-us-wrapper .contact-details-wrapper > div:first-child {
  height: 100%;
  position: relative;
}

.contact-us-wrapper .contact-details-wrapper > div:first-child > div:first-child {
  height: 100%;
  position: relative;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
}

.contact-us-wrapper .contact-details-wrapper > div:first-child > div:first-child > div > div {
  height: 100% !important;
}

.contact-us-wrapper .contact-details-wrapper .contact-us-info {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.29);
  background-color: #ffffff;
  padding: 42px 35px;
  max-width: 368px;
  min-width: 300px;
  position: absolute;
  top: 50px;
  left: 90px;
}

.contact-us-wrapper .contact-details-wrapper .contact-us-info p {
  font-size: 14px;
  line-height: 1.71;
  color: #404040;
}

.contact-us-wrapper .contact-details-wrapper .contact-us-info p.phone-number {
  direction: ltr;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
}

.contact-us-wrapper .contact-details-wrapper .contact-us-info h4 {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

@media only screen and (max-width: 767px) {
  .contact-us-wrapper .contact-details-wrapper .contact-us-info {
    top: 15px !important;
    left: 35px !important;
  }
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .contact-details-wrapper .contact-us-info {
    top: -33px !important;
    left: calc((100vw - 368px) / 2) !important;
  }
}

@media only screen and (max-width: 379px) {
  .contact-us-wrapper .contact-details-wrapper .contact-us-info {
    top: -33px !important;
    left: calc((100vw - 344px) / 2) !important;
    max-width: 344px !important;
    padding: 20px !important;
  }
  body.rtl .contact-us-wrapper .contact-details-wrapper .contact-us-info {
    left: auto;
    right: calc((100vw - 344px) / 2) !important;
  }
}

@media only screen and (max-width: 639px) {
  .contact-us-wrapper .tel-wrapper .react-tel-input .form-control {
    background: transparent;
    color: #FFF;
  }
}

.sessions-wrapper {
  background-color: #ffffff;
  padding: 20px 30px;
  padding-top: 0;
}

.sessions-wrapper .card-time {
  font-size: 14px;
  line-height: 1.43;
  color: #1c1c1c;
  margin-bottom: 10px;
}

.sessions-wrapper .tab-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 60px;
  background-color: #FFF;
  z-index: 2;
}

.sessions-wrapper .tab-header-wrapper h3 {
  font-size: 19px;
  font-weight: 600;
  line-height: 0.68;
  color: #000000;
}

.book-charger-wrapper .ant-modal-header {
  padding-left: 24px;
}

.book-charger-wrapper .ant-modal-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #008539;
}

.book-charger-wrapper .ant-modal-footer {
  padding-bottom: 35px;
}

.book-charger-wrapper .ant-modal-close svg {
  font-size: 25px;
}

.book-charger-wrapper .book-charger-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.book-charger-wrapper .book-charger-content time {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: normal;
  color: #525151;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.book-charger-wrapper .book-charger-content .ant-time-picker {
  width: 100%;
}

.book-charger-wrapper .book-charger-content .ant-time-picker .ant-time-picker-input {
  border: 0;
  border-bottom: 1px solid #dadce0;
  border-radius: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: #262626;
}

.book-charger-wrapper .book-charger-content .map-holder {
  height: 330px;
  overflow: hidden;
  border-radius: 10px;
}

.book-charger-wrapper .book-charger-content .map-holder > div > div {
  width: 95%;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 767px) {
  .book-charger-wrapper .book-charger-content .map-holder > div > div {
    width: 100% !important;
  }
}

.book-charger-wrapper .book-charger-content .map-holder input {
  position: absolute;
  top: 11px;
  left: 25px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
  padding: 5px 30px;
  width: 60%;
  height: 34px;
  z-index: 8;
  border: solid 0.5px #e2e2e2;
  background: #FFF url(/static/media/search.75d55897.svg) no-repeat;
  background-position: 7px;
}

.book-charger-wrapper .book-charger-content .map-holder input::-webkit-input-placeholder {
  color: #9c9c9c;
  font-size: 13px;
}

body.rtl .book-charger-wrapper .book-charger-content .map-holder input {
  right: 25px;
  left: auto;
  background-position: 98%;
}

.book-charger-wrapper .book-charger-content .map-holder .info-window-wrapper h5 {
  font-size: 10px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 7px;
}

.book-charger-wrapper .book-charger-content .map-holder .info-window-wrapper .address-holder {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 8px;
  margin-bottom: 4px;
}

.book-charger-wrapper .book-charger-content .map-holder .info-window-wrapper .address-holder address {
  font-size: 10px;
  font-weight: normal;
  color: #5d5d5d;
}

.book-charger-wrapper .book-charger-content .map-holder .info-window-wrapper button {
  width: 58px;
  height: 18px;
  border-radius: 9px;
  background-color: #008539;
  font-size: 11px;
  font-weight: normal;
  color: #ffffff;
}

.book-charger-wrapper .book-charger-content .map-holder .info-window-wrapper button:disabled {
  opacity: 0.3;
}

.book-charger-wrapper .book-charger-content .map-holder .info-window-wrapper .info-window__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.book-charger-wrapper .book-charger-content .map-holder .info-window-wrapper .info-window__footer p {
  font-size: 10px;
  line-height: 1.4;
  color: #008539;
  display: flex;
  align-items: center;
}

.book-charger-wrapper .book-charger-content .map-holder .info-window-wrapper .info-window__footer p.unavailable-alert {
  color: #d83025;
}

.book-charger-wrapper .book-charger-content .map-holder .gm-style-iw {
  width: 210px;
}

.sessions-wrapper__card--row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.sessions-wrapper__card {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.sessions-wrapper__card h6 {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: #363636;
  margin-bottom: 3px;
  opacity: 0.64;
}

.sessions-wrapper__card h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #01401c;
  display: flex;
}

.sessions-wrapper__card .card-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  flex: 1 1;
}

.sessions-wrapper__card .card-info:last-child {
  margin-bottom: 0;
}

.sessions-wrapper__card .card-price {
  width: 93px;
  height: 38px;
  background-color: #ffffff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.45px;
  color: #dc2f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 10px;
}

body.rtl .sessions-wrapper__card .card-price {
  border-top-right-radius: 0;
  border-top-left-radius: 10px;
}

.sessions-wrapper__card .card-info__time--hour {
  color: #008539;
}

.notifications-list-wrapper .notifications-list-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #f1f4f0;
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 2;
  box-shadow: 1px 2px 7px #5555552c;
}

body.rtl .notifications-list-wrapper .notifications-list-header {
  padding-right: 20px;
}

.notifications-list-wrapper .notifications-list-header h3 {
  color: #000000;
  font-weight: 600;
  font-size: 21px;
  -webkit-padding-start: 9px;
          padding-inline-start: 9px;
}

.notifications-list-wrapper .notifications-list-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 70px;
  width: 100%;
  height: 55px;
  padding-left: 20px;
  font-size: 17px;
  font-weight: 600;
  background: #fff;
  color: #16adb8;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  z-index: 5;
}

.notifications-list-wrapper .notifications-holder {
  height: calc(100vh - 56px);
  overflow: auto;
}

.notifications-list-wrapper .notifications-holder .notification-holder {
  display: flex;
  background-color: #fff;
  height: 101px;
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
  -webkit-padding-end: 20px;
          padding-inline-end: 20px;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}

.notifications-list-wrapper .notifications-holder .notification-holder.active {
  background-color: #f5faf2;
}

.notifications-list-wrapper .notifications-holder .notification-holder.active .notification-content p {
  font-weight: bold;
}

.notifications-list-wrapper .notifications-holder .notification-holder.is-date {
  height: 65px;
  cursor: default;
  align-items: center;
}

.notifications-list-wrapper .notifications-holder .notification-holder.is-last::after {
  display: none;
}

.notifications-list-wrapper .notifications-holder .notification-holder::after {
  content: '';
  display: block;
  position: absolute;
  background: #cfcfcf;
  height: 1px;
  width: calc(100% - 40px);
  margin: auto;
  bottom: 0;
  left: 20px;
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content {
  display: flex;
  align-items: flex-start;
  position: relative;
  top: 20px;
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.33;
}

@media only screen and (max-width: 991px) {
  .notifications-list-wrapper .notifications-holder .notification-holder .notification-content p {
    font-size: 13px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
  }
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  box-shadow: 0px 0px 3px 0px #7a7a7a3b;
}

.notifications-list-wrapper .notifications-holder .notification-holder time {
  position: absolute;
  bottom: 5px;
  left: 70px;
  color: #939191;
}

body.rtl .notifications-list-wrapper .notifications-holder .notification-holder time {
  left: 0;
  right: 70px;
}

.notifications-list-wrapper .notifications-holder .notification-holder h4 {
  font-size: 19px;
}

.notifications-list-wrapper .notifications-holder .lottie-spinner {
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}

.notifications-list-wrapper .notifications-holder .ant-empty {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 125px);
  width: 250px;
  height: 100px;
}

.web-notifications .ant-drawer-wrapper-body {
  overflow: hidden !important;
}

.notification-count {
  background: #db4a41;
  width: 27px;
  height: 27px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 25px;
}

.wallet-tab .wallet-header {
  background: #f2f3f7;
  padding: 19px 22px;
  border: 2px solid white;
}

.wallet-tab .wallet-header__title {
  font-size: 19px;
  font-weight: 600;
  line-height: 0.68;
  color: black;
}

.wallet-tab .wallet-balance {
  padding: 6px 0 22px 0;
  text-align: center;
}

.wallet-tab .wallet-balance__title {
  opacity: 0.37;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.18;
  color: #000000;
  margin-bottom: 9px;
}

.wallet-tab .wallet-balance__num {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.2;
  color: #000000;
  margin-bottom: 28px;
}

.wallet-tab .wallet-balane__button {
  background: transparent !important;
}

.wallet-tab .wallet-balane__button:hover {
  background: transparent !important;
  color: #62c2c9 !important;
}

.wallet-tab .wallet-balance__currency-symbol {
  color: #008539;
  display: contents;
}

.wallet-tab .wallet-header__subtitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.18;
  color: #01401c;
}

.wallet-tab .wallet-tabs {
  padding: 15px 53px;
}

.wallet-tab .wallet-tabs .wallet-tabs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-tab .wallet-tabs .wallet-tabs-header div {
  font-size: 20px;
}

.wallet-tab .wallet-tabs .wallet-tabs-header div.active {
  border-bottom: 1px solid #62c2c9;
}

.wallet-tab .wallet-tabs .ant-tabs .ant-tabs-bar {
  border-bottom: 1px solid #D1D1D1 !important;
}

body.rtl .wallet-tab .wallet-tabs .ant-tabs-top-bar {
  direction: ltr;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.wallet-tab .wallet-tabs .ant-tabs-nav {
  width: 100%;
}

.wallet-tab .wallet-tabs .ant-tabs-nav > div {
  width: 100%;
}

.wallet-tab .wallet-tabs .ant-tabs-nav .ant-tabs-tab {
  width: calc(100% / 3);
}

body.rtl .wallet-tab .wallet-tabs .ant-tabs-nav .ant-tabs-tab {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.wallet-tab .wallet-tabs .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  border-right: 1px solid #D1D1D1 !important;
}

body.rtl .wallet-tab .wallet-tabs .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  border-left: 1px solid #D1D1D1 !important;
  border-right: none !important;
}

.wallet-tab .wallet-tabs .ant-tabs-tab-active {
  color: #008539 !important;
  font-weight: bold !important;
  background: white !important;
}

.wallet-tab .wallet-tabs .ant-tabs-ink-bar {
  background-color: #008539 !important;
  height: 3px !important;
}

.wallet-tab .transactions {
  position: relative;
}

.wallet-tab .transactions .transaction-date {
  font-size: 14px;
  line-height: 1.43;
  color: #525151;
  padding: 17px 0 27px 0;
  -webkit-margin-start: -12px;
          margin-inline-start: -12px;
}

.wallet-tab .transactions .transaction-details {
  -webkit-padding-start: 37px;
          padding-inline-start: 37px;
  display: flex;
  justify-content: space-between;
}

.wallet-tab .transactions .transcations_empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wallet-tab .transactions .transcations_empty img {
  width: 250px;
}

.wallet-tab .transactions .transcations_empty p {
  margin-top: 20px;
  color: #01401c;
  font-size: 16px;
  font-weight: 600;
}

.wallet-tab .transactions .transaction-bank__card {
  font-size: 14px;
  color: #000000;
  line-height: 1.43;
  padding-bottom: 5px;
}

.wallet-tab .transactions .transaction-bank__card-details {
  font-size: 12px;
  color: #727272;
  line-height: 1.25;
}

.wallet-tab .transactions .transaction-bank__card-details-span {
  color: #008539;
  display: contents;
}

.wallet-tab .transactions .transaction-bank__card-num {
  color: #000000;
  display: contents;
}

.wallet-tab .transactions .transaction-value {
  display: flex;
  align-items: center;
}

.wallet-tab .transactions .transaction-value__bound {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  -webkit-padding-end: 10px;
          padding-inline-end: 10px;
}

.wallet-tab .transactions .transaction-bank_session-button {
  border-radius: 4px !important;
  min-height: 22px !important;
  font-size: 12px;
  padding: 0 10px;
  margin-top: 7px;
}

.wallet-tab .transactions .ant-timeline:not(:only-child) {
  border-bottom: 1px solid #C3C3C3;
}

.wallet-tab .transactions .ant-timeline:last-of-type {
  border-bottom: none !important;
}

.wallet-tab .transactions .ant-timeline-item {
  padding: 0 0 30px;
}

body.rtl .wallet-tab .transactions .ant-timeline-item-tail {
  border-right: none !important;
}

body.rtl .wallet-tab .transactions .ant-timeline-item-head-custom {
  margin-right: -33px;
}

body.rtl .wallet-tab .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated, body.rtl .wallet-tab .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: block !important;
}

body.rtl .wallet-tab .rtl .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content-animated {
  display: block !important;
}

.session-details-modal .ant-modal-header {
  border: none;
}

.session-details-modal .ant-modal-close-x {
  color: #c30707 !important;
}

.session-details-modal .anticon {
  font-size: 21px;
}

.session-details-modal .ant-modal-title {
  font-size: 17px;
  line-height: 1.18;
  color: #008539;
}

.session-details-modal .session-details-modal__footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.session-details-modal .session_submit {
  width: 40%;
  height: 43px;
}

.session-details-modal .ant-modal-centered .ant-modal {
  width: 50% !important;
  min-width: 600px !important;
}

@media only screen and (max-width: 575px) {
  .session-details-modal .ant-modal-centered .ant-modal {
    width: 100% !important;
    min-width: auto !important;
  }
}

@media only screen and (max-width: 575px) {
  .ant-tabs-tab {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 479px) {
  .ant-tabs-tab {
    font-size: 10px !important;
  }
}

.booking-tab {
  position: relative;
}

.booking-tab .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 60px;
  background-color: #FFF;
  z-index: 2;
}

.booking-tab .header__title {
  font-size: 19px;
  font-weight: 600;
  line-height: 0.68;
  color: #000000;
}

.booking-tab .booking-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
}

.booking-tab .booking-card__header-subtitle {
  font-size: 14px;
  line-height: 1.43;
  color: #1c1c1c;
}

.booking-tab .booking-card__content {
  border: solid 1px #f2f2f2;
  background-color: #f2f2f2;
  padding: 12px 11px 9px 19px;
  border-radius: 4px 4px 0 0;
  margin: 0 24px;
}

.booking-tab .booking-card__content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-tab .booking-card__content-row:not(:first-of-type) {
  padding-top: 18px;
}

.booking-tab .booking-card__content-row-has-two {
  justify-content: unset !important;
}

.booking-tab .content-element__lable {
  font-size: 14px;
  line-height: 1.43;
  color: #363636;
  opacity: 0.64;
  padding-bottom: 5px;
}

.booking-tab .content-element__value {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #01401c;
}

.booking-tab .content-element__value_red {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #db4a41;
}

.booking-tab .content-element__pound {
  background: white;
  padding: 10px 20px;
  border-radius: 0 6px 0 6px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 0.39px;
  color: #dc2f2f;
}

.booking-tab .booking-card__content-element-has-two {
  flex-basis: 45%;
}

body.rtl .booking-tab .booking-card__content-element-has-two {
  flex-basis: 48%;
}

.booking-tab .booking-card__footer {
  border: solid 1px #f2f2f2;
  background-color: #f2f2f2;
  padding: 12px 11px 9px 19px;
  border-radius: 0 0 4px 4px;
  margin: 2px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-tab .booking-card__footer-button {
  border-radius: 4px;
  border: solid 1px #afafaf;
  background-color: #ffffff;
  padding: 10px 25px;
  font-size: 13px;
  font-weight: 500;
  line-height: 0.85;
  color: #8f8e8e;
}

.booking-tab .booking-card__footer-button:focus {
  outline: none !important;
}

.booking-tab .no-booking {
  text-align: center;
  margin-bottom: 27px;
}

.booking-tab .no-booking .no-booking__note {
  font-size: 14px;
  color: #c0c0c0;
  line-height: 0.93;
}

.booking-tab .dashboard-wrapper {
  height: unset !important;
}

.booking-tab .booking-list .booking-list__subtitle {
  font-size: 17px;
  line-height: 0.76;
  color: #666666;
  padding: 15px 24px;
}

.booking-tab .booking-list header.grid-action-header {
  display: none;
}

.booking-tab .booking-list .ant-table-scroll .ant-table-thead tr > th div:after {
  display: none !important;
}

.booking-tab .booking-list .ant-table-content {
  border-top: 1px solid #D9D9D9;
}

.booking-tab .booking-list .ant-table-wrapper .ant-pagination {
  background: white !important;
}

.booking-tab .booking-list .ant-table-scroll .ant-table-body {
  height: auto !important;
  min-height: auto !important;
  max-height: auto !important;
}

.booking-tab .booking-list .ant-table-scroll .ant-table-tbody > tr > td {
  font-size: 15px;
  line-height: 1.2;
  color: #474747;
  font-weight: normal;
}

.booking-tab .booking-list .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.31 !important;
  color: #000000 !important;
}

.booking-tab .booking-list .ant-table-thead > tr > th {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.19 !important;
  color: #008539 !important;
  border-bottom: none !important;
  background-color: white !important;
}

.booking-tab .booking-list .normal {
  width: 12px;
  height: 12px;
  border: solid 2px #c0c0c0;
  border-radius: 50%;
  background: white;
  margin: auto;
}

.booking-tab .booking-list .had-note {
  width: 12px;
  height: 12px;
  background-color: #db4a41;
  border-radius: 50%;
  margin: auto;
}

.booking-tab .booking-list .more-icon-holder i {
  font-size: 18px !important;
}

.booking-tab .booking-list .ant-table-scroll .ant-table-thead tr > th:nth-child(2) {
  width: 6% !important;
}

.booking-tab .booking-list .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2) {
  width: 6% !important;
}

.booking-tab .booking-list .ant-table-scroll .ant-table-tbody > tr > td:nth-child(4) {
  width: 25% !important;
}

.booking-tab .booking-list .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div {
  justify-content: flex-end;
}

.cancel-booking .ant-modal-close-x {
  color: #c30707;
}

.cancel-booking .anticon {
  width: 16.8px;
  height: 16.8px;
}

.cancel-booking .cancel-booking__content {
  text-align: center;
}

.cancel-booking .cancel-booking__question {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.21;
  color: #000000;
  padding-bottom: 20px;
  padding-top: 30px;
}

.cancel-booking .cancel-booking__question span {
  color: #008539;
}

.cancel-booking .cancel-booking__note {
  font-size: 15px;
  line-height: 1.6;
  color: #000000;
  max-width: 50%;
  margin: auto;
}

.cancel-booking .ant-modal {
  min-width: 616px;
}

@media only screen and (max-width: 639px) {
  .cancel-booking .ant-modal {
    width: 95% !important;
    min-width: 95% !important;
  }
}

.cancel-booking .cancel-booking__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
}

.cancel-booking .cancel-booking__cancel {
  border-radius: 22px;
  border: solid 1px #707070;
  padding: 10px 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #707070;
  -webkit-margin-end: 30px;
          margin-inline-end: 30px;
  width: 178.7px;
  height: 43px;
}

.cancel-booking .cancel-booking__cancel:focus {
  outline: none !important;
}

.cancel-booking .cancel-booking__confirm {
  border-radius: 22px;
  background-color: #008539;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #ffffff;
  padding: 10px 26px;
  width: 178.7px;
  height: 43px;
}

.cancel-booking .cancel-booking__confirm:focus {
  outline: none !important;
}

.payment-modal .ant-modal-header {
  border: unset;
}

.payment-modal .ant-modal-header .ant-modal-title {
  margin: 2px 4px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: #177c71;
}

.payment-modal .ant-modal-content {
  border: unset;
}

.payment-modal .ant-modal-content .ant-modal-close-x {
  color: #c30707;
}

.payment-modal .ant-modal-body {
  border: unset;
  padding: 5px 20px;
}

.payment-modal .ant-modal-body div h4 {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
  color: #383838;
}

.payment-modal .ant-modal-body .response_payment_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.payment-modal .ant-modal-body .response_payment_modal img {
  width: 69px;
}

.payment-modal .ant-modal-body .response_payment_modal .payment_message {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
}

.payment-modal .ant-modal-body .response_payment_modal .amount_added_have p {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
  display: flex;
  align-items: center;
}

.payment-modal .ant-modal-body .response_payment_modal .amount_added_have p span {
  font-size: 20px;
  margin: 0px 2px;
  color: #2dbb54;
}

.payment-modal .ant-modal-footer {
  border: unset;
  display: flex;
  justify-content: center;
  padding: unset;
  padding-bottom: 30px;
}

.payment-modal .ant-modal-footer button {
  width: 165px;
  height: 43px;
  padding: 9px 46px 9px 46px;
  border-radius: 23px;
  background: -webkit-gradient(linear, left top, right top, color-stop(9%, #47b6b5), color-stop(91%, #177c71));
  background: -webkit-linear-gradient(left, #47b6b5 9%, #177c71 91%);
  background: linear-gradient(to right, #47b6b5 9%, #177c71 91%);
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .avatar-uploader .ant-upload.ant-upload-select-picture-card {
    margin-right: unset !important;
    margin-bottom: unset !important;
    margin: 0px 1px;
  }
}

.view_modal_icon {
  cursor: pointer;
  position: absolute;
  top: -43px;
  left: 8px;
  font-size: 18px;
}

body.rtl .view_modal_icon {
  right: 8px;
  left: unset;
}

.image-sizeExceeded {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: red;
}

.image-sizeExceeded .anticon {
  margin: 0px 10px;
  font-size: 20px;
}

